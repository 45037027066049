import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Divider,
  CircularProgress,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Description as DescriptionIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
  Sort as SortIcon,
  ViewModule as GridViewIcon,
  ViewList as ListViewIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

const PostRequirement = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const token = useAuthToken();
  
  // State declarations
  const [arts, setArts] = useState([]);
  const [filteredArts, setFilteredArts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [artToDelete, setArtToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  
  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  
  // Search and filter state
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState('ART_Name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [viewMode, setViewMode] = useState('grid');
  
  // Menu state
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  
  // Gigs data
  const [gigs, setGigs] = useState([]);

  // Industry options
  const industryOptions = [
    { value: 'Healthcare', label: 'Healthcare' },
    { value: 'Banking and Financial', label: 'Banking and Financial' },
    { value: 'Services', label: 'Services' },
    { value: 'Technology', label: 'Technology' },
    { value: 'Manufacturing', label: 'Manufacturing' },
    { value: 'Retail', label: 'Retail' },
  ];

  // Skills options
  const skillsOptions = [
    { value: 'html', label: 'HTML' },
    { value: 'css', label: 'CSS' },
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'ReactJS', label: 'ReactJS' },
    { value: 'AngularJS', label: 'AngularJS' },
    { value: 'Vue', label: 'Vue' },
    { value: 'Node.js', label: 'Node.js' },
    { value: 'Python', label: 'Python' },
    { value: 'Java', label: 'Java' },
    { value: 'PHP', label: 'PHP' },
    { value: 'SQL', label: 'SQL' },
    { value: 'AWS', label: 'AWS' },
    { value: 'Azure', label: 'Azure' },
  ];

  // Timezone options
  const timezoneOptions = [
    { value: 'Indian Standard time', label: 'Indian Standard Time (IST)' },
    { value: 'Eastern standard time', label: 'Eastern Standard Time (EST)' },
    { value: 'Central standard time', label: 'Central Standard Time (CST)' },
    { value: 'Mountain standard time', label: 'Mountain Standard Time (MST)' },
    { value: 'Pacific standard time', label: 'Pacific Standard Time (PST)' },
  ];

  // Work hours options
  const workHoursOptions = [
    { value: 'Morning Shift, 9am - 6pm', label: 'Morning Shift, 9am - 6pm' },
    { value: 'Night Shift, 10pm - 7am', label: 'Night Shift, 10pm - 7am' },
  ];

  // Availability options
  const availabilityOptions = [
    { value: 'full time', label: 'Full Time' },
    { value: 'part time', label: 'Part Time' },
  ];

  // Budget range options
  const budgetOptions = [
    { value: '100k - 115k', label: '$100k - $115k' },
    { value: '115k - 150k', label: '$115k - $150k' },
  ];

  // Form state
  const [formData, setFormData] = useState({
    ART_id: '',
    ART_Name: '',
    ART_Description: '',
    Industry: '',
    Primary_Skills: '',
    Secondary_Skills: '',
    Time_Zone: '',
    Preferred_Workhours: '',
    Availability: '',
    Budget_Range: '',
    Number_Of_Pods: '',
    Manager_Id: user?.data?.[0]?.emp_id || '',
    Organizational_Code: user?.data?.[0]?.client_code || '',
    Gigs_id: ''
  });
  
  const [viewArt, setViewArt] = useState(null);
  
  // Fetch ARTs and gigs on component mount
  useEffect(() => {
    fetchArts();
    fetchGigs();
  }, [user?.data, token]);
  
  // Apply filtering and sorting
  useEffect(() => {
    let result = [...arts];
    
    // Apply search
    if (searchQuery) {
      const lowerQuery = searchQuery.toLowerCase();
      result = result.filter(art => 
        (art.ART_Name && art.ART_Name.toLowerCase().includes(lowerQuery)) || 
        (art.ART_Description && art.ART_Description.toLowerCase().includes(lowerQuery))
      );
    }
    
    // Apply sorting
    result.sort((a, b) => {
      let fieldA = (a[sortField] || '').toString().toLowerCase();
      let fieldB = (b[sortField] || '').toString().toLowerCase();
      
      if (sortDirection === 'asc') {
        return fieldA.localeCompare(fieldB);
      } else {
        return fieldB.localeCompare(fieldA);
      }
    });
    
    setFilteredArts(result);
    setPage(0); // Reset to first page when filters change
  }, [arts, searchQuery, sortField, sortDirection]);
  
  // Fetch ARTs function
  const fetchArts = async () => {
    if (!user?.data?.[0]?.client_code || !token) return;
    
    setLoading(true);
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Date': new Date().toUTCString()
      };
      
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHGIGSLIST, 
        {
          Organization_Code: user.data[0].client_code,
        },
        { headers }
      );
      
      if (response.data?.data) {
        const artData = Array.isArray(response.data.data) ? response.data.data : [];
        setArts(artData);
        setFilteredArts(artData);
      }
    } catch (error) {
      console.error('Error fetching ARTs:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load ARTs. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch gigs for dropdown
  const fetchGigs = async () => {
    if (!user?.data?.[0]?.client_code || !token) return;
    
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Date': new Date().toUTCString()
      };
      
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHGIG, 
        {
          Organization_Code: user.data[0].client_code,
        },
        { headers }
      );
      
      if (response.data.body.statuscode === 200) {
        setGigs(response.data.body.body || []);
      }
    } catch (error) {
      console.error('Error fetching gigs:', error);
    }
  };
  
  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  // Handle create ART
  const handleCreateART = async () => {
    if (!formData.ART_Name || !formData.ART_Description || !formData.Gigs_id) {
      setSnackbar({
        open: true,
        message: 'Please fill all required fields.',
        severity: 'warning'
      });
      return;
    }
    
    setLoading(true);
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Date': new Date().toUTCString()
      };
      
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_POSTAGIG,
        {
          Industry: formData.Industry || null,
          Primary_Skills: formData.Primary_Skills || null,
          Secondary_Skills: formData.Secondary_Skills || null,
          Time_Zone: formData.Time_Zone || null,
          Preferred_Workhours: formData.Preferred_Workhours || null,
          Availability: formData.Availability || null,
          Budget_Range: formData.Budget_Range || null,
          Number_Of_Pods: formData.Number_Of_Pods || null,
          Manager_Id: user.data[0].emp_id || null,
          ART_Name: formData.ART_Name,
          ART_Description: formData.ART_Description,
          Organizational_Code: user.data[0].client_code,
          Gigs_id: formData.Gigs_id
        },
        { headers }
      );
      
      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: 'ART created successfully!',
          severity: 'success'
        });
        setCreateDialogOpen(false);
        resetFormData();
        fetchArts(); // Refresh ART list
      } else {
        throw new Error('Failed to create ART');
      }
    } catch (error) {
      console.error('Error creating ART:', error);
      setSnackbar({
        open: true,
        message: 'Failed to create ART. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Handle edit ART
  const handleEditART = async () => {
    if (!formData.ART_Name || !formData.ART_Description || !formData.ART_id) {
      setSnackbar({
        open: true,
        message: 'Please fill all required fields.',
        severity: 'warning'
      });
      return;
    }
    
    setLoading(true);
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Date': new Date().toUTCString()
      };
      
      const response = await axios.put(
        'https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/EditArt',
        {
          ART_id: formData.ART_id,
          Industry: formData.Industry || null,
          Primary_Skills: formData.Primary_Skills || null,
          Secondary_Skills: formData.Secondary_Skills || null,
          Time_Zone: formData.Time_Zone || null,
          Preferred_Workhours: formData.Preferred_Workhours || null,
          Availability: formData.Availability || null,
          Budget_Range: formData.Budget_Range || null,
          Number_Of_Pods: formData.Number_Of_Pods || null,
          Manager_Id: user.data[0].emp_id || null,
          ART_Name: formData.ART_Name,
          ART_Description: formData.ART_Description,
          Organizational_Code: user.data[0].client_code,
          Gigs_id: formData.Gigs_id
        },
        { headers }
      );
      
      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: 'ART updated successfully!',
          severity: 'success'
        });
        setEditDialogOpen(false);
        resetFormData();
        fetchArts(); // Refresh ART list
      } else {
        throw new Error('Failed to update ART');
      }
    } catch (error) {
      console.error('Error updating ART:', error);
      setSnackbar({
        open: true,
        message: `Failed to update ART: ${error.response?.data?.message || error.message}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle delete ART
  const handleDeleteART = async () => {
    if (!artToDelete || !artToDelete.ART_id) {
      setSnackbar({
        open: true,
        message: 'Invalid ART selected for deletion.',
        severity: 'error'
      });
      return;
    }
    
    setLoading(true);
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Date': new Date().toUTCString()
      };
      
      const response = await axios.post(
        'https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/deleteArt',
        {
          ART_id: artToDelete.ART_id || artToDelete.id
        },
        { headers }
      );
      
      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: 'ART deleted successfully!',
          severity: 'success'
        });
        setDeleteDialogOpen(false);
        setArtToDelete(null);
        fetchArts(); // Refresh ART list
      } else {
        throw new Error('Failed to delete ART');
      }
    } catch (error) {
      console.error('Error deleting ART:', error);
      setSnackbar({
        open: true,
        message: `Failed to delete ART: ${error.response?.data?.message || error.message}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Open delete confirmation dialog
  const openDeleteDialog = (art) => {
    setArtToDelete(art);
    setDeleteDialogOpen(true);
  };

  // Open edit dialog with ART data
  const openEditDialog = (art) => {
    setFormData({
      ART_id: art.ART_id || art.id,
      ART_Name: art.ART_Name,
      ART_Description: art.ART_Description,
      Industry: art.Industry || '',
      Primary_Skills: art.Primary_Skills || '',
      Secondary_Skills: art.Secondary_Skills || '',
      Time_Zone: art.Time_Zone || '',
      Preferred_Workhours: art.Preferred_Workhours || '',
      Availability: art.Availability || '',
      Budget_Range: art.Budget_Range || '',
      Number_Of_Pods: art.Number_Of_Pods || '',
      Manager_Id: art.Manager_Id || user.data[0].emp_id,
      Organizational_Code: user.data[0].client_code,
      Gigs_id: art.Gigs_id || ''
    });
    setEditDialogOpen(true);
  };
  
  // Open view dialog with ART data
  const openViewDialog = (art) => {
    setViewArt(art);
    setViewDialogOpen(true);
  };
  
  // Reset form data
  const resetFormData = () => {
    setFormData({
      ART_id: '',
      ART_Name: '',
      ART_Description: '',
      Industry: '',
      Primary_Skills: '',
      Secondary_Skills: '',
      Time_Zone: '',
      Preferred_Workhours: '',
      Availability: '',
      Budget_Range: '',
      Number_Of_Pods: '',
      Manager_Id: user?.data?.[0]?.emp_id || '',
      Organizational_Code: user?.data?.[0]?.client_code || '',
      Gigs_id: ''
    });
  };
  
  // Close dialogs
  const handleCloseDialogs = () => {
    setCreateDialogOpen(false);
    setEditDialogOpen(false);
    setViewDialogOpen(false);
    setDeleteDialogOpen(false);
    setArtToDelete(null);
    resetFormData();
  };
  
  // Handle close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false
    });
  };
  
  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  // Handle sort menu
  const handleSortMenuOpen = (event) => {
    setSortMenuAnchor(event.currentTarget);
  };
  
  const handleSortMenuClose = () => {
    setSortMenuAnchor(null);
  };
  
  const handleSort = (field) => {
    if (field === sortField) {
      // Toggle direction if same field
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // New field, set to ascending
      setSortField(field);
      setSortDirection('asc');
    }
    handleSortMenuClose();
  };
  
  // Get paginated data
  const getPaginatedData = () => {
    return filteredArts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };
  
  // Find gig name by ID
  const getGigNameById = (gigId) => {
    const gig = gigs.find(g => g.Gigs_id === gigId || g.id === gigId);
    return gig ? gig.Gig_Name : 'Unknown Gig';
  };
  
  // Render ART cards (grid view)
  const renderARTCards = () => {
    if (loading && arts.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      );
    }
    
    if (filteredArts.length === 0) {
      return (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="textSecondary">
            {searchQuery ? 'No ARTs match your search criteria.' : 'No ARTs found. Create your first ART by clicking the "Create New ART" button.'}
          </Typography>
        </Paper>
      );
    }
    
    return (
      <Grid container spacing={3}>
        {getPaginatedData().map((art) => (
          <Grid item xs={12} sm={6} md={4} key={art.ART_id || art.id}>
            <Card 
              elevation={3} 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 20px rgba(0,0,0,0.1)'
                }
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="h2" gutterBottom noWrap>
                  {art.ART_Name}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Gig: {getGigNameById(art.Gigs_id)}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="textSecondary" 
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {art.ART_Description}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                <Tooltip title="View Details">
                  <IconButton 
                    size="small" 
                    color="primary"
                    onClick={() => openViewDialog(art)}
                  >
                    <DescriptionIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton 
                    size="small" 
                    color="primary"
                    onClick={() => openEditDialog(art)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={parseInt(art.delete_check) === 1 ? "Delete" : "Cannot delete - associated with pods"}>
                  <span>
                    <IconButton 
                      size="small" 
                      color="error"
                      onClick={() => openDeleteDialog(art)}
                      disabled={parseInt(art.delete_check) === 0}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };
  
  // Render ART list (list view)
  const renderARTList = () => {
    if (loading && arts.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      );
    }
    
    if (filteredArts.length === 0) {
      return (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="textSecondary">
            {searchQuery ? 'No ARTs match your search criteria.' : 'No ARTs found. Create your first ART by clicking the "Create New ART" button.'}
          </Typography>
        </Paper>
      );
    }
    
    return (
      <Paper elevation={2}>
        <List>
          {getPaginatedData().map((art, index) => (
            <React.Fragment key={art.ART_id || art.id}>
              <ListItem 
                alignItems="flex-start"
                sx={{ 
                  py: 2,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
                secondaryAction={
                  <Box>
                    <Tooltip title="View Details">
                      <IconButton 
                        edge="end" 
                        onClick={() => openViewDialog(art)}
                        color="primary"
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton 
                        edge="end" 
                        onClick={() => openEditDialog(art)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={parseInt(art.delete_check) === 1 ? "Delete" : "Cannot delete - associated with pods"}>
                      <span>
                        <IconButton 
                          edge="end" 
                          onClick={() => openDeleteDialog(art)}
                          color="error"
                          disabled={parseInt(art.delete_check) === 0}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                }
              >
                <ListItemText
                  primary={
                    <Typography variant="h6" component="div">
                      {art.ART_Name}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        color="primary"
                        sx={{ display: 'block', mb: 0.5 }}
                      >
                        Gig: {getGigNameById(art.Gigs_id)}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          display: 'inline-block',
                          maxWidth: '80%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {art.ART_Description}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              {index < getPaginatedData().length - 1 && <Divider component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    );
  };
  
  return (
    <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          ART Management
        </Typography>
        <Box>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={fetchArts}
            sx={{ mr: 2 }}
          >
            Refresh
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setCreateDialogOpen(true)}
          >
            Create New ART
          </Button>
        </Box>
      </Box>
      
      {/* Toolbar with search and filters */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Search ARTs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                startIcon={<SortIcon />}
                onClick={handleSortMenuOpen}
                sx={{ mr: 2 }}
                size="small"
              >
                Sort By
              </Button>
              <Tooltip title="Toggle View">
                <IconButton 
                  onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
                  color="primary"
                  size="small"
                >
                  {viewMode === 'grid' ? <ListViewIcon /> : <GridViewIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Display ARTs based on view mode */}
      {viewMode === 'grid' ? renderARTCards() : renderARTList()}
      
      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <TablePagination
          component="div"
          count={filteredArts.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[9, 18, 36, 99]}
          labelRowsPerPage="ARTs per page:"
          showFirstButton
          showLastButton
        />
      </Box>
      
      {/* Create ART Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Create New ART</Typography>
            <IconButton onClick={handleCloseDialogs} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required margin="normal">
                  <InputLabel>Select Gig</InputLabel>
                  <Select
                    name="Gigs_id"
                    value={formData.Gigs_id}
                    onChange={handleInputChange}
                    label="Select Gig"
                  >
                    <MenuItem value="">
                      <em>Select a Gig</em>
                    </MenuItem>
                    {gigs.map((gig) => (
                      <MenuItem key={gig.Gigs_id || gig.id} value={gig.Gigs_id || gig.id}>
                        {gig.Gig_Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="ART_Name"
                  label="ART Name"
                  value={formData.ART_Name}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 50 }}
                  helperText={`${formData.ART_Name.length}/50`}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  rows={3}
                  name="ART_Description"
                  label="ART Description"
                  value={formData.ART_Description}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 200 }}
                  helperText={`${formData.ART_Description.length}/200`}
                />
              </Grid>
              
              {/* Additional fields commented out for brevity */}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleCreateART} 
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Create ART
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Edit ART Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Edit ART</Typography>
            <IconButton onClick={handleCloseDialogs} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth required margin="normal">
                  <InputLabel>Select Gig</InputLabel>
                  <Select
                    name="Gigs_id"
                    value={formData.Gigs_id}
                    onChange={handleInputChange}
                    label="Select Gig"
                  >
                    <MenuItem value="">
                      <em>Select a Gig</em>
                    </MenuItem>
                    {gigs.map((gig) => (
                      <MenuItem key={gig.Gigs_id || gig.id} value={gig.Gigs_id || gig.id}>
                        {gig.Gig_Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="ART_Name"
                  label="ART Name"
                  value={formData.ART_Name}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 50 }}
                  helperText={`${formData.ART_Name.length}/50`}
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  rows={3}
                  name="ART_Description"
                  label="ART Description"
                  value={formData.ART_Description}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 200 }}
                  helperText={`${formData.ART_Description.length}/200`}
                />
              </Grid>
              
              {/* Additional fields commented out for brevity */}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleEditART} 
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Update ART
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* View ART Dialog */}
      <Dialog
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">ART Details</Typography>
            <IconButton onClick={handleCloseDialogs} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {viewArt && (
            <List>
              <ListItem>
                <ListItemText 
                  primary="ART ID" 
                  secondary={viewArt.ART_id || viewArt.id} 
                  primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
              <Divider component="li" />
              
              <ListItem>
                <ListItemText 
                  primary="ART Name" 
                  secondary={viewArt.ART_Name} 
                  primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
              <Divider component="li" />
              
              <ListItem>
                <ListItemText 
                  primary="Associated Gig" 
                  secondary={getGigNameById(viewArt.Gigs_id)} 
                  primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
              <Divider component="li" />
              
              <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <ListItemText 
                  primary="ART Description" 
                  primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                  sx={{ mb: 1 }}
                />
                <Typography variant="body1" sx={{ width: '100%' }}>
                  {viewArt.ART_Description}
                </Typography>
              </ListItem>
              <Divider component="li" />
              
              {viewArt.Industry && (
                <>
                  <ListItem>
                    <ListItemText 
                      primary="Industry" 
                      secondary={viewArt.Industry} 
                      primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                      secondaryTypographyProps={{ variant: 'body1' }}
                    />
                  </ListItem>
                  <Divider component="li" />
                </>
              )}
              
              {/* Additional fields */}
              
              <ListItem>
                <ListItemText 
                  primary="Organization Code" 
                  secondary={viewArt.Organizational_Code} 
                  primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
            </List>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCloseDialogs}>Close</Button>
          <Button 
            variant="contained" 
            onClick={() => {
              handleCloseDialogs();
              if (viewArt) {
                openEditDialog(viewArt);
              }
            }}
          >
            Edit
          </Button>
          {viewArt && parseInt(viewArt.delete_check) === 1 && (
            <Button 
              variant="outlined" 
              color="error"
              onClick={() => {
                handleCloseDialogs();
                openDeleteDialog(viewArt);
              }}
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the ART "{artToDelete?.ART_Name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button 
            color="error" 
            variant="contained"
            onClick={handleDeleteART}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PostRequirement;