// FreelancerInvoice.jsx
import React, { useState } from 'react';
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Constants
const EXCHANGE_RATES = {
  USD: 1,
  EUR: 0.92,
  GBP: 0.79,
  INR: 83.12,
  AUD: 1.52,
  CAD: 1.35,
  SGD: 1.34,
  NZD: 1.64,
};

const CURRENCIES = {
  USD: { symbol: '$', name: 'US Dollar' },
  EUR: { symbol: '€', name: 'Euro' },
  GBP: { symbol: '£', name: 'British Pound' },
  INR: { symbol: '₹', name: 'Indian Rupee' },
  AUD: { symbol: 'A$', name: 'Australian Dollar' },
  CAD: { symbol: 'C$', name: 'Canadian Dollar' },
  SGD: { symbol: 'S$', name: 'Singapore Dollar' },
  NZD: { symbol: 'NZ$', name: 'New Zealand Dollar' },
};

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  boxShadow: theme.shadows[2],
}));

const FreelancerInvoice = () => {
  // State
  const [invoiceData, setInvoiceData] = useState({
    clientName: '',
    projectName: '',
    hoursWorked: '',
    hourlyRate: '',
    selectedMonth: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
    localCurrency: 'USD',
  });

  const [generatedInvoice, setGeneratedInvoice] = useState(null);

  // Constants
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Handlers
  const handleInputChange = (field) => (event) => {
    setInvoiceData({
      ...invoiceData,
      [field]: event.target.value
    });
  };

  const calculateAmount = () => {
    const baseAmount = invoiceData.hoursWorked * invoiceData.hourlyRate;
    const exchangeRate = EXCHANGE_RATES[invoiceData.localCurrency];
    return baseAmount * exchangeRate;
  };

  const generateInvoice = () => {
    const totalAmount = calculateAmount();
    const currencySymbol = CURRENCIES[invoiceData.localCurrency].symbol;

    setGeneratedInvoice({
      ...invoiceData,
      totalAmount,
      formattedAmount: `${currencySymbol}${totalAmount.toFixed(2)}`,
      invoiceNumber: `INV-${Date.now().toString().slice(-6)}`,
      date: new Date().toLocaleDateString(),
    });
  };

  const isFormValid = () => {
    return (
      invoiceData.clientName &&
      invoiceData.projectName &&
      invoiceData.hoursWorked > 0 &&
      invoiceData.hourlyRate > 0
    );
  };

  // Component Render
  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom>
          Freelancer Invoice Generator
        </Typography>

        <StyledCard>
          <CardContent>
            <Grid container spacing={3}>
              {/* Client Information */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Client Name"
                  value={invoiceData.clientName}
                  onChange={handleInputChange('clientName')}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Project Name"
                  value={invoiceData.projectName}
                  onChange={handleInputChange('projectName')}
                />
              </Grid>

              {/* Time Period Selection */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    value={invoiceData.selectedMonth}
                    label="Month"
                    onChange={handleInputChange('selectedMonth')}
                  >
                    {months.map((month, index) => (
                      <MenuItem key={month} value={index}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={invoiceData.selectedYear}
                    label="Year"
                    onChange={handleInputChange('selectedYear')}
                  >
                    {[2023, 2024, 2025].map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Hours and Rate */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Hours Worked"
                  value={invoiceData.hoursWorked}
                  onChange={handleInputChange('hoursWorked')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Hourly Rate (USD)"
                  value={invoiceData.hourlyRate}
                  onChange={handleInputChange('hourlyRate')}
                />
              </Grid>

              {/* Currency Selection */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Local Currency</InputLabel>
                  <Select
                    value={invoiceData.localCurrency}
                    label="Local Currency"
                    onChange={handleInputChange('localCurrency')}
                  >
                    {Object.entries(CURRENCIES).map(([code, { name }]) => (
                      <MenuItem key={code} value={code}>
                        {code} - {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Generate Button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={generateInvoice}
                  disabled={!isFormValid()}
                >
                  Generate Invoice
                </Button>
              </Grid>
            </Grid>

            {/* Invoice Preview */}
            {generatedInvoice && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Invoice Preview
                </Typography>
                <Paper sx={{ p: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Invoice Number</Typography>
                      <Typography variant="body1">{generatedInvoice.invoiceNumber}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Date</Typography>
                      <Typography variant="body1">{generatedInvoice.date}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Description</TableCell>
                              <TableCell align="right">Hours</TableCell>
                              <TableCell align="right">Rate (USD)</TableCell>
                              <TableCell align="right">Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>{generatedInvoice.projectName}</TableCell>
                              <TableCell align="right">{generatedInvoice.hoursWorked}</TableCell>
                              <TableCell align="right">${generatedInvoice.hourlyRate}</TableCell>
                              <TableCell align="right">{generatedInvoice.formattedAmount}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={3} align="right">
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                  Total ({generatedInvoice.localCurrency})
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                  {generatedInvoice.formattedAmount}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            )}
          </CardContent>
        </StyledCard>
      </Box>
    </Container>
  );
};

export default FreelancerInvoice;