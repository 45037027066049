import React, { Component } from 'react';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  Stack,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  handleBackClick = () => {
    window.history.back();
  };

  handleHomeClick = () => {
    window.location.href = '/';
  };

  handleReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Container
          sx={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            py: 4,
          }}
        >
          <Paper
            elevation={3}
            sx={{
              maxWidth: 'sm',
              width: '100%',
              p: 4,
              textAlign: 'center',
            }}
          >
            <Box sx={{ mb: 3 }}>
              <ErrorOutlineIcon
                color="warning"
                sx={{ fontSize: 64 }}
              />
            </Box>

            <Typography variant="h4" component="h1" gutterBottom>
              Oops! Something went wrong
            </Typography>

            <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
              We apologize for the inconvenience. You can try going back to the
              previous page or return to the home page.
            </Typography>

            <Stack spacing={2}>
              <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={this.handleBackClick}
                fullWidth
              >
                Go Back
              </Button>

              <Button
                variant="outlined"
                onClick={this.handleHomeClick}
                fullWidth
              >
                Go to Home
              </Button>

              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={this.handleReload}
                fullWidth
              >
                Reload Page
              </Button>
            </Stack>

            {process.env.NODE_ENV === 'development' && this.state.error && (
              <Box sx={{ mt: 4, textAlign: 'left' }}>
                <Typography
                  variant="body2"
                  color="error"
                  fontFamily="monospace"
                  gutterBottom
                >
                  {this.state.error.toString()}
                </Typography>
                <Box
                  component="pre"
                  sx={{
                    mt: 2,
                    p: 2,
                    bgcolor: 'grey.100',
                    borderRadius: 1,
                    overflow: 'auto',
                    maxHeight: 200,
                    fontSize: '0.875rem',
                  }}
                >
                  {this.state.errorInfo.componentStack}
                </Box>
              </Box>
            )}
          </Paper>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;