import React, { useState, useEffect } from "react";
import {
  Box, Card, CardContent, Typography, Table, TableContainer, Paper, Button,
  MenuItem, Select, Pagination, IconButton, Checkbox, FormControl, InputLabel,
  OutlinedInput, Stack, Chip, Grid, Drawer, Dialog, DialogTitle, DialogContent,
  Menu, ListItemIcon, ListItemText, TextField, Snackbar, CircularProgress, Alert,
  TableHead, TableRow, TableCell, TableBody,Tooltip
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import { useHistory } from 'react-router-dom';
import moment from "moment";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  FilterList as FilterListIcon,
  Download as DownloadIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
  VideoCall as VideoCallIcon,
  DateRange as DateRangeIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  PictureAsPdf as PictureAsPdfIcon,
  TableRows as TableRowsIcon
} from "@mui/icons-material";
import { DateRangePicker } from "react-date-range";
import {



  Badge,
  Avatar,
  List,
  ListItem,
  Divider,
 
} from "@mui/material";
import { formatDistanceToNow } from 'date-fns';
import CommentIcon from "@mui/icons-material/Comment";
import CloseIcon from '@mui/icons-material/Close';
// Styled Components

const CommentDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '600px',
    maxHeight: '80vh',
  },
}));

const CommentListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  '& .MuiListItemText-root': {
    margin: 0,
  },
  '& .comment-header': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  '& .comment-metadata': {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
}));
const StyledCard = styled(Card)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
}));

const ContentWrapper = styled(Box)(({ theme, collapsed }) => ({
  marginLeft: collapsed ? "60px" : "240px",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    marginLeft: "1rem",
    padding: theme.spacing(1)
  }
}));

const LoaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '400px',
  gap: '16px'
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
  fontSize: "0.875rem"
}));

// Custom Hooks
const useVideoHandling = (token, isLoggedIn, history) => {
  const handleVideoClick = async (loomUrl) => {
    if (!isLoggedIn) {
      sessionStorage.setItem('redirectAfterLogin', window.location.pathname);
      history.push('/login');
      return;
    }

    try {
      const [filename] = loomUrl.split('?')[0].split('/').slice(-1);
      const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONTs3;
      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { url: cloudFrontUrl + filename },
        { headers: { Authorization: token, 'Content-Type': 'application/json' }}
      );

      const { signedUrl } = JSON.parse(response.data.body);
      if (!signedUrl) throw new Error('Signed URL not found');

      const state = btoa(JSON.stringify({
        url: signedUrl,
        exp: Date.now() + (5 * 60 * 1000),
        isAuth: true
      }));

      window.open(`/video-viewer/${state}`, '_blank', 'width=1280,height=720');
    } catch (error) {
      console.error('Error handling video:', error);
      alert('Error accessing video. Please try again.');
    }
  };

  return handleVideoClick;
};

// Components
const SortableTableHeader = ({ column, label, sortConfig, onSort }) => (
  <StyledTableCell>
    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', userSelect: 'none' }}
         onClick={() => onSort(column)}>
      {label}
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
        <ArrowUpwardIcon sx={{ 
          fontSize: '0.8rem',
          color: sortConfig.column === column && sortConfig.direction === 'asc' ? 'primary.main' : 'action.disabled'
        }} />
        <ArrowDownwardIcon sx={{ 
          fontSize: '0.8rem',
          marginTop: '-8px',
          color: sortConfig.column === column && sortConfig.direction === 'desc' ? 'primary.main' : 'action.disabled'
        }} />
      </Box>
    </Box>
  </StyledTableCell>
);


const CommentCell = ({ comments, activity_id, onCommentAdded }) => {
  const [open, setOpen] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = useAuthToken();
  const { user } = useSelector((state) => state.auth);
  
  // Parse comments string to array
  const parsedComments = comments ? JSON.parse(comments) : [];
  const commentCount = parsedComments.length;

  const handleAddComment = async () => {
    if (!newComment.trim() || !activity_id) return;
    
    setIsSubmitting(true);
    try {
      const commentObj = {
        id: parseInt(activity_id),
        user_id: parseInt(user.data[0].emp_id),
        new_text: newComment,
        is_reply: false,
        author: user.data[0].emp_firstname
      };

      const response = await axios.post(
        "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/addCommentpodactivity",
        [commentObj],
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          }
        }
      );

      if (response.status === 200) {
        const newComments = [
          ...parsedComments,
          {
            text: newComment,
            user_id: parseInt(user.data[0].emp_id),
            author: user.data[0].emp_firstname,
            timestamp: new Date().toISOString(),
            replies: []
          }
        ];
        
        if (onCommentAdded) {
          onCommentAdded(activity_id, JSON.stringify(newComments));
        }
        
        setNewComment("");
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Tooltip title={`${commentCount} comments`}>
        <IconButton 
          onClick={() => setOpen(true)}
          color={commentCount > 0 ? "primary" : "default"}
        >
          <Badge badgeContent={commentCount} color="primary">
            <CommentIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <CommentDialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Comments History</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {parsedComments.length > 0 ? (
            <List>
              {parsedComments.map((comment, index) => (
                <React.Fragment key={comment.comment_id || index}>
                  <CommentListItem>
                    <div className="comment-header">
                      <Box display="flex" alignItems="center" gap={1}>
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                          {comment.author ? comment.author[0].toUpperCase() : 'U'}
                        </Avatar>
                        <Typography variant="subtitle2">
                          {comment.author || 'User'}
                        </Typography>
                      </Box>
                      <Typography variant="caption" className="comment-metadata">
                        {formatDistanceToNow(new Date(comment.timestamp), { addSuffix: true })}
                      </Typography>
                    </div>
                    <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                      {comment.text}
                    </Typography>
                    {comment.replies && comment.replies.length > 0 && (
                      <Box ml={4} mt={1}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Replies:
                        </Typography>
                        {comment.replies.map((reply, replyIndex) => (
                          <Box key={replyIndex} mt={1} ml={2}>
                            <Typography variant="body2">{reply}</Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </CommentListItem>
                  {index < parsedComments.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Box p={3} textAlign="center">
              <Typography color="textSecondary">
                No comments yet
              </Typography>
            </Box>
          )}
          {/* Comment Form */}
          <Box sx={{ 
              mt: 2, 
              pt: 2, 
              borderTop: 1, 
              borderColor: 'divider',
              position: 'sticky',
              bottom: 0,
              bgcolor: 'background.paper'
            }}>
              <TextField
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                placeholder="Write a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                sx={{ mb: 1 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddComment}
                disabled={isSubmitting || !newComment.trim()}
                sx={{ float: 'right' }}
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'Add Comment'}
              </Button>
            </Box>
        </DialogContent>
      </CommentDialog>
    </>
  );
};

const EditableTableRow = ({
  activity,
  onSave,
  selectedIds,
  handleCheckboxChange,
  token,
  isUserLoggedIn,
  handleVideoClick,
  handleCommentAdded,
  intl
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [userStories, setUserStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Parse initial Jira issues from activity
  const parseJiraIssues = (issueString) => {
    if (!issueString) return [];
    return issueString.split(',').map(issue => {
      const [key] = issue.split(':');
      return key.trim();
    });
  };

  // Initialize editedData with parsed Jira issues
  const [editedData, setEditedData] = useState({
    jira_issue: parseJiraIssues(activity.jira_issue),
    today_focus: activity.today_focus || "",
    accomplishments: activity.accomplishments || "",
    blockers: activity.blockers || "",
    loom_url: activity.loom_url || ""
  });

  // Fetch user stories when editing starts
  useEffect(() => {
    const fetchUserStories = async () => {
      if (!isEditing) return;
      
      setIsLoading(true);
      try {
        const response = await fetch(
          'https://q0aippwzde.execute-api.us-east-1.amazonaws.com/prod/jira_issues_list_manager',
          {
            method: 'POST',
            headers: {
              Authorization: token,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              pod_id: activity.pod_id,
              c_id: activity.created_by
            })
          }
        );
        
        const data = await response.json();
        if (data.statusCode === 200 && data.body) {
          const parsedBody = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
          if (Array.isArray(parsedBody.jiraIssues)) {
            setUserStories(parsedBody.jiraIssues);
          }
        }
      } catch (error) {
        console.error('Error fetching user stories:', error);
        setSnackbar({
          open: true,
          message: "Failed to load Jira issues",
          severity: "error"
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserStories();
  }, [isEditing, activity.pod_id, activity.created_by, token]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });

  const JiraIssueCell = ({ issueString }) => {
    if (!issueString) return null;
  
    // Parse the issue strings (handles multiple issues separated by commas)
    const parseIssues = (issuesStr) => {
      return issuesStr.split(',').map(issue => {
        const firstColonIndex = issue.indexOf(':');
        if (firstColonIndex === -1) return { key: issue.trim(), url: '' };
        
        return {
          key: issue.substring(0, firstColonIndex).trim(),
          url: issue.substring(firstColonIndex + 1).trim()
        };
      });
    };
  
    const issues = parseIssues(issueString);
  
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
        {issues.map((issue, index) => (
          <Tooltip 
            key={index}
            title={`Click to open ${issue.key} in Jira`}
            arrow
            placement="top"
          >
            <Chip
              label={issue.key}
              size="small"
              clickable
              onClick={() => {
                if (issue.url) {
                  window.open(issue.url, '_blank', 'noopener,noreferrer');
                }
              }}
              sx={{
                backgroundColor: theme => theme.palette.mode === 'dark' ? '#0277BD' : '#2196F3',
                color: 'white',
                '&:hover': {
                  backgroundColor: theme => theme.palette.mode === 'dark' ? '#0288D1' : '#1E88E5',
                  cursor: 'pointer'
                },
                transition: 'background-color 0.2s ease'
              }}
            />
          </Tooltip>
        ))}
      </Box>
    );
  };

  const handleSave = async () => {
    try {
      // Format Jira issues back to string with URLs
      const formattedJiraIssue = editedData.jira_issue.map(issueKey => {
        const issue = userStories.find(story => story.issue_key === issueKey);
        return issue ? `${issueKey}:${issue.issue_url || ''}` : issueKey;
      }).join(',');

      const poddata = [{
        pod_candidates: activity.firstName,
        ...editedData,
        jira_issue: formattedJiraIssue,
        id: activity.id
      }];

      const response = await fetch(
        process.env.REACT_APP_TALENTLEAD_PODACTIVITYUPDATELEAD,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify({ poddata, status: 'draft' })
        }
      );

      if (!response.ok) throw new Error('Update failed');

      setSnackbar({
        open: true,
        message: "Successfully updated activity",
        severity: "success"
      });
      setIsEditing(false);
      if (onSave) onSave();
    } catch (error) {
      console.error("Error updating activity:", error);
      setSnackbar({
        open: true,
        message: "Failed to update activity",
        severity: "error"
      });
    }
  };

  const handleChange = (field) => (event) => {
    if (field === 'jira_issue') {
      setEditedData(prev => ({
        ...prev,
        [field]: event.target.value
      }));
    } else {
      setEditedData(prev => ({
        ...prev,
        [field]: event.target.value
      }));
    }
  };

  return (
    <>
      <TableRow sx={{
        '&:hover': { backgroundColor: 'action.hover' },
        backgroundColor: isEditing ? 'action.selected' : 
          activity.posted_to_client === 1 ? 'action.selected' : 'inherit'
      }}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedIds.includes(activity.id)}
            onChange={(e) => handleCheckboxChange(e, activity.id)}
            disabled={activity.posted_to_client === 1 || isEditing}
          />
        </TableCell>
        <TableCell>{activity.firstName}</TableCell>
        <TableCell>
          {isEditing ? (
            <FormControl fullWidth size="small">
              <Select
                multiple
                value={editedData.jira_issue}
                onChange={handleChange('jira_issue')}
                displayEmpty
                disabled={isLoading}
                renderValue={(selected) => {
                  if (selected.length === 0) return 'Select Jira Issues';
                  return selected.join(', ');
                }}
              >
                {userStories.map((story) => (
                  <MenuItem key={story.issue_key} value={story.issue_key}>
                    <Checkbox checked={editedData.jira_issue.indexOf(story.issue_key) > -1} />
                    <ListItemText 
                      primary={story.issue_key} 
                      secondary={story.issue_summary}
                      sx={{ whiteSpace: 'normal' }}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <JiraIssueCell issueString={activity.jira_issue} />
          )}
        </TableCell>
          {['today_focus', 'accomplishments', 'blockers'].map(field => (
          <TableCell key={field}>
            {isEditing ? (
              <TextField
                fullWidth
                multiline
                rows={2}
                value={editedData[field]}
                onChange={handleChange(field)}
                size="small"
              />
            ) : activity[field]}
          </TableCell>
        ))}
        <TableCell>
          {isEditing ? (
            <TextField
              fullWidth
              size="small"
              value={editedData.loom_url}
              onChange={handleChange('loom_url')}
              placeholder="Enter video URL"
            />
          ) : (
            activity.loom_url && isUserLoggedIn && (
              <Button
                variant="text"
                size="small"
                onClick={() => handleVideoClick(activity.loom_url)}
                startIcon={<VideoCallIcon />}
              >
                View Video
              </Button>
            )
          )}
        </TableCell>
        <TableCell>
  <CommentCell 
    comments={activity.comments || '[]'}
    activity_id={activity.id}
    onCommentAdded={handleCommentAdded}
  />
</TableCell>
        <TableCell>{moment(activity.createdAt).format('MM-DD-YYYY')}</TableCell>
        <TableCell>
          <Chip
            label={activity.posted_to_client === 1 
              ? intl.formatMessage({ id: "approved" })
              : intl.formatMessage({ id: "pending" })
            }
            color={activity.posted_to_client === 1 ? "success" : "warning"}
            size="small"
          />
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={1}>
            {!activity.posted_to_client && !isEditing && (
              <IconButton
                color="primary"
                size="small"
                onClick={() => setIsEditing(true)}
              >
                <EditIcon />
              </IconButton>
            )}
            {isEditing && (
              <>
                <IconButton color="success" size="small" onClick={handleSave}>
                  <SaveIcon />
                </IconButton>
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => {
                    setEditedData({
                      jira_issue: activity.jira_issue || "",
                      today_focus: activity.today_focus || "",
                      accomplishments: activity.accomplishments || "",
                      blockers: activity.blockers || "",
                      loom_url: activity.loom_url || ""
                    });
                    setIsEditing(false);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </>
            )}
          </Stack>
        </TableCell>
      </TableRow>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })} 
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

const PodActivity = ({ collapsed }) => {
  const [selectedPodId, setSelectedPodId] = useState([]);
  const [podDetails, setPodDetails] = useState([]);
  const [podActivities, setPodActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const token = useAuthToken();
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [downloadMenuAnchor, setDownloadMenuAnchor] = useState(null);
  const [userStories, setUserStories] = useState([]);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState("all");
  const history = useHistory();
  const handleVideoClick = useVideoHandling(token, isLoggedIn, history);
  
  const [dateRange, setDateRange] = useState([{
    startDate: moment().subtract(7, "days").toDate(),
    endDate: moment().toDate(),
    key: "selection"
  }]);

  const [sortConfig, setSortConfig] = useState({
    column: "createdAt",
    direction: "desc"
  });

  useEffect(() => {
    console.log("useEffect triggered", token, user); // Check when this runs
    if (token && user?.data?.length > 0) {
      fetchData();
    } else {
      console.log("Missing token or user data", { token, user });
    }
  }, [token, user]);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_CLIENT_GETCLIENTPOD,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            clientID: user.data[0].emp_id
          })
        }
      );
  
      const data = await response.json();
      setPodDetails(data.data.podDetails);
      const activities = data.data.activities.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      
      // If the API doesn't return comments, initialize with empty array
      const activitiesWithComments = activities.map(activity => ({
        ...activity,
        comments: activity.comments || '[]'
      }));
      
      console.log("Response received:", response.status);
      
      console.log("Data received:", data);
      setPodActivities(activitiesWithComments);
      setFilteredActivities(activitiesWithComments);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSort = (column) => {
    const newDirection = 
      sortConfig.column === column && sortConfig.direction === 'asc' 
        ? 'desc' 
        : 'asc';
  
    setSortConfig({ column, direction: newDirection });
  
    const sortedActivities = [...filteredActivities].sort((a, b) => {
      if (column === 'status') {
        const statusA = a.posted_to_client === 1 ? 'Approved' : 'Pending';
        const statusB = b.posted_to_client === 1 ? 'Approved' : 'Pending';
        return newDirection === 'asc' 
          ? statusA.localeCompare(statusB) 
          : statusB.localeCompare(statusA);
      }
  
      if (column === 'createdAt') {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return newDirection === 'asc' 
          ? dateA - dateB 
          : dateB - dateA;
      }
  
      return 0; // Default case: no sorting
    });
  
    setFilteredActivities(sortedActivities);
  };
  

  const handleApprove = async () => {
    if (selectedIds.length === 0) {
      alert(intl.formatMessage({ id: "selectItemsWarning" }));
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_PODACTIVITYPOSTLEAD, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          poddata: [{ id: selectedIds, manager_id: user.data[0].emp_id }],
          manager_id: user.data[0].emp_id
        })
      });

      if (response.ok) {
        alert(intl.formatMessage({ id: "approvalSuccess" }));
        fetchData();
        setSelectedIds([]);
      } else {
        throw new Error('Approval failed');
      }
    } catch (error) {
      console.error("Error:", error);
      alert(intl.formatMessage({ id: "approvalError" }));
    }
  };

  
  const handleDownload = async (format) => {
    const generateCSV = () => {
      let csvContent = "data:text/csv;charset=utf-8," +
        "First Name,Categories,Jira Issue,Today's Focus,Accomplishments,Blocker,Video URL,Posted Date,Status\n";

      filteredActivities.forEach((activity) => {
        const row = [
          activity.firstName,
          activity.categories,
          activity.jira_issue,
          activity.today_focus || "",
          activity.accomplishments || "",
          activity.blockers || "",
          activity.loom_url || "",
          moment(activity.createdAt).format("MM-DD-YYYY"),
          activity.posted_to_client === 1 ? "Approved" : "Pending"
        ].map(field => `"${field}"`).join(",") + "\n";
        csvContent += row;
      });

      const link = document.createElement("a");
      link.setAttribute("href", encodeURI(csvContent));
      link.setAttribute("download", "pod_activities.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const generatePDF = () => {
      if (filteredActivities.length > 0) {
        const doc = new jsPDF('landscape');
        const headers = [
          "First Name", "Categories", "Jira Issue", "Today's Focus",
          "Accomplishments", "Blocker", "Video URL", "Posted Date", "Status"
        ];
        
        const rows = filteredActivities.map((activity) => [
          activity.firstName,
          activity.categories,
          activity.jira_issue,
          activity.today_focus || "",
          activity.accomplishments || "",
          activity.blockers || "",
          activity.loom_url || "",
          moment(activity.createdAt).format("MM-DD-YYYY"),
          activity.posted_to_client === 1 ? "Approved" : "Pending"
        ]);

        doc.autoTable({
          head: [headers],
          body: rows,
          styles: { fontSize: 8, cellPadding: 3 },
          headStyles: { fillColor: [0, 180, 216] },
          margin: { top: 10 }
        });

        doc.save("pod_activities.pdf");
      }
    };

    format === "csv" ? generateCSV() : generatePDF();
    setDownloadMenuAnchor(null);
  };

  const stats = [
    {
      title: intl.formatMessage({ id: "totalActivities" }),
      value: filteredActivities.length,
      color: "#1976d2"
    },
    {
      title: intl.formatMessage({ id: "pendingApproval" }),
      value: filteredActivities.filter(a => !a.posted_to_client).length,
      color: "#ed6c02"
    },
    {
      title: intl.formatMessage({ id: "approved" }),
      value: filteredActivities.filter(a => a.posted_to_client === 1).length,
      color: "#2e7d32"
    }
  ];


 const isActivityInRange = (activity, range) => {
    if (!activity.createdAt || !range.startDate || !range.endDate) {
      return false;
    }

    const activityDate = new Date(activity.createdAt);
    const startDate = new Date(range.startDate);
    const endDate = new Date(range.endDate);

    activityDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    return activityDate >= startDate && activityDate <= endDate;
  };

 

  const handleDateRangeChange = (ranges) => {
    setDateRange([ranges.selection]);
    const activities = podActivities.filter((activity) => {
      const isInDateRange = isActivityInRange(activity, ranges.selection);
      const matchesMember =
        !selectedTeamMember || activity.firstName === selectedTeamMember;
      return isInDateRange && matchesMember;
    });
    setFilteredActivities(activities);
  };


  const handleStatusFilterChange = (event) => {
    const status = event.target.value;
    setStatusFilter(status);
    
    let results = [...podActivities];
    if (selectedPodId.length > 0) {
      results = results.filter((activity) =>
        selectedPodId.some(podId => podId.toString() === activity.pod_id.toString())
      );
    }
    
    if (selectedTeamMember) {
      results = results.filter(activity => activity.firstName === selectedTeamMember);
    }
    
    if (status !== "all") {
      results = results.filter(activity =>
        status === "approved" ? activity.posted_to_client === 1 : activity.posted_to_client === 0
      );
    }
    
    setFilteredActivities(results);
    setCurrentPage(1);
  };

  const handlePodSelect = (event) => {
    const selectedValues = event.target.value;
    setSelectedPodId(selectedValues);
    
    let filteredResult = podActivities;
    if (selectedValues.length > 0) {
      filteredResult = podActivities.filter((activity) =>
        selectedValues.some(podId => podId.toString() === activity.pod_id.toString())
      );
    }

    setFilteredActivities(filteredResult);
    setCurrentPage(1);
  };

  const handleTeamMemberSelect = (event) => {
    const selectedMember = event.target.value;
    setSelectedTeamMember(selectedMember);
    
    const activities = selectedMember
      ? podActivities.filter((activity) => activity.firstName === selectedMember)
      : podActivities;
    
    setFilteredActivities(activities);
    setCurrentPage(1);
  };

  const handleCheckboxChange = (event, id) => {
    setSelectedIds(prev => 
      event.target.checked 
        ? [...prev, id]
        : prev.filter(selectedId => selectedId !== id)
    );
  };
  const handleCommentAdded = async (activityId, newComments) => {
    setPodActivities(prevActivities => 
      prevActivities.map(activity => 
        activity.id === activityId 
          ? { ...activity, comments: newComments }
          : activity
      )
    );
    
    setFilteredActivities(prevActivities => 
      prevActivities.map(activity => 
        activity.id === activityId 
          ? { ...activity, comments: newComments }
          : activity
      )
    );
  };
  const rowsPerPage = 10;
  const totalPages = Math.ceil(filteredActivities.length / rowsPerPage);
  const paginatedActivities = filteredActivities.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

 

  return (
    <ContentWrapper collapsed={collapsed}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {intl.formatMessage({ id: "podActivity" })}
        </Typography>
        
        {isLoading ? (
          <LoaderWrapper>
            <CircularProgress size={60} />
            <Typography variant="h6" color="textSecondary">
              {intl.formatMessage({ id: "loadingData" })}
            </Typography>
          </LoaderWrapper>
        ) : (
          <>
            <Grid container spacing={3} sx={{ mb: 4 }}>
              {stats.map((stat, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <StyledCard>
                    <CardContent>
                      <Typography color="textSecondary" gutterBottom>
                        {stat.title}
                      </Typography>
                      <Typography variant="h4" sx={{ color: stat.color }}>
                        {stat.value}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>

            <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>{intl.formatMessage({ id: "selectPods" })}</InputLabel>
                <Select
                  multiple
                  value={selectedPodId}
                  onChange={handlePodSelect}
                  input={<OutlinedInput label={intl.formatMessage({ id: "selectPods" })} />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return intl.formatMessage({ id: "allPods" });
                    }
                    return podDetails
                      .filter((pod) => selected.includes(pod.id))
                      .map((pod) => pod.podName)
                      .join(", ");
                  }}
                >
                  {podDetails.map((pod) => (
                    <MenuItem key={pod.id} value={pod.id}>
                      <Checkbox checked={selectedPodId.indexOf(pod.id) > -1} />
                      <ListItemText primary={pod.podName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                variant="contained"
                startIcon={<FilterListIcon />}
                onClick={() => setShowFilters(!showFilters)}
              >
                {intl.formatMessage({ id: "filters" })}
              </Button>

              <Button
                variant="contained"
                startIcon={<DateRangeIcon />}
                onClick={() => setShowDateRangePicker(!showDateRangePicker)}
              >
                {intl.formatMessage({ id: "dateRange" })}
              </Button>

              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={(e) => setDownloadMenuAnchor(e.currentTarget)}
              >
                {intl.formatMessage({ id: "export" })}
              </Button>
            </Stack>

            <Menu
              anchorEl={downloadMenuAnchor}
              open={Boolean(downloadMenuAnchor)}
              onClose={() => setDownloadMenuAnchor(null)}
            >
              <MenuItem onClick={() => handleDownload("csv")}>
                <ListItemIcon>
                  <TableRowsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>CSV</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleDownload("pdf")}>
                <ListItemIcon>
                  <PictureAsPdfIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>PDF</ListItemText>
              </MenuItem>
            </Menu>

            <Dialog
          open={showDateRangePicker}
          onClose={() => setShowDateRangePicker(false)}
          maxWidth="md"
        >
          <DialogTitle>{intl.formatMessage({ id: "selectDateRange" })}</DialogTitle>
          <DialogContent>
            <DateRangePicker
              onChange={handleDateRangeChange}
             
              ranges={dateRange}
              months={2}
              direction="horizontal"
            />
          </DialogContent>
        </Dialog>


            <Drawer
              anchor="right"
              open={showFilters}
              onClose={() => setShowFilters(false)}
            >
              <Box sx={{ width: 300, p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  {intl.formatMessage({ id: "filters" })}
                </Typography>
                <Stack spacing={3}>
                  <FormControl fullWidth>
                    <InputLabel>{intl.formatMessage({ id: "teamMember" })}</InputLabel>
                    <Select
                      value={selectedTeamMember}
                      onChange={handleTeamMemberSelect}
                      input={<OutlinedInput label={intl.formatMessage({ id: "teamMember" })} />}
                    >
                      <MenuItem value="">
                        {intl.formatMessage({ id: "allMembers" })}
                      </MenuItem>
                      {[...new Set(podActivities.map((activity) => activity.firstName))].map(
                        (member) => (
                          <MenuItem key={member} value={member}>
                            {member}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel>{intl.formatMessage({ id: "status" })}</InputLabel>
                    <Select
                      value={statusFilter}
                      onChange={handleStatusFilterChange}
                      input={<OutlinedInput label={intl.formatMessage({ id: "status" })} />}
                    >
                      <MenuItem value="all">
                        {intl.formatMessage({ id: "allStatus" })}
                      </MenuItem>
                      <MenuItem value="pending">
                        {intl.formatMessage({ id: "pending" })}
                      </MenuItem>
                      <MenuItem value="approved">
                        {intl.formatMessage({ id: "approved" })}
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <Button
                    variant="outlined"
                    onClick={() => {
                      setStatusFilter("all");
                      setSelectedTeamMember("");
                      setFilteredActivities(podActivities);
                      setCurrentPage(1);
                    }}
                    fullWidth
                  >
                    {intl.formatMessage({ id: "clearFilters" })}
                  </Button>
                </Stack>
              </Box>
            </Drawer>

            <TableContainer component={Paper} sx={{ mt: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        onChange={(e) => {
                          const ids = e.target.checked
                            ? paginatedActivities
                                .filter(a => a.posted_to_client !== 1)
                                .map(a => a.id)
                            : [];
                          setSelectedIds(ids);
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>{intl.formatMessage({ id: "teamMember" })}</StyledTableCell>
                    <StyledTableCell>{intl.formatMessage({ id: "jiraIssue" })}</StyledTableCell>
                    <StyledTableCell>{intl.formatMessage({ id: "todaysFocus" })}</StyledTableCell>
                    <StyledTableCell>{intl.formatMessage({ id: "accomplishments" })}</StyledTableCell>
                    <StyledTableCell>{intl.formatMessage({ id: "blocker" })}</StyledTableCell>
                    <StyledTableCell>{intl.formatMessage({ id: "videourl" })}</StyledTableCell>
                    <StyledTableCell>{intl.formatMessage({ id: "comments" })}</StyledTableCell>
                    <SortableTableHeader
                      column="createdAt"
                      label={intl.formatMessage({ id: "postedDate" })}
                      sortConfig={sortConfig}
                      onSort={handleSort}
                    />
                    <SortableTableHeader
                      column="status"
                      label={intl.formatMessage({ id: "status" })}
                      sortConfig={sortConfig}
                      onSort={handleSort}
                    />
                    <StyledTableCell>{intl.formatMessage({ id: "actions" })}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedActivities.map((activity) => (
                    <EditableTableRow
                      key={activity.id}
                      activity={activity}
                      userStories={userStories}
                      onSave={fetchData}
                      selectedIds={selectedIds}
                      handleCheckboxChange={handleCheckboxChange}
                      token={token}
                      isUserLoggedIn={isLoggedIn}
                      handleVideoClick={handleVideoClick}
                      handleCommentAdded={handleCommentAdded} 
                      intl={intl}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, page) => setCurrentPage(page)}
                color="primary"
                shape="rounded"
              />
              <Button
                variant="contained"
                color="success"
                onClick={handleApprove}
                disabled={selectedIds.length === 0}
              >
                {intl.formatMessage({ id: "approve" })} ({selectedIds.length})
              </Button>
            </Box>
          </>
        )}
      </Box>
    </ContentWrapper>
  );
};

export default PodActivity;