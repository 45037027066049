import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import {
  Box,
  Paper,
  TextField,
  Button,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Stack,
  Grid,
  Typography,
  Alert,
  IconButton,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  Tooltip,
  CircularProgress,
  Autocomplete,
  Chip,
  FormHelperText,
  useTheme,
  alpha
} from '@mui/material';
import {
  Assignment as AssignmentIcon,
  CalendarToday as CalendarIcon,
  Category as CategoryIcon,
  Description as DescriptionIcon,
  BugReport as BugReportIcon,
  Save as SaveIcon,
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  Label as LabelIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import { useAuthToken } from "../TokenContext";
import TalentViewTask from './TalentViewTask';

import { useSelector } from "react-redux";
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`task-tab-${index}`}
      aria-labelledby={`task-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function TaskManagement() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const location = useLocation();
  const { podname, podid } = location.state || {};
  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();

  // Validate required props
  useEffect(() => {
    if (!podid || !user?.data?.[0]?.c_id || !token) {
      console.error('Missing required props:', { podid, user, token });
    }
  }, [podid, user, token]);

  // State Management
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    podTaskName: '',
    description: '',
    dueDate: '',
    category: '',
    jiraItems: [],
    isBlocker: false,
   
   
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [jiraIssues, setJiraIssues] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  // Constants
  const categories = [
    { value: 'Design', icon: '🎨', color: '#FF69B4' },
    { value: 'Coding', icon: '💻', color: '#4169E1' },
    { value: 'Testing', icon: '🔍', color: '#32CD32' },
    { value: 'Documentation', icon: '📝', color: '#FFD700' }
  ];

  const priorities = [
    { value: 'High', color: '#ff4444' },
    { value: 'Medium', color: '#ffbb33' },
    { value: 'Low', color: '#00C851' }
  ];

  // Validation
  const validateForm = () => {
    const newErrors = {};
    if (!formData.podTaskName.trim()) {
      newErrors.podTaskName = 'Task name is required';
    }
    if (!formData.description.trim()) {
      newErrors.description = 'Description is required';
    }
    if (!formData.dueDate) {
      newErrors.dueDate = 'Due date is required';
    } else {
      const selectedDate = new Date(formData.dueDate);
      const today = new Date();
      if (selectedDate < today) {
        newErrors.dueDate = 'Due date cannot be in the past';
      }
    }
    if (!formData.category) {
      newErrors.category = 'Category is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Event Handlers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user types
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleJiraItemsChange = (event, newValue) => {
    setFormData(prev => ({
      ...prev,
      jiraItems: newValue
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_TALENT_TALENTCREATEPODTASK,
        {
          project_key: podname,
          pod_task_name: formData.podTaskName,
          description: formData.description,
          due_date: formData.dueDate,
          candidate_id: user.data[0].c_id,
          Candidate_Name: user.data[0].firstName,
          issuetype: "story",
          summary: '',
          category: formData.category,
          pod_id: podid,
          issue_key: formData.jiraItems,
          blockercheck: formData.isBlocker ? 1 : 0,
        
        },
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data) {
        setSuccess(true);
        // Reset form
        setFormData({
          podTaskName: '',
          description: '',
          dueDate: '',
          category: '',
          jiraItems: [],
          isBlocker: false,
          
      
        });
        setTimeout(() => setSuccess(false), 3000);
      }
    } catch (error) {
      console.error('Error creating task:', error);
      setErrors(prev => ({
        ...prev,
        submit: error.response?.data?.message || 'Failed to create task. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };



  // Fetch JIRA issues
  useEffect(() => {
    const fetchJiraIssues = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_JIRAISSUESLISTTALENTS,
          {
            pod_id: podid,
            c_id: user.data[0].c_id
          },
          { 
            headers: { 
              Authorization: token,
              'Content-Type': 'application/json'
            } 
          }
        );

        if (response.data.statusCode === 200 && response.data.body) {
          const parsed = JSON.parse(response.data.body);
          setJiraIssues(parsed.jiraIssues || []);
        }
      } catch (error) {
        console.error('Error fetching JIRA issues:', error);
        setErrors(prev => ({
          ...prev,
          jira: 'Failed to fetch JIRA issues'
        }));
      }
    };

    if (podid && token) {
      fetchJiraIssues();
    }
  }, [podid, token, user.data]);

  const getInputStyle = (error) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: 1,
      backgroundColor: isDarkMode 
        ? alpha(theme.palette.background.paper, 0.1)
        : alpha(theme.palette.background.paper, 0.8),
      '&:hover': {
        backgroundColor: isDarkMode
          ? alpha(theme.palette.background.paper, 0.15)
          : alpha(theme.palette.background.paper, 0.9)
      },
      ...(error && {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main
        }
      })
    }
  });

  return (
    <Box sx={{ p: 3, width: 1200, mx: 'auto' }}>
      <Paper
        elevation={3}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          backgroundColor: isDarkMode
            ? alpha(theme.palette.background.paper, 0.8)
            : '#fff'
        }}
      >
        {/* Header */}
        <Box
          sx={{
            p: 3,
            background: isDarkMode
              ? `linear-gradient(45deg, ${alpha(theme.palette.primary.dark, 0.8)} 30%, ${alpha(theme.palette.primary.main, 0.8)} 90%)`
              : `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
            color: '#fff'
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Tooltip title="Back">
              <IconButton
                component={Link}
                to="/dashboard"
                sx={{ color: '#fff' }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Typography variant="h5" fontWeight="bold">
              Task Management
            </Typography>
          </Stack>
        </Box>

        {/* Tabs */}
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              textTransform: 'none',
              minHeight: 64
            }
          }}
        >
          <Tab label="Create Task" />
          <Tab label="View Tasks" />
        </Tabs>

        {/* Create Task Form */}
        <TabPanel value={activeTab} index={0}>
          <form onSubmit={handleSubmit}>
            {success && (
              <Alert
                severity="success"
                sx={{ mb: 3, borderRadius: 1 }}
              >
                Task created successfully!
              </Alert>
            )}

            {errors.submit && (
              <Alert
                severity="error"
                sx={{ mb: 3, borderRadius: 1 }}
              >
                {errors.submit}
              </Alert>
            )}

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  {/* Task Name */}
                  <TextField
                    label="Task Name"
                    required
                    fullWidth
                    name="podTaskName"
                    value={formData.podTaskName}
                    onChange={handleInputChange}
                    error={!!errors.podTaskName}
                    helperText={errors.podTaskName || `${formData.podTaskName.length}/50 characters`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AssignmentIcon color="primary" />
                        </InputAdornment>
                      )
                    }}
                    sx={getInputStyle(errors.podTaskName)}
                  />

                  {/* Description */}
                  <TextField
                    label="Description"
                    required
                    multiline
                    rows={4}
                    fullWidth
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    error={!!errors.description}
                    helperText={errors.description || `${formData.description.length}/100 characters`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DescriptionIcon color="primary" />
                        </InputAdornment>
                      )
                    }}
                    sx={getInputStyle(errors.description)}
                  />

                 
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  {/* Due Date */}
                  <TextField
                    label="Due Date"
                    type="date"
                    required
                    fullWidth
                    name="dueDate"
                    value={formData.dueDate}
                    onChange={handleInputChange}
                    error={!!errors.dueDate}
                    helperText={errors.dueDate}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarIcon color="primary" />
                        </InputAdornment>
                      )
                    }}
                    sx={getInputStyle(errors.dueDate)}
                  />

                  {/* Category */}
                  <FormControl fullWidth error={!!errors.category}>
                    <InputLabel>Category</InputLabel>
                    <Select
                      name="category"
                      value={formData.category}
                      onChange={handleInputChange}
                      label="Category"
                      required
                      startAdornment={
                        <InputAdornment position="start">
                          <CategoryIcon color="primary" />
                        </InputAdornment>
                      }
                      sx={getInputStyle(errors.category)}
                    >
                      {categories.map(category => (
                        <MenuItem key={category.value} value={category.value}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <span>{category.icon}</span>
                            <span>{category.value}</span>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.category && (
                      <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                        {errors.category}
                      </Typography>
                    )}
                  </FormControl>
{/* JIRA Issues */}
                  <FormControl fullWidth error={!!errors.jira}>
                    <Autocomplete
                      multiple
                      options={jiraIssues}
                      getOptionLabel={(option) => 
                        typeof option === 'string' ? option : option.issue_key
                      }
                      value={formData.jiraItems.map(item => 
                        jiraIssues.find(issue => issue.issue_key === item) || item
                      )}
                      onChange={(event, newValue) => {
                        setFormData(prev => ({
                          ...prev,
                          jiraItems: newValue.map(v => 
                            typeof v === 'string' ? v : v.issue_key
                          )
                        }));
                      }}
                      filterOptions={(options, { inputValue }) => {
                        return options.filter(option =>
                          option.issue_key.toLowerCase().includes(inputValue.toLowerCase()) ||
                          (option.summary && option.summary.toLowerCase().includes(inputValue.toLowerCase()))
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search JIRA issues..."
                          error={!!errors.jira}
                          sx={{
                            ...getInputStyle(errors.jira),
                            '& .MuiOutlinedInput-root': {
                              padding: '3px 8px'
                            }
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem 
                          {...props}
                          sx={{
                            py: 1,
                            px: 2,
                            '&:hover': {
                              backgroundColor: 'action.hover'
                            }
                          }}
                        >
                          <Box sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                          }}>
                            <Box sx={{ 
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1
                            }}>
                              <BugReportIcon 
                                sx={{ 
                                  color: theme.palette.primary.main,
                                  fontSize: 20
                                }} 
                              />
                              <Typography 
                                variant="body2"
                                sx={{ 
                                  color: theme.palette.text.primary,
                                  fontWeight: 500
                                }}
                              >
                                {option.issue_key}
                              </Typography>
                            </Box>
                            {option.issue_summary && (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ 
                                  ml: 4,
                                  mt: 0.5,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {option.issue_summary}
                              </Typography>
                            )}
                          </Box>
                        </MenuItem>
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option.issue_key}
                            label={
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <BugReportIcon sx={{ fontSize: 16 }} />
                                {option.issue_key}
                              </Box>
                            }
                            onDelete={getTagProps({ index }).onDelete}
                            sx={{
                              m: 0.5,
                              backgroundColor: theme.palette.primary.main,
                              color: '#fff',
                              '& .MuiChip-deleteIcon': {
                                color: '#fff',
                                '&:hover': {
                                  color: alpha('#fff', 0.7)
                                }
                              },
                              height: 28,
                              borderRadius: 1
                            }}
                          />
                        ))
                      }
                      ListboxProps={{
                        sx: {
                          maxHeight: 300,
                          '& .MuiAutocomplete-option': {
                            padding: 1
                          }
                        }
                      }}
                      sx={{
                        '& .MuiAutocomplete-tag': {
                          margin: 0.5
                        }
                      }}
                    />
                    {errors.jira && (
                      <FormHelperText error>{errors.jira}</FormHelperText>
                    )}
                  </FormControl>
                  {/* Blocker Checkbox */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isBlocker"
                        checked={formData.isBlocker}
                        onChange={(e) => setFormData(prev => ({
                          ...prev,
                          isBlocker: e.target.checked
                        }))}
                        color="error"
                      />
                    }
                    label={
                      <Typography color="error">
                        This task is a blocker
                      </Typography>
                    }
                  />
                </Stack>
              </Grid>
            </Grid>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
              sx={{
                mt: 4,
                py: 1.5,
                borderRadius: 1,
                background: isDarkMode
                  ? `linear-gradient(45deg, ${alpha(theme.palette.primary.dark, 0.8)} 30%, ${alpha(theme.palette.primary.main, 0.8)} 90%)`
                  : `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
                boxShadow: `0 3px 5px 2px ${alpha(theme.palette.primary.main, 0.3)}`,
                '&:hover': {
                  background: isDarkMode
                    ? `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.9)} 30%, ${alpha(theme.palette.primary.light, 0.9)} 90%)`
                    : `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`
                }
              }}
            >
              {loading ? 'Creating Task...' : 'Create Task'}
            </Button>
          </form>
        </TabPanel>

        {/* View Tasks Panel */}
        <TabPanel value={activeTab} index={1}>
          <TalentViewTask/>
        </TabPanel>
      </Paper>
    </Box>
  );
}