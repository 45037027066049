import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  Divider,
  Grid,
  Card,
  CardContent,
  Tooltip,
  Breadcrumbs,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Alert,
  AvatarGroup,
  Snackbar,
  Paper,
  Stack,
  LinearProgress,
  Fade // Add this
} from '@mui/material';
import EnhancedInviteModal from './EnhancedInviteModal';
// Icons - Add all required icons
import {
  Close as CloseIcon,
  PersonAdd as PersonAddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  People as PeopleIcon,
  GitHub as GitHubIcon,
  AddTask as AddTaskIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  CheckCircle as CheckCircleIcon,
  ErrorOutline as ErrorOutlineIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  AccessTime as AccessTimeIcon,
  Star as StarIcon,
  Groups as GroupsIcon,
  Refresh as RefreshIcon,
  Visibility as VisibilityIcon,
  Group as GroupIcon
} from '@mui/icons-material';

// Other imports remain the same...
import { styled } from '@mui/material/styles';



// Images and other assets
import defaultimg from './assets/defaultimge.png';

// Third-party imports
import axios from 'axios';
import { useSelector } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import moment from 'moment';
import AWS from 'aws-sdk';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';

// Component imports
import Popup from './Popup';
import TaskForm from './TaskForm';
import Mastergithub from './Mastergithub';
import Popupstakeholdermanager from './Popupstakeholdermanager';

// Styled Components - Without theme dependency to avoid errors



// Global styles to remove all lines
const GlobalStyles = styled('div')({
  '& .MuiTabs-indicator': {
    display: 'none !important'
  },
  '& [role="tabpanel"]': {
    borderTop: 'none !important'
  },
  '& .MuiCardContent-root': {
    border: 'none !important'
  },
  '& .MuiTab-root': {
    borderBottom: 'none !important'
  },
  '& .MuiBox-root > .MuiBox-root': {
    borderBottom: 'none !important',
    '&::after': {
      display: 'none !important'
    }
  },
  '& .MuiTabPanel-root': {
    borderTop: 'none !important'
  },
  '& *': {
    borderBottom: 'none !important',
    borderTop: 'none !important'
  }
});


// Updated styled components with theme support
const MainContainer = styled(Box)(({ theme }) => ({
  margin: "auto",
  marginTop: "5rem",
  width: "80vw",
  padding: '24px',
  background: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f6f9fc',
  border: 'none !important'
}));

const PageCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  overflow: 'hidden',
  border: 'none !important',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 24px 0 rgba(0,0,0,0.2)' 
    : '0 4px 24px 0 rgba(34,41,47,0.1)',
  background: theme.palette.mode === 'dark' 
    ? theme.palette.background.paper 
    : '#fff',
  transition: 'transform 0.3s ease'
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  padding: '24px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  border: 'none !important',
  background: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : 'linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%)',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
}));

const StatCard = styled(Card)(({ theme }) => ({
  padding: '24px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  borderRadius: '12px',
  background: theme.palette.mode === 'dark' 
    ? theme.palette.background.paper 
    : '#fff',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 2px 4px rgba(0,0,0,0.2)'
    : '0 2px 4px rgba(0,0,0,0.05)',
  border: 'none'
}));

const TeamMemberCard = styled(Card)(({ theme }) => ({
  padding: '16px',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  marginBottom: '8px',
  background: theme.palette.mode === 'dark' 
    ? theme.palette.background.paper 
    : '#fff',
  border: 'none',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 2px 4px rgba(0,0,0,0.2)'
    : '0 2px 4px rgba(0,0,0,0.05)',
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 8px rgba(0,0,0,0.3)'
      : '0 4px 8px rgba(0,0,0,0.1)',
    background: theme.palette.mode === 'dark'
      ? theme.palette.action.hover
      : theme.palette.background.paper
  }
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  borderRadius: '16px',
  padding: '24px',
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflow: 'auto',
  backgroundColor: theme.palette.mode === 'dark' 
    ? theme.palette.background.paper 
    : '#fff',
  border: 'none',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px rgba(0,0,0,0.3)'
    : '0 4px 24px rgba(34,41,47,0.1)',
  '& > *': {
    border: 'none !important'
  },
  '@media (max-width: 600px)': {
    width: '95vw',
    margin: '16px',
    padding: '16px',
  }
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.mode === 'dark'
    ? 'rgba(0, 0, 0, 0.8)'
    : 'rgba(255, 255, 255, 0.8)',
  zIndex: 9999,
  gap: '16px',
  border: 'none'
}));

const ActionButton = styled(Button)(({ theme, buttonColor = '#1DBF73' }) => ({
  textTransform: 'none',
  fontWeight: 600,
  padding: '8px 16px',
  borderRadius: '8px',
  backgroundColor: buttonColor,
  color: theme.palette.mode === 'dark' ? '#fff' : 'white',
  border: 'none',
  boxShadow: theme.palette.mode === 'dark'
    ? `0 2px 8px ${buttonColor}20`
    : `0 2px 8px ${buttonColor}40`,
  '&:hover': {
    backgroundColor: buttonColor,
    opacity: 0.9,
    boxShadow: theme.palette.mode === 'dark'
      ? `0 4px 12px ${buttonColor}30`
      : `0 4px 12px ${buttonColor}40`,
  }
}));

const StyledChip = styled(Chip)(({ theme, statusColor = '#1DBF73' }) => ({
  borderRadius: '8px',
  fontWeight: 600,
  backgroundColor: theme.palette.mode === 'dark' 
    ? `${statusColor}20`
    : `${statusColor}15`,
  color: theme.palette.mode === 'dark'
    ? theme.palette.primary.light
    : statusColor,
  border: 'none',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark'
      ? `${statusColor}30`
      : `${statusColor}25`,
  }
}));

// Custom Alert with dark mode support
const CustomAlert = styled(Alert)(({ severity, theme }) => {
  const colors = {
    success: theme.palette.mode === 'dark' ? '#2E7D32' : '#388e3c',
    error: theme.palette.mode === 'dark' ? '#D32F2F' : '#d32f2f',
    warning: theme.palette.mode === 'dark' ? '#ED6C02' : '#f57c00',
    info: theme.palette.mode === 'dark' ? '#0288D1' : '#0288d1'
  };

  return {
    borderRadius: '8px',
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#fff',
    backgroundColor: colors[severity] || colors.info,
    '& .MuiAlert-icon': {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#fff'
    },
    '& .MuiAlert-action': {
      color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#fff'
    }
  };
});




// Style for the tabs container to remove the blue line
const TabsContainer = styled(Box)({
  position: 'relative',
  '&::after': {
    display: 'none !important'
  },
  '& .MuiTabs-root': {
    minHeight: 'unset',
    '&::before, &::after': {
      display: 'none !important'
    }
  }
});





const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});


// Utility Functions
const getStatusColor = (status) => {
  const colors = {
    active: '#4CAF50',
    inactive: '#FF5252',
    pending: '#FFA726',
    completed: '#9C27B0'
  };
  return colors[status?.toLowerCase()] || colors.active;
};



// AWS Configuration
const S3Config = {
  region: process.env.REACT_APP_AWS_REGION || "us-east-1",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
};
// Main Component
const FindPodsDetails = () => {
  const intl = useIntl();
  const history = useHistory();
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  const myBucket = new AWS.S3(S3Config);
 const theme = useTheme();
  // State Management
  const [podDetails, setPodDetails] = useState({
    podName: '',
    email: '',
    podsize: 0,
    status: 'active',
    picture: '',
    skills: []
  });
  const [candidates, setCandidates] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [podImage, setPodImage] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [invitedManagers, setInvitedManagers] = useState([]);
  const [availableManagers, setAvailableManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState('');
  
  // Loading and Error States
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingText, setLoadingText] = useState('Loading pod details...');
  
  // Modal States
  const [modals, setModals] = useState({
    stakeholder: false,
    task: false,
    settings: false,
    invite: false,
    manager: false,
  });

  // Dialog States
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: '',
    message: '',
    action: null,
    podId: null,
    actionText: '',
    actionColor: ''
  });

  // Snackbar State
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
    duration: 5000
  });

  // Get Pod ID from localStorage
  const id = localStorage.getItem('selectedItemId');
  const handleNavigation = () => {
    history.push({
      pathname: '/ClientManagePod',
      state: { podid: id },
    });
  };

  // Effects
  useEffect(() => {
    if (token && id) {
      initializeData();
    }
  }, [token, id]);

  // Data Fetching Functions
  const initializeData = async () => {
    setPageLoading(true);
    try {
      await Promise.all([
        fetchPodDetails(),
        fetchStakeholders(),
        fetchInvitedManagers() 
      ]);
    } catch (error) {
      console.error('Error initializing data:', error);
      showSnackbar('Failed to load pod data', 'error');
    } finally {
      setPageLoading(false);
    }
  };

  const fetchPodDetails = async () => {
    setLoading(true);
    setLoadingText('Fetching pod details...');
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };

      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETPODID,
        { id },
        { headers }
      );

      if (response.data?.data) {
        const { podDetails, candidates } = response.data.data;
        setPodDetails(podDetails);
        setCandidates(candidates || []);

        // Fetch Pod Image if exists
        if (podDetails.picture) {
          await fetchPodImage(podDetails.picture);
        }
      }
    } catch (error) {
      console.error('Error fetching pod details:', error);
      setError('Failed to load pod details');
      showSnackbar('Error loading pod details', 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchPodImage = async (imageKey) => {
    try {
      const params = {
        Bucket: "globldataprod",
        Key: imageKey,
        Expires: 900 // 15 minutes
      };

      const url = await myBucket.getSignedUrl('getObject', params);
      setPodImage(url);
    } catch (error) {
      console.error('Error fetching pod image:', error);
      // Don't show error for image load failure
    }
  };

  const fetchStakeholders = async () => {
    setLoadingText('Fetching stakeholders...');
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };

      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETPODSTAKEHOLDERSLIST,
        { podID: id },
        { headers }
      );

      if (Array.isArray(response.data.data)) {
        setStakeholders(response.data.data.map(s => s.stakeholdername));
      } else {
        setStakeholders([]);
      }
    } catch (error) {
      console.error('Error fetching stakeholders:', error);
      showSnackbar('Error loading stakeholders', 'warning');
    }
  };
  const fetchInvitedManagers = async () => {
    setLoadingText('Fetching managers...');
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };
  
      const response = await axios.post(
        'https://gswwl7r41e.execute-api.us-east-1.amazonaws.com/prod/fetchinvitedManagers',
        { 
          client_code: user.data[0].client_code , 
          pod_id: parseInt(id) 
        },
        { headers }
      );
  
      if (response.data?.status === 200 && Array.isArray(response.data.data)) {
        setInvitedManagers(response.data.data);
      } else {
        setInvitedManagers([]);
      }
    } catch (error) {
      console.error('Error fetching invited managers:', error);
      showSnackbar('Error loading invited managers', 'warning');
    }
  };
  
  // Function to fetch managers that can be invited
  const fetchAvailableManagers = async () => {
    setLoadingText('Fetching available managers...');
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };
  
      const response = await axios.post(
        'https://l04w2z37l5.execute-api.us-east-1.amazonaws.com/prod/fetchManagerswithoutinvitation',
        { 
          client_code: user.data[0].client_code , 
          pod_id: id
        },
        { headers }
      );
  
      if (response.data?.status === 200 && Array.isArray(response.data.data)) {
        setAvailableManagers(response.data.data);
      } else {
        setAvailableManagers([]);
      }
    } catch (error) {
      console.error('Error fetching available managers:', error);
      showSnackbar('Error loading available managers', 'warning');
    }
  };
  
  // Function to invite a manager
  const inviteManager = async () => {
    if (!selectedManager) {
      showSnackbar('Please select a manager to invite', 'warning');
      return;
    }
  
    setLoading(true);
    setLoadingText('Inviting manager...');
    
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'

      };

const payload= { 
  client_code: user.data[0].client_code , 
  pod_id: id,
  manager_id: selectedManager.toString(),
  invited_by: user.data[0].emp_id
}
console.log("invite manger",payload)
  
      const response = await axios.post(
        'https://t1lo2h81g9.execute-api.us-east-1.amazonaws.com/prod/addinvitedManagers',
        { 
          client_code: user.data[0].client_code , 
          pod_id: id,
          manager_id: selectedManager.toString(),
          invited_by: user.data[0].emp_id
        },
        { headers }
      );
  
      if (response.data?.status === 200) {
        showSnackbar('Manager invited successfully', 'success');
        toggleModal('manager', false);
        setSelectedManager('');
        await fetchInvitedManagers();
      } else {
        showSnackbar('Failed to invite manager', 'error');
      }
    } catch (error) {
      console.error('Error inviting manager:', error);
      showSnackbar('Error inviting manager', 'error');
    } finally {
      setLoading(false);
    }
  };
  
  // Add this function to handle opening the manager modal
  const handleOpenManagerModal = async () => {
    await fetchAvailableManagers();
    toggleModal('manager', true);
  };
  // Action Handlers
  const handlePodAction = (action, podId) => {
    const actions = {
      activate: {
        title: 'Activate Pod',
        message: 'Are you sure you want to activate this pod?',
        actionText: 'Activate',
        actionColor: '#4CAF50'
      },
      deactivate: {
        title: 'Deactivate Pod',
        message: 'Are you sure you want to deactivate this pod?',
        actionText: 'Deactivate',
        actionColor: '#FF5252'
      },
      delete: {
        title: 'Delete Pod',
        message: 'This action cannot be undone. Are you sure?',
        actionText: 'Delete',
        actionColor: '#FF5252'
      }
    };

    const selectedAction = actions[action];
    setConfirmDialog({
      open: true,
      action,
      podId,
      title: selectedAction.title,
      message: selectedAction.message,
      actionText: selectedAction.actionText,
      actionColor: selectedAction.actionColor
    });
  };

  const executeAction = async () => {
    const { action, podId } = confirmDialog;
    setConfirmDialog(prev => ({ ...prev, open: false }));
    setLoading(true);

    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };

      let url;
      switch (action) {
        case 'activate':
          url = process.env.REACT_APP_CLIENT_ACTIVATEPOD;
          break;
        case 'deactivate':
          url = process.env.REACT_APP_CLIENT_DEACTIVATEPOD;
          break;
        case 'delete':
          url = process.env.REACT_APP_CLIENT_DELETEPOD;
          break;
        default:
          throw new Error('Invalid action');
      }

      const response = await axios.post(
        url,
        { podID: podId },
        { headers }
      );

      if (response.status === 200) {
        showSnackbar(`Pod ${action}d successfully`, 'success');
        if (action === 'delete') {
          history.push('/Team');
        } else {
          await fetchPodDetails();
        }
      }
    } catch (error) {
      console.error(`Error ${action}ing pod:`, error);
      showSnackbar(`Failed to ${action} pod`, 'error');
    } finally {
      setLoading(false);
    }
  };

  // Modal Handlers
  const toggleModal = (modalName, value) => {
    setModals(prev => ({
      ...prev,
      [modalName]: value
    }));
  };

  // Snackbar Handlers
  const showSnackbar = (message, severity = 'info', duration = 5000) => {
    setSnackbar({
      open: true,
      message,
      severity,
      duration
    });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // Loading State
  if (pageLoading) {
    return (
      <LoadingOverlay>
        <CircularProgress size={40} />
        <Typography variant="body1" color="textSecondary">
          {loadingText}
        </Typography>
      </LoadingOverlay>
    );
  }

  // Error State
  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <ErrorOutlineIcon sx={{ fontSize: 48, color: 'error.main', mb: 2 }} />
        <Typography variant="h6" color="error" gutterBottom>
          {error}
        </Typography>
        <Button
          startIcon={<RefreshIcon />}
          onClick={initializeData}
          sx={{ mt: 2 }}
        >
          Retry
        </Button>
      </Box>
    );
  }

 // Render Functions
 const renderHeader = () => (
  <HeaderSection>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
  <Avatar
    src={podDetails.picture ? 
      `https://${process.env.REACT_APP_BUCKETNAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/${podDetails.picture}` : 
      defaultimg
    }
    alt={podDetails.podName}
    onError={(e) => {
      console.error('Pod image failed to load:', e.target.src);
      e.target.src = defaultimg;
    }}
    sx={{ 
      width: 80, 
      height: 80,
      border: '3px solid white',
      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.05)'
      }
    }}
  />
  <Box>
    <Typography variant="h5" fontWeight="600" gutterBottom>
      {podDetails.podName}
    </Typography>
    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
      {podDetails.email}
    </Typography>
    <StyledChip
      label={podDetails.status?.toUpperCase()}
      statusColor={getStatusColor(podDetails.status)}
      icon={podDetails.status === 'active' ? 
        <CheckCircleIcon /> : <AccessTimeIcon />
      }
    />
  </Box>
</Box>

    <Box sx={{ 
      display: 'flex', 
      gap: 2, 
      flexWrap: 'wrap',
      ml: 'auto'
    }}>
    <ActionButton
      startIcon={<EditIcon />}
      onClick={handleNavigation}
    >
      {intl.formatMessage({ id: 'manage' })}
    </ActionButton>

      <ActionButton
        buttonColor={podDetails.status === "Active" ? "#ff4444" : "#4CAF50"}
        startIcon={<PowerSettingsNewIcon />}
        onClick={() => handlePodAction(
          podDetails.status === "Active" ? 'deactivate' : 'activate',
          podDetails.id
        )}
      >
        {podDetails.status === "Active" 
          ? intl.formatMessage({ id: 'deactivatePod' })
          : intl.formatMessage({ id: 'activatePod' })}
      </ActionButton>
    </Box>
  </HeaderSection>
);

const renderStats = () => (
  <Grid container spacing={3} sx={{ p: 3 }}>
    <Grid item xs={12} sm={6} md={3}>
      <StatCard>
        <Box sx={{ 
          p: 2, 
          backgroundColor: 'rgba(0, 180, 216, 0.1)', 
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <GroupsIcon sx={{ fontSize: 32, color: '#00B4D8' }} />
        </Box>
        <Box>
          <Typography variant="h4" fontWeight="bold" sx={{ mb: 0.5 }}>
            {podDetails.podsize}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Team Size
          </Typography>
        </Box>
        {/* <LinearProgress 
          variant="determinate" 
          value={(candidates.length / podDetails.podsize) * 100}
          sx={{ 
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 4,
            borderRadius: '0 0 12px 12px',
            backgroundColor: 'rgba(0, 180, 216, 0.1)',
           '& .MuiLinearProgress-bar': {
  backgroundColor: 'green'
}
          }}
        /> */}
      </StatCard>
    </Grid>

    <Grid item xs={12} sm={6} md={3}>
      <StatCard>
        <Box sx={{ 
          p: 2, 
          backgroundColor: 'rgba(76, 175, 80, 0.1)', 
          borderRadius: '50%' 
        }}>
          <PeopleIcon sx={{ fontSize: 32, color: '#4CAF50' }} />
        </Box>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            {candidates.length}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Active Members
          </Typography>
        </Box>
      </StatCard>
    </Grid>

    <Grid item xs={12} sm={6} md={3}>
      <StatCard>
        <Box sx={{ 
          p: 2, 
          backgroundColor: 'rgba(156, 39, 176, 0.1)', 
          borderRadius: '50%' 
        }}>
          <StarIcon sx={{ fontSize: 32, color: '#9C27B0' }} />
        </Box>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            {podDetails.skills?.length || 0}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Skills
          </Typography>
        </Box>
      </StatCard>
    </Grid>

    <Grid item xs={12} sm={6} md={3}>
      <StatCard>
        <Box sx={{ 
          p: 2, 
          backgroundColor: 'rgba(255, 167, 38, 0.1)', 
          borderRadius: '50%' 
        }}>
          <GroupIcon sx={{ fontSize: 32, color: '#FFA726' }} />
        </Box>
        <Box>
          <Typography variant="h4" fontWeight="bold">
            {stakeholders.length}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Stakeholders
          </Typography>
        </Box>
      </StatCard>
    </Grid>
  </Grid>
);
const renderManagersSection = () => (
  <Card sx={{ p: 3, mt: 3 }}>
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      mb: 3 
    }}>
      <Typography variant="h6" fontWeight="600">
        Pod Managers
      </Typography>
      <ActionButton
        startIcon={<PersonAddIcon />}
        size="small"
        onClick={handleOpenManagerModal}
      >
        Add Manager
      </ActionButton>
    </Box>

    <Box sx={{ 
      maxHeight: 300, 
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.2)',
        borderRadius: '3px',
      }
    }}>
      {invitedManagers.length > 0 ? (
        invitedManagers.map((manager) => (
          <TeamMemberCard 
            key={manager.emp_id}
          >
            <Avatar
              sx={{ 
                width: 40, 
                height: 40,
                border: '2px solid white',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                bgcolor: theme.palette.primary.main
              }}
            >
              {manager.emp_firstname ? manager.emp_firstname[0].toUpperCase() : 'M'}
            </Avatar>
            <Box>
              <Typography variant="subtitle2" fontWeight="600">
                {manager.emp_firstname || 'Manager'} 
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Pod Manager
              </Typography>
            </Box>
          </TeamMemberCard>
        ))
      ) : (
        <Box sx={{ 
          textAlign: 'center', 
          py: 4,
          color: 'text.secondary' 
        }}>
          <PeopleIcon sx={{ fontSize: 48, mb: 1, opacity: 0.5 }} />
          <Typography>
            No managers assigned yet
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Add managers to help oversee this pod
          </Typography>
        </Box>
      )}
    </Box>
  </Card>
);


const renderPodDetails = () => (
  <Grid container spacing={3} sx={{ p: 3 }}>
    <Grid item xs={12} md={8}>
      <Card sx={{ height: '100%', p: 3 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 3 
        }}>
          <Typography variant="h6" fontWeight="600">
            Pod Details
          </Typography>
          <ActionButton
            startIcon={<AddIcon />}
            size="small"
            onClick={() => toggleModal('task', true)}
          >
            Create Task
          </ActionButton>
        </Box>

        <Stack spacing={3}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
            <Typography variant="subtitle2" sx={{ width: 120, color: 'text.secondary' }}>
              Skills:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {podDetails.skills?.map((skill, index) => (
                <StyledChip
                  key={index}
                  label={skill.skill}
                  size="small"
                  statusColor="#00B4D8"
                />
              ))}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="subtitle2" sx={{ width: 120, color: 'text.secondary' }}>
              Stakeholders:
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {stakeholders.length > 0 ? (
                <AvatarGroup 
                  max={4} 
                  sx={{ 
                    '& .MuiAvatar-root': { 
                      width: 32, 
                      height: 32,
                      fontSize: '0.875rem',
                      border: '2px solid white',
                    }
                  }}
                >
                  {stakeholders.map((stakeholder, index) => (
                    <Tooltip key={index} title={stakeholder} arrow>
                      <Avatar sx={{ bgcolor: `hsl(${index * 60}, 70%, 50%)` }}>
                        {stakeholder[0].toUpperCase()}
                      </Avatar>
                    </Tooltip>
                  ))}
                </AvatarGroup>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No stakeholders yet
                </Typography>
              )}
              <Tooltip title="Add Stakeholder">
                <IconButton
                  size="small"
                  onClick={() => toggleModal('stakeholder', true)}
                  sx={{
                    backgroundColor: 'rgba(0, 180, 216, 0.1)',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 180, 216, 0.2)',
                    }
                  }}
                >
                  <PersonAddIcon sx={{ color: '#00B4D8' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Stack>

        <Divider sx={{ my: 3 }} />

        <Stack direction="row" spacing={2}>
          <ActionButton
            startIcon={<SettingsIcon />}
            onClick={() => toggleModal('settings', true)}
          >
            Settings
          </ActionButton>

          <ActionButton
            startIcon={<DeleteIcon />}
            buttonColor="#ff4444"
            onClick={() => handlePodAction('delete', podDetails.id)}
          >
            Delete Pod
          </ActionButton>
        </Stack>
        {renderManagersSection()}
      </Card>
  
    </Grid>
   
    <Grid item xs={12} md={4}>
      {renderTeamMembers()}
    </Grid>
  </Grid>
);

// Updated renderManagerModal with proper dropdown functionality
const renderManagerModal = () => {

  
  // Function to handle dropdown toggle
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  
  // Function to handle selection
  const handleSelect = (managerId) => {
    setSelectedManager(managerId);
    setDropdownOpen(false);
  };
  
  return (
    <StyledModal
      open={modals.manager}
      onClose={() => toggleModal('manager', false)}
      closeAfterTransition
    >
      <Fade in={modals.manager}>
        <ModalContent>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 3 
          }}>
            <Typography variant="h6" fontWeight="600">
              Add Pod Manager
            </Typography>
            <IconButton onClick={() => toggleModal('manager', false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          
          <Box sx={{ mb: 3 }}>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Select a manager to invite to this pod:
            </Typography>
            
            {availableManagers.length === 0 ? (
              <Alert severity="info" sx={{ mb: 2 }}>
                No managers available to invite.
              </Alert>
            ) : (
              <Box sx={{ position: 'relative' }}>
                {/* Dropdown Selector */}
                <Box
                  onClick={toggleDropdown}
                  sx={{
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: '8px',
                    p: 2,
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    bgcolor: 'background.paper',
                    '&:hover': {
                      borderColor: 'primary.main'
                    }
                  }}
                >
                  <Typography variant="body1" color={selectedManager ? 'text.primary' : 'text.secondary'}>
                    {selectedManager 
                      ? `${availableManagers.find(m => m.emp_id === selectedManager)?.emp_firstname || 'Manager'} (ID: ${selectedManager})`
                      : 'Select a manager'
                    }
                  </Typography>
                  <Box>
                    {dropdownOpen ? (
                      <Box sx={{ transform: 'rotate(180deg)' }}>▼</Box>
                    ) : (
                      <Box>▼</Box>
                    )}
                  </Box>
                </Box>
                
                {/* Dropdown Options */}
                {dropdownOpen && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '100%',
                      left: 0,
                      right: 0,
                      border: '1px solid',
                      borderColor: 'divider',
                      borderRadius: '8px',
                      mt: 1,
                      p: 1,
                      boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                      bgcolor: 'background.paper',
                      zIndex: 10,
                      maxHeight: '200px',
                      overflow: 'auto'
                    }}
                  >
                    {availableManagers.map((manager) => (
                      <Box
                        key={manager.emp_id}
                        onClick={() => handleSelect(manager.emp_id)}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1,
                          cursor: 'pointer',
                          borderRadius: '4px',
                          '&:hover': {
                            bgcolor: 'rgba(0, 180, 216, 0.05)'
                          }
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 32,
                            height: 32,
                            mr: 2,
                            bgcolor: manager.emp_id === selectedManager ? theme.palette.primary.main : 'grey.400'
                          }}
                        >
                          {manager.emp_firstname ? manager.emp_firstname[0].toUpperCase() : 'M'}
                        </Avatar>
                        <Typography variant="body2">
                          {manager.emp_firstname || 'Manager'} (ID: {manager.emp_id})
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
                
                {/* Selected Manager Display */}
                {selectedManager && (
                  <Box sx={{ 
                    p: 2, 
                    border: '1px solid',
                    borderColor: 'primary.light',
                    borderRadius: '8px',
                    mt: 2,
                    bgcolor: 'rgba(0, 180, 216, 0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                        sx={{
                          width: 36,
                          height: 36,
                          mr: 2,
                          bgcolor: theme.palette.primary.main
                        }}
                      >
                        {availableManagers.find(m => m.emp_id === selectedManager)?.emp_firstname?.[0]?.toUpperCase() || 'M'}
                      </Avatar>
                      <Box>
                        <Typography variant="body1" fontWeight="500">
                          {availableManagers.find(m => m.emp_id === selectedManager)?.emp_firstname || 'Manager'}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Selected Manager
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      size="small"
                      color="inherit"
                      startIcon={<CloseIcon fontSize="small" />}
                      onClick={() => setSelectedManager('')}
                    >
                      Clear
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button 
              variant="outlined" 
              onClick={() => {
                setSelectedManager('');
                toggleModal('manager', false);
              }}
            >
              Cancel
            </Button>
            <ActionButton
              onClick={inviteManager}
              disabled={!selectedManager || availableManagers.length === 0}
            >
              Invite Manager
            </ActionButton>
          </Box>
        </ModalContent>
      </Fade>
    </StyledModal>
  );
};


const renderTeamMembers = () => (
  <Card sx={{ height: '100%', p: 3 }}>
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      mb: 3 
    }}>
      <Typography variant="h6" fontWeight="600">
        Team Members
      </Typography>
      <ActionButton
        startIcon={<PersonAddIcon />}
        size="small"
        onClick={() => toggleModal('invite', true)}
      >
        Invite
      </ActionButton>
    </Box>

    <Box sx={{ 
      maxHeight: 400, 
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,0.2)',
        borderRadius: '3px',
      }
    }}>
      {candidates.length > 0 ? (
        candidates.map((member) => (
         
            <NavHashLink 
                                to={{ pathname: '/Candidatedetailsbyid', state: { c_id: member.c_id,talent_type:member.talent_type } }}
                                style={{ textDecoration: 'none' }}
                              >
                                 <TeamMemberCard 
            key={member.c_id}
         
          >
            <Avatar
              src={member.profilePicture ? 
                `https://globldataprod.s3.amazonaws.com/${member.profilePicture}` : 
                defaultimg
              }
              sx={{ 
                width: 40, 
                height: 40,
                border: '2px solid white',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
              }}
            />
            <Box>
              <Typography variant="subtitle2" fontWeight="600">
                {member.firstName} {member.lastName}
              </Typography>
              {member.work_position && (
                <Typography variant="caption" color="text.secondary">
                  {member.work_position}
                </Typography>
              )}
            </Box>
            
            <IconButton 
              className="view-details"
              size="small"
              sx={{ 
                ml: 'auto',
                opacity: 0,
                transition: 'all 0.2s ease',
                '.MuiTeamMemberCard-root:hover &': {
                  opacity: 1
                }
              }}
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            </TeamMemberCard>
            </NavHashLink>
        ))
      ) : (
        <Box sx={{ 
          textAlign: 'center', 
          py: 4,
          color: 'text.secondary' 
        }}>
          <PeopleIcon sx={{ fontSize: 48, mb: 1, opacity: 0.5 }} />
          <Typography>
            No team members yet
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Start by inviting members to your pod
          </Typography>
        </Box>
      )}
    </Box>
  </Card>
);



// Final Return with all components
return (
  <GlobalStyles>
  <MainContainer>
    {/* Breadcrumb Navigation */}
    <Box sx={{ mb: 4 }}>
      <Breadcrumbs>
        <Link 
          to="/Team" 
          style={{ 
            color: '#03045e', 
            fontSize: '18px', 
            fontWeight: 600,
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          {intl.formatMessage({ id: 'myPods' })}
        </Link>
        <Typography color="text.secondary" fontSize="18px" fontWeight={600}>
          {intl.formatMessage({ id: 'viewPod' })}
        </Typography>
      </Breadcrumbs>
    </Box>

    {/* Create Pod Button */}
    <Box sx={{ 
      mb: 3, 
      display: 'flex', 
      justifyContent: 'flex-end' 
    }}>
      <ActionButton 
        startIcon={<AddIcon />}
        onClick={() => history.push('/client-createpod')}
      >
        {intl.formatMessage({ id: 'createPod' })}
      </ActionButton>
    </Box>

    {/* Main Content */}
    <PageCard>
      {renderHeader()}
      {renderStats()}
      {renderPodDetails()}
    </PageCard>

    {/* Modals */}
    {/* Stakeholder Modal */}
    <StyledModal
      open={modals.stakeholder}
      onClose={() => toggleModal('stakeholder', false)}
      closeAfterTransition
    >
      <Fade in={modals.stakeholder}>
        <ModalContent>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 2 
          }}>
            <Typography variant="h6" fontWeight="600">
              {intl.formatMessage({ id: 'manageStakeholders' })}
            </Typography>
            <IconButton 
              onClick={() => toggleModal('stakeholder', false)}
              sx={{
                '&:hover': { backgroundColor: 'rgba(0,0,0,0.04)' }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Popupstakeholdermanager 
            pod_id={podDetails.id} 
            onSuccess={() => {
              toggleModal('stakeholder', false);
              fetchStakeholders();
              showSnackbar('Stakeholders updated successfully', 'success');
            }}
          />
        </ModalContent>
      </Fade>
    </StyledModal>

    {/* Invite Members Modal */}
    <StyledModal
  open={modals.invite}
  onClose={() => toggleModal('invite', false)}
  closeAfterTransition
>
  <Fade in={modals.invite}>
    <ModalContent>
      <EnhancedInviteModal
        podId={podDetails.id}
        onSuccess={() => {
          toggleModal('invite', false);
          fetchPodDetails();
          showSnackbar('Team members added successfully', 'success');
        }}
        onClose={() => toggleModal('invite', false)}
      />
    </ModalContent>
  </Fade>
</StyledModal>

    {/* Task Modal */}
    <StyledModal
      open={modals.task}
      onClose={() => toggleModal('task', false)}
      closeAfterTransition
    >
      <Fade in={modals.task}>
        <ModalContent>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 2 
          }}>
            <Typography variant="h6" fontWeight="600">
              {intl.formatMessage({ id: 'createTask' })}
            </Typography>
            <IconButton onClick={() => toggleModal('task', false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TaskForm
            podid={podDetails.id} 
            podname={podDetails.podName}
            onClose={() => {
              toggleModal('task', false);
              showSnackbar('Task created successfully', 'success');
            }}
          />
        </ModalContent>
      </Fade>
    </StyledModal>

    {/* Settings Modal */}
    <StyledModal
      open={modals.settings}
      onClose={() => toggleModal('settings', false)}
      closeAfterTransition
    >
      <Fade in={modals.settings}>
        <ModalContent>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 2 
          }}>
            <Typography variant="h6" fontWeight="600">
              {intl.formatMessage({ id: 'podSettings' })}
            </Typography>
            <IconButton onClick={() => toggleModal('settings', false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Mastergithub
            podid={podDetails.id} 
            name={podDetails.podName}
            onClose={() => {
              toggleModal('settings', false);
              showSnackbar('Settings updated successfully', 'success');
            }}
          />
        </ModalContent>
      </Fade>
    </StyledModal>
    {renderManagerModal()}
    {/* Confirmation Dialog */}
    <Dialog
      open={confirmDialog.open}
      onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          padding: '8px',
          minWidth: '300px'
        }
      }}
    >
      <DialogTitle sx={{ 
        color: confirmDialog.actionColor,
        pb: 1,
        fontWeight: 600
      }}>
        {confirmDialog.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {confirmDialog.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 1 }}>
        <Button
          onClick={() => setConfirmDialog({ ...confirmDialog, open: false })}
          sx={{ 
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.04)'
            }
          }}
        >
          {intl.formatMessage({ id: 'cancel' })}
        </Button>
        <ActionButton
          buttonColor={confirmDialog.actionColor}
          onClick={executeAction}
          autoFocus
        >
          {confirmDialog.actionText}
        </ActionButton>
      </DialogActions>
    </Dialog>

    {/* Snackbar */}
    <Snackbar
      open={snackbar.open}
      autoHideDuration={snackbar.duration}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <CustomAlert
        elevation={6}
        variant="filled"
        onClose={handleSnackbarClose}
        severity={snackbar.severity}
        sx={{ minWidth: '300px' }}
        action={
          <IconButton
            size="small"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        {snackbar.message}
      </CustomAlert>
    </Snackbar>

    {/* Loading Overlay */}
    {loading && (
      <LoadingOverlay>
        <CircularProgress size={40} sx={{ color: '#00B4D8' }} />
        <Typography variant="body2" color="text.secondary">
          {loadingText}
        </Typography>
      </LoadingOverlay>
    )}

    {/* Mobile Action Buttons */}
    {window.innerWidth <= 600 && (
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: 'background.paper',
          borderTop: '1px solid',
          borderColor: 'divider',
          zIndex: 1000,
          display: 'flex',
          gap: 1,
          justifyContent: 'space-around',
        }}
      >
        <ActionButton
          size="small"
          startIcon={<AddTaskIcon />}
          onClick={() => toggleModal('task', true)}
          sx={{ flex: 1 }}
        >
          {intl.formatMessage({ id: 'task' })}
        </ActionButton>
        
        <ActionButton
          size="small"
          startIcon={<PersonAddIcon />}
          onClick={() => toggleModal('invite', true)}
          sx={{ flex: 1 }}
        >
          {intl.formatMessage({ id: 'invite' })}
        </ActionButton>
        
        <ActionButton
          size="small"
          startIcon={<SettingsIcon />}
          onClick={() => toggleModal('settings', true)}
          sx={{ flex: 1 }}
        >
          {intl.formatMessage({ id: 'settings' })}
        </ActionButton>
      </Box>
    )}
  </MainContainer>
  </GlobalStyles>
);
};

// Make sure you have only one export default statement
export default FindPodsDetails;