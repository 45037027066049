import React, { useEffect, useState ,useCallback} from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useAuthToken } from "../TokenContext";
import { DateRangePicker } from 'react-date-range';
import { formatDistanceToNow } from 'date-fns';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import {
  Box,
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  Select,
  MenuItem,
  Grid,
  IconButton,
  Chip,
  Alert,
  InputLabel,
  Stack,
  Button,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Avatar,
  List,
  ListItem,
  Divider,
  Badge,
  CircularProgress,
  Tooltip,
  Menu,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  styled
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  FilterList as FilterListIcon,
  Clear as ClearIcon,
  Warning as WarningIcon,
  DateRange as DateRangeIcon,
  Download as DownloadIcon,
  TableRows as TableRowsIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Comment as CommentIcon,
  Close as CloseIcon
} from '@mui/icons-material';

// Styled Components
const CommentDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '600px',
    maxHeight: '80vh',
  },
}));

const CommentListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  '& .MuiListItemText-root': {
    margin: 0,
  },
  '& .comment-header': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  '& .comment-metadata': {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
}));

// Comment Cell Component
const CommentCell = ({ comments, pod_task_id, onCommentAdded }) => {
  const [open, setOpen] = useState(false);
  const [newComment, setNewComment] = useState("");
  const parsedComments = comments ? JSON.parse(comments) : [];
  const commentCount = parsedComments.length;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = useAuthToken();
  const { user } = useSelector((state) => state.auth);

  const handleAddComment = async () => {
    if (!newComment.trim() || !pod_task_id) return;
    
    setIsSubmitting(true);
    try {
      const commentObj = {
        pod_task_id: parseInt(pod_task_id),
        user_id: parseInt(user.data[0].c_id),
        new_text: newComment,
        is_reply: false,
        author: user.data[0].firstName
      };

      const response = await axios.post(
        "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/addCommentpodtask",
        [commentObj],
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          }
        }
      );

      if (response.status === 200) {
        const newComments = [
          ...parsedComments,
          {
            text: newComment,
            user_id: parseInt(user.data[0].c_id),
            author: user.data[0].firstname,
            timestamp: new Date().toISOString(),
            replies: []
          }
        ];
        
        if (onCommentAdded) {
          onCommentAdded(pod_task_id, JSON.stringify(newComments));
        }
        
        setNewComment("");
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Tooltip title={`${commentCount} comments`}>
        <IconButton 
          onClick={() => setOpen(true)}
          color={commentCount > 0 ? "primary" : "default"}
        >
          <Badge badgeContent={commentCount} color="primary">
            <CommentIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <CommentDialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Comments History</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {parsedComments.length > 0 ? (
            <List>
              {parsedComments.map((comment, index) => (
                <React.Fragment key={index}>
                  <CommentListItem>
                    <div className="comment-header">
                      <Box display="flex" alignItems="center" gap={1}>
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                          {comment.author ? comment.author[0].toUpperCase() : 'U'}
                        </Avatar>
                        <Typography variant="subtitle2">
                          {comment.author || 'User'}
                        </Typography>
                      </Box>
                      <Typography variant="caption" className="comment-metadata">
                        {formatDistanceToNow(new Date(comment.timestamp), { addSuffix: true })}
                      </Typography>
                    </div>
                    <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                      {comment.text}
                    </Typography>
                  </CommentListItem>
                  {index < parsedComments.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Box p={3} textAlign="center">
              <Typography color="textSecondary">
                No comments yet
              </Typography>
            </Box>
          )}
          <Box sx={{ 
            mt: 2, 
            pt: 2, 
            borderTop: 1, 
            borderColor: 'divider',
            position: 'sticky',
            bottom: 0,
            bgcolor: 'background.paper'
          }}>
            <TextField
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              placeholder="Write a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              sx={{ mb: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddComment}
              disabled={isSubmitting || !newComment.trim()}
              sx={{ float: 'right' }}
            >
              {isSubmitting ? <CircularProgress size={24} /> : 'Add Comment'}
            </Button>
          </Box>
        </DialogContent>
      </CommentDialog>
    </>
  );
};

const TalentViewTask = () => {
  const theme = useTheme();
  const [tasks, setTasks] = useState([]);
  const [podNameFilter, setPodNameFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const [downloadMenu, setDownloadMenu] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().subtract(30, 'days').toDate(),
      endDate: moment().toDate(),
      key: 'selection'
    }
  ]);

  // Memoize fetchTasks to prevent unnecessary recreations
  const fetchTasks = useCallback(async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLIENTVIEWMYTASK,
        { candidate_Id: user.data[0].c_id },
        { 
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          }
        }
      );
      setTasks(response.data.data || []);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  }, [token, user?.data]);

  // Initial fetch
  useEffect(() => {
    if (token && user?.data?.[0]?.c_id) {
      fetchTasks();
    }
  }, [fetchTasks]);

  // Memoize task status update
  const handleTaskStatusUpdate = useCallback(async (task, newStatus) => {
    try {
      await axios.post(
        process.env.REACT_APP_TALENT_UPDATEPODTASKSTATUS,
        {
          pod_task_id: task.pod_task_id,
          jira_item: task.jira_item,
          pod_id: task.pod_id,
          candidateID: task.candidate_id,
          status: newStatus,
          pod_task_name: task.pod_task_name,
        },
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          }
        }
      );
      fetchTasks();
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  }, [token, fetchTasks]);

  // Memoize filtered tasks
// Add this state for tracking if "All Time" is selected
const [showAllDates, setShowAllDates] = useState(false);

// Modify the filtered tasks logic
const filteredTasks = React.useMemo(() => {
  return tasks.filter(task => {
    const isStatusMatch = statusFilter === 'all' || task.status === statusFilter;
    const isPodNameMatch = podNameFilter === 'all' || task.project_key?.toLowerCase() === podNameFilter;
    const isDateMatch = showAllDates ? true : 
      moment(task.due_date).isBetween(dateRange[0].startDate, dateRange[0].endDate, 'day', '[]');
    return isStatusMatch && isPodNameMatch && isDateMatch;
  });
}, [tasks, statusFilter, podNameFilter, dateRange, showAllDates]);

// Add button to toggle all dates and modify the date picker dialog
<Dialog
  open={showDatePicker}
  onClose={() => setShowDatePicker(false)}
  maxWidth="md"
>
  <DialogTitle>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography>{intl.formatMessage({ id: 'selectDateRange' })}</Typography>
      <Button
        variant="outlined"
        onClick={() => {
          setShowAllDates(!showAllDates);
          setShowDatePicker(false);
        }}
      >
        {showAllDates ? 'Set Date Range' : 'Show All Time'}
      </Button>
    </Box>
  </DialogTitle>
  <DialogContent>
    <DateRangePicker
      onChange={item => {
        setDateRange([item.selection]);
        setShowAllDates(false);
      }}
      ranges={dateRange}
      months={2}
      direction="horizontal"
    />
  </DialogContent>
</Dialog>

const handleDateClick = () => {
  if (!showDatePicker) {
    setShowDatePicker(true);
  } else {
    setShowAllDates(!showAllDates);
    setShowDatePicker(false);
  }
};

const handleDateRangeChange = (ranges) => {
  setDateRange([ranges.selection]);
  setShowAllDates(false);
};

const clearDateRange = () => {
  setShowAllDates(true);
  setShowDatePicker(false);
};
// Update the clear filters function
const handleClearFilters = useCallback(() => {
  setPodNameFilter('all');
  setStatusFilter('all');
  setShowAllDates(true);  // Show all dates when clearing filters
  setDateRange([{
    startDate: moment().subtract(30, 'days').toDate(),
    endDate: moment().toDate(),
    key: 'selection'
  }]);
}, []);
 

  const handleCommentAdded = useCallback((taskId, newComments) => {
    setTasks(prevTasks => 
      prevTasks.map(task => 
        task.pod_task_id === taskId 
          ? { ...task, comment: newComments }
          : task
      )
    );
    fetchTasks();
  }, [fetchTasks]);

  const handleDownload = useCallback(async (format) => {
    try {
      if (filteredTasks.length === 0) {
        throw new Error('No data to export');
      }

      const data = filteredTasks.map(task => ({
        'Task Name': task.pod_task_name,
        'Status': task.status,
        'Description': task.description,
        'Due Date': moment(task.due_date).format('MM/DD/YYYY'),
        'Has Blocker': task.blockercheck === 1 ? 'Yes' : 'No'
      }));

      switch (format) {
        case 'csv':
          const headers = Object.keys(data[0]).join(',');
          const csvData = data.map(row => Object.values(row).join(',')).join('\n');
          const csvContent = `${headers}\n${csvData}`;
          const blob = new Blob([csvContent], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `tasks_${moment().format('YYYY-MM-DD')}.csv`;
          link.click();
          break;

        case 'pdf':
          const doc = new jsPDF('landscape');
          doc.autoTable({
            head: [Object.keys(data[0])],
            body: data.map(Object.values),
            styles: { fontSize: 8, cellPadding: 2 },
            headStyles: { fillColor: [0, 123, 255] }
          });
          doc.save(`tasks_${moment().format('YYYY-MM-DD')}.pdf`);
          break;

        default:
          throw new Error('Unsupported format');
      }
    } catch (error) {
      console.error('Export error:', error);
    }
    setDownloadMenu(null);
  }, [filteredTasks]);

// Add pagination states
// Add pagination states
const [currentPage, setCurrentPage] = useState(1);
const rowsPerPage = 10;
const pageCount = Math.ceil(filteredTasks.length / rowsPerPage);

// Get current page tasks
const getCurrentPageTasks = useCallback(() => {
  const startIndex = (currentPage - 1) * rowsPerPage;
  return filteredTasks.slice(startIndex, startIndex + rowsPerPage);
}, [currentPage, filteredTasks]);

// Reset to first page when filters change
useEffect(() => {
  setCurrentPage(1);
}, [statusFilter, podNameFilter, dateRange]);

const handlePageChange = useCallback((newPage) => {
  setCurrentPage(newPage);
}, []);
  return (
    <Box sx={{ bgcolor: theme.palette.background.default, }}>
      <Container maxWidth="xl" sx={{ mt: 3, pb: 4 }}>
        <Stack spacing={3}>
          {/* Filters Section */}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} alignItems="center" flexWrap="wrap">
                <FormControl size="small" sx={{ minWidth: 200 }}>
                  <InputLabel>{intl.formatMessage({ id: 'podName' })}</InputLabel>
                  <Select
                    value={podNameFilter}
                    onChange={(e) => setPodNameFilter(e.target.value)}
                    label={intl.formatMessage({ id: 'podName' })}
                    startAdornment={
                      <InputAdornment position="start">
                        <FilterListIcon fontSize="small" />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="all">{intl.formatMessage({ id: 'all' })}</MenuItem>
                    {Array.from(new Set(tasks.map(task => task.project_key)))
                      .map(podName => (
                        <MenuItem key={podName} value={podName.toLowerCase()}>
                          {podName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl size="small" sx={{ minWidth: 200 }}>
                  <InputLabel>{intl.formatMessage({ id: 'status' })}</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    label={intl.formatMessage({ id: 'status' })}
                    startAdornment={
                      <InputAdornment position="start">
                        <FilterListIcon fontSize="small" />
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="all">All</MenuItem>
                    {['To Do', 'In Progress', 'Done'].map(status => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
               
                <Button
    variant="outlined"
    sx={{
      bgcolor: 'white',
      border: '1px solid #ddd',
      '&:hover': {
        bgcolor: '#f5f5f5',
        border: '1px solid #ddd'
      }
    }}
    onClick={handleDateClick}
  >
    <DateRangeIcon sx={{ mr: 1 }} />
    {showAllDates ? 
      'All Time' : 
      `${moment(dateRange[0].startDate).format('MMM DD').toUpperCase()} - ${moment(dateRange[0].endDate).format('MMM DD').toUpperCase()}`
    }
  </Button>

  {!showAllDates && (
    <Button
      size="small"
      onClick={clearDateRange}
      startIcon={<ClearIcon />}
      sx={{
        bgcolor: 'white',
        border: '1px solid #ddd',
        '&:hover': {
          bgcolor: '#f5f5f5',
          border: '1px solid #ddd'
        }
      }}
    >
      Clear Date Range
    </Button>
  )}



                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  onClick={(e) => setDownloadMenu(e.currentTarget)}
                >
                  Export
                </Button>

                {(podNameFilter !== 'all' || statusFilter !== 'all') && (
                  <Button
                    size="small"
                    startIcon={<ClearIcon />}
                    onClick={handleClearFilters}
                  >
                    {intl.formatMessage({ id: 'clearFilters' })}
                  </Button>
                )}
              </Stack>
            </Grid>
          </Grid>

          {/* Date Range Picker Dialog */}
          <Dialog
  open={showDatePicker}
  onClose={() => setShowDatePicker(false)}
  maxWidth="md"
>
  <DialogTitle>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography>Select Date Range</Typography>
      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          onClick={clearDateRange}
        >
          Show All Time
        </Button>
      </Stack>
    </Box>
  </DialogTitle>
  <DialogContent>
    <DateRangePicker
      ranges={dateRange}
      onChange={handleDateRangeChange}
      months={2}
      direction="horizontal"
      maxDate={new Date()}
    />
  </DialogContent>
</Dialog>


          {/* Export Menu */}
          <Menu
            anchorEl={downloadMenu}
            open={Boolean(downloadMenu)}
            onClose={() => setDownloadMenu(null)}
          >
            <MenuItem onClick={() => handleDownload("csv")}>
              <ListItemIcon>
                <TableRowsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>CSV</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleDownload("pdf")}>
              <ListItemIcon>
                <PictureAsPdfIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>PDF</ListItemText>
            </MenuItem>
          </Menu>

          {/* Tasks Table */}
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: theme.palette.primary.main }}>
                  <TableCell sx={{ color: 'white' }}>{intl.formatMessage({ id: 'podTask' })}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{intl.formatMessage({ id: 'description' })}</TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    <Stack direction="row" alignItems="center">
                      {intl.formatMessage({ id: 'dueDate' })}
                      <IconButton size="small" sx={{ color: 'white', ml: 0.5 }}>
          <KeyboardArrowDownIcon />
        </IconButton>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>{intl.formatMessage({ id: 'status' })}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{intl.formatMessage({ id: 'blocker' })}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{intl.formatMessage({ id: 'comments' })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getCurrentPageTasks().length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Alert severity="info">No tasks found</Alert>
                    </TableCell>
                  </TableRow>
                ) : (
                  getCurrentPageTasks().map(task => (
                    <TableRow key={task.pod_task_id} hover>
                      <TableCell>{task.pod_task_name}</TableCell>
                      <TableCell>{task.description}</TableCell>
                      <TableCell>
                        {moment(task.due_date).format('MMM DD, YYYY')}
                      </TableCell>
                      <TableCell>
                        <FormControl size="small" sx={{ minWidth: 130 }}>
                          <Select
                            value={task.status}
                            onChange={(e) => handleTaskStatusUpdate(task, e.target.value)}
                            sx={{
                              '& .MuiSelect-select': {
                                py: 1,
                                '& .MuiChip-root': {
                                  height: 24
                                }
                              }
                            }}
                          >
                            {['To Do', 'In Progress', 'Done'].map(status => (
                              <MenuItem key={status} value={status}>
                                <Chip
                                  label={status}
                                  size="small"
                                  color={
                                    status === 'Done' ? 'success' :
                                    status === 'In Progress' ? 'warning' :
                                    'default'
                                  }
                                />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {task.blockercheck === 1 && (
                          <Chip
                            icon={<WarningIcon />}
                            label="Blocker"
                            color="error"
                            size="small"
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <CommentCell 
                          comments={task.comment} 
                          pod_task_id={task.pod_task_id}
                          onCommentAdded={handleCommentAdded}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Pagination */}
          {filteredTasks.length > 0 && (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center',
              mt: 2,
              gap: 1
            }}>
              <Button
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
                variant="outlined"
              >
                {intl.formatMessage({ id: "prev" })}
              </Button>
              
              {(() => {
                const totalPages = pageCount;
                const maxVisiblePages = 5;
                let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
                let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
                
                if (endPage - startPage + 1 < maxVisiblePages) {
                  startPage = Math.max(1, endPage - maxVisiblePages + 1);
                }

                const pages = [];

                if (startPage > 1) {
                  pages.push(
                    <Button 
                      key={1} 
                      variant={currentPage === 1 ? "contained" : "outlined"}
                      onClick={() => handlePageChange(1)}
                    >
                      1
                    </Button>
                  );
                  if (startPage > 2) {
                    pages.push(
                      <Box key="ellipsis1" sx={{ mx: 1 }}>...</Box>
                    );
                  }
                }

                for (let i = startPage; i <= endPage; i++) {
                  pages.push(
                    <Button
                      key={i}
                      variant={currentPage === i ? "contained" : "outlined"}
                      onClick={() => handlePageChange(i)}
                    >
                      {i}
                    </Button>
                  );
                }

                if (endPage < totalPages) {
                  if (endPage < totalPages - 1) {
                    pages.push(
                      <Box key="ellipsis2" sx={{ mx: 1 }}>...</Box>
                    );
                  }
                  pages.push(
                    <Button 
                      key={totalPages} 
                      variant={currentPage === totalPages ? "contained" : "outlined"}
                      onClick={() => handlePageChange(totalPages)}
                    >
                      {totalPages}
                    </Button>
                  );
                }

                return pages;
              })()}

              <Button
                disabled={currentPage === pageCount}
                onClick={() => handlePageChange(currentPage + 1)}
                variant="outlined"
              >
                {intl.formatMessage({ id: "next" })}
              </Button>
            </Box>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default TalentViewTask;