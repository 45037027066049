import styled from "styled-components";
import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Badge from "./Badge";
import Avatar from "./assets/MaskGroup.png";
import { cardShadow, hoverEffect, themeColor } from "../utils";
import { useState } from "react";
import ShowMore from "react-show-more-button/dist/components/ShowMore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rsvp } from "@mui/icons-material";
import axios from "axios";
import moment from 'moment';
import { useAuthToken } from "../TokenContext";
const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}



const InvoicesClient = () => {

  const token = useAuthToken();



  const { isLoggedIn, user } = useSelector(state => state.auth);

  const [getpoddetails, setGetpoddetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [id, setId] = useState('');



  useEffect(() => {
    if (user && user.subscriptiondata && user.subscriptiondata[0]) {
      const body = {
        customerId: user.subscriptiondata[0].customerId || null,
      };
    
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
      };
    
      axios.post(process.env.REACT_APP_CLIENT_LISTOFINVOICES, body, { headers: headers })
        .then((res) => {
          const responseData = JSON.parse(res.data.body);
          setGetpoddetails(responseData.body);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error:", err);
          setErrorMessage("Failed to fetch data. Please try again later.");
          setIsLoading(false);
        });
    } else {
      // Handle the case where user or subscriptiondata is undefined
      // You might want to set appropriate default values or show an error message
      return  <p>No Data Available</p>
    }
  }, [token]);
  
 




 

   
  



  return (
    <Div>

      <TitleText>Your Invoices</TitleText>




      <CardContent>




      {isLoading ? (
     <p>You don't have any invoices.</p>
      ) : !Array.isArray(getpoddetails) || getpoddetails.length === 0 ? (
        <p>You don't have any invoices.</p>
      ) : (<>
<TableContainer  component={Paper}>
            <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: '#00B4D8', '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'white', fontWeight: '600' } }}>
               
              <TableRow>

                <TableCell align="right" sx={{ fontWeight: 'bold' }}>Amount Paid</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>Date</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>Amount Due</TableCell>
              </TableRow>
            </TableHead>
            {
              getpoddetails.map((x, i) => (
                <TableBody>


                  <TableRow

                    key={""}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                      '&:nth-child(even)': {
                        backgroundColor: '#90E0EF'
                      },
                      '&:nth-child(odd)': {
                        backgroundColor: '#caf0f8'
                      },
                      '& td, & th': {
                        fontFamily: 'Poppins,Medium',
                        fontSize: '15px',
                        color: 'black',
                      },
                    }}
                  
                  >

                    <TableCell align="right" >{x.amount_paid}</TableCell>

                    <TableCell align="right" >{ x.created}</TableCell>

                    <TableCell align="right" >{x.status}</TableCell>

                    <TableCell align="right" >{x.amount_due}</TableCell>

                  

                  </TableRow>

                </TableBody>
              ))}
          </Table>
          </TableContainer>
        </>)}




      </CardContent>









    </Div>
  );
};

const Div = styled.div`
  // display:flex;
  display:flex;

  jutify-content: center;
  flex-direction: column;
  align-items:center;
  margin:auto;
  margin-top: 8rem;
  align-items: center;

 
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display:flex;

  overflow:hidden;
justify-content:center;
align-items:center;
margin:auto;
width:25rem;
  }
`;


const ButtonEdit = styled.button`

  text-decoration: none  !important;
  border-radius: 10px;
  background-color:#00B4D8;
  width: 10rem;
  height: 2rem;
  color: white;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    width: 5rem;
    height: 3rem;
    margin: auto;
    margin-top:2rem;
  }

`;

const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;



const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  margin-top:4rem;
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin:auto;
  }
`;






export default InvoicesClient
