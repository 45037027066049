import React, { useState } from 'react';
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { login } from "../action/auth";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Alert,
  Paper,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 16,
  width: '100%',
  maxWidth: 650,
  margin: 'auto',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '90%',
    padding: theme.spacing(2)
  }
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: '100%'
}));

// User type configuration
const USER_TYPES = {
  candidate: {
    value: 'candidate',
    label: 'Talent',
    redirects: {
      profile0: '/Createyourprofile',
      default: '/Updateyourprofile'
    },
    errorMessage: 'User not found as talent.'
  },
  podprovider: {
    value: 'podprovider',
    label: 'Partner',
    redirects: {
      profile0: '/provider-profile',
      default: '/provider-profile'
    },
    errorMessage: 'User not found as Partner.'
  },
  manager: {
    value: 'manager',
    label: 'Manager',
    redirects: {
      profile0: '/client-profile',
      default: '/client-profile'
    },
    errorMessage: 'User not found as manager.'
  },
  stakeholder: {
    value: 'stakeholder',
    label: 'Stake Holder',
    redirects: {
      profile0: '/stakeholder-profile',
      default: '/stakeholder-profile'
    },
    errorMessage: 'User not found as Stake Holder.'
  },
  partnerguest: {
    value: 'partnerguest',
    label: 'Partner Guest',
    redirects: {
      profile0: '/partnerguest-profile',
      default: '/partnerguest-profile'
    },
    errorMessage: 'User not found as partnerguest.'
  },
  customer: {
    value: 'customer',
    label: 'Admin',
    redirects: {
      profile0: '/costomer-profile',
      default: '/subscriptions'
    },
    errorMessage: 'User not found as Client-Admin.'
  }
};

const Login = () => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({
    username: '',
    password: '',
    table: '',
    showPassword: false
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const validateForm = () => {
    if (!formState.table) {
      return 'Please select a user type!';
    }
    if (!formState.username) {
      return 'Please enter an email address!';
    }
    if (!formState.username.toLowerCase().match(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    )) {
      return 'Please enter a valid email address!';
    }
    if (!formState.password) {
      return 'Please enter a password!';
    }
    return null;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await Auth.signIn(formState.username, formState.password);
      const userType = USER_TYPES[formState.table];

      try {
        const data = await dispatch(login(formState.username, formState.table));
        const redirectPath = data[0].profile === "0" || data[0].profile === 0
          ? userType.redirects.profile0
          : userType.redirects.default;
        window.location.href = redirectPath;
      } catch (err) {
        setError(userType.errorMessage);
      }
    } catch (error) {
      setError(error.message || 'An error occurred during login');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setFormState(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
    setError(null);
  };

  const togglePasswordVisibility = () => {
    setFormState(prev => ({
      ...prev,
      showPassword: !prev.showPassword
    }));
  };

  return (
    <Container component="main" maxWidth="sm" sx={{margin:"auto"}}>
      <Box my={4}>
        <StyledPaper elevation={3}>
          <Typography component="h1" variant="h4" gutterBottom>
            Login
          </Typography>

          {error && (
            <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
              {error}
            </Alert>
          )}

          {loading && (
            <CircularProgress sx={{ mb: 2 }} />
          )}

          <StyledForm onSubmit={handleSubmit}>
            <StyledFormControl>
              <Select
                value={formState.table}
                name="table"
                onChange={handleChange}
                displayEmpty
                variant="outlined"
                required
              >
                <MenuItem value="">Select User Type</MenuItem>
                {Object.entries(USER_TYPES).map(([key, { value, label }]) => (
                  <MenuItem key={key} value={value}>{label}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <StyledFormControl>
              <TextField
                name="username"
                label="Email Address"
                value={formState.username}
                onChange={handleChange}
                variant="outlined"
                required
                fullWidth
                type="email"
              />
            </StyledFormControl>

            <StyledFormControl>
              <TextField
                name="password"
                label="Password"
                value={formState.password}
                onChange={handleChange}
                variant="outlined"
                required
                fullWidth
                type={formState.showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                      >
                        {formState.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </StyledFormControl>

            <Box display="flex" justifyContent="space-between" mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.showPassword}
                    onChange={togglePasswordVisibility}
                    color="primary"
                  />
                }
                label="Show Password"
              />
              <Link
                to="/forgotpassword"
                style={{
                  color: theme.palette.mode === 'dark' ? '#E0E0E0' : 'inherit',
                  textDecoration: 'none',
                  alignSelf: 'center'
                }}
              >
                Forgot password?
              </Link>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              sx={{
                mt: 2,
                mb: 2,
                bgcolor: '#1DBF73',
                '&:hover': {
                  bgcolor: '#19a864'
                }
              }}
            >
              {loading ? <CircularProgress size={24} /> : 'Login'}
            </Button>

            <Box textAlign="center">
              <Typography variant="body1">
                Don't have an account?{' '}
                <Link
                  to="/register"
                  style={{
                    color: theme.palette.mode === 'dark' ? '#E0E0E0' : 'inherit',
                    textDecoration: 'none',
                    fontWeight: 'bold'
                  }}
                >
                  Register
                </Link>
              </Typography>
            </Box>
          </StyledForm>
        </StyledPaper>
      </Box>
    </Container>
  );
};

export default Login;