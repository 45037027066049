import React, { useEffect, useState, useRef } from "react";
import Detailsviewmetrics from "./Detailsviewmetrics";

import TicketsDetailviewmetrics from "./TicketsDetailviewmetrics";

import Defectsdetailviewchart from "./Defectsdetailviewchart";
import Githublinesofcodebydaywise from "./Githublinesofcodebydaywise";
import DetailviewGithublinesofcode from "./DetailviewGithublinesofcode";
import DetailPRcountmanager from "./DetailPRcountmanager";
import PRcountgraph from "./PRcountgraph";
import SprintVelocitiesChart from "./SprintVelocityChart ";
import CloudCost from "./CloudCost";
import CicdPiechartmanagerdetailvivew from "./CicdPiechartmanagerdetailvivew";
import ProjectPieChart from "./ProjectPieChart";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import TimeDetailView from "./TimeDetailView";
import Select from "react-select"; // Import react-select
import DoraMetricsModal from "./DoraMetricsModal";
import Dorametricslinegraph from "./Dorametricslinegraph";
import { useIntl } from "react-intl";
import DetailsTaskmetricsManager from "./DetailsTaskmetricsManager";
import DetailbitbucketPRcountmanager from "./DetailbitbucketPRcountmanager";
import Bitbucketcodebydaywisemanager from "./Bitbucketcodebydaywisemanager";
import DetailBitbucketcodemanager from "./DetailBitbucketcodemanager";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
const YourProjects = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  width: '80vw',
  marginTop: '2rem',
  height: 'max-height',
  backgroundColor: theme?.palette?.mode === 'dark' ? '#1E1E1E' : '#0077b6',
  borderRadius: '1rem',
  padding: '0.8rem',
  boxShadow: '0px 4px 6px #00000029',
  transition: '0.45s ease-in-out',
  '@media screen and (min-width: 320px) and (max-width: 1080px)': {
    width: '80vw',
    height: 'max-content',
    marginLeft: '4rem',
    marginTop: '1rem'
  }
}));

const YourProjects2 = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  width: '80vw',
  marginTop: '2rem',
  height: 'max-height',
  backgroundColor: theme?.palette?.mode === 'dark' ? '#1E1E1E' : '#0077b6',
  borderRadius: '1rem',
  padding: '0.8rem',
  boxShadow: '0px 4px 6px #00000029',
  transition: '0.45s ease-in-out',
  '@media screen and (min-width: 320px) and (max-width: 1080px)': {
    width: '80vw',
    height: 'max-content',
    marginLeft: '4rem',
    marginTop: '1rem'
  }
}));

const Project = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.3rem',
  '@media screen and (min-width: 320px) and (max-width: 1080px)': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const Border = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  width: '100%',
  backgroundColor: theme?.palette?.mode === 'dark' ? '#2d2d2d' : 'white',
  borderRadius: '1rem',
  height: '20rem',
  padding: '20px',
  '@media screen and (min-width: 320px) and (max-width: 1080px)': {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70vw'
  }
}));

const Border1 = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  width: '100%',
  backgroundColor: theme?.palette?.mode === 'dark' ? '#2d2d2d' : 'white',
  borderRadius: '1rem',
  height: '90%',
  padding: '20px',
  '@media screen and (min-width: 320px) and (max-width: 1080px)': {
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70vw'
  }
}));

const Dropdowns = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  '& .select__control': {
    backgroundColor: theme?.palette?.mode === 'dark' ? '#2d2d2d' : 'white',
    height: '3rem',
    overflow: 'auto'
  },
  '& .select__menu': {
    backgroundColor: theme?.palette?.mode === 'dark' ? '#2d2d2d' : 'white',
  },
  '@media screen and (min-width: 320px) and (max-width: 1080px)': {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

// Updated StyledSelect component
const selectStyles = (theme) => ({
 
  control: (base) => ({
    ...base,
    width: '40vw',
    margin: '0.5rem',
    backgroundColor: theme.palette.mode === 'dark' ? '#2a2a2a' : theme.palette.background.paper,
    borderColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.palette.mode === 'dark' ? '#505050' : theme.palette.primary.main
    },
    minHeight: '56px'
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? '#2a2a2a' : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: 4
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected 
      ? theme.palette.mode === 'dark' ? '#3b82f6' : theme.palette.primary.main
      : state.isFocused 
        ? theme.palette.mode === 'dark' ? '#404040' : theme.palette.action.hover
        : 'transparent',
    color: state.isSelected
      ? '#ffffff'
      : theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.action.hover
    },
    cursor: 'pointer',
    padding: '8px 12px'
  }),
  input: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  placeholder: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#808080' : theme.palette.text.secondary
  }),
  singleValue: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.grey[200]
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#808080' : theme.palette.grey[600],
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#505050' : theme.palette.grey[300],
      color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.error.main
    }
  })
});
// Updated SprintSelect component
const SprintSelect = styled('select')(({ theme }) => ({
  textDecoration: 'none',
  border: '1px solid #404040',
  borderRadius: '4px',
  width: '15em',
  height: '3rem',
  padding: '0 0.5rem',
  backgroundColor: '#2B2B2B',
  color: 'white',
  cursor: 'pointer',
  '&:focus': {
    borderColor: '#0077b6',
    outline: 'none',
    boxShadow: '0 0 0 1px #0077b6'
  },
  '& option': {
    backgroundColor: '#2B2B2B',
    color: 'white'
  },
  '@media screen and (min-width: 320px) and (max-width: 1080px)': {
    width: '10em'
  }
}));
const TitleText = styled(Typography)(({ theme }) => ({
  height: '10%',
  color: theme?.palette?.mode === 'dark' ? 'white' : 'black',
  fontWeight: 600,
  cursor: 'pointer',
  fontSize: '18px',
  fontFamily: 'Poppins, Medium'
}));

const PodMetricsmanager = () => {
  const [podData, setPodData] = useState([]);
  const [selectedPod, setSelectedPod] = useState("");
  const [fetchMetrics, setFetchMetrics] = useState("");
  const [selectedPods, setSelectedPods] = useState([]);
  const [sprintData, setSprintData] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState({
    name: "", // Selected sprint name
    startDate: "", // Selected sprint start date
    endDate: "", // Selected sprint end date
  });
  const intl = useIntl();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();

  useEffect(() => {
    if (user.data[0].type === "stakeholder") {
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }
      fetch(process.env.REACT_APP_STAKEHOLDER_SPRINTFILTERSTAKEHOLDER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          org_code: user.data[0].client_code,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200 && data.body) {
            const parsedBody = JSON.parse(data.body);
            if (Array.isArray(parsedBody.sprint_filter)) {
              setSprintData(parsedBody.sprint_filter);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data from stakeholder API:", error);
        });
    } else {
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }
      fetch(process.env.REACT_APP_CLIENT_SPRINTFILTERMANAGER, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          org_code: user.data[0].client_code,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200 && data.body) {
            const parsedBody = JSON.parse(data.body);
            if (Array.isArray(parsedBody.sprint_filter)) {
              setSprintData(parsedBody.sprint_filter);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [token]);

  useEffect(() => {
    const fetchPodData = async () => {
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }
  
      if (!user?.data?.[0]) {
        console.error("User data is missing or invalid");
        return;
      }
  
      try {
        const isStakeholder = user.data[0].type === "stakeholder";
        const apiUrl = isStakeholder 
          ? process.env.REACT_APP_STAKEHOLDER_GETPODSFORSTAKEHOLDER 
          : process.env.REACT_APP_CLIENT_GETPODSFORMANAGER;
  
        const requestBody = isStakeholder
          ? { org_code: user.data[0].client_code }
          : { emp_id: user.data[0].emp_id };
  
        const headers = {
          'Content-Type': 'application/json',
          ...(isStakeholder ? {} : { Authorization: token })
        };
  
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers,
          body: JSON.stringify(requestBody)
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
  
        if (data.statusCode !== 200) {
          throw new Error(`API error: ${data.statusCode}`);
        }
  
        if (!Array.isArray(data.body)) {
          throw new Error('Invalid response format: body is not an array');
        }
  
        setPodData(data.body);
  
      } catch (error) {
        console.error('Error fetching pod data:', {
          message: error.message,
          stack: error.stack,
          cause: error.cause
        });
        
        // You might want to set an error state here if you have one
        // setError('Failed to fetch pod data. Please try again later.');
      }
    };
  
    fetchPodData();
  }, [token, user]);
  const podOptions = podData.map((pod) => ({
    value: pod.pod_id,
    label: pod.pod_name,
  }));

  const handlePodSelect = (selectedOptions) => {
    setSelectedPods(selectedOptions.map((option) => option.value));
  };

  const handleSprintSelect = (e) => {
    const selectedSprintName = e.target.value;
    const selectedSprintData = sprintData.find(
      (sprint) => sprint.sprint_name === selectedSprintName
    );
    setSelectedSprint({
      name: selectedSprintName,
      startDate: selectedSprintData ? selectedSprintData.sprint_start_date : "",
      endDate: selectedSprintData ? selectedSprintData.sprint_end_date : "",
    });
  };

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      flexDirection: "column",
    }}>
      <div style={{
        display: "inline",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        flexDirection: "column",
        marginTop: "5rem",
      }}>
        <TitleText>{intl.formatMessage({ id: "podAnalytics" })} </TitleText>
      </div>

      <YourProjects2>
        <Dropdowns>
          <Select
            options={podOptions}
            isMulti
            value={podOptions.filter((option) =>
              selectedPods.includes(option.value)
            )}
            onChange={(selectedOptions) => 
              setSelectedPods(selectedOptions?.map((option) => option.value) || [])
            }
            placeholder={intl.formatMessage({ id: "selectPods" })}
            styles={selectStyles(theme)}
          />
          <SprintSelect
            value={selectedSprint.name}
            onChange={handleSprintSelect}
          >
            <option value="">Select a Sprint</option>
            {sprintData.map((sprint, index) => (
              <option key={index} value={sprint.sprint_name}>
                {sprint.sprint_name}
              </option>
            ))}
          </SprintSelect>
        </Dropdowns>
        <Border1>
          <Project>
            <div>
              <CicdPiechartmanagerdetailvivew initialPodIds={selectedPods} />
            </div>
            <div>
              <ProjectPieChart initialPodIds={selectedPods} />
            </div>
            <div>
              <CloudCost initialPodIds={selectedPods} />
            </div>
            <div style={{ marginLeft: "5px" }}>
              <DetailbitbucketPRcountmanager initialPodIds={selectedPods} />
            </div>
            <div style={{ marginLeft: "5px" }}>
              <TicketsDetailviewmetrics initialPodIds={selectedPods} />
            </div>
          </Project>
        </Border1>
      </YourProjects2>

      <YourProjects2>
        <Border1>
          <Project>
            <div>
              <DetailBitbucketcodemanager initialPodIds={selectedPods} />
            </div>
            <div>
              <SprintVelocitiesChart initialPodIds={selectedPods} />
            </div>
          </Project>
        </Border1>
      </YourProjects2>

      <YourProjects2>
        <Border1>
          <h6 style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
            {intl.formatMessage({ id: "doraMetrics" })}
          </h6>
          <Project>
            <div>
              <Dorametricslinegraph />
            </div>
          </Project>
        </Border1>
      </YourProjects2>
    </div>
  );
};

export default PodMetricsmanager;
