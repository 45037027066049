import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Divider,
  CssBaseline,
  Tooltip,
  useTheme as useMUITheme,
} from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { 
  FaHome, FaListAlt, FaTachometerAlt, FaQuestionCircle, 
  FaSearch, FaChartLine, FaChartBar, FaTasks, FaComment, 
  FaInfoCircle, FaChartPie, FaPlusCircle, FaClipboardList, 
  FaBars, FaRobot 
} from 'react-icons/fa';
import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PeopleIcon from '@mui/icons-material/People';
import { useIntl } from 'react-intl';
import {

  SyncAlt,
} from '@mui/icons-material';
const drawerWidth = 240;
const collapsedWidth = 60;

const DrawerStyled = styled(Drawer)`
  & .MuiDrawer-paper {
    width: ${(props) => (props.collapsed ? `${collapsedWidth}px` : `${drawerWidth}px`)};
    transition: width 0.3s ease, background-color 0.3s ease;
    box-sizing: border-box;
   
    background-color: ${props => props.theme.palette.mode === 'dark' ? '#121212' : '#1a1a1a'};
     color:white;
    overflow: auto !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    
    @media screen and (max-width: 768px) {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none !important;
    }
  }
`;

const DrawerHeader = styled.div`
  display: flex;
   margin-top:5rem;
  align-items: center;
  padding: 0 8px;
  min-height: 64px;
  justify-content: flex-end;
`;

const StyledListItem = styled(ListItem)`
  color:white;
  padding-left: ${(props) => (props.collapsed ? '16px' : '24px')};

  &.Mui-selected {
    background-color: ${props => props.theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.16)' : 'rgba(255, 255, 255, 0.08)'} !important;
  }

  &:hover {
    background-color: ${props => props.theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(255, 255, 255, 0.04)'} !important;
  }

  & .MuiListItemIcon-root {
  color:white;
    min-width: ${(props) => (props.collapsed ? 'auto' : '56px')};
    justify-content: center;
  }

  & .MuiListItemText-root {
    display: ${(props) => (props.collapsed ? 'none' : 'block')};
   color:white;
  }
`;

const IconWrapper = styled.div`
  font-size: 24px;
   color:white;
`;

const StyledDivider = styled(Divider)`
  background-color: ${props => props.theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(255, 255, 255, 0.08)'};
`;

const Sidebarnew = ({ open, onClose, collapsed, setCollapsed }) => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const intl = useIntl();
  const theme = useMUITheme();

  const menuItems = [
    { 
      text: intl.formatMessage({ id: 'home' }), 
      icon: <Tooltip title="Home" arrow><span><FaHome /></span></Tooltip>, 
      path: '/' 
    },
    ...(user.data[0].type === 'Candidate'
      ? [
          { 
            text: intl.formatMessage({ id: 'dashboard' }), 
            icon: <Tooltip title="Dashboard" arrow><span><FaTachometerAlt /></span></Tooltip>, 
            path: '/candidate-dashboard' 
          },
          { 
            text: intl.formatMessage({ id: 'manageProfile' }), 
            icon: <Tooltip title="Manage Profile" arrow><span><FaInfoCircle /></span></Tooltip>, 
            path: '/Updateyourprofile' 
          },
          
          { 
            text: intl.formatMessage({ id: 'myPods' }), 
            icon: <Tooltip title="My Pods" arrow><span><PeopleIcon /></span></Tooltip>, 
            path: '/Pods' 
          },
          // { 
          //   text: intl.formatMessage({ id: 'myTasks' }), 
          //   icon: <Tooltip title="My Tasks" arrow><span><FaTasks /></span></Tooltip>, 
          //   path: '/TalentViewTask' 
          // },
          { 
            text: intl.formatMessage({ id: 'podBoard' }), 
            icon: <Tooltip title="Pod Board" arrow><span><FaTachometerAlt /></span></Tooltip>, 
            path: '/KanbanboardTalent' 
          },
          { 
            text: intl.formatMessage({ id: 'podMetrics' }), 
            icon: <Tooltip title="Pod Metrics" arrow><span><FaChartBar /></span></Tooltip>, 
            path: '/PodMetricsTalentDetailview' 
          },
          { 
            text: intl.formatMessage({ id: 'podAssist' }), 
            icon: <Tooltip title="Pod Assistant" arrow><span><FaQuestionCircle /></span></Tooltip>, 
            path: '/OpenAi' 
          },
          // { 
          //   text: intl.formatMessage({ id: 'findPods' }), 
          //   icon: <Tooltip title="Find Pods" arrow><span><FaSearch /></span></Tooltip>, 
          //   path: '/FindPods' 
          // },
         
          
         
          
          { 
            text: intl.formatMessage({ id: 'podFeedback' }), 
            icon: <Tooltip title="Pod Feedback" arrow><span><FaComment /></span></Tooltip>, 
            path: '/Reviewsandratingcandidate' 
          },
          { 
            text: intl.formatMessage({ id: 'podsync' }), 
            icon: <Tooltip title="Pod Sync" arrow><span>< SyncAlt /></span></Tooltip>, 
            path: '/MeetingSummariesTimeline' 
          },
         
        ]
      : []),
    ...(user.data[0].type === 'Client'
      ? [
          { 
            text: intl.formatMessage({ id: 'dashboard' }), 
            icon: <Tooltip title="Dashboard" arrow><span><FaTachometerAlt /></span></Tooltip>, 
            path: '/client-dashboard' 
          },
          { 
            text: intl.formatMessage({ id: 'manageProfile' }), 
            icon: <Tooltip title="Manage Profile" arrow><span><FaInfoCircle /></span></Tooltip>, 
            path: '/client-profile' 
          },
          { 
            text: intl.formatMessage({ id: 'myPods' }), 
            icon: <Tooltip title="My Pods" arrow><span><PeopleIcon /></span></Tooltip>, 
            path: '/Team' 
          },
          { 
            text: intl.formatMessage({ id: 'createPod' }), 
            icon: <Tooltip title="Create Pod" arrow><span><FaPlusCircle /></span></Tooltip>, 
            path: '/client-createpod' 
          },
          // { 
          //   text: intl.formatMessage({ id: 'findPods' }), 
          //   icon: <Tooltip title="Find Pods" arrow><span><FaSearch /></span></Tooltip>, 
          //   path: '/FindPodsClient' 
          // },
          { 
            text: intl.formatMessage({ id: 'podActivity' }), 
            icon: <Tooltip title="Pod Activity" arrow><span><FaListAlt /></span></Tooltip>, 
            path: '/PodActivity' 
          },
       
          { 
            text: intl.formatMessage({ id: 'podTasks' }), 
            icon: <Tooltip title="Pod Tasks" arrow><span><FaTasks /></span></Tooltip>, 
            path: '/ViewPodTasksManagerbyid' 
          },
          { 
            text: intl.formatMessage({ id: 'POD Productivity' }), 
            icon: <Tooltip title="Pod Productivity" arrow><span><RocketLaunchIcon /></span></Tooltip>, 
            path: '/PodProductivity' 
          },
          { 
            text: intl.formatMessage({ id: 'podBoard' }), 
            icon: <Tooltip title="Pod Board" arrow><span><FaTachometerAlt /></span></Tooltip>, 
            path: '/KanbanBoard' 
          },
          { 
            text: intl.formatMessage({ id: 'podRetro' }), 
            icon: <Tooltip title="Pod Retro" arrow><span><FaClipboardList /></span></Tooltip>, 
            path: '/Podretromanager' 
          },
        
          { 
            text: intl.formatMessage({ id: 'podMetrics' }), 
            icon: <Tooltip title="Pod Metrics" arrow><span><FaChartBar /></span></Tooltip>, 
            path: '/PodMetricsmanager' 
          },
          { 
            text: intl.formatMessage({ id: 'podAssist' }), 
            icon: <Tooltip title="Pod Assistant" arrow><span><FaQuestionCircle /></span></Tooltip>, 
            path: '/OpenAi' 
          },
          { 
            text: intl.formatMessage({ id: 'podQuery' }), 
            icon: <Tooltip title="Pod Query" arrow><span><FaChartPie /></span></Tooltip>, 
            path: '/PodInsights' 
          },
          
          { 
            text: intl.formatMessage({ id: 'podAutomation' }), 
            icon: <Tooltip title="Pod Automation" arrow><span><FaRobot /></span></Tooltip>, 
            path: '/PodAutomation' 
          },
          { 
            text: intl.formatMessage({ id: 'podsync' }), 
            icon: <Tooltip title="Pod Sync" arrow><span>< SyncAlt /></span></Tooltip>, 
            path: '/MeetingSummariesTimeline' 
          },
        
          ...(user.data[0].manager_type === 'PartnerClient' 
            ? [{ 
                text: intl.formatMessage({ id: 'partnerMetrics' }), 
                icon: <Tooltip title="Partner Metrics" arrow><span><FaChartLine /></span></Tooltip>, 
                path: '/PartnerMetricsDashboard' 
              }]
            : []
          ),
        ]
      : []),
    ...(user.data[0].type === 'stakeholder'
      ? [
          { 
            text: intl.formatMessage({ id: 'dashboard' }), 
            icon: <Tooltip title="Dashboard" arrow><span><FaTachometerAlt /></span></Tooltip>, 
            path: '/StakeholderDashboard' 
          },
          { 
            text: intl.formatMessage({ id: 'podActivity' }), 
            icon: <Tooltip title="Pod Activity" arrow><span><FaListAlt /></span></Tooltip>, 
            path: '/PodActivitystakeholderviewall' 
          },
          { 
            text: intl.formatMessage({ id: 'podMetrics' }), 
            icon: <Tooltip title="Pod Metrics" arrow><span><FaChartBar /></span></Tooltip>, 
            path: '/PodMetricsmanager' 
          },
          { 
            text: intl.formatMessage({ id: 'manageProfile' }), 
            icon: <Tooltip title="Manage Profile" arrow><span><FaInfoCircle /></span></Tooltip>, 
            path: '/stakeholder-profile' 
          },
        ]
      : []),
  ];
  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <DrawerStyled 
      variant="persistent" 
      anchor="left" 
      open={open} 
      collapsed={collapsed}
      theme={theme}
    >
      <DrawerHeader>
        <IconButton onClick={handleToggleCollapse}>
          <MenuIcon sx={{ color: '#fff' }} />
        </IconButton>
      </DrawerHeader>
      <StyledDivider theme={theme} />
      <List>
        {menuItems.map((item) => (
          <Tooltip 
            key={item.text}
            title={collapsed ? item.text : ""} 
            placement="right"
            arrow
          >
            <StyledListItem
              button
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              collapsed={collapsed}
              theme={theme}
            >
              <ListItemIcon collapsed={collapsed}>
                <IconWrapper theme={theme}>{item.icon}</IconWrapper>
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          </Tooltip>
        ))}
      </List>
    </DrawerStyled>
  );
};

export default Sidebarnew;