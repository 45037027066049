import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  Grid,
  Button,
  Divider,
  Rating,
  Paper,
  Stack,
  Chip,
  List,
  ListItem,
  CircularProgress,
  Link as MuiLink,
  Breadcrumbs,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  alpha,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Email as EmailIcon,
  Description as DescriptionIcon,
  Group as GroupIcon,
  Warning as WarningIcon,
  NavigateNext,
  ArrowBack,
  Code as CodeIcon,
  LocationOn as LocationIcon,
  CalendarToday as CalendarIcon,
  AttachMoney as MoneyIcon,
  Person as PersonIcon,
  Language as LanguageIcon,
  StarRate as StarIcon
} from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useAuthToken } from "../TokenContext";
import axios from "axios";

// Styled components with dark mode theming
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: theme.palette.mode === 'dark'
    ? '0 8px 24px rgba(0, 0, 0, 0.5)'
    : '0 8px 24px rgba(0, 0, 0, 0.12)',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(145deg, #1e1e1e 0%, #2d2d2d 100%)'
    : 'linear-gradient(145deg, #f0f4f8 0%, #e7f0f7 100%)',
  overflow: 'visible',
  position: 'relative',
  marginBottom: theme.spacing(4),
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 12px 28px rgba(0, 0, 0, 0.7)'
      : '0 12px 28px rgba(0, 0, 0, 0.18)'
  }
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : theme.palette.background.default,
  marginBottom: theme.spacing(4),
  boxShadow: theme.palette.mode === 'dark'
    ? '0 6px 18px rgba(0, 0, 0, 0.5)'
    : '0 6px 18px rgba(0, 0, 0, 0.12)',
  transition: 'transform 0.3s',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(145deg, #1e1e1e 0%, #2d2d2d 100%)'
    : 'linear-gradient(145deg, #ffffff 0%, #f7f9fc 100%)',
  border: theme.palette.mode === 'dark'
    ? '1px solid rgba(50, 55, 60, 0.9)'
    : '1px solid rgba(230, 235, 240, 0.9)',
  '&:hover': {
    transform: 'translateY(-3px)'
  }
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
  color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : 'inherit'
}));

const MemberLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.mode === 'dark' ? theme.palette.primary.light : 'inherit',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const GradientAvatar = styled(Avatar)(({ theme }) => ({
  width: 90,
  height: 90,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 20px rgba(0, 0, 0, 0.5)'
    : '0 4px 20px rgba(0, 0, 0, 0.15)',
  border: `3px solid ${theme.palette.mode === 'dark' ? '#2d2d2d' : '#fff'}`,
  background: 'linear-gradient(45deg, #3f51b5, #2196f3)'
}));

const StyledChip = styled(Chip)(({ theme, color = 'primary' }) => ({
  borderRadius: '6px',
  fontWeight: 500,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 2px 5px rgba(0, 0, 0, 0.25)'
    : '0 2px 5px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 8px rgba(0, 0, 0, 0.4)'
      : '0 4px 8px rgba(0, 0, 0, 0.12)'
  }
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  position: 'relative',
  borderRadius: theme.spacing(3),
  overflow: 'hidden',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 20px rgba(0, 0, 0, 0.5)'
    : '0 4px 20px rgba(0, 0, 0, 0.15)',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(120deg, #0d47a1, #2979ff)'
    : 'linear-gradient(120deg, #1976d2, #64b5f6)',
  padding: {
    xs: theme.spacing(3),
    md: theme.spacing(5)
  }
}));

const MemberCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  overflow: 'visible',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  transition: 'all 0.3s ease',
  background: theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0, 0, 0, 0.4)'
    : '0 4px 12px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 12px 20px rgba(0, 0, 0, 0.6)'
      : '0 12px 20px rgba(0, 0, 0, 0.1)'
  }
}));

const ReviewsSection = styled(StyledPaper)(({ theme }) => ({
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(145deg, #1e1e1e 0%, #2d2d2d 100%)'
    : 'linear-gradient(145deg, #ffffff 0%, #f8f9fb 100%)'
}));

const ReviewHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(90deg, rgba(255, 180, 0, 0.15) 0%, rgba(255, 180, 0, 0.05) 100%)'
    : 'linear-gradient(90deg, rgba(255, 180, 0, 0.12) 0%, rgba(255, 180, 0, 0.03) 100%)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  border: theme.palette.mode === 'dark'
    ? '1px solid rgba(255, 180, 0, 0.3)'
    : '1px solid rgba(255, 180, 0, 0.2)'
}));

const ReviewCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  height: '100%',
  position: 'relative',
  overflow: 'visible',
  transition: 'all 0.2s ease',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(145deg, #2d2d2d 0%, #1e1e1e 100%)'
    : 'linear-gradient(145deg, #ffffff 0%, #f8f9fb 100%)',
  border: theme.palette.mode === 'dark'
    ? '1px solid rgba(80, 90, 100, 0.6)'
    : '1px solid rgba(200, 210, 220, 0.6)',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 20,
    left: -15,
    width: 30,
    height: 30,
    backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#f8f9fb',
    transform: 'rotate(45deg)',
    borderLeft: theme.palette.mode === 'dark'
      ? '1px solid rgba(80, 90, 100, 0.6)'
      : '1px solid rgba(200, 210, 220, 0.6)',
    borderBottom: theme.palette.mode === 'dark'
      ? '1px solid rgba(80, 90, 100, 0.6)'
      : '1px solid rgba(200, 210, 220, 0.6)',
    boxShadow: theme.palette.mode === 'dark'
      ? '-2px 2px 5px rgba(0, 0, 0, 0.2)'
      : '-2px 2px 5px rgba(0, 0, 0, 0.03)',
    display: { xs: 'none', sm: 'block' }
  },
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 10px 20px rgba(0, 0, 0, 0.4)'
      : '0 10px 20px rgba(0, 0, 0, 0.12)'
  }
}));

const StyledDialogPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  boxShadow: theme.palette.mode === 'dark'
    ? '0 8px 32px rgba(0, 0, 0, 0.7)'
    : '0 8px 32px rgba(0, 0, 0, 0.2)',
  padding: theme.spacing(1),
  background: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff'
}));

const FindPodDetailsClient = () => {
  const token = useAuthToken();
  const theme = useTheme();
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const [podDetails, setPodDetails] = useState({});
  const [candidates, setCandidates] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchPodDetails();
    return () => {
      setPodDetails({});
      setCandidates([]);
      setReviews([]);
    };
  }, [token]);

  const handleBackToFindPods = () => {
    history.push('/FindPodsClient');
  };

  const fetchPodDetails = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETPODID,
        { id },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          }
        }
      );
      setPodDetails(response.data.data.podDetails);
      setCandidates(response.data.data.candidates);
      setReviews(response.data.data.podDetails.reviews)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pod details:", error);
      setLoading(false);
    }
  };

  const handleHirePod = async () => {
    if (!user?.subscriptiondata?.length || user.subscriptiondata[0].Status !== "active") {
      setDialogOpen(true);
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_HIREPOD,
        {
          podID: podDetails.id,
          clientID: user.data[0].emp_id
        },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          }
        }
      );
      alert(response.data.message || 'Pod hired successfully!');
      window.location.reload();
    } catch (error) {
      console.error("Error hiring pod:", error);
      alert('Failed to hire pod. Please try again.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  const TIME_INTERVALS = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];

  // Function to calculate time since a given date
  const timeSince = (date) => {
    const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
    const interval = TIME_INTERVALS.find(i => i.seconds < seconds);
    
    if (!interval) {
      return 'just now';
    }
    
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  };

  // Format date if available
  const formatDate = (dateString) => {
    if (!dateString) return 'Not specified';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      {/* Header with background */}
      <Box 
        sx={{ 
          mb: 6, 
          position: 'relative',
          borderRadius: 3,
          overflow: 'hidden',
          boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
          background: 'linear-gradient(120deg, #1976d2, #64b5f6)',
          p: { xs: 3, md: 5 }
        }}
      >
        <Box 
          sx={{ 
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.1,
            backgroundImage: 'url("data:image/svg+xml,%3Csvg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z" fill="%23ffffff" fill-opacity="1" fill-rule="evenodd"/%3E%3C/svg%3E")'
          }}
        />
        
        {/* Breadcrumbs */}
        <Breadcrumbs 
          separator={<NavigateNext fontSize="small" sx={{ color: 'white' }} />} 
          sx={{ mb: 3 }}
        >
          <Button
            onClick={handleBackToFindPods}
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              color: 'rgba(255, 255, 255, 0.8)',
              textTransform: 'none',
              fontSize: '0.875rem',
              '&:hover': {
                color: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)'
              }
            }}
            startIcon={<ArrowBack fontSize="small" />}
          >
            Find Pods
          </Button>
          <Typography sx={{ color: 'white', fontWeight: 'medium' }}>Pod Details</Typography>
        </Breadcrumbs>
        
        <Typography 
          variant="h3" 
          fontWeight={700} 
          sx={{ 
            color: 'white',
            textShadow: '0 2px 4px rgba(0,0,0,0.2)'
          }}
        >
          Pod Details
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {/* Main Pod Info Card */}
        <Grid item xs={12}>
          <StyledCard elevation={6}>
            <CardContent sx={{ pb: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8} md={9}>
                  <Box display="flex" alignItems="flex-start">
                    <GradientAvatar
                      src={podDetails.picture ? `https://globldataprod.s3.amazonaws.com/${podDetails.picture}` : '/defaultimg.png'}
                    />
                    <Box sx={{ ml: 3, flexGrow: 1 }}>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4" sx={{ fontWeight: 600, mb: 1 }}>
                          {podDetails.podName}
                        </Typography>
                        {podDetails.status === "Hired" ? (
                          <StyledChip 
                            label="Already Hired"
                            color="success"
                            sx={{ fontWeight: 'bold' }}
                          />
                        ) : user?.data[0]?.type !== "PartnerGuest" && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleHirePod}
                            size="small"
                            sx={{ 
                              boxShadow: '0 4px 10px rgba(33, 150, 243, 0.3)',
                              px: 2,
                              '&:hover': {
                                boxShadow: '0 6px 14px rgba(33, 150, 243, 0.4)', 
                              }
                            }}
                          >
                            Hire Pod
                          </Button>
                        )}
                      </Box>
                      
                      <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                        <Rating 
                          value={Number(podDetails.avgrating) || 0} 
                          readOnly 
                          precision={0.5} 
                          icon={<StarIcon fontSize="inherit" color="primary" />}
                        />
                        <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary', fontWeight: 500 }}>
                          ({podDetails.avgrating || 0})
                        </Typography>
                      </Box>

                      <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                        <StyledChip 
                          label={podDetails.status || 'Status unknown'} 
                          color={podDetails.status === 'Active' ? 'success' : 'default'}
                          size="small"
                        />
                        <StyledChip 
                          label={podDetails.podtype || 'Type unknown'} 
                          color="primary" 
                          variant="outlined"
                          size="small"
                        />
                        <StyledChip 
                          label={`Tech: ${podDetails.techStack || 'Unknown'}`}
                          color="info" 
                          variant="outlined"
                          size="small"
                          icon={<CodeIcon fontSize="small" />}
                        />
                        <StyledChip 
                          label={`Size: ${podDetails.podsize || 0}`}
                          color="secondary" 
                          variant="outlined"
                          size="small"
                          icon={<PersonIcon fontSize="small" />}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Divider sx={{ my: 3 }} />

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <DetailRow>
                    <EmailIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>{podDetails.email || 'No email provided'}</Typography>
                  </DetailRow>
                  
                  <DetailRow>
                    <DescriptionIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                    <Typography variant="body1">
                      {podDetails.podDescription || 'No description provided'}
                    </Typography>
                  </DetailRow>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <DetailRow>
                    <LocationIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                    <Typography variant="body1">
                      {podDetails.location || 'Location not specified'}
                    </Typography>
                  </DetailRow>
                  
                  <DetailRow>
                    <CalendarIcon sx={{ mr: 1.5, color: 'primary.main' }} />
                    <Typography variant="body1">
                      Created on: {formatDate(podDetails.created_at)}
                    </Typography>
                  </DetailRow>
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Team Members Section */}
        <Grid item xs={12}>
          <StyledPaper elevation={4}>
            <Box display="flex" alignItems="center" mb={2}>
              <GroupIcon sx={{ mr: 2, color: 'primary.main', fontSize: 28 }} />
              <Typography variant="h5" fontWeight={600}>
                Pod Team Members
              </Typography>
            </Box>
            <Divider sx={{ mb: 3 }} />
            
            <Grid container spacing={3}>
              {candidates
                .filter(member => member.talentcheck === "0")
                .map((member) => (
                  <Grid item xs={12} sm={6} md={4} key={member.c_id}>
                    <Card 
                      elevation={2} 
                      sx={{ 
                        borderRadius: 2,
                        overflow: 'visible',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-8px)',
                          boxShadow: '0 12px 20px rgba(0,0,0,0.1)'
                        }
                      }}
                    >
                      <Box 
                        sx={{ 
                          position: 'absolute', 
                          top: -15, 
                          left: '50%', 
                          transform: 'translateX(-50%)',
                          zIndex: 2 
                        }}
                      >
                        <Avatar
                          src={member.profilePicture ? `https://globldataprod.s3.amazonaws.com/${member.profilePicture}` : '/defaultimg.png'}
                          sx={{ 
                            width: 70, 
                            height: 70, 
                            border: '4px solid #fff',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
                            background: 'linear-gradient(135deg, #64b5f6, #1976d2)'
                          }}
                        />
                      </Box>
                      
                      <CardContent sx={{ pt: 6, textAlign: 'center' }}>
                        <Link 
                          to={{ 
                            pathname: '/Candidatedetailsbyid', 
                            state: { c_id: member.c_id } 
                          }}
                          style={{ textDecoration: 'none' }}
                        >
                          <Typography 
                            variant="h6" 
                            fontWeight={600} 
                            color="primary.main"
                            sx={{ mb: 0.5 }}
                          >
                            {member.firstName} {member.lastName}
                          </Typography>
                        </Link>
                        
                        <Typography 
                          variant="subtitle1" 
                          color="text.secondary"
                          sx={{ mb: 2 }}
                        >
                          {member.about || member.work_position || 'Team Member'}
                        </Typography>
                        
                        <Box display="flex" justifyContent="center" mb={2}>
                          <StyledChip
                            label={member.primaryskill || 'Skill'}
                            color="primary"
                            size="small"
                            sx={{ mr: 1 }}
                          />
                          {member.secondaryskill && (
                            <StyledChip
                              label={member.secondaryskill}
                              color="info"
                              size="small"
                            />
                          )}
                        </Box>
                        
                        <Box 
                          sx={{ 
                            mt: 'auto', 
                            pt: 2, 
                            borderTop: '1px solid', 
                            borderTopColor: 'divider'
                          }}
                        >
                          <StyledChip 
                            label={`${member.ratePerhour || 0}/hr`} 
                            color="success"
                            sx={{ 
                              fontWeight: 'bold',
                              fontSize: '0.9rem'
                            }}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              {candidates.filter(member => member.talentcheck === "0").length === 0 && (
                <Grid item xs={12}>
                  <Paper 
                    elevation={0} 
                    sx={{ 
                      py: 4, 
                      px: 2, 
                      textAlign: 'center',
                      background: alpha('#e3f2fd', 0.5),
                      borderRadius: 2
                    }}
                  >
                    <Typography color="text.secondary" variant="h6">
                      No team members available to display
                    </Typography>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </StyledPaper>
        </Grid>

        {/* Reviews Section */}
        <Grid item xs={12}>
          <StyledPaper elevation={6}>
            <Box 
              display="flex" 
              alignItems="center" 
              mb={3}
              sx={{
                background: 'linear-gradient(90deg, rgba(255, 180, 0, 0.12) 0%, rgba(255, 180, 0, 0.03) 100%)',
                borderRadius: 2,
                padding: 2,
                border: '1px solid rgba(255, 180, 0, 0.2)'
              }}
            >
              <StarIcon sx={{ mr: 2, color: '#FFB400', fontSize: 32 }} />
              <Typography variant="h5" fontWeight={600} color="#B07800">
                Pod Reviews
              </Typography>
            </Box>
            <Divider sx={{ mb: 4, borderColor: 'rgba(0, 0, 0, 0.1)', borderWidth: 1 }} />
            
            {reviews && reviews.length > 0 ? (
  <Grid container spacing={3}>
    {reviews.map((review, index) => (
      <Grid item xs={12} md={6} key={index}>
        <Card
          elevation={3}
          sx={{
            borderRadius: 2,
            padding: 3,
            height: '100%',
            position: 'relative',
            overflow: 'visible',
            transition: 'all 0.2s ease',
            background: theme => theme.palette.mode === 'dark'
              ? 'linear-gradient(145deg, #2d2d2d 0%, #1e1e1e 100%)'
              : 'linear-gradient(145deg, #ffffff 0%, #f8f9fb 100%)',
            border: theme => theme.palette.mode === 'dark'
              ? '1px solid rgba(55, 65, 75, 0.6)'
              : '1px solid rgba(200, 210, 220, 0.6)',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 20,
              left: -15,
              width: 30,
              height: 30,
              backgroundColor: theme => theme.palette.mode === 'dark' ? '#2d2d2d' : '#f8f9fb',
              transform: 'rotate(45deg)',
              borderLeft: theme => theme.palette.mode === 'dark'
                ? '1px solid rgba(55, 65, 75, 0.6)'
                : '1px solid rgba(200, 210, 220, 0.6)',
              borderBottom: theme => theme.palette.mode === 'dark'
                ? '1px solid rgba(55, 65, 75, 0.6)'
                : '1px solid rgba(200, 210, 220, 0.6)',
              boxShadow: theme => theme.palette.mode === 'dark'
                ? '-2px 2px 5px rgba(0, 0, 0, 0.2)'
                : '-2px 2px 5px rgba(0, 0, 0, 0.03)',
              display: { xs: 'none', sm: 'block' }
            },
            '&:hover': {
              transform: 'translateY(-5px)',
              boxShadow: theme => theme.palette.mode === 'dark'
                ? '0 10px 20px rgba(0, 0, 0, 0.4)'
                : '0 10px 20px rgba(0, 0, 0, 0.12)'
            }
          }}
        >
          <Box 
            display="flex" 
            alignItems="center" 
            mb={2}
            sx={{
              pb: 2,
              borderBottom: theme => theme.palette.mode === 'dark'
                ? '1px solid rgba(255, 255, 255, 0.07)'
                : '1px solid rgba(0, 0, 0, 0.07)'
            }}
          >
            <Avatar
              src={review.profilePicture}
              sx={{ 
                mr: 2, 
                width: 50, 
                height: 50,
                border: theme => theme.palette.mode === 'dark'
                  ? '2px solid #333'
                  : '2px solid #fff',
                boxShadow: theme => theme.palette.mode === 'dark'
                  ? '0 3px 10px rgba(0, 0, 0, 0.3)'
                  : '0 3px 10px rgba(0, 0, 0, 0.15)',
                background: 'linear-gradient(135deg, #90caf9, #42a5f5)'
              }}
            />
            <Box>
              <Typography 
                variant="h6" 
                fontWeight={600}
                sx={{
                  color: theme => theme.palette.mode === 'dark' ? '#fff' : 'inherit'
                }}
              >
                Client
              </Typography>
              <Typography 
                variant="caption" 
                color={theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'text.secondary'} 
                sx={{ fontSize: '0.85rem' }}
              >
                {timeSince(new Date(review.r_createdAt))}
              </Typography>
            </Box>
          </Box>
          
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              background: theme => theme.palette.mode === 'dark'
                ? 'rgba(255, 180, 0, 0.12)'
                : 'rgba(255, 180, 0, 0.07)',
              borderRadius: 1.5,
              py: 1,
              px: 2,
              mb: 2,
              border: theme => theme.palette.mode === 'dark'
                ? '1px solid rgba(255, 180, 0, 0.25)'
                : '1px solid rgba(255, 180, 0, 0.15)'
            }}
          >
            <Rating 
              value={Number(review.rating)} 
              readOnly 
              precision={0.5}
              size="large"
              sx={{ 
                color: '#FFB400',
                '& .MuiRating-iconFilled': {
                  filter: 'drop-shadow(0 1px 2px rgba(255, 180, 0, 0.5))'
                }
              }}
            />
            <Typography 
              variant="subtitle1" 
              fontWeight={600} 
              color={theme => theme.palette.mode === 'dark' ? '#FFB400' : '#B07800'}
              sx={{ ml: 1 }}
            >
              {Number(review.rating).toFixed(1)}
            </Typography>
          </Box>
          
          <Typography 
            variant="body1" 
            sx={{ 
              mt: 2,
              fontStyle: review.review ? 'normal' : 'italic',
              color: theme => review.review 
                ? (theme.palette.mode === 'dark' ? theme.palette.grey[300] : 'text.primary') 
                : (theme.palette.mode === 'dark' ? theme.palette.grey[500] : 'text.secondary'),
              backgroundColor: theme => review.review 
                ? 'transparent' 
                : (theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)'),
              padding: review.review ? 0 : 2,
              borderRadius: review.review ? 0 : 1,
              fontWeight: review.review ? 400 : 300
            }}
          >
            {review.review || 'No comment provided'}
          </Typography>
        </Card>
      </Grid>
    ))}
  </Grid>
) : (
  <Paper 
    elevation={0} 
    sx={{ 
      py: 5, 
      px: 3, 
      textAlign: 'center',
      background: theme => theme.palette.mode === 'dark' 
        ? alpha('#FFB400', 0.05)  // darker, more subtle background in dark mode
        : alpha('#FFB400', 0.08),
      borderRadius: 2,
      border: '1px solid',
      borderColor: theme => theme.palette.mode === 'dark' 
        ? alpha('#FFB400', 0.2)   // slightly stronger border in dark mode for contrast
        : alpha('#FFB400', 0.15)
    }}
  >
    <Typography 
      color={theme => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'text.secondary'} 
      variant="h6" 
      fontWeight={500}
    >
      No reviews yet
    </Typography>
  </Paper>
)}
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Subscription Dialog */}
      <Dialog 
        open={dialogOpen} 
        onClose={() => setDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
            p: 1
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Box display="flex" alignItems="center">
            <Avatar 
              sx={{ 
                mr: 2, 
                bgcolor: 'warning.light',
                color: 'warning.dark'
              }}
            >
              <WarningIcon />
            </Avatar>
            <Typography variant="h5" fontWeight={600}>
              Subscription Required
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pt: 2, px: 3 }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You need an active subscription to hire pods. Please subscribe to continue.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button 
            onClick={() => setDialogOpen(false)}
            variant="outlined"
            sx={{ 
              borderRadius: 2,
              textTransform: 'none'
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // Add subscription page navigation here
              setDialogOpen(false);
            }}
            sx={{ 
              borderRadius: 2,
              boxShadow: '0 4px 10px rgba(33, 150, 243, 0.3)',
              textTransform: 'none',
              px: 3
            }}
          >
            Subscribe Now
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FindPodDetailsClient;