import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import Select from "react-select";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PersonIcon from "@mui/icons-material/Person";
import moment from "moment";
import { Drawer, TextField, Button, CircularProgress ,alpha, useTheme,
  styled,
  Paper,
  Typography,
  Box} from "@mui/material";
import { useSelector } from "react-redux";
import "./kanband.css";

import { FaBug, FaJira } from 'react-icons/fa';
// Styled Components
// Styled Components
const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})(({ theme, collapsed }) => ({
  position: 'relative',
  width: `calc(100vw - ${collapsed ? '60px' : '240px'} - 48px)`,
  marginLeft: collapsed ? '60px' : '240px',
  overflowX: 'hidden',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    width: '100vw'
  },
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F5F5F5',
  color: theme.palette.text.primary,
  minHeight: 'calc(100vh - 64px)', // Account for AppBar height
  padding: theme.spacing(3)
}));
const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
    color: theme.palette.text.primary,
  }
}));
const FiltersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    '& > *': {
      width: '100%'
    }
  }
}));

const SelectWrapper = styled(Box)(({ theme }) => ({
  width: "20rem",
  marginBottom: theme.spacing(4),
  '& .select__control': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
    borderColor: theme.palette.divider,
    minHeight: 56,
    // marginTop: 10,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },
  '& .select__menu': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
    color: theme.palette.text.primary,
    zIndex: 999,
    boxShadow: theme.shadows[3]
  },
  '& .select__option': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1)
    }
  }
}));

const SprintSelect = styled('select')(({ theme }) => ({
  textDecoration: 'none',
  border: '1px solid rgb(233, 224, 224)',
  borderRadius: '4px',
  width: "20rem",
  height: '56px',
  padding: '0 0.5rem',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
  color: theme.palette.text.primary,
  cursor: 'pointer',
  '&:focus': {
    borderColor: theme.palette.primary.main,
    outline: 'none',
    boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
  }
}));



const StyledColumn = styled(Box)(({ theme, collapsed }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  minWidth: '280px',
  width: '280px',
  marginRight: theme.spacing(2),
  maxHeight: 'calc(100vh - 220px)', // Account for header, controls and padding
  overflowY: 'auto',
  scrollbarWidth: 'none',  // Firefox
  msOverflowStyle: 'none',  // IE and Edge
  '&::-webkit-scrollbar': {
    display: 'none'  // Chrome, Safari, Opera
  },
  boxShadow: theme.shadows[1],
  '& .column-header': {
    borderBottom: '3px solid',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
}));

const StyledTask = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: `6px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2]
  },
  '& .task-content': {
    width: '100%',
    textAlign: 'left'
  },
  '& .task-header': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  '& .task-title': {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.text.primary
  },
  '& .task-id': {
    fontSize: '0.75rem',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 8px',
    borderRadius: '4px',
    display: 'inline-block',
    marginBottom: theme.spacing(1)
  },
  '& .task-assignee': {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '0.75rem',
    color: theme.palette.text.secondary
  },
  '& .task-due-date': {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5)
  }
}));

const ScrollButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  transform: 'translateY(-50%)',
  width: 40,
  height: 40,
  minWidth: 40,
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '50%',
  boxShadow: theme.shadows[2],
  zIndex: 1,
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));


const KanbanBoard = ({collapsed}) => {
  // State declarations
  const [tasks, setTasks] = useState([]);
  const [columns, setColumns] = useState({
    "To Do": { title: "To Do", items: [], type: "open" },
    "In Progress": { title: "In Progress", items: [], type: "open" },
    Done: { title: "Done", items: [], type: "closed" },
  });
  const [selectedPodId, setSelectedPodId] = useState([]);
  const [authorizationDetails, setAuthorizationDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [podNames, setPodNames] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const theme = useTheme();
const [sprintData, setSprintData] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState({
    name: "", // Selected sprint name
    startDate: "", // Selected sprint start date
    endDate: "", // Selected sprint end date
  });
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const scrollContainerRef = useRef(null);

  // Initial data fetching
  useEffect(() => {
    fetchPodNames();
  }, [token]);

  useEffect(() => {
    if (selectedPodId.length > 0 ) {
      fetchAuthorizationTokensAndList();
    }else {
      resetStates();
    }
  }, [selectedPodId, selectedCandidate,selectedSprint.name]);

  const resetStates = () => {
    setCandidates([]);
    setTasks([]);
    setColumns({
      "To Do": { title: "To Do", items: [], type: "open" },
      "In Progress": { title: "In Progress", items: [], type: "open" },
      Done: { title: "Done", items: [], type: "closed" },
    });
  };

  // Helper functions
  const parseStatusString = (statusString) => {
    if (!statusString) return [];
    return statusString.split(",").map((status) => status.trim());
  };

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const currentScroll = scrollContainerRef.current.scrollLeft;
      scrollContainerRef.current.scrollTo({
        left: currentScroll + (direction === "left" ? -scrollAmount : scrollAmount),
        behavior: "smooth",
      });
    }
  };

  // Column initialization
  const initializeColumns = (authData) => {
    if (!authData) return columns;

    const openStatuses = parseStatusString(authData.jira_open_story_bug_status?.[0]);
    const closedStatuses = parseStatusString(authData.jira_closed_story_bug_status?.[0]);

    const newColumns = {};
    openStatuses.forEach((status) => {
      newColumns[status] = { title: status, items: [], type: "open" };
    });
    closedStatuses.forEach((status) => {
      newColumns[status] = { title: status, items: [], type: "closed" };
    });

    return newColumns;
  };

  // Task grouping
  const groupTasksByStatus = (tasks, columns) => {
    const groupedColumns = { ...columns };
    Object.keys(groupedColumns).forEach((key) => {
      groupedColumns[key].items = [];
    });

    tasks.forEach((task) => {
      const status = task.status || "To Do";
      if (groupedColumns[status]) {
        groupedColumns[status].items.push(task);
      }
    });

    return groupedColumns;
  };

  // Comment rendering and handling functions
  const renderJiraComment = (content) => {
    if (!content) return null;

    return content.map((item, index) => {
      if (item.type === 'paragraph') {
        return (
          <div key={index} style={{ marginBottom: '8px' }}>
            {item.content?.map((contentItem, cIndex) => {
              if (contentItem.type === 'text') {
                return <span key={cIndex}>{contentItem.text}</span>;
              }
              if (contentItem.type === 'mention') {
                return (
                  <span 
                    key={cIndex} 
                    style={{ 
                      color: '#0052CC', 
                      fontWeight: '500',
                      backgroundColor: '#EAE6FF',
                      padding: '0 4px',
                      borderRadius: '3px',
                      margin: '0 2px'
                    }}
                  >
                    {contentItem.attrs.text}
                  </span>
                );
              }
              return contentItem.type === 'hardBreak' ? <br key={cIndex} /> : null;
            })}
          </div>
        );
      }
      return null;
    });
  };

  const renderComments = () => {
    if (!selectedTask) return null;

    // Handle POD task comments
    if (selectedTask.pod_task_id && selectedTask.comment) {
      try {
        const podComments = JSON.parse(selectedTask.comment);
        return podComments.map((comment, index) => (
          <div
            key={index}
            className="comment"
            style={{
              marginBottom: "20px",
              padding: "12px",
              backgroundColor: theme => theme.palette.mode === 'dark' 
              ? theme.palette.background.paper 
              : '#f8f9fa',              borderRadius: "8px",
              boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
            }}
          >
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "12px",
              alignItems: "center",
            }}>
              <span style={{
                backgroundColor: "#0073e6",
                color: "white",
                padding: "4px 8px",
                borderRadius: "4px",
                fontSize: "14px",
                fontWeight: "500",
              }}>
                 {comment.author}
              </span>
              <small style={{ color: "#666" }}>
                {moment(comment.timestamp).format("MMM DD, YYYY HH:mm")}
              </small>
            </div>
            <div style={{
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              lineHeight: "1.5",
            }}>
              {comment.text}
            </div>
          </div>
        ));
      } catch (error) {
        console.error("Error parsing POD comments:", error);
        return null;
      }
    }

    // Handle Jira comments
   return (
         <>
         <Box><Typography variant="subtitle1">
       Comments
     </Typography></Box>
       
           {selectedTask.comments?.map((comment, index) => (
             <div
               key={index}
               className="comment"
               style={{
                 marginBottom: "20px",
                 padding: "12px",
                 backgroundColor: theme => theme.palette.mode === 'dark' 
                 ? theme.palette.background.paper 
                 : '#f8f9fa',
                 borderRadius: "8px",
                 boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
               }}
             >
                
               <div style={{
                 display: "flex",
                 justifyContent: "space-between",
                 marginBottom: "12px",
                 alignItems: "center",
               }}>
                 <span style={{
                   backgroundColor: "#0073e6",
                   color: "white",
                   padding: "4px 8px",
                   borderRadius: "4px",
                   fontSize: "14px",
                   fontWeight: "500",
                 }}>
                   {comment.author}
                 </span>
                 <small style={{ color: "#666" }}>
                   {moment(comment.created).format("MMM DD, YYYY HH:mm")}
                 </small>
               </div>
               <div style={{
                 whiteSpace: "pre-wrap",
                 wordBreak: "break-word",
                 lineHeight: "1.5",
               }}>
                 {renderJiraComment(comment.body?.content)}
               </div>
             </div>
           ))}
         </>
       );
     }

 
const handleAddComment = async () => {
  if (!newComment.trim() || !selectedTask) return;

  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  try {
    if (selectedTask.pod_task_id) {
      // For POD tasks, prepare array of all comments including the new one
      let existingComments = [];
      if (selectedTask.comment) {
        try {
          existingComments = JSON.parse(selectedTask.comment);
        } catch (error) {
          console.error("Error parsing existing comments:", error);
          existingComments = [];
        }
      }

      // Create the new comment object
      const newCommentObj = {
        pod_task_id: parseInt(selectedTask.pod_task_id),
        user_id: parseInt(user.data[0].emp_id),
        new_text: newComment,
        is_reply: false,
        author:user.data[0].emp_firstname
      };

      // Create array of all comments (previous + new)
      const allComments = existingComments.map(comment => ({
        pod_task_id: parseInt(selectedTask.pod_task_id),
        user_id: parseInt(comment.user_id),
        new_text: comment.text,
        is_reply: false
      }));
      allComments.push(newCommentObj);

      // Send request with all comments
      const response = await axios.post(
        "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/addCommentpodtask",
        [newCommentObj],
        { headers }
      );

      if (response.status === 200) {
        setSelectedTask(prevTask => ({
          ...prevTask,
          comment: JSON.stringify([
            ...existingComments,
            {
              text: newComment,
              user_id: parseInt(user.data[0].emp_id),
              timestamp: new Date().toISOString(),
              replies: []
            }
          ])
        }));
        setNewComment("");
      }
    } else if (selectedTask.key) {
      // Jira comment handling remains the same
      const response = await axios.post(
        "https://o0quulyyma.execute-api.us-east-1.amazonaws.com/prod/addCommentjirapodboard",
        {
          jiraUrl: authorizationDetails.Jira_URL,
          issueKey: selectedTask.key,
          commentText: newComment,
          username: authorizationDetails.Jira_admin_login_email_address,
          password: authorizationDetails.Jira_admin_API_Token,
        },
        { headers }
      );

      if (response.status === 200) {
        setSelectedTask((prevTask) => ({
          ...prevTask,
          comments: [
            ...(prevTask.comments || []),
            {
              author: user.data[0].emp_name || "current_user",
              body: {
                type: "doc",
                version: 1,
                content: [
                  {
                    type: "paragraph",
                    content: [{ type: "text", text: newComment }],
                  },
                ],
              },
              created: new Date().toISOString(),
            },
          ],
        }));
        setNewComment("");
      }
    }
  } catch (error) {
    console.error("Error adding comment:", error);
    alert("Failed to add comment. Please try again.");
  }
};

  // Data fetching functions
  const fetchPodNames = async () => {
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHPODSFORMANAGER,
        { clientID: user.data[0].emp_id },
        { headers }
      );

      const data = response.data.data;
      const podNameOptions = data.map((pod) => ({
        label: pod.podName,
        value: pod.id,
      }));

      setPodNames(podNameOptions);
    } catch (error) {
      console.error("Error fetching pod names:", error);
    }
  };
  
    useEffect(() => {
      if (user.data[0].type === "stakeholder") {
        if (!token) {
          console.log("Token is missing. API requests will not be made.");
          return;
        }
        fetch(process.env.REACT_APP_STAKEHOLDER_SPRINTFILTERSTAKEHOLDER, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            manager_id: user.data[0].emp_id,
            org_code: user.data[0].client_code,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.statusCode === 200 && data.body) {
              const parsedBody = JSON.parse(data.body);
              if (Array.isArray(parsedBody.sprint_filter)) {
                setSprintData(parsedBody.sprint_filter);
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching data from stakeholder API:", error);
          });
      } else {
        if (!token) {
          console.log("Token is missing. API requests will not be made.");
          return;
        }
        fetch(process.env.REACT_APP_CLIENT_SPRINTFILTERMANAGER, {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            manager_id: user.data[0].emp_id,
            org_code: user.data[0].client_code,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.statusCode === 200 && data.body) {
              const parsedBody = JSON.parse(data.body);
              if (Array.isArray(parsedBody.sprint_filter)) {
                setSprintData(parsedBody.sprint_filter);
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    }, [token]);
    const handleSprintSelect = (e) => {
      const selectedSprintName = e.target.value;
      const selectedSprintData = sprintData.find(
        (sprint) => sprint.sprint_name === selectedSprintName
      );
      setSelectedSprint({
        name: selectedSprintName,
        startDate: selectedSprintData ? selectedSprintData.sprint_start_date : "",
        endDate: selectedSprintData ? selectedSprintData.sprint_end_date : "",
      });
    };
  

    const fetchAuthorizationTokensAndList = async () => {
      setLoading(true);
      
      if (!selectedPodId || selectedPodId.length === 0) {
        setLoading(false);
        return;
      }
  
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
  
      try {
        // Fetch authorization tokens
        const authorizationResponse = await axios.post(
          process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
          { Organizational_Code: user.data[0].client_code },
          { headers }
        );
  
        const authorizationData = authorizationResponse.data.body.data[0];
        setAuthorizationDetails(authorizationData);
  
        // Initialize columns
        const initialColumns = initializeColumns(authorizationData);
        setColumns(initialColumns);
  
        // Fetch candidates if pod is selected
        if (selectedPodId.length > 0) {
          const emailFetchingResponse = await axios.post(
            process.env.REACT_APP_CLIENT_FETCHPODCANDIDATES,
            { podID: selectedPodId },
            { headers }
          );
  
          const allCandidates = emailFetchingResponse?.data?.data || [];
          setCandidates(allCandidates);
  
          // Get selected candidate names
          const candidateNames = selectedCandidate.map(option => option.value);
  
          // Prepare payload for task fetching
          const listPayload = {
            pod_Id: selectedPodId,
            username: authorizationData.Jira_admin_login_email_address,
            password: authorizationData.Jira_admin_API_Token,
            projectKey: authorizationData.Jira_Kanben_Project_name,
            jira_url: authorizationData.Jira_URL,
            assignees: allCandidates.map(candidate => candidate.email),
            candidateName: candidateNames,
            sprintName: selectedSprint.name
          };
  
          // Fetch tasks with all filters applied
          const response = await axios.post(
            "https://irl3zjh84e.execute-api.us-east-1.amazonaws.com/prod/testSingleviewtask",
            listPayload,
            { headers }
          );
  
          // Combine and process tasks
          const combinedTasks = [
            ...(response.data.data.podMembers || []).map((task) => ({
              ...task,
              comments: task.comments || [],
            })),
            ...(response.data.data.jiraIssues || []).map((task) => ({
              ...task,
              comments: task.comments || [],
            })),
          ];
  
          setTasks(combinedTasks);
          setColumns(groupTasksByStatus(combinedTasks, initialColumns));
        }
      } catch (error) {
        console.error("Error in fetchAuthorizationTokensAndList:", error);
      } finally {
        setLoading(false);
      }
    };

  const handleTaskClick = async (task) => {
    const fullTask = tasks.find(
      (t) => (t.key || t.pod_task_id) === (task.key || task.pod_task_id)
    );
    
    if (fullTask) {
      setSelectedTask(fullTask);
      if (fullTask.pod_task_id) {
        try {
          const headers = {
            Authorization: token,
            "Content-Type": "application/json",
          };
          
          const response = await axios.post(
            "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/fetchpodtaskComment",
            { pod_task_id: parseInt(fullTask.pod_task_id) },
            { headers }
          );

          // if (response.status === 200) {
          //   setSelectedTask(prevTask => ({
          //     ...prevTask,
          //     comment: response.data.comment
          //   }));
          // }
        } catch (error) {
          console.error("Error fetching pod task comments:", error);
        }
      }
    } else {
      setSelectedTask({
        ...task,
        comments: [],
      });
    }
  };

  const handleDrawerClose = () => {
    setSelectedTask(null);
    setNewComment("");
  };

  // Drag and drop handlers
  const onDragEnd = async (result) => {
    if (!result.destination || isUpdating) return;

    const { source, destination, draggableId } = result;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const task = Object.values(columns).reduce((found, column) => {
      if (found) return found;
      return column.items.find(
        (task) => (task.key || task.pod_task_id).toString() === draggableId
      );
    }, null);

    if (!task) return;

    const oldColumns = columns;
    const newColumns = { ...columns };
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];

    const [removed] = sourceItems.splice(source.index, 1);
    const movedTask = { ...removed, status: destination.droppableId };
    destItems.splice(destination.index, 0, movedTask);

    newColumns[source.droppableId] = {
      ...sourceColumn,
      items: sourceItems,
    };
    newColumns[destination.droppableId] = {
      ...destColumn,
      items: destItems,
    };

    setColumns(newColumns);
    setIsUpdating(true);

    try {
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      if (!authorizationDetails?.Jira_admin_API_Token || 
          !authorizationDetails?.Jira_admin_login_email_address || 
          !authorizationDetails?.Jira_URL) {
        throw new Error("Missing authorization details");
      }

      if (task.pod_task_id) {
        // Update pod task
        const updateResponse = await axios.put(
          process.env.REACT_APP_CLIENT_UPDATEKANBANBOARDPODTASK,
          {
            pod_task_id: task.pod_task_id,
            status: destination.droppableId,
          },
          { headers }
        );

        if (updateResponse.status !== 200) {
          throw new Error("Failed to update pod task status");
        }
      } else {
        // Update JIRA task
        const transitionId = await fetchTransitionId(
          destination.droppableId,
          task.key
        );

        if (!transitionId) {
          throw new Error("Invalid transition for this status change");
        }

        const updateResponse = await axios.put(
          process.env.REACT_APP_CLIENT_KANBANBOARDUPDATE,
          {
            Jira_URL: authorizationDetails.Jira_URL,
            Jira_admin_login_email_address: authorizationDetails.Jira_admin_login_email_address,
            Jira_admin_API_Token: authorizationDetails.Jira_admin_API_Token,
            key: task.key,
            transitionId: transitionId,
            status: destination.droppableId,
          },
          {
            headers,
            timeout: 10000
          }
        );

        if (updateResponse.status !== 200) {
          throw new Error("Failed to update JIRA task status");
        }
      }
    } catch (error) {
      console.error("Error updating task status:", error);
      setColumns(oldColumns);
      alert("Failed to update task status. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  const fetchTransitionId = async (status, key) => {
    if (!status || !key) return null;

    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHSTATUSFORSPECIFICJIRAISSUE,
        {
          Jira_admin_login_email_address: authorizationDetails.Jira_admin_login_email_address,
          Jira_admin_API_Token: authorizationDetails.Jira_admin_API_Token,
          key: key,
          Jira_URL: authorizationDetails.Jira_URL,
          status: status,
        },
        {
          headers,
          timeout: 5000
        }
      );

      if (!response.data?.body) {
        throw new Error("Invalid response format");
      }

      const statusData = response.data.body;
      const matchingStatus = statusData.find((s) => s.name === status);

      return matchingStatus?.id || null;
    } catch (error) {
      console.error("Error fetching transitionId:", error);
      return null;
    }
  };
// Return/Render section
return (
  <Box 
  sx={{
    bgcolor: theme => theme.palette.mode === 'dark' ? 'background.default' : '#F5F5F5',
    color: 'text.primary',
   
  }}
    >
   <Typography variant="h4" sx={{ textAlign: 'center', mb: 3 }}>
        {intl.formatMessage({ id: "podBoard" })}
      </Typography>
      
    {/* Pod and Candidate Selection Controls */}
    <Box className="board-controls-wrapper">
     <FiltersContainer>
        <SelectWrapper>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Pod Selection *</Typography>
          <Select
            isMulti
            options={podNames}
            value={podNames.filter((option) => selectedPodId.includes(option.value))}
            onChange={(selectedOptions) => setSelectedPodId(selectedOptions.map((opt) => opt.value))}
            placeholder="Select Pods (Required)"
            classNamePrefix="select"
          />
          {!selectedPodId?.length && (
            <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
              Select a pod to view sprint and Talent filters
            </Typography>
          )}
        </SelectWrapper>

        {selectedPodId?.length > 0 && (
          <>
            <SelectWrapper sx={{ opacity: 1, transition: 'opacity 0.3s' }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>Sprint Selection</Typography>
              <SprintSelect
                value={selectedSprint.name}
                onChange={(e) => {
                  const selectedSprintName = e.target.value;
                  const selectedSprintData = sprintData.find(
                    (sprint) => sprint.sprint_name === selectedSprintName
                  );
                  setSelectedSprint({
                    name: selectedSprintName,
                    startDate: selectedSprintData ? selectedSprintData.sprint_start_date : "",
                    endDate: selectedSprintData ? selectedSprintData.sprint_end_date : "",
                  });
                }}
              >
                <option value="">Select a Sprint</option>
                {sprintData.map((sprint, index) => (
                  <option key={index} value={sprint.sprint_name}>
                    {sprint.sprint_name}
                  </option>
                ))}
              </SprintSelect>
            </SelectWrapper>

            <SelectWrapper sx={{ opacity: 1, transition: 'opacity 0.3s' }}>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>Talent Selection</Typography>
              <Select
                isMulti
                options={candidates.map(candidate => ({
                  value: candidate.Candidate_Name,
                  label: candidate.Candidate_Name
                }))}
                value={selectedCandidate}
                onChange={(selectedOptions) => setSelectedCandidate(selectedOptions || [])}
                placeholder="Select Candidates"
                classNamePrefix="select"
              />
              <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
                Filter by specific Talents (optional)
              </Typography>
            </SelectWrapper>
          </>
        )}
      </FiltersContainer>
    </Box>

    {/* Kanban Board */}
    <StyledBox collapse={collapsed} >
      <ScrollButton
        className="scroll-button scroll-left"
        onClick={() => handleScroll("left")}
      >
        ←
      </ScrollButton>

      <DragDropContext onDragEnd={onDragEnd}           
      
       >
          <Box className="scrollable-board" ref={scrollContainerRef}
          >
            {Object.entries(columns).map(([columnId, column]) => (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <StyledColumn collapsed={collapsed}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Typography variant="h6" sx={{ 
                      borderBottom: 3,
                      borderColor: column.type === "closed" ? "success.main" : "warning.main",
                      pb: 1,
                      mb: 2
                    }}>
                      {column.title} ({column.items.length})
                    </Typography>

                    {loading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <CircularProgress size={24} />
                      </Box>
                    ) : (
                      column.items.map((task, index) => (
                        <Draggable
                          key={task.key || task.pod_task_id}
                          draggableId={(task.key || task.pod_task_id).toString()}
                          index={index}
                        >
                          {(provided) => (
                            <StyledTask
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Button
                                onClick={() => handleTaskClick(task)}
                                sx={{ 
                                  width: '100%', 
                                  textAlign: 'left', 
                                  color: 'text.primary',
                                  textTransform: 'none',
                                  p: 0
                                }}
                              >
                                <Box className="task-content">
                                  <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                                    {task.key ? (
                                      <FaJira size={16} color={theme.palette.primary.main} />
                                    ) : (
                                      <FaBug size={16} color={theme.palette.success.main} />
                                    )}
                                    <Typography>{task.summary || task.pod_task_name}</Typography>
                                  </Box>
                                  <Typography 
                                    sx={{ 
                                      bgcolor: 'primary.main',
                                      color: 'primary.contrastText',
                                      px: 1,
                                      py: 0.5,
                                      borderRadius: 1,
                                      display: 'inline-block',
                                      fontSize: '0.875rem',
                                      mb: 1
                                    }}
                                  >
                                    {task.key}
                                  </Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <PersonIcon color="success" />
                                    <Typography variant="body2">{task.Candidate_Name}</Typography>
                                  </Box>
                                  <Typography variant="body2" sx={{ mt: 1 }}>
                                    Due Date: {moment(task.due_date).format("MM-DD-YYYY") || "Not Set"}
                                  </Typography>
                                </Box>
                              </Button>
                            </StyledTask>
                          )}
                        </Draggable>
                      ))
                    )}
                    {provided.placeholder}
                  </StyledColumn>
                )}
              </Droppable>
            ))}
          </Box>
        </DragDropContext>
      <ScrollButton
        className="scroll-button scroll-right"
        onClick={() => handleScroll("right")}
      >
        →
      </ScrollButton>
    </StyledBox >

    {/* Comment Drawer */}
    <StyledDrawer 
        anchor="right" 
        open={!!selectedTask} 
        onClose={handleDrawerClose}
      >
        <Box sx={{ width: 400, p: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 2, mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          {selectedTask?.key ? (
            <FaJira size={16} color={theme.palette.primary.main} />
          ) : (
            <FaBug size={16} color={theme.palette.success.main} />
          )}
          <Typography variant="subtitle1">
            Task Details
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h6">
              {selectedTask?.key || selectedTask?.pod_task_id} - {selectedTask?.summary || selectedTask?.pod_task_name}
            </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PersonIcon sx={{ fontSize: 16 }} />
            <Typography variant="body2">
              Assignee: {selectedTask?.Candidate_Name || 'Not Assigned'}
            </Typography>
          </Box>
          
          <Typography variant="body2">
          Due Date: {moment(selectedTask?.due_date).format("MM-DD-YYYY") || "Not Set"}

          </Typography>
          
          <Typography variant="body2">
            Status: {selectedTask?.status}
          </Typography>
          
          {selectedTask?.priority && (
            <Typography variant="body2">
              Priority: {selectedTask.priority}
            </Typography>
          )}
        </Box>
          </Box>
         

          <Box sx={{ 
            maxHeight: 'calc(100vh - 300px)',
            overflowY: 'auto',
            px: 0.5,
            mb: 2
          }}>
            {renderComments()}
          </Box>

          <Box sx={{ 
            borderTop: 1, 
            borderColor: 'divider',
            pt: 2,
            mt: 2,
            position: 'sticky',
            bottom: 0,
            bgcolor: 'background.default'
          }}>
            <TextField
              label="Add a comment"
              multiline
              rows={3}
              fullWidth
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              variant="outlined"
              size="small"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddComment}
              disabled={!newComment.trim()}
              sx={{ mt: 1, float: 'right' }}
            >
              Add Comment
            </Button>
          </Box>
        </Box>
      </StyledDrawer>
    </Box>
);
};

export default KanbanBoard;