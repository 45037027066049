import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Alert
} from '@mui/material';

const ForgotPassword = () => {
  const { isLoggedIn } = useSelector(state => state.auth);
  const history = useHistory();
  
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [state, setState] = useState({
    email: "",
    errors: {
      cognito: null,
      blankfield: false,
      invalidEmail: false
    }
  });

  const clearErrorState = () => {
    setState(prev => ({
      ...prev,
      errors: {
        cognito: null,
        blankfield: false,
        invalidEmail: false
      }
    }));
  };

  const forgotPasswordHandler = async (event) => {
    event.preventDefault();
    clearErrorState();

    // Form validation
    const error = Validate(event, state);
    if (error) {
      setState(prev => ({
        ...prev,
        errors: { ...prev.errors, ...error }
      }));
      return;
    }

    try {
      await Auth.forgotPassword(state.email);
      localStorage.setItem("Forgetemail", state.email);
      history.push('/forgotpasswordverification');
    } catch (error) {
      console.error('Forgot password error:', error);
      setState(prev => ({
        ...prev,
        errors: {
          ...prev.errors,
          cognito: error.message,
          invalidEmail: error.code === 'UserNotFoundException'
        }
      }));
    }
  };

  const onInputChange = event => {
    const { id, value } = event.target;
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setIsValidEmail(isValid);

    setState(prev => ({
      ...prev,
      [id]: value,
      errors: {
        ...prev.errors,
        invalidEmail: false,
        cognito: null
      }
    }));
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FormErrors formerrors={state.errors} />
          
          <Typography
            component="h1"
            variant="h4"
            sx={{ 
              mb: 4,
              fontWeight: 600
            }}
          >
            {isLoggedIn ? 'Reset Password' : 'Forgot Password'}
          </Typography>
          
          <Box 
            component="form" 
            onSubmit={forgotPasswordHandler} 
            sx={{ width: '100%' }}
          >
            <TextField
              fullWidth
              id="email"
              label="Email Address"
              variant="outlined"
              type="email"
              value={state.email}
              onChange={onInputChange}
              error={!isValidEmail || state.errors.invalidEmail}
              helperText={
                !isValidEmail 
                  ? 'Please enter a valid email address'
                  : state.errors.invalidEmail 
                    ? 'This email is not registered'
                    : ' '
              }
              sx={{ mb: 3 }}
            />

            {state.errors.cognito && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {state.errors.cognito}
              </Alert>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              disabled={!isValidEmail || state.errors.invalidEmail}
              sx={{
                mt: 2,
                mb: 2,
                bgcolor: 'success.main',
                '&:hover': {
                  bgcolor: 'success.dark',
                },
                height: '48px',
                fontSize: '1.1rem',
                fontWeight: 600
              }}
            >
              Send Code
            </Button>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default ForgotPassword;