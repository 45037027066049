import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import TableChartIcon from '@mui/icons-material/TableChart';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

// Export utility component
const ExportOptions = ({ messages, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExporting, setIsExporting] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Helper function to ensure we process all message parts
  const extractAllMessageContent = (messages) => {
    let allContent = [];
    
    messages.forEach(message => {
      if (message.type === 'user') {
        // Add user message
        allContent.push({
          type: 'User',
          content: message.text || '',
        });
      } else {
        // For bot messages, carefully extract all parts
        
        // Add regular text if present
        if (message.text) {
          allContent.push({
            type: 'Bot',
            content: message.text,
          });
        }
        
        // Process each insight individually to preserve structure
        if (message.insights && Array.isArray(message.insights)) {
          message.insights.forEach(insight => {
            if (insight) {
              allContent.push({
                type: 'Bot',
                content: insight,
              });
            }
          });
        }
        
        // Add Jira stories as separate entries if present
        if (message.jiraStories && Array.isArray(message.jiraStories)) {
          message.jiraStories.forEach(story => {
            allContent.push({
              type: 'Bot',
              content: `Jira Story: ${story}`,
            });
          });
        }
      }
    });
    
    return allContent;
  };

  // Export as CSV
  const exportToCSV = () => {
    setIsExporting(true);
    try {
      // Get all content from messages
      const allContent = extractAllMessageContent(messages);
      
      if (allContent.length === 0) {
        alert("No content to export");
        return;
      }
      
      // Create CSV content with BOM for Excel compatibility
      let csvContent = '\ufeff';
      
      // Add headers
      csvContent += 'Type,Message\r\n';
      
      // Process each content item
      allContent.forEach(item => {
        // Clean up the content and escape for CSV
        const cleanContent = (item.content || '')
          // Replace line breaks with spaces to keep it on one line
          .replace(/\n/g, ' ')
          // Replace consecutive spaces with a single space
          .replace(/\s+/g, ' ')
          .trim();
          
        // Escape quotes by doubling them
        const escapedContent = cleanContent.replace(/"/g, '""');
        
        // Add the row to CSV
        csvContent += `"${item.type}","${escapedContent}"\r\n`;
      });
      
      // Create a download link for the CSV
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'pod_automation_export.csv';
      
      // Trigger download
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to CSV:', error);
      alert("Error exporting to CSV: " + error.message);
    } finally {
      setIsExporting(false);
      handleClose();
    }
  };

  // Export as PDF
  const exportToPDF = () => {
    setIsExporting(true);
    try {
      // Get all content 
      const allContent = extractAllMessageContent(messages);
      
      if (allContent.length === 0) {
        alert("No content to export");
        return;
      }
      
      // Create a new PDF document in landscape orientation for more space
      const doc = new jsPDF('landscape');
      
      // Add title
      doc.setFontSize(18);
      doc.text('Pod Automation Report', 14, 15);
      doc.setFontSize(10);
      
      // Prepare data for the table
      const tableData = allContent.map(item => [
        item.type,
        // Limit cell content length to avoid PDF generation issues
        (item.content || '').substring(0, 10000)
      ]);
      
      // Add table to PDF with optimized settings
      doc.autoTable({
        startY: 20,
        head: [['Type', 'Message']],
        body: tableData,
        theme: 'grid',
        headStyles: { 
          fillColor: [66, 139, 202],
          textColor: [255, 255, 255],
          fontStyle: 'bold'
        },
        styles: { 
          overflow: 'linebreak',
          cellPadding: 2,
          fontSize: 8,
          lineColor: [80, 80, 80],
          lineWidth: 0.1
        },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 'auto' }
        },
        alternateRowStyles: {
          fillColor: [240, 240, 240]
        },
        margin: { top: 20, right: 10, bottom: 10, left: 10 },
        
        // Handle very long text by breaking it into multiple lines
        didParseCell: function(data) {
          if (data.column.index === 1) {
            const text = data.cell.text || '';
            // Break long text into multiple lines for better readability
            if (text.length > 100) {
              data.cell.styles.cellPadding = 1; // Reduce padding for long text
            }
          }
        }
      });
      
      // Save the PDF
      doc.save('pod_automation_export.pdf');
    } catch (error) {
      console.error('Error exporting to PDF:', error);
      alert("Error exporting to PDF: " + error.message);
    } finally {
      setIsExporting(false);
      handleClose();
    }
  };

  // Export as Word (DOCX)
  const exportToWord = () => {
    setIsExporting(true);
    try {
      // Get all content
      const allContent = extractAllMessageContent(messages);
      
      if (allContent.length === 0) {
        alert("No content to export");
        return;
      }
      
      // Start Word HTML content
      let wordContent = `
        <html xmlns:o="urn:schemas-microsoft-com:office:office" 
              xmlns:w="urn:schemas-microsoft-com:office:word" 
              xmlns="http://www.w3.org/TR/REC-html40">
        <head>
          <meta charset="utf-8">
          <title>Pod Automation Report</title>
          <xml>
            <w:WordDocument>
              <w:View>Print</w:View>
              <w:Zoom>100</w:Zoom>
              <w:DoNotOptimizeForBrowser/>
            </w:WordDocument>
          </xml>
          <style>
            body { font-family: Calibri, sans-serif; margin: 1cm; }
            h1 { font-size: 16pt; font-weight: bold; color: #2F5496; margin-bottom: 0.5cm; }
            table { border-collapse: collapse; width: 100%; margin-bottom: 0.5cm; }
            th { background-color: #4285F4; color: white; font-weight: bold; padding: 8px; text-align: left; border: 1px solid #ddd; }
            td { padding: 8px; border: 1px solid #ddd; vertical-align: top; }
            tr:nth-child(even) { background-color: #f2f2f2; }
            tr:hover { background-color: #e9e9e9; }
            .user-row { background-color: #E3F2FD; }
            .pre-wrap { white-space: pre-wrap; }
          </style>
        </head>
        <body>
          <h1>Pod Automation Report</h1>
          <table>
            <tr>
              <th style="width: 15%;">Type</th>
              <th style="width: 85%;">Message</th>
            </tr>
      `;
      
      // Process each content item
      allContent.forEach(item => {
        const isUser = item.type === 'User';
        const safeContent = (item.content || '')
          .replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/\n/g, '<br>');
          
        wordContent += `
          <tr${isUser ? ' class="user-row"' : ''}>
            <td>${item.type}</td>
            <td class="pre-wrap">${safeContent}</td>
          </tr>
        `;
      });
      
      // Close the table and HTML
      wordContent += `
          </table>
        </body>
        </html>
      `;
      
      // Create and download the Word file
      const blob = new Blob([wordContent], { type: 'application/msword' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'pod_automation_export.doc';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to Word:', error);
      alert("Error exporting to Word: " + error.message);
    } finally {
      setIsExporting(false);
      handleClose();
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<DownloadIcon />}
        onClick={handleClick}
        disabled={disabled || messages.length === 0 || isExporting}
        sx={{ ml: 1 }}
      >
        {isExporting ? <CircularProgress size={20} /> : 'Export'}
      </Button>
      
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={exportToCSV}>
          <ListItemIcon>
            <TableChartIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export as CSV</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={exportToPDF}>
          <ListItemIcon>
            <PictureAsPdfIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export as PDF</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={exportToWord}>
          <ListItemIcon>
            <DescriptionIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Export as Word</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ExportOptions;