// es/messages.js
const messagesEs = {
  about: 'Acerca de',
  acceptanceCriteria: 'Criterios de Aceptación',
  accomplishments: 'Logros',
  actions: 'Acciones',
  activatePod: 'Activar Pod',
  activePods: 'Pods activos',
  activity: 'Actividad',
  add: 'Agregar',
  addBlocker: 'Agregar Información de Bloqueo',
  addingCandidateWait: 'Añadiendo Candidato Espere...',
  addPortfolio: 'Agregar portafolio',
  addStakeholder: 'Agregar Stakeholder',
  addVideoUpdate: 'Agregar Actualización de Video',
  all: 'Todo',
  allReporters: 'Todos los reporteros',
  allRepoCheckins: 'Todos los check-ins del repositorio',
  approve: 'Aprobar',
  findAgents:"Encontrar agentes",
  approved: 'Aprobado',
  podTime: "Tiempo del POD",
  podVelocity: "Velocidad del POD",
 podBlockers: "Bloqueadores del POD",
 podsync:"Sincronización POD",
  approvalRequests: 'Solicitudes de aprobación',
  approvedPRCount: 'Número de PR aprobados',
  applyFilters: 'Aplicar Filtros',
  assignedItems: 'Elementos asignados',
  backToPods: 'Volver a Mis Pods',
  basicInformation: 'Información básica',
  blocker: 'Bloqueador',
  blockerDetails: 'Detalles del Bloqueo',
  blockers: 'bloqueadores',
  board: 'Tablero',
  browseTalent: 'Buscar talento',
  bugs: 'Bugs',
  calendlyUrl: 'URL Calendly',
  cancel: 'Cancelar',
  candidate: 'Candidato',
  candidates: 'Candidatos',
  categories: 'Categorías',
  certifications: 'Certificaciones',
  certificationUrl: 'URL de certificación',
  checkBoxBlocker: 'Marque esta casilla si es un bloqueador',
  ciCdPipeline: 'Pipeline CI/CD',
  clear: 'Limpiar',
  clickHereUploadVideoClip: 'Haga clic aquí para subir un clip de video !',
  client: 'Cliente',
  cloudCost: 'Costo en la Nube',
  code: 'Líneas de Código',
  codeSmells: 'Olores de código',
  companyName: 'Nombre de la empresa',
  copied: '¡Copiado!',
  copyright: 'Copyright © 2025-presente Brilli Global, Inc. Todos los derechos reservados.',
  copyToClipboard: 'Copiar al portapapeles',
  cost: 'Costo',
  costDetails: 'Detalles del costo',
  country: 'País',
  create: 'Crear',
  createArt: 'Crear ART',
  createGig: 'Crear proyecto',
  createPod: 'Crear POD',
  createProfile: 'Crear perfil',
  createProfileOpportunity: 'Crea tu perfil en nuestra base de talentos para ser incluido en diversas oportunidades de trabajo remoto.',
  createTask: 'Crear tarea',
  createYourOwnPod: 'Crea tu propio Pod y busca proyectos',
  creatingProfile: 'Creando perfil',
  currency: 'Moneda',
  currentWeek: 'Semana actual',
  dashboard: 'Tablero',
  date: 'Fecha',
  dateRangeFilter: 'Filtro de rango de fechas',
  deactivatePod: 'Desactivar Pod',
  deactivateToUpdate: '¡Desactive el pod para actualizar el pod!',
  defects: 'Defectos',
  delete: 'Eliminar',
  deletePod: 'Eliminar Pod',
  description: 'Descripción',
  detailsForGitHubRepo: 'Detalles del repositorio en GitHub',
  done: 'Hecho',
  Done: 'Completado',
  doraMetrics: 'Métricas Dora',
  dueDate: 'Fecha de vencimiento',
  duplicatedLinesDensity: 'Densidad de líneas duplicadas',
  edit: 'Editar',
  editPod: 'Edite su pod y navegue por proyectos',
  education: 'Educación',
  educationAndCertifications: 'Educación y Certificaciones',
  email: 'Correo Electrónico',
  endDate: 'Fecha de fin',
  enterEmailToInvite: 'Ingrese un correo electrónico para invitar',
  enterPodMission: 'Ingresar la misión del Pod',
  enterPodName: 'Ingresar el nombre del Pod',
  enterStoryDescription: 'Ingrese una Descripción de la Historia',
  executionDate: 'Fecha de ejecución',
  executionID: 'ID de ejecución',
  externalPod: 'Pod externo',
  fileNameNoSpaces: 'El nombre del archivo no debe incluir espacios entre todos los elementos o caracteres',
  fileUpload: 'Carga de archivos',
  findBestFreelancers: '¡Encuentra a los mejores freelancers o pods de talento para obtener la entrega de tus proyectos más rápido!',
  findBestPod: 'Encuentra grupos que coincidan con tu experiencia/conjunto de habilidades',
  findPods: 'Buscar PODs',
  findTalent: 'Encontrar talento',
  findYourBestTalentPod: 'Encuentra tu mejor POD de talento',
  firstName: 'Nombre',
  focus: 'Enfoque',
  getStarted: 'Empezar',
  gitHubRepo: 'Repositorio de GitHub',
  gitHubUsername: 'Nombre de usuario de GitHub',
  github: 'Github',
  githubSummary: 'Resumen de GitHub',
  githubUrl: 'URL de Github',
  githubUser: 'Usuario de GitHub',
  globalWorkPlatform: 'Plataforma de trabajo global',
  home: 'Inicio',
  inactivePod: 'Pod Inactivo',
  individual: 'Individual',
  industry: 'Industria',
  inProgress: 'En progreso',
  institute: 'Instituto',
  internalPod: 'Pod interno',
  invite: 'Invitar',
  invoices: 'Facturas',
  issueKey: 'Clave del problema',
  issueStatus: 'Estado del problema',
  issueSummary: 'Resumen del problema',
  jiraIssue: 'Problema de Jira',
  jiraIssues: 'Problemas Jira',
  jiraItem: 'Elemento Jira',
  jiraTimeDetails: 'Registro de Tiempo',
  jiraUsername: 'Nombre de usuario de Jira',
  joinPod: 'Unirse al Pod',
  keepDoing: 'Seguir haciendo',
  keyword: 'Palabra clave',
  languageSpoken: 'Idioma hablado',
  lastName: 'Apellido',
  latestSprintVelocity: 'Última Velocidad del Sprint',
  linkedIn: 'LinkedIn',
  linkedInUrl: 'URL de LinkedIn',
  linesOfCodeByDay: 'Líneas de código por día',
  listOfTalents: 'Lista de talentos',
  loading: 'Cargando',
  loadingAcceptanceCriteria: 'Cargando Criterios de Aceptación',
  loadingStoryDescription: 'Cargando Descripción de la Historia',
  loadingTestCases: 'Cargando Casos de Prueba',
  logOut: 'Cerrar Sesión',
  login: 'Iniciar sesión',
  manage: 'Gestionar',
  manageActivities: 'Gestiona tus actividades y actualizaciones diarias del Pod',
  manageProfile: 'Gestionar perfil',
  manageProfileHere: 'Administra tu perfil aquí',
  managePod: 'Gestionar Pod',
  managePods: 'Gestiona todos tus pods y sus actividades',
  markComplete: 'Marcar como completado',
  middleName: 'Segundo nombre',
  myPods: 'Mis Pods',
  myTasks: 'Mis tareas',
  name: 'Nombre',
  ncloc: 'Ncloc',
  newActivityUpdate: 'Nueva Actualización de Actividad',
  next: 'Siguiente',
  noArtOptionsAvailable: 'No hay opciones de ART disponibles',
  noBlockers: 'No hay bloqueadores',
  noCandidates: 'No hay candidatos añadidos',
  noCandidatesAddedToPod: '¡No hay candidatos añadidos al Pod!',
  noCandidateReviews: 'No hay revisiones de candidatos.',
  noData: 'Sin Datos',
  noDataAvailable: 'No hay datos disponibles',
  noExperience: 'Sin experiencia',
  noMatchingPods: 'No hay pods disponibles que coincidan',
  noNotifications: 'No hay notificaciones',
  noPodActivity: 'No tienes ninguna actividad de POD',
  noPods: 'No tienes ningún Pod',
  noPodTasks: 'No tienes ninguna tarea de Pod',
  noReviews: 'No hay revisiones',
  noStakeholders: 'No hay stakeholders para este pod',
  noSubscription: 'No tienes ningún plan de suscripción',
  noVideoAvailable: 'No hay video disponible',
  notifications: 'Notificaciones',
  notificationRecipient: 'Destinatario de la notificación',
  otherInformation: 'Otra información',
  pending: 'Pendiente',
  pendingPods: 'Pods pendientes',
  phoneNumber: 'Número de teléfono',
  pipelineExecutionDetails: 'Detalles de ejecución del pipeline',
  pipelineName: 'Nombre del pipeline',
  pipelineVersion: 'Versión del pipeline',
  pleaseEnterStoryDescription: 'Por favor, ingrese una descripción de la historia y haga clic en enviar',
  pod: 'Pod',
  podActivities: 'Actividades del Pod',
  podActivity: 'Actividad del Pod',
  podAnalytics: 'Análisis de POD',
  podAssist: 'Asistente de Pod',
  podAutomation: 'Automatización de POD',
  podBoard: 'Tablero del Pod',
  podCategories: 'Categorías de Pod',
  podDescription: 'Descripción del Pod',
  podExperience: 'Experiencia de Pod',
  podFeedback: 'Feedback de Pod',
  podMetrics: 'Métricas de Pod',
  podName: 'Nombre del Pod',
  podQuery: 'Consulta de POD',
  podRequests: 'SOLICITUDES DE POD',
  podRetro: 'Retro del Pod',
  podrate: 'Tasa de Reproducción',
  podSize: 'Tamaño del Pod',
  podTask: 'Tarea del Pod',
  podTasks: 'Tareas del Pod',
  podTaskDescription: 'Descripción de la tarea de Pod',
  podTaskDueDate: 'Fecha de vencimiento de la tarea de Pod',
  podTaskName: 'Nombre de la tarea de Pod',
  podTaskOwner: 'Propietario de la tarea de Pod',
  podTaskStatus: 'Estado de la tarea de Pod',
  podTechStack: 'Tech Stack del Pod',
  PodTeamMember: 'miembro del equipo del POD',
  podType: 'Tipo de Pod',
  portfolio: 'Portafolio',
  portfolioUrl: 'URL del portafolio',
  post: 'Publicar',
  postActivity: 'Publicar Actividad',
  postPodActivity: 'Publicar Actividad del Pod',
  postRequirement: 'Publicar Requisito',
  posting: 'Publicando...',
  postedDate: 'Fecha de publicación',
  postedRequirements: 'Tus Requisitos Publicados del Pod',
  positionName: 'Nombre del puesto',
  prCount: 'Conteo de PR',
  preferredWorkHours: 'Horas de trabajo preferidas',
  preferredWorkHoursMonthly: 'Horas de trabajo preferidas /mes',
  prev: 'Anterior',
  previousPods: 'Pods anteriores',
  previousWeek: 'Semana anterior',
  primarySkill: 'Habilidad principal',
  privacyPolicy: 'Política de privacidad',
  privatePods: 'Pods privados',
  prSummary: 'Resumen de PR',
  projectKey: 'Clave del proyecto',
  profileLink: 'Enlace del Perfil',
  profileVisibility: 'Visibilidad del Perfil',
  public: 'Público',
  qualification: 'Calificación',
  ratePerHour: 'Tarifa /hora',
  ratePerMonth: 'Tarifa /mes',
  recentSearches: 'Búsquedas recientes',
  region: 'Región',
  Register: 'Registrarse',
  resetPassword: 'Restablecer Contraseña',
  restricted: 'Restringido',
  retro: 'Retro',
  reviewAndRating: 'Revisión y Calificación',
  revisionSummary: 'Resumen de la revisión',
  revisionURL: 'URL de la revisión',
  resume: 'Currículum',
  resumeLink: 'Ver Currículum',
  rowsPerPage: 'Filas por página',
  save: 'Guardar',
  search: 'Buscar',
  searchProjects: 'Buscar proyectos',
  secondarySkill: 'Habilidad secundaria',
  select: 'Seleccionar',
  selectArtName: 'Seleccionar el nombre del ART',
  selectAUser: 'Seleccionar un usuario',
  selectFilter: 'Seleccionar filtro',
  selectItemsPerPage: 'Seleccionar elementos por página',
  selectJiraIssues: 'Seleccionar Problemas de Jira',
  selectPod: 'Seleccione un Pod',
  selectPodTeamMember: 'Seleccionar miembro del equipo del POD',
  selectPods: 'Seleccionar Pods',
  services: 'Servicios',
  setting: 'Configuración',
  shareableLink: 'Enlace Compartible',
  signUpToGetStarted: '¡"Regístrate" a continuación para comenzar!',
  skills: 'Habilidades',
  skillsAndExpertise: 'Habilidades y experiencia',
  sonarqubeprojectkey: 'Clave del Proyecto Sonarqube',
  sonarqubeStats: 'Estadísticas de Sonarqube',
  specialization: 'Especialización',
  sprintVelocity: 'Velocidad de sprint',
  sprint: 'Sprint',
  spotsLeft: 'Lugares Disponibles',
  startDate: 'Fecha de inicio',
  startDoing: 'Empezar a hacer',
  status: 'Estado',
  stopDoing: 'Dejar de hacer',
  storyDescription: 'Descripción de la Historia',
  stories: 'Historias',
  submit: 'Enviar',
  submittedDate: 'Fecha de envío',
  submittedPRCount: 'Número de PR enviados',
  subscription: 'Suscripción',
  talentApprovalRequests: 'Solicitudes de aprobación de talentos',
  talentCode: 'Código de talento',
  talent: 'Talento',
  taskForm: 'Formulario de tarea',
  taskManager: 'Administrador de tareas',
  taskName: 'Nombre de la tarea',
  tasks: 'Tareas',
  teamMember: 'Miembro del equipo',
  techStack: 'TechStack',
  termsOfService: 'Términos del servicio',
  testCases: 'Casos de Prueba',
  time: 'Tiempo',
  timeLog: 'Registro de tiempo',
  timeSubmit: 'Enviar Hora',
  timeZone: 'Zona horaria',
  
  toDo: 'Por hacer',
  todaysFocus: 'Enfoque del día',
  totalLinesChangedLastWeek: 'Total de líneas modificadas la semana pasada',
  totalLinesChangedThisWeek: 'Total de líneas modificadas esta semana',
  totalPRCount: 'Número total de PR',
  university: 'Universidad',
  update: 'Actualizar',
  updateProfileHere: 'Actualiza tu perfil aquí',
  updatingVisibility: 'Actualizando visibilidad...',
  upload: 'Subir',
  userStory: 'Historia de usuario',
  validFrom: 'Válido desde',
  validTill: 'Válido hasta',
  videoUrl: 'URL del video',
  view: 'Ver',
  viewActivities: 'Ver Actividades',
  viewActivity: 'Ver actividad',
  viewDetails: 'Ver detalles',
  viewPodActivities: 'Ver actividades del Pod',
  viewPodDetails: 'Ver detalles del Pod',
  viewProfile: 'Ver perfil',
  viewTasks: 'Ver tareas',
  viewTimeEntries: 'Ver entradas de tiempo',
  viewVideo: 'Ver video',
  visitFindTalent: "Por favor, visite la página 'Buscar talento' y agregue individuos talentosos a su pod.",
  vulnerabilities: 'Vulnerabilidades',
  welcome: '¡Bienvenido a Globl.ai!',
  workExperience: 'Experiencia laboral',
  yourPod: 'Tu Pod',
  yourPods: 'Tus Pods',
  yourPostedPodActivity: 'Su actividad de Pod publicada'
};

export default messagesEs;
