import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  Stack,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NotificationsNone, LayersOutlined } from '@mui/icons-material';
import { useAuthToken } from "../TokenContext";
import { podprovideredit } from "./action/auth";
import axios from "axios";

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  height: 400,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 40,
  padding: theme.spacing(1, 4),
  minWidth: 120,
  textTransform: 'none',
  marginTop: theme.spacing(2),
}));

// Plan Card Component
const PlanDetails = ({ plan, isCurrentPlan, onSelect, onCancel }) => (
  <StyledCard>
    <LayersOutlined sx={{ fontSize: 50, color: 'primary.main', mb: 2 }} />
    <Typography variant="h6" gutterBottom>
      {plan.Product_Name}
    </Typography>
    <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 2, flexGrow: 1 }}>
      {plan.Desc}
    </Typography>
    <Typography variant="h5" color="primary" gutterBottom>
      ${plan.Amount}<span style={{ fontSize: '0.8rem' }}>/year</span>
    </Typography>
    
    {isCurrentPlan ? (
      <Stack spacing={1}>
        <StyledButton variant="outlined" disabled>
          Current Plan
        </StyledButton>
        <StyledButton variant="outlined" color="error" onClick={onCancel}>
          Cancel Subscription
        </StyledButton>
      </Stack>
    ) : (
      <StyledButton variant="outlined" color="primary" onClick={() => onSelect(plan)}>
        Choose Plan
      </StyledButton>
    )}
  </StyledCard>
);

// Payment Form Component
const PaymentForm = ({ paymentData, onInputChange, loading, onSubmit }) => (
  <Box component="form" onSubmit={onSubmit} sx={{ maxWidth: 500, mx: 'auto', p: 3 }}>
    <Stack spacing={3}>
      <TextField
        fullWidth
        label="Card Number"
        value={paymentData.number}
        id="number"
        onChange={onInputChange}
        inputProps={{ maxLength: 16 }}
        required
      />
      <TextField
        fullWidth
        label="Cardholder Name"
        value={paymentData.name}
        id="name"
        onChange={onInputChange}
        required
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Expiry Month"
            value={paymentData.exp_month}
            id="exp_month"
            onChange={onInputChange}
            inputProps={{ maxLength: 2 }}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Expiry Year"
            value={paymentData.exp_year}
            id="exp_year"
            onChange={onInputChange}
            inputProps={{ maxLength: 4 }}
            required
          />
        </Grid>
      </Grid>
      <TextField
        fullWidth
        label="CVC"
        value={paymentData.cvc}
        id="cvc"
        onChange={onInputChange}
        inputProps={{ maxLength: 3 }}
        type="password"
        required
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={loading}
        sx={{ height: 56 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Add Card'}
      </Button>
    </Stack>
  </Box>
);

// Confirmation Component
const ConfirmationView = ({ selectedPlan, loading, onConfirm }) => (
  <Box sx={{ maxWidth: 500, mx: 'auto', textAlign: 'center', py: 4 }}>
    <Typography variant="h5" gutterBottom>
      Confirm Subscription
    </Typography>
    <Typography variant="body1" gutterBottom>
      You are about to subscribe to {selectedPlan.Product_Name} for ${selectedPlan.Amount}/year
    </Typography>
    <Button
      variant="contained"
      onClick={onConfirm}
      disabled={loading}
      sx={{ mt: 3 }}
    >
      {loading ? <CircularProgress size={24} /> : 'Confirm Subscription'}
    </Button>
  </Box>
);

// Success Component
const SuccessView = ({ selectedPlan }) => (
  <Box sx={{ textAlign: 'center', py: 4 }}>
    <Typography variant="h5" gutterBottom color="primary">
      Subscription Successful!
    </Typography>
    <Typography variant="body1" gutterBottom>
      You have successfully subscribed to {selectedPlan.Product_Name}
    </Typography>
    <Button
      component={Link}
      to="/PartnerInvite"
      variant="contained"
      sx={{ mt: 3 }}
    >
      Invite Managers
    </Button>
  </Box>
);

// Loading Component
const LoadingView = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', py: 8 }}>
    <CircularProgress />
  </Box>
);

// Error Component
const ErrorView = ({ message }) => (
  <Alert 
    severity="error" 
    sx={{ 
      mb: 3,
      maxWidth: 500,
      mx: 'auto'
    }}
  >
    {message}
  </Alert>
);

// Main Component
const PartnerSubscription = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();

  // State
  const [activeTab, setActiveTab] = useState(0);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [step, setStep] = useState('plans');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentData, setPaymentData] = useState({
    number: '',
    exp_month: '',
    exp_year: '',
    cvc: '',
    name: '',
    zip: ''
  });

  // Fetch plans
  useEffect(() => {
    if (token && !plans.length) {
      fetchPlans();
    }
  }, [token, fetchPlans]);

  const fetchPlans = async () => {
    let isMounted = true;

   
    if (!token) return;

    try {
      setLoading(true);
      setError(null);

      const response = await axios.post(
        process.env.REACT_APP_CLIENT_STRIPEPRICELIST,
        {},
        { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/json'
          } 
        }
      );

      if (!isMounted) return;

      if (!response.data?.data?.phase_1_admin) {
        throw new Error('Invalid response format');
      }

      const partnerPlans = response.data.data.phase_1_admin.filter(
        plan => plan.Product_Name.toLowerCase().includes('partner')
      );

      if (partnerPlans.length === 0) {
        setError('No partner plans available');
        return;
      }

      setPlans(partnerPlans);
    } catch (err) {
      if (isMounted) {
        console.error('Error fetching plans:', err);
        setError(err.response?.data?.message || 'Failed to load subscription plans');
      }
    } finally {
     
        setLoading(false);
      
    }
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setStep('payment');
  };

  const handlePaymentInputChange = (e) => {
    setPaymentData(prev => ({
      ...prev,
      [e.target.id]: e.target.value
    }));
  };

  console.log('userdata',user)
  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const paymentResponse = await axios.post(
        process.env.REACT_APP_CLIENT_CREATEPAYMENTMETHOD,
        paymentData,
        { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/json'
          } 
        }
      );

      if (!paymentResponse.data?.result?.id) {
        throw new Error('Invalid payment response');
      }

      setPaymentMethod(paymentResponse.data.result.id);
      setStep('confirmation');
    } catch (err) {
      setError('Payment processing failed. Please try again.');
      console.error('Payment error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSubscription = async () => {
    setLoading(true);
    setError(null);

    try {
      const subscriptionData = {
        paymentMethodId: paymentMethod,
        customerId: user.data[0].customerId,
        items: [{ price: selectedPlan.Price_ID }],
        subId: user.data[0].subscriptionID
      };

      const response = await axios.post(
        process.env.REACT_APP_CLIENT_CREATESUBSCRIPTION,
        subscriptionData,
        { 
          headers: { 
            Authorization: token,
            'Content-Type': 'application/json'
          } 
        }
      );

      if (!response.data?.id) {
        throw new Error('Invalid subscription response');
      }

      await dispatch(podprovideredit({
        ...user.data[0],
        subscriptionID: response.data.id,
        priceID: selectedPlan.Price_ID
      }));

      setStep('success');
    } catch (err) {
      setError('Subscription creation failed. Please try again.');
      console.error('Subscription error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!window.confirm('Are you sure you want to cancel your subscription?')) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      await axios.delete(
        process.env.REACT_APP_CLIENT_CANCELSUBSCRIPTIONDB,
        {
          headers: { 
            Authorization: token,
            'Content-Type': 'application/json'
          },
          data: { 
            subId: user.data[0].subscriptionID 
          }
        }
      );

      await dispatch(podprovideredit({
        ...user.data[0],
        subscriptionID: null,
        priceID: null
      }));

      // Show success message before reloading
      alert('Subscription cancelled successfully');
      window.location.reload();
    } catch (err) {
      setError('Failed to cancel subscription. Please try again.');
      console.error('Cancellation error:', err);
    } finally {
      setLoading(false);
    }
  };

  const renderPlans = () => {
    console.log('Rendering Plans:', { loading, error, plans });
  
    if (loading) {
      return <LoadingView />;
    }
  
    if (error) {
      return <ErrorView message={error} />;
    }
  
    if (!plans || plans.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', py: 8 }}>
          <Typography variant="h6">No plans available at the moment</Typography>
          <Typography variant="body2">Debug Info: {JSON.stringify({ loading, error })}</Typography>
        </Box>
      );
    }
  
    return (
      <Grid container spacing={4} justifyContent="center">
        {plans.map(plan => (
          <Grid item xs={12} sm={6} md={4} key={plan.Price_ID}>
            <PlanDetails
              plan={plan}
              isCurrentPlan={user.data[0]?.priceID === plan.Price_ID}
              onSelect={handlePlanSelect}
              onCancel={handleCancelSubscription}
            />
          </Grid>
        ))}
      </Grid>
    );
  };
  const renderContent = () => {
    if (activeTab === 0) {
      return (
        <Box sx={{ textAlign: 'center', py: 8 }}>
          <Typography variant="h5">Coming Soon</Typography>
        </Box>
      );
    }

    switch (step) {
      case 'plans':
        return renderPlans();

      case 'payment':
        return (
          <PaymentForm
            paymentData={paymentData}
            onInputChange={handlePaymentInputChange}
            loading={loading}
            onSubmit={handlePaymentSubmit}
          />
        );

      case 'confirmation':
        return (
          <ConfirmationView
            selectedPlan={selectedPlan}
            loading={loading}
            onConfirm={handleConfirmSubscription}
          />
        );

      case 'success':
        return (
          <SuccessView selectedPlan={selectedPlan} />
        );

      default:
        return null;
    }
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom align="center">
          Subscription Plans
        </Typography>
        
        <Paper sx={{ mb: 4 }}>
          <Tabs 
            value={activeTab} 
            onChange={(e, newValue) => setActiveTab(newValue)}
            centered
          >
            <Tab label="TALENT ACCESS" />
            <Tab label="POD MANAGEMENT" />
          </Tabs>
        </Paper>

        {renderContent()}
      </Box>
    </Container>
  );
};

export default PartnerSubscription;