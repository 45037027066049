// Updated Invite Members Modal Component
// This component enhances the existing modal with team member selection functionality

import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography,
  Button,
  Chip,
  Avatar,
  IconButton,
  CircularProgress,
  TextField,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  InputAdornment,
  Paper,
  Alert,
  Fade,
  Grow
} from '@mui/material';

import {
  Close as CloseIcon,
  PersonAdd as PersonAddIcon,
  Search as SearchIcon,
  CheckCircle as CheckCircleIcon,
  Add as AddIcon,
  FilterList as FilterListIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Email as EmailIcon,
  Group as GroupIcon,
  Person as PersonIcon
} from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import defaultimg from './assets/defaultimge.png';

// Styled Components
const SearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.05)',
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.03)',
      boxShadow: theme.palette.mode === 'dark' ? '0 0 0 2px rgba(144, 202, 249, 0.2)' : '0 0 0 2px rgba(0, 180, 216, 0.2)',
    },
  },
  '& .MuiInputBase-input': {
    padding: '12px 14px',
  },
}));

const CandidateListItem = styled(ListItem)(({ theme, selected }) => ({
  borderRadius: '12px',
  marginBottom: '8px',
  transition: 'all 0.2s ease',
  backgroundColor: selected ? 
    (theme.palette.mode === 'dark' ? 'rgba(0, 180, 216, 0.15)' : 'rgba(0, 180, 216, 0.08)') : 
    (theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.01)'),
  '&:hover': {
    backgroundColor: selected ?
      (theme.palette.mode === 'dark' ? 'rgba(0, 180, 216, 0.2)' : 'rgba(0, 180, 216, 0.12)') :
      (theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)'),
  },
  boxShadow: selected ? 
    (theme.palette.mode === 'dark' ? '0 2px 8px rgba(0,0,0,0.2)' : '0 2px 8px rgba(0,0,0,0.05)') : 
    'none',
  border: selected ? 
    `1px solid ${theme.palette.mode === 'dark' ? 'rgba(0, 180, 216, 0.3)' : 'rgba(0, 180, 216, 0.2)'}` : 
    `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'}`,
}));

const FilterChip = styled(Chip)(({ theme, active }) => ({
  borderRadius: '8px',
  backgroundColor: active ? 
    (theme.palette.mode === 'dark' ? 'rgba(0, 180, 216, 0.2)' : 'rgba(0, 180, 216, 0.1)') :
    (theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'),
  color: active ? 
    (theme.palette.mode === 'dark' ? '#90CAF9' : '#00B4D8') :
    (theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.secondary),
  borderColor: active ? 
    (theme.palette.mode === 'dark' ? 'rgba(0, 180, 216, 0.5)' : 'rgba(0, 180, 216, 0.3)') :
    'transparent',
  boxShadow: active ? 
    (theme.palette.mode === 'dark' ? '0 0 8px rgba(0, 180, 216, 0.2)' : 'none') :
    'none',
  fontWeight: active ? 600 : 400,
  '&:hover': {
    backgroundColor: active ? 
      (theme.palette.mode === 'dark' ? 'rgba(0, 180, 216, 0.25)' : 'rgba(0, 180, 216, 0.15)') :
      (theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.08)'),
  }
}));

const ActionButton = styled(Button)(({ theme, buttonColor = '#1DBF73' }) => ({
  textTransform: 'none',
  fontWeight: 600,
  padding: '10px 20px',
  borderRadius: '10px',
  backgroundColor: buttonColor,
  color: theme.palette.mode === 'dark' ? '#fff' : 'white',
  border: 'none',
  boxShadow: theme.palette.mode === 'dark'
    ? `0 2px 8px ${buttonColor}20`
    : `0 2px 8px ${buttonColor}40`,
  '&:hover': {
    backgroundColor: buttonColor,
    opacity: 0.9,
    boxShadow: theme.palette.mode === 'dark'
      ? `0 4px 12px ${buttonColor}30`
      : `0 4px 12px ${buttonColor}40`,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.12)' : 'rgba(0,0,0,0.12)',
    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)',
  }
}));

const StatusChip = styled(Chip)(({ theme, status }) => {
  const colors = {
    invited: { bg: '#FFC107', color: '#000' },
    active: { bg: '#4CAF50', color: '#FFF' },
    available: { bg: '#2196F3', color: '#FFF' },
    unavailable: { bg: '#F44336', color: '#FFF' }
  };
  
  const statusColor = colors[status] || colors.available;
  
  return {
    borderRadius: '6px',
    height: '24px',
    fontSize: '0.75rem',
    fontWeight: 600,
    backgroundColor: theme.palette.mode === 'dark' ? `${statusColor.bg}50` : `${statusColor.bg}20`,
    color: theme.palette.mode === 'dark' ? statusColor.bg : statusColor.bg,
    border: `1px solid ${statusColor.bg}50`,
    '& .MuiChip-label': {
      padding: '0 8px',
    }
  };
});

const SelectedCandidateChip = styled(Chip)(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0, 180, 216, 0.15)' : 'rgba(0, 180, 216, 0.1)',
  border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(0, 180, 216, 0.3)' : 'rgba(0, 180, 216, 0.2)'}`,
  boxShadow: theme.palette.mode === 'dark' ? '0 2px 4px rgba(0,0,0,0.2)' : '0 1px 3px rgba(0,0,0,0.08)',
  margin: '4px',
  '& .MuiChip-deleteIcon': {
    color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.5)',
    '&:hover': {
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    }
  }
}));

const EmptyStatePaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
  textAlign: 'center',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.01)',
  borderRadius: '16px',
  border: `1px dashed ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
  marginTop: '16px',
  marginBottom: '16px'
}));

// Main Component
const EnhancedInviteModal = ({ podId, onSuccess, onClose }) => {
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  const [availableCandidates, setAvailableCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [filters, setFilters] = useState({
    skill: 'all',
    availability: 'all'
  });
  const [showFilters, setShowFilters] = useState(false);
  const [activeTab, setActiveTab] = useState('existing'); // 'existing' or 'email'
  const [emailInvites, setEmailInvites] = useState('');
  const [emailInvitesList, setEmailInvitesList] = useState([]);
  const [emailValid, setEmailValid] = useState(true);
  const [emailInputs, setEmailInputs] = useState([{emails: ''}]);
  // Fetch candidates that can be added to the pod
  useEffect(() => {
    if (token && podId) {
      fetchAvailableCandidates();
    }
  }, [token, podId]);

  const fetchAvailableCandidates = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };

      const response = await axios.post(
        'https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/listcandidatesforPodadd',
        { 
          pod_id: podId,
          client_code: user.data[0].client_code
        },
        { headers }
      );

      if (response.data?.status === 200 && Array.isArray(response.data.data)) {
        // Add a field to track UI selection state (separate from API data)
        const candidatesWithSelectionState = response.data.data.map(candidate => ({
          ...candidate,
          isSelected: false
        }));
        setAvailableCandidates(candidatesWithSelectionState);
      } else {
        setAvailableCandidates([]);
        setError('No candidates available or error fetching candidates');
      }
    } catch (error) {
      console.error('Error fetching available candidates:', error);
      setError('Failed to load candidates. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectCandidate = (candidate) => {
    // Check if already selected
    const isAlreadySelected = selectedCandidates.some(
      selected => selected.c_id === candidate.c_id
    );

    if (isAlreadySelected) {
      // Remove from selection
      setSelectedCandidates(prev => 
        prev.filter(selected => selected.c_id !== candidate.c_id)
      );
      
      // Update the available candidates list
      setAvailableCandidates(prev => 
        prev.map(c => 
          c.c_id === candidate.c_id ? { ...c, isSelected: false } : c
        )
      );
    } else {
      // Add to selection
      setSelectedCandidates(prev => [...prev, candidate]);
      
      // Update the available candidates list
      setAvailableCandidates(prev => 
        prev.map(c => 
          c.c_id === candidate.c_id ? { ...c, isSelected: true } : c
        )
      );
    }
  };

  const handleRemoveSelection = (candidateId) => {
    // Remove from selected list
    setSelectedCandidates(prev => 
      prev.filter(candidate => candidate.c_id !== candidateId)
    );
    
    // Update isSelected state in the available list
    setAvailableCandidates(prev => 
      prev.map(candidate => 
        candidate.c_id === candidateId ? { ...candidate, isSelected: false } : candidate
      )
    );
  };

  const handleInviteCandidates = async () => {
    if (selectedCandidates.length === 0) {
      setError('Please select at least one candidate to invite');
      return;
    }

    setSubmitting(true);
    setError(null);
    setSuccess(null);
    
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };

      // Create payload for each selected candidate
      const invitePromises = selectedCandidates.map(talent => 
        axios.post(
          'https://10idhcjsuk.execute-api.us-east-1.amazonaws.com/dev/add_candidates_to_pod',
          {
            candidate_id: talent.c_id,
            pod_id: podId,
            Candidate_Name: talent.firstName
          },
          { headers }
        )
      );

      // Execute all invites in parallel
      await Promise.all(invitePromises);
      
      setSuccess(`Successfully invited ${selectedCandidates.length} candidate(s) to the pod`);
      
      // Clear selections after successful invite
      setSelectedCandidates([]);
      
      // Refetch available candidates to get updated list
      await fetchAvailableCandidates();
      
      // Notify parent component of success
      setTimeout(() => {
        onSuccess && onSuccess();
      }, 1500);
      
    } catch (error) {
      console.error('Error inviting candidates:', error);
      setError('Failed to invite candidates. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  // Filter and search functions
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
  };

  const toggleFilters = () => {
    setShowFilters(prev => !prev);
  };

  // Add email validation
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  
  // Handle adding email invites
  const handleAddEmailInvite = () => {
    if (!emailInvites.trim()) return;
    
    // Split by commas, semicolons, or whitespace
    const emails = emailInvites.split(/[,;\s]+/).filter(email => email.trim());
    
    const validEmails = [];
    const invalidEmails = [];
    
    emails.forEach(email => {
      if (validateEmail(email.trim())) {
        validEmails.push(email.trim());
      } else {
        invalidEmails.push(email.trim());
      }
    });
    
    if (invalidEmails.length > 0) {
      setEmailValid(false);
      setError(`Invalid email(s): ${invalidEmails.join(', ')}`);
    } else {
      setEmailValid(true);
      setError(null);
      
      // Add valid emails to the list (avoid duplicates)
      const newEmailList = [...emailInvitesList];
      validEmails.forEach(email => {
        if (!newEmailList.includes(email)) {
          newEmailList.push(email);
        }
      });
      
      setEmailInvitesList(newEmailList);
      setEmailInvites(''); // Clear the input field
    }
  };
  
  // Remove an email from the list
  const handleRemoveEmail = (emailToRemove) => {
    setEmailInvitesList(prev => prev.filter(email => email !== emailToRemove));
  };
  
  // Handle email invitation submission
  const handleEmailInvitations = async (e) => {
    e.preventDefault();
    
    if (emailInputs.some(input => !input.emails)) {
      setError('Please fill all email fields');
      return;
    }
    
    setSubmitting(true);
    setError(null);
    setSuccess(null);
    
    // Check for duplicate emails
    const emailArray = emailInputs.map(item => item.emails);
    const uniqueEmails = [...new Set(emailArray)];
    
    if (emailArray.length !== uniqueEmails.length) {
      setError('Email addresses should be unique');
      setSubmitting(false);
      return;
    }
    
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };
      
      // Use the same API endpoint and payload structure as the original Popup component
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_EMAILINVITE,
        {
          invite: emailInputs,
          emp_id: user.data[0].emp_id
        },
        { headers }
      );
      
      if (response.data?.status === 200) {
        setSuccess('Invite emails sent successfully');
        setEmailInputs([{emails: ''}]); // Reset to initial state
        
        // Notify parent component of success
        setTimeout(() => {
          onSuccess && onSuccess();
        }, 1500);
      } else if (response.data?.status === 400) {
        setError(response.data.data || 'Failed to send invitations');
      }
    } catch (error) {
      console.error('Error sending email invitations:', error);
      setError('Failed to send email invitations. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  // Apply filters and search to the candidate list
  const filteredCandidates = availableCandidates.filter(candidate => {
    // Search query filter
    const matchesSearch = 
      !searchQuery || 
      candidate.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      candidate.lastName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      candidate.skills?.some(skill => 
        skill.toLowerCase().includes(searchQuery.toLowerCase())
      );

    // More filters can be added here as needed
    return matchesSearch;
  });

  return (
    <Box sx={{ width: '100%', maxWidth: '800px' }}>
      {/* Header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h5" fontWeight="600">
          Invite Team Members
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)',
            '&:hover': {
              backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.08)',
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      
      {/* Tabs for switching between invitation methods */}
      <Box sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button 
            variant={activeTab === 'existing' ? 'contained' : 'outlined'}
            onClick={() => setActiveTab('existing')}
            sx={{
              borderRadius: '10px 10px 0 0',
              textTransform: 'none',
              fontWeight: 600,
              flexGrow: 1,
              py: 1.5,
              backgroundColor: activeTab === 'existing' ? '#1DBF73' : 'transparent',
              color: activeTab === 'existing' ? 'white' : 'text.primary',
              '&:hover': {
                backgroundColor: activeTab === 'existing' ? '#1DBF73' : 'rgba(0,0,0,0.05)',
              }
            }}
            startIcon={<GroupIcon />}
          >
            Existing Members
          </Button>
          <Button 
            variant={activeTab === 'email' ? 'contained' : 'outlined'}
            onClick={() => setActiveTab('email')}
            sx={{
              borderRadius: '10px 10px 0 0',
              textTransform: 'none',
              fontWeight: 600,
              flexGrow: 1,
              py: 1.5,
              backgroundColor: activeTab === 'email' ? '#1DBF73' : 'transparent',
              color: activeTab === 'email' ? 'white' : 'text.primary',
              '&:hover': {
                backgroundColor: activeTab === 'email' ? '#1DBF73' : 'rgba(0,0,0,0.05)',
              }
            }}
            startIcon={<PersonAddIcon />}
          >
            Invite by Email
          </Button>
        </Box>
      </Box>

      {/* Content based on active tab */}
      <Box sx={{ mb: 3 }}>
        {activeTab === 'existing' ? (
          <>
            {/* Search and Filters for Existing Members */}
            <Box sx={{ mb: 3 }}>
              <SearchField
                fullWidth
                placeholder="Search by name or skills..."
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton 
                        size="small" 
                        onClick={toggleFilters}
                        sx={{ 
                          bgcolor: showFilters ? 'rgba(0, 180, 216, 0.1)' : 'transparent',
                          transition: 'all 0.2s ease'
                        }}
                      >
                        {showFilters ? <ExpandLessIcon /> : <FilterListIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                variant="outlined"
              />

              {/* Filter options - can be expanded with more filters */}
              <Grow in={showFilters}>
                <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  <Typography variant="body2" color="text.secondary" sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
                    <FilterListIcon fontSize="small" sx={{ mr: 0.5 }} /> Filters:
                  </Typography>
                  
                  <FilterChip 
                    label="All Skills" 
                    active={filters.skill === 'all'}
                    onClick={() => handleFilterChange('skill', 'all')}
                    size="small"
                    variant={filters.skill === 'all' ? "filled" : "outlined"}
                  />
                  
                  <FilterChip 
                    label="Development" 
                    active={filters.skill === 'development'}
                    onClick={() => handleFilterChange('skill', 'development')} 
                    size="small"
                    variant={filters.skill === 'development' ? "filled" : "outlined"}
                  />
                  
                  <FilterChip 
                    label="Design" 
                    active={filters.skill === 'design'}
                    onClick={() => handleFilterChange('skill', 'design')} 
                    size="small"
                    variant={filters.skill === 'design' ? "filled" : "outlined"}
                  />
                  
                  <FilterChip 
                    label="Management" 
                    active={filters.skill === 'management'}
                    onClick={() => handleFilterChange('skill', 'management')} 
                    size="small"
                    variant={filters.skill === 'management' ? "filled" : "outlined"}
                  />
                </Box>
              </Grow>
            </Box>

            {/* Selected Candidates Section */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                Selected Candidates ({selectedCandidates.length})
              </Typography>
              
              <Paper
                elevation={0}
                sx={{
                  p: selectedCandidates.length > 0 ? 2 : 0,
                  border: theme => 
                    selectedCandidates.length > 0 
                      ? `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`
                      : 'none',
                  borderRadius: '12px',
                  backgroundColor: theme => 
                    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.01)',
                  display: 'flex',
                  flexWrap: 'wrap',
                  minHeight: '50px',
                  alignItems: 'center',
                  justifyContent: selectedCandidates.length === 0 ? 'center' : 'flex-start'
                }}
              >
                {selectedCandidates.length > 0 ? (
                  selectedCandidates.map((candidate) => (
                    <SelectedCandidateChip
                      key={candidate.c_id}
                      avatar={
                        <Avatar
                          src={candidate.profilePicture ? 
                            `https://globldataprod.s3.amazonaws.com/${candidate.profilePicture}` : 
                            defaultimg
                          }
                          alt={`${candidate.firstName} ${candidate.lastName}`}
                        />
                      }
                      label={`${candidate.firstName} ${candidate.lastName}`}
                      onDelete={() => handleRemoveSelection(candidate.c_id)}
                    />
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Select candidates to invite to this pod
                  </Typography>
                )}
              </Paper>
            </Box>

            {/* Available Candidates List */}
            <Typography variant="subtitle1" fontWeight="600" gutterBottom>
              Available Candidates ({filteredCandidates.length})
            </Typography>
          </>
        ) : (
          <>
            {/* Email Invitation Section */}
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" fontWeight="600" gutterBottom>
                Invite New Members by Email
              </Typography>
              
              <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <TextField
                  fullWidth
                  label="Email Addresses"
                  placeholder="Enter email addresses (separated by commas)"
                  variant="outlined"
                  value={emailInvites}
                  onChange={(e) => {
                    setEmailInvites(e.target.value);
                    if (!emailValid) setEmailValid(true); // Reset validation on change
                  }}
                  error={!emailValid}
                  helperText={!emailValid ? "Please enter valid email addresses" : "Enter multiple emails separated by commas"}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)',
                    }
                  }}
                />
                <Button
                  variant="contained"
                  onClick={handleAddEmailInvite}
                  sx={{
                    minWidth: '120px',
                    borderRadius: '12px',
                    bgcolor: '#1DBF73',
                    '&:hover': {
                      bgcolor: '#19a362',
                    }
                  }}
                >
                  Add
                </Button>
              </Box>
              
              {/* Email List Display */}
              <Paper
                elevation={0}
                sx={{
                  p: emailInvitesList.length > 0 ? 2 : 0,
                  border: theme => 
                    emailInvitesList.length > 0 
                      ? `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`
                      : 'none',
                  borderRadius: '12px',
                  backgroundColor: theme => 
                    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.01)',
                  display: 'flex',
                  flexWrap: 'wrap',
                  minHeight: '100px',
                  alignItems: 'center',
                  justifyContent: emailInvitesList.length === 0 ? 'center' : 'flex-start'
                }}
              >
                {emailInvitesList.length > 0 ? (
                  emailInvitesList.map((email, index) => (
                    <SelectedCandidateChip
                      key={index}
                      avatar={
                        <Avatar sx={{ bgcolor: `hsl(${index * 40}, 70%, 50%)` }}>
                          {email[0].toUpperCase()}
                        </Avatar>
                      }
                      label={email}
                      onDelete={() => handleRemoveEmail(email)}
                    />
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Add email addresses to invite new members
                  </Typography>
                )}
              </Paper>
              
              {/* Helpful Instructions */}
              <Box sx={{ mt: 3, mb: 2, p: 2, bgcolor: 'rgba(0, 180, 216, 0.05)', borderRadius: '12px', border: '1px dashed rgba(0, 180, 216, 0.3)' }}>
                <Typography variant="subtitle2" fontWeight="600" gutterBottom>
                  ✉️ Email Invitation Guidelines
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  • Enter multiple email addresses separated by commas<br />
                  • Invitees will receive instructions to join this pod<br />
                  • They will need to create an account if they don't have one already
                </Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
      
      {/* Error and Success Messages */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2, borderRadius: '8px' }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert 
          severity="success" 
          sx={{ mb: 2, borderRadius: '8px' }}
          onClose={() => setSuccess(null)}
        >
          {success}
        </Alert>
      )}
      
      {/* Candidates List */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress size={32} />
        </Box>
      ) : filteredCandidates.length > 0 ? (
        <Box
          sx={{
            maxHeight: '300px',
            overflow: 'auto',
            mb: 3,
            pr: 1,
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme => 
                theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
              borderRadius: '3px',
            }
          }}
        >
          <List sx={{ p: 0 }}>
            {filteredCandidates.map((candidate) => (
              <Grow
                key={candidate.c_id}
                in={true}
                timeout={300}
                style={{ transformOrigin: '0 0 0' }}
              >
                <CandidateListItem
                  selected={candidate.isSelected}
                  onClick={() => handleSelectCandidate(candidate)}
                  sx={{ cursor: 'pointer' }}
                >
                  <ListItemAvatar>
                    <Avatar
                      src={candidate.profilePicture ? 
                        `https://globldataprod.s3.amazonaws.com/${candidate.profilePicture}` : 
                        defaultimg
                      }
                      alt={`${candidate.firstName} ${candidate.lastName}`}
                      sx={{
                        width: 48,
                        height: 48,
                        border: theme => 
                          `2px solid ${
                            candidate.isSelected 
                              ? (theme.palette.mode === 'dark' ? '#90CAF9' : '#00B4D8')
                              : 'transparent'
                          }`,
                        boxShadow: candidate.isSelected ? '0 0 0 2px rgba(0, 180, 216, 0.3)' : 'none'
                      }}
                    />
                  </ListItemAvatar>
                  
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="subtitle1" fontWeight={candidate.isSelected ? 600 : 400}>
                          {candidate.firstName} {candidate.lastName}
                        </Typography>
                        {candidate.isSelected && (
                          <CheckCircleIcon 
                            fontSize="small" 
                            color="primary" 
                            sx={{ 
                              ml: 1,
                              animation: 'pulse 1.5s infinite ease-in-out',
                              '@keyframes pulse': {
                                '0%': { opacity: 0.7 },
                                '50%': { opacity: 1 },
                                '100%': { opacity: 0.7 },
                              }
                            }} 
                          />
                        )}
                      </Box>
                    }
                    secondary={
                      <Box sx={{ mt: 0.5 }}>
                        {candidate.work_position && (
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            {candidate.work_position}
                          </Typography>
                        )}
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 0.5 }}>
                          {candidate.skills && candidate.skills.slice(0, 3).map((skill, idx) => (
                            <Chip
                              key={idx}
                              label={skill}
                              size="small"
                              sx={{
                                height: '20px',
                                fontSize: '0.75rem',
                                borderRadius: '4px',
                                backgroundColor: theme => 
                                  theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : 'rgba(0,0,0,0.05)',
                              }}
                            />
                          ))}
                          {candidate.skills && candidate.skills.length > 3 && (
                            <Chip
                              label={`+${candidate.skills.length - 3}`}
                              size="small"
                              sx={{
                                height: '20px',
                                fontSize: '0.75rem',
                                borderRadius: '4px',
                                backgroundColor: theme => 
                                  theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)',
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    }
                  />
                  
                  <ListItemSecondaryAction>
                    <Checkbox
                      checked={candidate.isSelected}
                      onChange={() => handleSelectCandidate(candidate)}
                      edge="end"
                      sx={{
                        color: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)',
                        '&.Mui-checked': {
                          color: theme => theme.palette.mode === 'dark' ? '#90CAF9' : '#00B4D8',
                        },
                      }}
                    />
                  </ListItemSecondaryAction>
                </CandidateListItem>
              </Grow>
            ))}
          </List>
        </Box>
      ) : (
        <EmptyStatePaper>
          <PersonAddIcon sx={{ fontSize: 48, opacity: 0.5, mb: 2 }} />
          <Typography variant="h6" gutterBottom>
            No candidates available
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {searchQuery ? 
              'No candidates match your search criteria. Try different keywords.' :
              'There are no available candidates to add to this pod.'
            }
          </Typography>
          {searchQuery && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => setSearchQuery('')}
            >
              Clear Search
            </Button>
          )}
        </EmptyStatePaper>
      )}

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 500,
          }}
        >
          Cancel
        </Button>
        
        {activeTab === 'existing' ? (
          <ActionButton
            startIcon={<PersonAddIcon />}
            onClick={handleInviteCandidates}
            disabled={selectedCandidates.length === 0 || submitting}
          >
            {submitting ? 'Inviting...' : `Invite ${selectedCandidates.length > 0 ? `(${selectedCandidates.length})` : ''}`}
          </ActionButton>
        ) : (
          <ActionButton
            startIcon={<PersonAddIcon />}
            onClick={handleEmailInvitations}
            disabled={emailInvitesList.length === 0 || submitting}
          >
            {submitting ? 'Sending...' : `Send Email Invites${emailInvitesList.length > 0 ? ` (${emailInvitesList.length})` : ''}`}
          </ActionButton>
        )}
      </Box>
    </Box>
  );
};

export default EnhancedInviteModal;