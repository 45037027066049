import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  Alert,
  FormHelperText,
  
  alpha,
  useTheme,
  Autocomplete,
  Chip,
} from "@mui/material";
import axios from "axios";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import {

  BugReport as BugReportIcon,
  
} from '@mui/icons-material';
// Styled components
const ContentWrapper = styled(Box)(({ theme, collapsed }) => ({
  marginLeft: collapsed ? "60px" : "240px",
  marginTop: "5rem",
  width: collapsed ? "80vw" : "70vw",
  margin: "auto",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    marginLeft: "4rem",
    width: "80vw",
    padding: theme.spacing(1),
  },
}));

const ErrorMessage = styled(Alert)({
  marginBottom: '1rem',
});

const TaskForm = ({ collapsed }) => {
    const theme = useTheme();
  
  const location = useLocation();
  const history = useHistory();
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector(state => state.auth);
  const isDarkMode = theme.palette.mode === 'dark';
  // State management
  const { Id: podId, podname } = location.state || {};
  const [pods, setPods] = useState([]);
  const [selectedPodId, setSelectedPodId] = useState(podId || '');
  const [selectedPodName, setSelectedPodName] = useState(podname || '');
  const [podMembers, setPodMembers] = useState([]);
  const [slackUsers, setSlackUsers] = useState([]);
  const [userStories, setUserStories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [formData, setFormData] = useState({
    podTaskName: "",
    description: "",
    dueDate: "",
    taskOwner: "",
    category: "",
    status: "",
    jiraItems: [],
    notificationRecipient: "",
    hasBlocker: false
  });

  // API calls
  const fetchPods = async () => {
    if (!user?.data?.[0]?.emp_id || !token) return;
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETMULTIPLECLIENTPODS,
        { clientID: user.data[0].emp_id },
        { headers: { Authorization: token } }
      );
      if (response.data?.data?.podDetails) {
        setPods(response.data.data.podDetails);
      }
    } catch (err) {
      setError(intl.formatMessage({ id: "podsFetchError" }));
    }
  };

  const fetchPodMembers = async () => {
    if (!selectedPodId || !token) return;
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_CLIENTFETCHPODMEMBERS,
        { pod_Id: selectedPodId },
        { headers: { Authorization: token } }
      );
      if (response.data?.data) {
        setPodMembers(response.data.data);
      }
    } catch (err) {
      setError(intl.formatMessage({ id: "podMembersFetchError" }));
    }
  };

  const fetchJiraIssues = async () => {
    if (!formData.taskOwner || !selectedPodId || !token) return;
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_JIRAISSUESLISTTALENTS,
        {
          pod_id: selectedPodId,
          c_id: formData.taskOwner
        },
        { headers: { Authorization: token } }
      );
      if (response.data.statusCode === 200 && response.data.body) {
        const parsed = JSON.parse(response?.data?.body);
        setUserStories(parsed.jiraIssues || []);
      }
    } catch (err) {
      setError(intl.formatMessage({ id: "jiraIssuesFetchError" }));
    }
  };

  const fetchSlackUsers = async () => {
    if (!token) return;
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_SLACKUSERSFETCH,
        { token: process.env.REACT_APP_SLACK_TOKEN },
        { headers: { Authorization: token } }
      );
      if (response.data?.body?.users) {
        setSlackUsers(response.data.body.users);
      }
    } catch (err) {
      console.error("Error fetching Slack users:", err);
    }
  };

  // Submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      await axios.post(
        process.env.REACT_APP_CLIENT_CLIENTCREATEPODTAS,
        {
          project_key: selectedPodName,
          pod_task_name: formData.podTaskName,
          description: formData.description,
          due_date: formData.dueDate,
          candidate_id: parseInt(formData.taskOwner),
          category: formData.category,
          status: formData.status,
          pod_id: selectedPodId,
          issue_key: formData.jiraItems,
          blockercheck: formData.hasBlocker ? 1 : 0
        },
        { headers: { Authorization: token } }
      );

      if (formData.notificationRecipient) {
        await axios.post(
          process.env.REACT_APP_CLIENT_SLACKINDIVIDUALTASKNOTIFICATION,
          {
            token: process.env.REACT_APP_SLACK_TOKEN,
            user_id: formData.notificationRecipient,
            task_name: formData.podTaskName
          },
          { headers: { Authorization: token } }
        );
      }

      setSuccess(intl.formatMessage({ id: "taskCreatedSuccess" }));
      setTimeout(() => {
        history.push(`/ViewPodTasksManagerbyid`);
      }, 2000);
    } catch (err) {
      setError(intl.formatMessage({ id: "taskCreationError" }));
    } finally {
      setLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    fetchPods();
    fetchSlackUsers();
  }, [token, user]);

  useEffect(() => {
    if (selectedPodId) {
      fetchPodMembers();
      setFormData(prev => ({
        ...prev,
        taskOwner: "",
        jiraItems: []
      }));
    }
  }, [selectedPodId, token]);

  useEffect(() => {
    if (formData.taskOwner && selectedPodId) {
      fetchJiraIssues();
    }
  }, [formData.taskOwner, selectedPodId, token]);

  // Helper text for fields
  const getCharacterCount = (field, limit) => `${formData[field].length}/${limit}`;
  const isPodSelected = Boolean(selectedPodId);
 const getInputStyle = (error) => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: 1,
      backgroundColor: isDarkMode 
        ? alpha(theme.palette.background.paper, 0.1)
        : alpha(theme.palette.background.paper, 0.8),
      '&:hover': {
        backgroundColor: isDarkMode
          ? alpha(theme.palette.background.paper, 0.15)
          : alpha(theme.palette.background.paper, 0.9)
      },
      ...(error && {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main
        }
      })
    }
  });

  return (
    <ContentWrapper collapsed={collapsed}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
        {intl.formatMessage({ id: "createTask" })}
      </Typography>

      {error && <ErrorMessage severity="error">{error}</ErrorMessage>}
      {success && <ErrorMessage severity="success">{success}</ErrorMessage>}
      {!isPodSelected && (
        <Alert severity="info" sx={{ mb: 2 }}>
          Please select a Pod to enable other fields
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 800, mx: "auto" }}>
        <Stack spacing={3}>
          {/* Pod Selection */}
          <FormControl fullWidth required>
            <InputLabel>{intl.formatMessage({ id: "selectPod" })}</InputLabel>
            <Select
              value={selectedPodId}
              onChange={e => {
                const pod = pods.find(p => p.id === e.target.value);
                setSelectedPodId(e.target.value);
                setSelectedPodName(pod?.podName || '');
              }}
              label={intl.formatMessage({ id: "selectPod" })}
            >
              {pods.map((pod) => (
                <MenuItem key={pod.id} value={pod.id}>
                  {pod.podName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Task Name */}
          <TextField
            required
            fullWidth
            label={intl.formatMessage({ id: "podTaskName" })}
            value={formData.podTaskName}
            onChange={e => setFormData(prev => ({ ...prev, podTaskName: e.target.value }))}
            inputProps={{ maxLength: 50 }}
            disabled={!isPodSelected}
            helperText={getCharacterCount('podTaskName', 50)}
          />

          {/* Description */}
          <TextField
            required
            fullWidth
            multiline
            rows={3}
            label={intl.formatMessage({ id: "podTaskDescription" })}
            value={formData.description}
            onChange={e => setFormData(prev => ({ ...prev, description: e.target.value }))}
            inputProps={{ maxLength: 100 }}
            disabled={!isPodSelected}
            helperText={getCharacterCount('description', 100)}
          />

          {/* Due Date */}
          <TextField
            required
            fullWidth
            type="date"
            label={intl.formatMessage({ id: "podTaskDueDate" })}
            value={formData.dueDate}
            onChange={e => setFormData(prev => ({ ...prev, dueDate: e.target.value }))}
            InputLabelProps={{ shrink: true }}
            disabled={!isPodSelected}
          />

          {/* Task Owner */}
          <FormControl fullWidth required>
            <InputLabel>{intl.formatMessage({ id: "podTaskOwner" })}</InputLabel>
            <Select
              value={formData.taskOwner}
              onChange={e => setFormData(prev => ({ ...prev, taskOwner: e.target.value }))}
              label={intl.formatMessage({ id: "podTaskOwner" })}
              disabled={!isPodSelected}
            >
              {podMembers.map(member => (
                <MenuItem key={member.c_id} value={member.c_id}>
                  {member.firstName}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {!isPodSelected ? "Select a Pod first" : ""}
            </FormHelperText>
          </FormControl>

          {/* Status */}
          <FormControl fullWidth required>
            <InputLabel>{intl.formatMessage({ id: "podTaskStatus" })}</InputLabel>
            <Select
              value={formData.status}
              onChange={e => setFormData(prev => ({ ...prev, status: e.target.value }))}
              label={intl.formatMessage({ id: "podTaskStatus" })}
              disabled={!isPodSelected}
            >
              <MenuItem value="To Do">To Do</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Done">Done</MenuItem>
            </Select>
          </FormControl>

          {/* Category */}
          <FormControl fullWidth required>
            <InputLabel>{intl.formatMessage({ id: "podCategories" })}</InputLabel>
            <Select
              value={formData.category}
              onChange={e => setFormData(prev => ({ ...prev, category: e.target.value }))}
              label={intl.formatMessage({ id: "podCategories" })}
              disabled={!isPodSelected}
            >
              <MenuItem value="Design">Design</MenuItem>
              <MenuItem value="Coding">Coding</MenuItem>
              <MenuItem value="Testing">Testing</MenuItem>
              <MenuItem value="Documentation">Documentation</MenuItem>
            </Select>
          </FormControl>

          {/* JIRA Issues */}
          <FormControl fullWidth >
                    <Autocomplete
                      multiple
                      options={userStories}
                      getOptionLabel={(option) => 
                        typeof option === 'string' ? option : option.issue_key
                      }
                      value={formData.jiraItems.map(item => 
                        userStories.find(issue => issue.issue_key === item) || item
                      )}
                      onChange={(event, newValue) => {
                        setFormData(prev => ({
                          ...prev,
                          jiraItems: newValue.map(v => 
                            typeof v === 'string' ? v : v.issue_key
                          )
                        }));
                      }}
                      filterOptions={(options, { inputValue }) => {
                        return options.filter(option =>
                          option.issue_key.toLowerCase().includes(inputValue.toLowerCase()) ||
                          (option.summary && option.summary.toLowerCase().includes(inputValue.toLowerCase()))
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search JIRA issues..."
                          disabled={!formData.taskOwner}
                          sx={{
                          
                            '& .MuiOutlinedInput-root': {
                              padding: '3px 8px'
                            }
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem 
                          {...props}
                          sx={{
                            py: 1,
                            px: 2,
                            '&:hover': {
                              backgroundColor: 'action.hover'
                            }
                          }}
                        >
                          <Box sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                          }}>
                            <Box sx={{ 
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1
                            }}>
                              <BugReportIcon 
                                sx={{ 
                                  color: theme.palette.primary.main,
                                  fontSize: 20
                                }} 
                              />
                              <Typography 
                                variant="body2"
                                sx={{ 
                                  color: theme.palette.text.primary,
                                  fontWeight: 500
                                }}
                              >
                                {option.issue_key}
                              </Typography>
                            </Box>
                            {option.issue_summary && (
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ 
                                  ml: 4,
                                  mt: 0.5,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {option.issue_summary}
                              </Typography>
                            )}
                          </Box>
                        </MenuItem>
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option.issue_key}
                            label={
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <BugReportIcon sx={{ fontSize: 16 }} />
                                {option.issue_key}
                              </Box>
                            }
                            onDelete={getTagProps({ index }).onDelete}
                            sx={{
                              m: 0.5,
                              backgroundColor: theme.palette.primary.main,
                              color: '#fff',
                              '& .MuiChip-deleteIcon': {
                                color: '#fff',
                                '&:hover': {
                                  color: alpha('#fff', 0.7)
                                }
                              },
                              height: 28,
                              borderRadius: 1
                            }}
                          />
                        ))
                      }
                      ListboxProps={{
                        sx: {
                          maxHeight: 300,
                          '& .MuiAutocomplete-option': {
                            padding: 1
                          }
                        }
                      }}
                      sx={{
                        '& .MuiAutocomplete-tag': {
                          margin: 0.5
                        }
                      }}
                    />
                    <FormHelperText>
              {!formData.taskOwner ? "Select a Task Owner to view JIRA issues" : ""}
            </FormHelperText>
                  </FormControl>

          {/* Slack Notification */}
          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: "notificationRecipient" })}</InputLabel>
            <Select
              value={formData.notificationRecipient}
              onChange={e => setFormData(prev => ({ ...prev, notificationRecipient: e.target.value }))}
              label={intl.formatMessage({ id: "notificationRecipient" })}
              disabled={!isPodSelected}
            >
              {slackUsers.map(user => (
                <MenuItem key={user.user_id} value={user.user_id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Optional: Select a user to notify via Slack</FormHelperText>
          </FormControl>

          {/* Blocker Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.hasBlocker}
                onChange={e => setFormData(prev => ({ ...prev, hasBlocker: e.target.checked }))}
                disabled={!isPodSelected}
              />
            }
            label={intl.formatMessage({ id: "checkBoxBlocker" })}
          />

          {/* Submit Button */}
          <Button
            type="submit"
            variant="contained"
            disabled={loading || !isPodSelected || !formData.podTaskName || !formData.taskOwner}
            fullWidth
            sx={{ mt: 2 }}
          >
            {loading 
              ? intl.formatMessage({ id: "submitting" })
              : intl.formatMessage({ id: "submit" })
            }
          </Button>
        </Stack>
      </Box>
    </ContentWrapper>
  );
};

export default TaskForm;