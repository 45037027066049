import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import axios from 'axios';
import moment from 'moment';
import {
  Box,
  Card,
  Typography,
  CircularProgress,
  Fade,
  Divider,
  Paper,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Chip,
  IconButton,
  Tooltip,
  Container,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import SortIcon from '@mui/icons-material/Sort';
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import HistoryIcon from '@mui/icons-material/History';

// Styled Components with enhanced styling
const PageWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === 'dark'
    ? `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[900]} 100%)`
    : `linear-gradient(120deg, #f8faff 0%, #e6f2ff 100%)`,
  minHeight: '100vh',
  width: '100%',
  position: 'relative',
  margin: '0',
  padding: theme.spacing(3),
  transition: 'background 0.3s ease',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  }
}));

const ContentWrapper = styled(Container)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  maxWidth: '1200px',
  margin: '0 auto',
  padding: 0,
  width: '100%',
}));

const HeaderCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(2),
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(135deg, #2D3748 0%, #1A202C 100%)'
    : 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 8px 24px rgba(0, 0, 0, 0.2)'
    : '0 8px 24px rgba(66, 153, 225, 0.08)',
  padding: theme.spacing(3),
  textAlign: 'center',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '5px',
    background: 'linear-gradient(90deg, #3182CE 0%, #63B3ED 100%)',
  }
}));

const PodCard = styled(Card)(({ theme }) => ({
  margin: '20px auto',
  width: '100%',
  maxWidth: '1200px',
  borderRadius: theme.spacing(2),
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(135deg, #2D3748 0%, #1A202C 100%)'
    : 'linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%)',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 10px 30px rgba(0, 0, 0, 0.2)'
    : '0 10px 30px rgba(66, 153, 225, 0.1)',
  transition: 'all 0.3s ease',
  color: theme.palette.mode === 'dark' ? '#E0E0E0' : 'inherit',
  overflow: 'hidden',
}));

const FormSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.mode === 'dark' 
    ? '#1A202C'
    : 'inherit',
  color: theme.palette.mode === 'dark' ? '#E0E0E0' : 'inherit',
}));

const FormSectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 600,
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  '&:after': {
    content: '""',
    display: 'block',
    height: '1px',
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    background: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.1)',
  }
}));

const TableSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(66, 153, 225, 0.03)'
    : 'rgba(66, 153, 225, 0.02)',
  borderRadius: theme.shape.borderRadius,
  transition: 'background-color 0.3s ease'
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(135deg, #3182CE 0%, #63B3ED 100%)',
  color: '#ffffff',
  fontWeight: 600,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5, 3),
  transition: 'all 0.2s ease',
  textTransform: 'none',
  boxShadow: '0 4px 14px rgba(66, 153, 225, 0.2)',
  '&:hover': {
    background: 'linear-gradient(135deg, #2C5282 0%, #3182CE 100%)',
    boxShadow: '0 6px 20px rgba(66, 153, 225, 0.3)',
    transform: 'translateY(-2px)',
  },
  '&:disabled': {
    background: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.12)'
      : 'rgba(0, 0, 0, 0.12)',
    color: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.3)'
      : 'rgba(0, 0, 0, 0.26)'
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3182CE',
      borderWidth: '2px',
    }
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#3182CE'
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.2s ease',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#3182CE',
      borderWidth: '2px',
    }
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#3182CE'
  }
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(66, 153, 225, 0.1)'
      : 'rgba(66, 153, 225, 0.05)',
    fontWeight: 700,
    fontSize: '0.875rem',
    color: theme.palette.mode === 'dark' ? '#E0E0E0' : '#2D3748',
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}`,
  padding: theme.spacing(2),
  fontSize: '0.875rem',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'background-color 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(66, 153, 225, 0.05)'
      : 'rgba(66, 153, 225, 0.03)',
  }
}));

const ScrollableTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: '500px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
    }
  }
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const SprintChip = styled(Chip)(({ theme }) => ({
  fontWeight: 600,
  background: theme.palette.mode === 'dark' 
    ? 'rgba(66, 153, 225, 0.2)'
    : 'rgba(66, 153, 225, 0.1)',
  color: theme.palette.mode === 'dark' ? '#63B3ED' : '#3182CE',
  border: 'none',
}));

const PodRetroManager = () => {
  const token = useAuthToken();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useSelector(state => state.auth);

  // State declarations
  const [loading, setLoading] = useState(true);
  const [podDetails, setPodDetails] = useState([]);
  const [selectedPodId, setSelectedPodId] = useState('');
  const [retrolisting, setRetrolisting] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState('');
  const [sprintData, setSprintData] = useState([]);
  const [currentPageretro, setCurrentPageretro] = useState(1);
  const [teamFilter, setTeamFilter] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [showFormSection, setShowFormSection] = useState(true);
  const itemsPerPageretro = 5;

  // Initial data fetch
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const headers = {
          'Authorization': token,
          'Content-Type': 'application/json',
        };

        // Fetch pod details
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_GETCLIENTPOD,
          { clientID: user.data[0].emp_id },
          { headers }
        );

        if (response.data?.data) {
          setPodDetails(response.data.data.podDetails || []);
        }

        // Fetch sprint data
        const authResponse = await axios.post(
          process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
          { Organizational_Code: user.data[0].client_code },
          { headers }
        );

        if (authResponse.data?.body?.data?.[0]) {
          const authData = authResponse.data.body.data[0];
          const sprintResponse = await axios.post(
            process.env.REACT_APP_CLIENT_FETCHSPRINTNUMBERS,
            {
              Jira_URL: authData.Jira_URL,
              Jira_admin_login_email_address: authData.Jira_admin_login_email_address,
              Jira_admin_API_Token: authData.Jira_admin_API_Token
            },
            { headers }
          );

          if (sprintResponse.data?.body) {
            const parsedData = JSON.parse(sprintResponse.data.body);
            setSprintData(parsedData.sprints || []);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [token, user]);

  // Fetch retro listing when pod is selected
  useEffect(() => {
    if (selectedPodId) {
      fetchRetroListing(selectedPodId);
    }
  }, [selectedPodId]);

  const getRetroFields = (retroString) => {
    try {
      return typeof retroString === 'string' ? JSON.parse(retroString) : retroString;
    } catch (error) {
      console.error('Error parsing retro:', error);
      return {};
    }
  };

  const fetchRetroListing = async (podId) => {
    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_PODRETROLIST,
        { pods_id: podId },
        { 
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data?.data) {
        const processedData = response.data.data.map(item => ({
          ...item,
          retroFields: getRetroFields(item.retro)
        }));
        setRetrolisting(processedData);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching retro listing:', error);
      setLoading(false);
    }
  };

  const handleSubmitpodretro = async (e) => {
    e.preventDefault();
    setLoading(true);

    const retroFeedback = {
      start_doing: e.target.start_doing.value,
      stop_doing: e.target.stop_doing.value,
      keep_doing: e.target.keep_doing.value,
    };

    const formData = {
      pod_feedback: e.target.podRetro.value,
      retro: retroFeedback,
      sprint: selectedSprint,
      status: "Completed",
      submitted_by: user.data[0].emp_firstname || null,
      submitted_by_id: user.data[0].emp_id,
      pods_id: selectedPodId
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_TALENT_PODRETROS,
        formData,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === 200) {
        // Show success message
        alert("Posted Successfully.");
        fetchRetroListing(selectedPodId);
        e.target.reset();
        setSelectedSprint('');
      } else {
        alert(response.data.data || "Error submitting retro");
      }
      setLoading(false);
    } catch (error) {
      console.error('Error submitting retro:', error);
      alert("Error submitting retro. Please try again.");
      setLoading(false);
    }
  };

  const sortedAndFilteredRetros = useMemo(() => {
    let filtered = [...retrolisting];
    
    if (teamFilter) {
      filtered = filtered.filter(item => item.submitted_by === teamFilter);
    }

    return filtered.sort((a, b) => {
      const dateA = moment(a.createdAt);
      const dateB = moment(b.createdAt);
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
  }, [retrolisting, teamFilter, sortDirection]);

  const startIndex = (currentPageretro - 1) * itemsPerPageretro;
  const endIndex = Math.min(startIndex + itemsPerPageretro, sortedAndFilteredRetros.length);
  const currentRetros = sortedAndFilteredRetros.slice(startIndex, endIndex);
  const totalPages = Math.ceil(sortedAndFilteredRetros.length / itemsPerPageretro);

  // Get unique team members for filter
  const uniqueMembers = useMemo(() => {
    return Array.from(new Set(retrolisting.map(item => item.submitted_by)))
      .filter(member => member); // Filter out null/undefined
  }, [retrolisting]);

  const toggleFormSection = () => {
    setShowFormSection(!showFormSection);
  };

  if (loading) {
    return (
      <Fade in={true} timeout={800}>
        <LoadingContainer>
          <CircularProgress size={60} sx={{ color: '#3182CE' }} />
          <Typography variant="body1" color="textSecondary">
            {intl.formatMessage({ id: 'loading' }) || 'Loading...'}
          </Typography>
        </LoadingContainer>
      </Fade>
    );
  }

  return (
    <PageWrapper>
      <ContentWrapper>
        <HeaderCard>
          <Typography 
            variant={isMobile ? "h5" : "h4"} 
            fontWeight={700} 
            sx={{ 
              color: theme.palette.mode === 'dark' ? '#E0E0E0' : '#2D3748',
              marginBottom: theme.spacing(1) 
            }}
          >
            {intl.formatMessage({ id: 'podRetro' }) || 'Pod Retrospective Manager'}
          </Typography>
          
        </HeaderCard>

        <Box mb={4} sx={{ maxWidth: '400px', width: '100%', mx: 'auto' }}>
          <StyledFormControl fullWidth variant="outlined" size="medium">
            <InputLabel>{intl.formatMessage({ id: 'selectPod' }) || 'Select Pod'}</InputLabel>
            <Select
              value={selectedPodId}
              onChange={(e) => setSelectedPodId(e.target.value)}
              label={intl.formatMessage({ id: 'selectPod' }) || 'Select Pod'}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {podDetails.map((pod) => (
                <MenuItem key={pod.id} value={pod.id}>
                  {pod.podName}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Box>

        {selectedPodId && (
          <PodCard>
            {showFormSection && (
              <>
                <FormSection>
                  <FormSectionTitle variant="h6">
                    <AddIcon fontSize="small" />
                    {intl.formatMessage({ id: 'newRetro' }) || 'New Retrospective'}
                  </FormSectionTitle>
                  
                  <form onSubmit={handleSubmitpodretro}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <StyledTextField
                          required
                          fullWidth
                          multiline
                          rows={3}
                          name="podRetro"
                          label={intl.formatMessage({ id: 'overallFeedback' }) || 'Overall Pod Feedback'}
                          variant="outlined"
                          placeholder="Share your thoughts about the pod's performance during this sprint..."
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <StyledFormControl fullWidth required variant="outlined">
                          <InputLabel>{intl.formatMessage({ id: 'sprint' }) || 'Sprint'}</InputLabel>
                          <Select
                            value={selectedSprint}
                            onChange={(e) => setSelectedSprint(e.target.value)}
                            label={intl.formatMessage({ id: 'sprint' }) || 'Sprint'}
                          >
                            {sprintData.map((sprint, index) => (
                              <MenuItem key={index} value={sprint.sprint_name}>
                                {sprint.sprint_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </StyledFormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                          {intl.formatMessage({ id: 'retroMatrix' }) || 'Retrospective Matrix'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <StyledTextField
                          required
                          fullWidth
                          multiline
                          rows={4}
                          name="start_doing"
                          label={intl.formatMessage({ id: 'startDoing' }) || 'Start Doing'}
                          variant="outlined"
                          placeholder="What should the team start doing..."
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <StyledTextField
                          required
                          fullWidth
                          multiline
                          rows={4}
                          name="stop_doing"
                          label={intl.formatMessage({ id: 'stopDoing' }) || 'Stop Doing'}
                          variant="outlined"
                          placeholder="What should the team stop doing..."
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <StyledTextField
                          required
                          fullWidth
                          multiline
                          rows={4}
                          name="keep_doing"
                          label={intl.formatMessage({ id: 'keepDoing' }) || 'Keep Doing'}
                          variant="outlined"
                          placeholder="What should the team continue doing..."
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <StyledButton
                          type="submit"
                          variant="contained"
                          size="large"
                          fullWidth
                          startIcon={<CheckCircleIcon />}
                        >
                          {intl.formatMessage({ id: 'submitRetro' }) || 'Submit Retrospective'}
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </form>
                </FormSection>

                <Divider />
              </>
            )}

            <TableSection>
              <Box mb={3} display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <HistoryIcon color="primary" />
                  <Typography variant="h6" fontWeight={600}>
                    {intl.formatMessage({ id: 'retroHistory' }) || 'Retrospective History'}
                  </Typography>
                </Box>

                <Box display="flex" gap={2}>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={toggleFormSection}
                    size="small"
                  >
                    {showFormSection 
                      ? (intl.formatMessage({ id: 'hideForm' }) || 'Hide Form') 
                      : (intl.formatMessage({ id: 'showForm' }) || 'Show Form')}
                  </Button>
                
                  <StyledFormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                    <InputLabel>
                      {intl.formatMessage({ id: 'filterByMember' }) || 'Filter by Member'}
                    </InputLabel>
                    <Select
                      value={teamFilter}
                      onChange={(e) => setTeamFilter(e.target.value)}
                      label={intl.formatMessage({ id: 'filterByMember' }) || 'Filter by Member'}
                    >
                      <MenuItem value="">
                        {intl.formatMessage({ id: 'allMembers' }) || 'All Members'}
                      </MenuItem>
                      {uniqueMembers.map((member, index) => (
                        <MenuItem key={index} value={member}>{member}</MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Box>
              </Box>

              <Paper elevation={0} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                <ScrollableTableContainer>
                  <Table>
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>{intl.formatMessage({ id: 'member' }) || 'Member'}</TableCell>
                        <TableCell 
                          onClick={() => setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')}
                          style={{ cursor: 'pointer' }}
                        >
                          <Box display="flex" alignItems="center" gap={0.5}>
                            {intl.formatMessage({ id: 'date' }) || 'Date'}
                            <SortIcon fontSize="small" color="action" />
                          </Box>
                        </TableCell>
                        <TableCell>{intl.formatMessage({ id: 'sprint' }) || 'Sprint'}</TableCell>
                        <TableCell>{intl.formatMessage({ id: 'feedback' }) || 'Feedback'}</TableCell>
                        <TableCell>{intl.formatMessage({ id: 'startDoing' }) || 'Start Doing'}</TableCell>
                        <TableCell>{intl.formatMessage({ id: 'stopDoing' }) || 'Stop Doing'}</TableCell>
                        <TableCell>{intl.formatMessage({ id: 'keepDoing' }) || 'Keep Doing'}</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {currentRetros.length > 0 ? (
                        currentRetros.map((retro, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              <Box display="flex" alignItems="center" gap={1}>
                                <Avatar 
                                  sx={{ 
                                    width: 30, 
                                    height: 30, 
                                    bgcolor: `hsl(${(retro.submitted_by?.charCodeAt(0) || 0) * 10}, 70%, 60%)`,
                                    fontSize: '0.8rem'
                                  }}
                                >
                                  {retro.submitted_by?.charAt(0) || '?'}
                                </Avatar>
                                {retro.submitted_by}
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell>
                              {moment(retro.createdAt).format("MMM DD, YYYY")}
                            </StyledTableCell>
                            <StyledTableCell>
                              <SprintChip size="small" label={retro.sprint} />
                            </StyledTableCell>
                            <StyledTableCell sx={{ maxWidth: 180, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <Tooltip title={retro.pod_feedback || ''}>
                                <Typography variant="body2">
                                  {retro.pod_feedback}
                                </Typography>
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell sx={{ maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <Tooltip title={retro.retroFields.start_doing || ''}>
                                <Typography variant="body2">
                                  {retro.retroFields.start_doing}
                                </Typography>
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell sx={{ maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <Tooltip title={retro.retroFields.stop_doing || ''}>
                                <Typography variant="body2">
                                  {retro.retroFields.stop_doing}
                                </Typography>
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell sx={{ maxWidth: 150, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <Tooltip title={retro.retroFields.keep_doing || ''}>
                                <Typography variant="body2">
                                  {retro.retroFields.keep_doing}
                                </Typography>
                              </Tooltip>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={7} align="center" sx={{ py: 4 }}>
                            <Typography variant="body1" color="textSecondary">
                              {intl.formatMessage({ id: 'noRetrosFound' }) || 'No retrospectives found'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </ScrollableTableContainer>

                {totalPages > 0 && (
                  <Box p={3} display="flex" justifyContent="center" alignItems="center">
                    <Box display="flex" gap={1} alignItems="center">
                      <IconButton
                        onClick={() => setCurrentPageretro(prev => Math.max(1, prev - 1))}
                        disabled={currentPageretro === 1}
                        size="small"
                        sx={{ 
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: 'divider'
                        }}
                      >
                        <ArrowBackIosNewIcon fontSize="small" />
                      </IconButton>
                      
                      <Box sx={{ minWidth: '120px', textAlign: 'center' }}>
                        <Typography variant="body2">
                          {`${intl.formatMessage({ id: 'page' }) || 'Page'} ${currentPageretro} ${intl.formatMessage({ id: 'of' }) || 'of'} ${totalPages}`}
                        </Typography>
                      </Box>
                      
                      <IconButton
                        onClick={() => setCurrentPageretro(prev => Math.min(totalPages, prev + 1))}
                        disabled={currentPageretro === totalPages}
                        size="small"
                        sx={{ 
                          borderRadius: '4px',
                          border: '1px solid',
                          borderColor: 'divider'
                        }}
                      >
                        <ArrowForwardIosIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                )}
              </Paper>
            </TableSection>
          </PodCard>
        )}

        {!selectedPodId && (
          <Paper 
            elevation={0} 
            sx={{ 
              p: 4, 
              textAlign: 'center', 
              borderRadius: 2,
              background: theme.palette.mode === 'dark' 
                ? 'rgba(66, 153, 225, 0.05)'
                : 'rgba(66, 153, 225, 0.03)',
            }}
          >
            <Typography variant="h6" gutterBottom color="textSecondary">
              {intl.formatMessage({ id: 'selectPodPrompt' }) || 'Please select a pod to view retrospectives'}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {intl.formatMessage({ id: 'podSelectHelp' }) || 'Use the dropdown above to select a pod and manage its retrospectives'}
            </Typography>
          </Paper>
        )}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default PodRetroManager;