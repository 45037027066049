import './BrowseTalent.css';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Breadcrumbs,
  
  Link,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,

  Avatar,
  Chip,
  Select,
  MenuItem,
  FormControl,
  
  InputLabel,
  FormHelperText,
  Dialog,
  createTheme,
  CircularProgress,
  Alert,
  Stack,
  InputAdornment,
  useTheme,
  alpha,
  Pagination
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBack from '@mui/icons-material/ArrowBack';

import { styled } from '@mui/material/styles';
import {
  Search as SearchIcon,
  LocationOn as LocationOnIcon,
  Work as WorkIcon,
  AccessTime as AccessTimeIcon,
  Add as AddIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import moment from 'moment';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import { NavHashLink } from 'react-router-hash-link';
import AWS from 'aws-sdk';
import { useHistory } from 'react-router-dom';
// Rest of the component remains the same, but replace the pagination logic with:
// AWS Configuration
const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
};




















// Update the theme configuration
const theme = createTheme({
  palette: {
    primary: {
      main: '#4F46E5', // Modern indigo
      light: '#818CF8',
      dark: '#3730A3',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#10B981', // Modern emerald
      light: '#34D399',
      dark: '#059669',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#10B981', // Emerald
      light: '#6EE7B7',
      dark: '#047857'
    },
    warning: {
      main: '#F59E0B', // Warm amber
      light: '#FBBF24',
      dark: '#D97706'
    },
    error: {
      main: '#EF4444', // Vibrant red
      light: '#FCA5A5',
      dark: '#DC2626'
    },
    info: {
      main: '#3B82F6', // Bright blue
      light: '#60A5FA',
      dark: '#2563EB'
    },
    background: {
      default: '#F9FAFB',
      paper: '#FFFFFF'
    },
    text: {
      primary: '#111827',
      secondary: '#4B5563',
      disabled: '#9CA3AF'
    },
    divider: '#E5E7EB',
  },
  // Enhanced shadows for better depth
  shadows: [
    'none',
    '0px 1px 3px rgba(0, 0, 0, 0.08)',
    '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
    '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
    '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
  ]
});

const TALENT_TYPE_LABELS = {
  PARTNER: "P",
  FREELANCER: "F",
  CLIENT: "I"
};

// Enhanced component styles
// Add this updated componentStyles object in your code


// Styled Components with dark mode support
const StyledContainer = styled(Container)(({ theme }) => ({
  // marginTop: theme.spacing(12),
  marginBottom: theme.spacing(4),
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%)'
    : 'linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%)',
  '@media (max-width: 600px)': {
    marginTop: theme.spacing(8),
  }
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.palette.mode === 'dark' 
      ? '0 8px 24px rgba(0,0,0,0.4)' 
      : theme.shadows[8]
  },
  '& .MuiCardContent-root': {
    backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  },
  '& .MuiTypography-root': {
    color: theme.palette.mode === 'dark' ? '#fff' : 'inherit'
  },
  '& .MuiChip-root': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(255,255,255,0.1)' 
      : 'rgba(0,0,0,0.08)',
    color: theme.palette.mode === 'dark' ? '#fff' : 'inherit'
  }
}));

const SearchBar = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 20px rgba(0,0,0,0.2)'
    : `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
  marginBottom: theme.spacing(4),
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#252525' : '#f8fafc',
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
    }
  }
}));

const FilterGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : theme.palette.background.paper,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
  '& .MuiFormLabel-root': {
    color: theme.palette.mode === 'dark' ? '#fff' : 'inherit'
  },
  '& .MuiSelect-select': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' 
      ? 'rgba(255,255,255,0.2)' 
      : 'rgba(0,0,0,0.1)',
  }
}));

// Enhanced component styles with dark mode support
const componentStyles = {


    // Updated talent type chips with dark mode support
    talentTypeChip: {
      PARTNER: theme => ({
        backgroundColor: theme.palette.mode === 'dark' 
          ? 'rgba(16, 185, 129, 0.2)' 
          : 'rgba(16, 185, 129, 0.1)',
        color: theme.palette.mode === 'dark' ? '#34D399' : '#059669',
        borderColor: '#10B981',
        fontWeight: 500
      }),
      FREELANCER: theme => ({
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(245, 158, 11, 0.2)'
          : 'rgba(245, 158, 11, 0.1)',
        color: theme.palette.mode === 'dark' ? '#FBBF24' : '#D97706',
        borderColor: '#F59E0B',
        fontWeight: 500
      }),
      CLIENT: theme => ({
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(59, 130, 246, 0.2)'
          : 'rgba(59, 130, 246, 0.1)',
        color: theme.palette.mode === 'dark' ? '#60A5FA' : '#2563EB',
        borderColor: '#3B82F6',
        fontWeight: 500
      })
    },
  
    // Updated availability status chips with dark mode support
    availabilityChip: {
      available: theme => ({
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(16, 185, 129, 0.2)'
          : 'rgba(16, 185, 129, 0.1)',
        color: theme.palette.mode === 'dark' ? '#34D399' : '#059669',
        borderColor: theme.palette.mode === 'dark' ? '#34D399' : '#10B981',
        fontWeight: 500,
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(16, 185, 129, 0.3)'
            : 'rgba(16, 185, 129, 0.15)'
        }
      }),
      unavailable: theme => ({
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(239, 68, 68, 0.2)'
          : 'rgba(239, 68, 68, 0.1)',
        color: theme.palette.mode === 'dark' ? '#FCA5A5' : '#DC2626',
        borderColor: theme.palette.mode === 'dark' ? '#FCA5A5' : '#EF4444',
        fontWeight: 500,
        '&:hover': {
          backgroundColor: theme.palette.mode === 'dark'
            ? 'rgba(239, 68, 68, 0.3)'
            : 'rgba(239, 68, 68, 0.15)'
        }
      })
    },
  
    // Updated status chips for better visibility in dark mode
    statusChip: theme => ({
      borderRadius: '8px',
      padding: '4px 12px',
      fontWeight: 500,
      height: '24px',
      fontSize: '0.75rem',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid',
      transition: 'all 0.2s ease',
      '&:hover': {
        transform: 'translateY(-1px)'
      }
    }),
  
  talentCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: '16px',
    overflow: 'hidden',
    backgroundColor: theme => theme.palette.mode === 'dark' ? '#2d2d2d' : '#FFFFFF',
    boxShadow: theme => theme.palette.mode === 'dark'
      ? '0px 4px 20px rgba(0, 0, 0, 0.2)'
      : '0px 4px 20px rgba(34, 41, 47, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'translateY(-4px)',
      boxShadow: theme => theme.palette.mode === 'dark'
        ? '0px 12px 24px rgba(0, 0, 0, 0.3)'
        : '0px 12px 24px rgba(34, 41, 47, 0.15)'
    }
  },

  searchBar: {
    background: theme => theme.palette.mode === 'dark' ? '#2d2d2d' : '#FFFFFF',
    borderRadius: '12px',
    boxShadow: theme => theme.palette.mode === 'dark'
      ? '0px 4px 12px rgba(0, 0, 0, 0.2)'
      : '0px 4px 12px rgba(0, 0, 0, 0.05)',
    padding: '16px 24px',
    border: theme => `1px solid ${theme.palette.mode === 'dark' 
      ? 'rgba(255,255,255,0.1)' 
      : 'rgba(229, 231, 235, 0.5)'}`
  },

  filterSection: {
    backgroundColor: theme => theme.palette.mode === 'dark' ? '#2d2d2d' : '#FFFFFF',
    borderRadius: '16px',
    padding: '24px',
    boxShadow: theme => theme.palette.mode === 'dark'
      ? '0px 4px 16px rgba(0, 0, 0, 0.2)'
      : '0px 4px 16px rgba(0, 0, 0, 0.06)',
    border: theme => `1px solid ${theme.palette.mode === 'dark' 
      ? 'rgba(255,255,255,0.1)' 
      : 'rgba(229, 231, 235, 0.5)'}`
  },

  select: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      backgroundColor: theme => theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4F46E5',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#4F46E5',
      }
    }
  },

  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '16px',
      backgroundColor: theme => theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
      boxShadow: theme => theme.palette.mode === 'dark'
        ? '0px 20px 25px -5px rgba(0, 0, 0, 0.3)'
        : '0px 20px 25px -5px rgba(0, 0, 0, 0.1)',
      border: theme => `1px solid ${theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.1)'
        : 'rgba(229, 231, 235, 0.5)'}`
    }
  },

  primaryButton: {
    background: 'linear-gradient(135deg, #4F46E5 0%, #3730A3 100%)',
    borderRadius: '10px',
    textTransform: 'none',
    fontWeight: 500,
    padding: '10px 24px',
    boxShadow: '0px 4px 12px rgba(79, 70, 229, 0.2)',
    '&:hover': {
      background: 'linear-gradient(135deg, #4338CA 0%, #312E81 100%)',
      boxShadow: '0px 6px 16px rgba(79, 70, 229, 0.3)'
    }
  },

  skillChip: {
    backgroundColor: theme => theme.palette.mode === 'dark'
      ? 'rgba(79, 70, 229, 0.2)'
      : 'rgba(79, 70, 229, 0.08)',
    color: theme => theme.palette.mode === 'dark' ? '#818CF8' : '#4F46E5',
    borderRadius: '8px',
    fontWeight: 500,
    border: 'none',
    '&:hover': {
      backgroundColor: theme => theme.palette.mode === 'dark'
        ? 'rgba(79, 70, 229, 0.3)'
        : 'rgba(79, 70, 229, 0.12)'
    }
  }
};
const BrowseTalents = () => {
  const theme = useTheme();
  const intl = useIntl();
  const token = useAuthToken();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const myBucket = new AWS.S3(S3Config);
  const history = useHistory();
  // State Management
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [talents, setTalents] = useState([]);
  const [filteredTalents, setFilteredTalents] = useState([]);
  const [team, setTeam] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [subscription, setSubscription] = useState({});
  const [subscriptionFetch, setSubscriptionFetch] = useState([]);
  const [podDialogOpen, setPodDialogOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [podNames, setPodNames] = useState([]);
  const [selectedPod, setSelectedPod] = useState(null);

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  // Filter States
  const [filters, setFilters] = useState({
    primarySkill: 'default',
    secondarySkill: 'default',
    availability: 'default',
    timezone: 'default',
    workHours: 'default',
    jobTitle: 'default',
    location: 'default',
    budget: 'default'
  });

  // Initial Data Fetch
  useEffect(() => {
    if (user?.data?.[0]?.emp_id && token) {
      const initializeData = async () => {
        // Fetch team members first so we have the data when rendering talents
        await fetchTeamMembers();
        fetchPodNames();
        fetchInitialData();
      };
      
      initializeData();
    }
  }, [token, user]);
  const fetchInitialData = async () => {
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };

      const body = {
        client_code: user.data[0].client_code
      };

      const endpoint = user.data[0].client_code.includes("PARTNER")
        ? process.env.REACT_APP_PARTNER_FTECHSUBSCCRIPTIONPARTNER
        : process.env.REACT_APP_FETCHSUBSCRIPTIONDATA;

      const response = await axios.post(endpoint, body, { headers });
      
      setSubscription(response.data.data);
      setSubscriptionFetch(response.data.data[0].find_pods);
      await searchTalents(); // Initial talent search
    } catch (error) {
      console.error('Subscription fetch error:', error);
      setError(intl.formatMessage({ id: 'errorFetchingData' }));
    } finally {
      setLoading(false);
    }
  };

  const fetchPodNames = async () => {
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };

      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHPODSFORMANAGER,
        { clientID: user.data[0].emp_id },
        { headers }
      );

      setPodNames(response?.data?.data.map(pod => ({
        label: pod.podName,
        value: pod.id
      })));
    } catch (error) {
      console.error('Error fetching pod names:', error);
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };
  
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETTEAMMEMBERS,
        { clientID: user.data[0].emp_id },
        { headers }
      );
  
      // Handle the response - extract the team data array
      if (response.data && response.data.status === 200 && Array.isArray(response.data.data)) {
        // This is the correct format based on the API response you shared
        setTeam(response.data.data);
      } else {
        setTeam([]);
      }
    } catch (error) {
      console.error('Error fetching team members:', error);
      setTeam([]);
    }
  };

// ... continuing from Part 1

  // Search and Filter Functions
  const searchTalents = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': token,
      };

      const requestBody = {
        project_location: filters.location !== 'default' ? filters.location : '',
        job_title: filters.jobTitle !== 'default' ? filters.jobTitle : '',
        budget_range: filters.budget !== 'default' ? filters.budget : '',
        primaryskill: filters.primarySkill !== 'default' ? filters.primarySkill : '',
        secondaryskill: filters.secondarySkill !== 'default' ? filters.secondarySkill : '',
        timezone: filters.timezone !== 'default' ? filters.timezone : '',
        availability: filters.availability !== 'default' ? filters.availability : '',
        workhours: filters.workHours !== 'default' ? filters.workHours : '',
        client_id: user.data[0].emp_id,
        manager_type: user.data[0].manager_type
      };

      const response = await axios.post(
        process.env.REACT_APP_CLIENT_PPODCLIENTFINDTALENT,
        requestBody,
        { headers }
      );

      setTalents(response.data.data);
      setFilteredTalents(response.data.data);
    } catch (error) {
      console.error('Search error:', error);
      setError(intl.formatMessage({ id: 'searchError' }));
    }
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const resetFilters = async () => {
    const resetFilterState = {
      primarySkill: 'default',
      secondarySkill: 'default',
      availability: 'default',
      timezone: 'default',
      workHours: 'default',
      jobTitle: 'default',
      location: 'default',
      budget: 'default'
    };
    
    setFilters(resetFilterState);
    setSearchFilter('');
    
    // Immediately search with reset filters
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': token,
      };

      const requestBody = {
        project_location: '',
        job_title: '',
        budget_range: '',
        primaryskill: '',
        secondaryskill: '',
        timezone: '',
        availability: '',
        workhours: '',
        client_id: user.data[0].emp_id,
        manager_type: user.data[0].manager_type
      };

      const response = await axios.post(
        process.env.REACT_APP_CLIENT_PPODCLIENTFINDTALENT,
        requestBody,
        { headers }
      );

      setTalents(response.data.data);
      setFilteredTalents(response.data.data);
      setCurrentPage(1); // Reset to first page when clearing filters
    } catch (error) {
      console.error('Search error:', error);
      setError(intl.formatMessage({ id: 'searchError' }));
    }
  };

  const handleTextSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchFilter(searchTerm);

    if (!searchTerm) {
      setFilteredTalents(talents);
    } else {
      const filtered = talents.filter(item => 
        item.firstName.toLowerCase().includes(searchTerm) ||
        item.primaryskill.toLowerCase().includes(searchTerm) ||
        item.secondaryskill.toLowerCase().includes(searchTerm)
      );
      setFilteredTalents(filtered);
    }
  };

  // Pod Management
  const handleAddToPod = async (talent) => {
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json'
      };

      await axios.post(
        process.env.REACT_APP_CLIENT_ADDCANDIDATESTOPOD,
        {
          candidate_id: talent.c_id,
          pod_id: selectedPod,
          Candidate_Name: talent.firstName
        },
        { headers }
      );

      setPodDialogOpen(false);
      setSelectedCandidate(null);
      setSelectedPod(null);
      window.location.reload();
    } catch (error) {
      console.error('Error adding to pod:', error);
      setError(intl.formatMessage({ id: 'errorAddingToPod' }));
    }
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTalents.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredTalents.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Check if talent is already in team
  const isAlreadyInTeam = (c_id) => {
    // Skip if team is empty or c_id is not provided
    if (!team || !Array.isArray(team) || team.length === 0 || !c_id) {
      return false;
    }
    
    // Convert c_id to string for reliable comparison
    const talentIdStr = String(c_id);
    
    // Check if any team member's candidateID matches the talent's c_id
    return team.some(member => 
      member && 
      member.candidateID && 
      String(member.candidateID) === talentIdStr
    );
  };

  // Render Functions
  const renderTalentCard = (talent) => {
    const isAdded = isAlreadyInTeam(talent.c_id);
    const cardColorClass = 
    talent.talent_type === "PARTNER" ? "partner" :
    talent.talent_type === "FREELANCER" ? "freelancer" : "client";

    return (
<StyledCard sx={{
        ...componentStyles.talentCard,
        [`&.${cardColorClass}`]: componentStyles.talentCard[`.${cardColorClass}`]
      }}>
<CardContent>
          <Stack spacing={2}>
            {/* Profile Header */}
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar
                src={talent.profilePicture ? 
                  `https://globldataprod.s3.amazonaws.com/${talent.profilePicture}` : 
                  '/defaultimg.png'
                }
                sx={{ width: 60, height: 60 }}
              />
              <Box>
                <Stack direction="row" spacing={1} alignItems="center">
                  <NavHashLink 
                    to={{ pathname: '/Candidatedetailsbyid', state: { c_id: talent.c_id,talent_type:talent.talent_type } }}
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography variant="h6" color="primary">
                      {talent.firstName}
                    </Typography>
                  </NavHashLink>
                  <Chip
                    size="small"
                    label={TALENT_TYPE_LABELS[talent.talent_type] || "F"}
                    sx={talent.talent_type === "PARTNER" ? 
                      componentStyles.availabilityChip.available : 
                      componentStyles.availabilityChip.unavailable}
                  />
                </Stack>
                <Typography variant="body2" color="text.secondary">
                  {talent.work_position}
                </Typography>
              </Box>
            </Stack>

            {/* Location & Skills */}
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <LocationOnIcon fontSize="small" color="action" />
                <Typography variant="body2">{talent.country}</Typography>
              </Box>
              
              <Stack direction="row" spacing={1}>
                <Chip size="small" label={talent.primaryskill}  sx={componentStyles.skillChip} variant="outlined" />
                <Chip size="small" label={talent.secondaryskill}  sx={componentStyles.skillChip} variant="outlined" />
              </Stack>

              <Typography variant="body2" color="text.secondary">
                {talent.about}
              </Typography>
            </Stack>

            {/* Availability Status */}
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <AccessTimeIcon fontSize="small" color="action" />
                <Typography variant="body2">
                  {talent.timezone} • {talent.availability}
                </Typography>
              </Box>
              
              {talent.currentStatus === "Yes" ? (
                <Chip
                  size="small"
                  color="success"
                  label={intl.formatMessage({ id: 'currentlyAvailable' })}
                  variant="outlined"
                />
              ) : (
                <Stack spacing={0.5}>
                  <Chip
                    size="small"
                    color="error"
                    label={intl.formatMessage({ id: 'currentlyUnavailable' })}
                    variant="outlined"
                  />
                  {talent.availabledate && (
                    <Typography variant="caption" color="error">
                      {intl.formatMessage({ id: 'availableFrom' })}: 
                      {moment(talent.availabledate).format("YYYY-MM-DD")}
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </CardContent>

        <CardActions sx={{ p: 2, mt: 'auto' }}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<AddIcon />}
            disabled={isAdded}
           
            onClick={() => {
              if (talent.currentStatus === "No") {
                const availableDate = new Date(talent.availabledate);
                const now = new Date();
                const daysBetween = Math.abs(availableDate - now) / (1000 * 60 * 60 * 24);

                if (availableDate > now && daysBetween < 30) {
                  setSelectedCandidate(talent);
                  setPodDialogOpen(true);
                } else {
                  alert(intl.formatMessage({ id: 'candidateNotAvailable' }));
                }
              } else {
                setSelectedCandidate(talent);
                setPodDialogOpen(true);
              }
            }}
            
          >
            {isAdded ? 
              intl.formatMessage({ id: 'Pod Member' }) : 
              intl.formatMessage({ id: 'add To Pod' })}
          </Button>
        </CardActions>
      </StyledCard>
    );
  };


  const BUDGET_RANGES = [
    { value: "10-25", label: "$10-$25/hr" },
    { value: "25-50", label: "$25-$50/hr" },
    { value: "50-75", label: "$50-$75/hr" },
    { value: "100-150", label: "$100-$150/hr" },
   
  ];
  // Filter Options Constants
  const PRIMARY_SKILLS = [
    { value: 'html', label: 'HTML' },
    { value: 'css', label: 'CSS' },
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'Adobe Illustrator', label: 'Adobe Illustrator' },
    { value: 'ReactJS', label: 'ReactJS' },
    { value: 'AngularJS', label: 'AngularJS' },
    { value: 'Java', label: 'Java' },
    { value: 'Node.js', label: 'Node.js' },
    { value: 'Vue', label: 'Vue' },
    { value: 'Next', label: 'Next' },
    { value: 'Typescript', label: 'Typescript' },
    { value: 'Gatsby', label: 'Gatsby' },
    { value: 'Three.js', label: 'Three.js' },
    { value: 'Tableau', label: 'Tableau' },
    { value: 'Python', label: 'Python' },
    { value: 'Quality Assurance', label: 'Quality Assurance' },
    { value: 'IOS Development (Swift/Objective C)', label: 'IOS Development' },
    { value: 'Android Development (Java/Kotlin)', label: 'Android Development' },
    { value: 'Devops', label: 'Devops' },
    { value: 'Cloud (AWS/Azure/GCP)', label: 'Cloud' }
  ];

  const SECONDARY_SKILLS = [
    { value: 'Python', label: 'Python' },
    { value: 'Java', label: 'Java' },
    { value: 'PHP', label: 'PHP' },
    { value: 'SQL', label: 'SQL' },
    { value: 'Git', label: 'Git' },
    { value: 'c++', label: 'C++' },
    { value: 'Perl', label: 'Perl' },
    { value: 'AngularJS', label: 'AngularJS' },
    { value: 'Kotlin', label: 'Kotlin' },
    { value: 'AWS', label: 'AWS' },
    { value: 'NodeJs', label: 'NodeJs' },
    { value: 'Azure', label: 'Azure' }
  ];

  const TIMEZONES = [
      { value: 'africa_abidjan', label: 'Africa/Abidjan' },
      { value: 'africa_accra', label: 'Africa/Accra' },
      { value: 'africa_addis_ababa', label: 'Africa/Addis Ababa' },
      { value: 'africa_algiers', label: 'Africa/Algiers' },
      { value: 'africa_asmara', label: 'Africa/Asmara' },
      { value: 'africa_bamako', label: 'Africa/Bamako' },
      { value: 'africa_bangui', label: 'Africa/Bangui' },
      { value: 'africa_banjul', label: 'Africa/Banjul' },
      { value: 'africa_bissau', label: 'Africa/Bissau' },
      { value: 'africa_blantyre', label: 'Africa/Blantyre' },
      { value: 'africa_brazzaville', label: 'Africa/Brazzaville' },
      { value: 'africa_bujumbura', label: 'Africa/Bujumbura' },
      { value: 'africa_casablanca', label: 'Africa/Casablanca' },
      { value: 'africa_ceuta', label: 'Africa/Ceuta' },
      { value: 'africa_conakry', label: 'Africa/Conakry' },
      { value: 'africa_dakar', label: 'Africa/Dakar' },
      { value: 'africa_dar_es_salaam', label: 'Africa/Dar es Salaam' },
      { value: 'africa_djibouti', label: 'Africa/Djibouti' },
      { value: 'africa_douala', label: 'Africa/Douala' },
      { value: 'africa_el_aaiun', label: 'Africa/El Aaiun' },
      { value: 'africa_freetown', label: 'Africa/Freetown' },
      { value: 'africa_gaborone', label: 'Africa/Gaborone' },
      { value: 'africa_harare', label: 'Africa/Harare' },
      { value: 'africa_johannesburg', label: 'Africa/Johannesburg' },
      { value: 'africa_juba', label: 'Africa/Juba' },
      { value: 'africa_kampala', label: 'Africa/Kampala' },
      { value: 'africa_khartoum', label: 'Africa/Khartoum' },
      { value: 'africa_kigali', label: 'Africa/Kigali' },
      { value: 'africa_kinshasa', label: 'Africa/Kinshasa' },
      { value: 'africa_lagos', label: 'Africa/Lagos' },
      { value: 'africa_libreville', label: 'Africa/Libreville' },
      { value: 'africa_lome', label: 'Africa/Lome' },
      { value: 'africa_luanda', label: 'Africa/Luanda' },
      { value: 'africa_lubumbashi', label: 'Africa/Lubumbashi' },
      { value: 'africa_lusaka', label: 'Africa/Lusaka' },
      { value: 'africa_malabo', label: 'Africa/Malabo' },
      { value: 'africa_maputo', label: 'Africa/Maputo' },
      { value: 'africa_maseru', label: 'Africa/Maseru' },
      { value: 'africa_mbabane', label: 'Africa/Mbabane' },
      { value: 'africa_mogadishu', label: 'Africa/Mogadishu' },
      { value: 'africa_monrovia', label: 'Africa/Monrovia' },
      { value: 'africa_nairobi', label: 'Africa/Nairobi' },
      { value: 'africa_ndjamena', label: 'Africa/Ndjamena' },
      { value: 'africa_niamey', label: 'Africa/Niamey' },
      { value: 'africa_nouakchott', label: 'Africa/Nouakchott' },
      { value: 'africa_ouagadougou', label: 'Africa/Ouagadougou' },
      { value: 'africa_porto-novo', label: 'Africa/Porto-Novo' },
      { value: 'africa_sao_tome', label: 'Africa/Sao Tome' },
      { value: 'africa_tripoli', label: 'Africa/Tripoli' },
      { value: 'africa_tunis', label: 'Africa/Tunis' },
      { value: 'africa_windhoek', label: 'Africa/Windhoek' },
      { value: 'america_adak', label: 'America/Adak' },
      { value: 'america_anchorage', label: 'America/Anchorage' },
      { value: 'america_anguilla', label: 'America/Anguilla' },
      { value: 'america_antigua', label: 'America/Antigua' },
      { value: 'america_araguaina', label: 'America/Araguaina' },
      { value: 'america_argentina_buenos_aires', label: 'America/Argentina/Buenos Aires' },
      { value: 'america_argentina_catamarca', label: 'America/Argentina/Catamarca' },
      { value: 'america_argentina_comodrivadavia', label: 'America/Argentina/Comodrivadavia' },
      { value: 'america_argentina_cordoba', label: 'America/Argentina/Cordoba' },
      { value: 'america_argentina_jujuy', label: 'America/Argentina/Jujuy' },
      { value: 'america_argentina_la_rioja', label: 'America/Argentina/La Rioja' },
      { value: 'america_argentina_mendoza', label: 'America/Argentina/Mendoza' },
      { value: 'america_argentina_rio_gallegos', label: 'America/Argentina/Rio Gallegos' },
      { value: 'america_argentina_salta', label: 'America/Argentina/Salta' },
      { value: 'america_argentina_san_juan', label: 'America/Argentina/San Juan' },
      { value: 'america_argentina_san_luis', label: 'America/Argentina/San Luis' },
      { value: 'america_argentina_tucuman', label: 'America/Argentina/Tucuman' },
      { value: 'america_argentina_ushuaia', label: 'America/Argentina/Ushuaia' },
      { value: 'america_aruba', label: 'America/Aruba' },
      { value: 'america_asuncion', label: 'America/Asuncion' },
      { value: 'america_atikokan', label: 'America/Atikokan' },
      { value: 'america_bahia', label: 'America/Bahia' },
      { value: 'america_bahia_banderas', label: 'America/Bahia Banderas' },
      { value: 'america_barbados', label: 'America/Barbados' },
      { value: 'america_belem', label: 'America/Belem' },
      { value: 'america_belize', label: 'America/Belize' },
      { value: 'america_blanc-sablon', label: 'America/Blanc-Sablon' },
      { value: 'america_boa_vista', label: 'America/Boa Vista' },
      { value: 'america_bogota', label: 'America/Bogota' },
      { value: 'america_boise', label: 'America/Boise' },
      { value: 'america_cambridge_bay', label: 'America/Cambridge Bay' },
      { value: 'america_campo_grande', label: 'America/Campo Grande' },
      { value: 'america_cancun', label: 'America/Cancun' },
      { value: 'america_caracas', label: 'America/Caracas' },
      { value: 'america_cayenne', label: 'America/Cayenne' },
      { value: 'america_cayman', label: 'America/Cayman' },
      { value: 'america_chicago', label: 'America/Chicago' },
      { value: 'america_chihuahua', label: 'America/Chihuahua' },
      { value: 'america_coral_harbour', label: 'America/Coral Harbour' },
      { value: 'america_costa_rica', label: 'America/Costa Rica' },
      { value: 'america_creston', label: 'America/Creston' },
      { value: 'america_cuiaba', label: 'America/Cuiaba' },
      { value: 'america_curacao', label: 'America/Curacao' },
      { value: 'america_danmarkshavn', label: 'America/Danmarkshavn' },
      { value: 'america_dawson', label: 'America/Dawson' },
      { value: 'america_dawson_creek', label: 'America/Dawson Creek' },
      { value: 'america_denver', label: 'America/Denver' },
      { value: 'america_detroit', label: 'America/Detroit' },
      { value: 'america_dominica', label: 'America/Dominica' },
      { value: 'america_edmonton', label: 'America/Edmonton' },
      { value: 'america_eirunepe', label: 'America/Eirunepe' },
      { value: 'america_el_salvador', label: 'America/El Salvador' },
      { value: 'america_fort_nelson', label: 'America/Fort Nelson' },
      { value: 'america_fortaleza', label: 'America/Fortaleza' },
      { value: 'america_glace_bay', label: 'America/Glace Bay' },
      { value: 'america_godthab', label: 'America/Godthab' },
      { value: 'america_goose_bay', label: 'America/Goose Bay' },
      { value: 'america_grand_turk', label: 'America/Grand Turk' },
      { value: 'america_grenada', label: 'America/Grenada' },
      { value: 'america_guadeloupe', label: 'America/Guadeloupe' },
      { value: 'america_guatemala', label: 'America/Guatemala' },
      { value: 'america_guayaquil', label: 'America/Guayaquil' },
      { value: 'america_guyana', label: 'America/Guyana' },
      { value: 'america_halifax', label: 'America/Halifax' },
      { value: 'america_havana', label: 'America/Havana' },
      { value: 'america_hermosillo', label: 'America/Hermosillo' },
      { value: 'america_indiana_indianapolis', label: 'America/Indiana/Indianapolis' },
      { value: 'america_indiana_knox', label: 'America/Indiana/Knox' },
      { value: 'america_indiana_marengo', label: 'America/Indiana/Marengo' },
      { value: 'america_indiana_petersburg', label: 'America/Indiana/Petersburg' },
      { value: 'america_indiana_tell_city', label: 'America/Indiana/Tell City' },
      { value: 'america_indiana_vevay', label: 'America/Indiana/Vevay' },
      { value: 'america_indiana_vincennes', label: 'America/Indiana/Vincennes' },
      { value: 'america_indiana_winamac', label: 'America/Indiana/Winamac' },
      { value: 'america_inuvik', label: 'America/Inuvik' },
      { value: 'america_iqaluit', label: 'America/Iqaluit' },
      { value: 'america_jamaica', label: 'America/Jamaica' },
      { value: 'america_juneau', label: 'America/Juneau' },
      { value: 'america_kentucky_louisville', label: 'America/Kentucky/Louisville' },
      { value: 'america_kentucky_monticello', label: 'America/Kentucky/Monticello' },
      { value: 'america_kralendijk', label: 'America/Kralendijk' },
      { value: 'america_la_paz', label: 'America/La Paz' },
      { value: 'america_lima', label: 'America/Lima' },
      { value: 'america_los_angeles', label: 'America/Los Angeles' },
    
  ];

  // Main Render
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (subscription === "There Are No subscriction data." || subscriptionFetch === "False") {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Typography variant="h5">
          {intl.formatMessage({ id: 'noSubscription' })}
        </Typography>
      </Box>
    );
  }

  return (
    <StyledContainer maxWidth="xl">
         <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Link
          component="button"
          onClick={() => history.goBack()}
          underline="hover"
          sx={{
            color: 'text.secondary',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ArrowBack sx={{ mr: 0.5 }} fontSize="small" />
          {intl.formatMessage({ id: 'back' })}
        </Link><Typography color="text.primary">
          {intl.formatMessage({ id: 'browseTalent' })}
        </Typography>
      </Breadcrumbs>
      {/* Header Section */}
      <Typography variant="h4" sx={{ mb: 3, fontWeight: 600 }}>
        {intl.formatMessage({ id: 'browseTalent' })}
      </Typography>
      
      <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
        {intl.formatMessage({ id: 'findBestFreelancers' })}
      </Typography>

      {/* Search Bar */}
      <SearchBar  sx={{
  ...componentStyles.searchBar,
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
  marginBottom: theme.spacing(4)
}}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              placeholder={intl.formatMessage({ id: 'keyword' })}
              value={searchFilter}
              onChange={handleTextSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Button
              fullWidth
              variant="contained"
              onClick={searchTalents}
              sx={{ height: '100%' }}
            >
              {intl.formatMessage({ id: 'search' })}
            </Button>
          </Grid> */}
        </Grid>
      </SearchBar>

      {/* Filters Section */}
      <FilterGrid container spacing={2} sx={componentStyles.filterSection}>

        {/* Primary Skill */}
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: 'primarySkill' })}</InputLabel>
            <Select
              value={filters.primarySkill}
              onChange={(e) => handleFilterChange('primarySkill', e.target.value)}
              label={intl.formatMessage({ id: 'primarySkill' })}
              sx={componentStyles.select}
            >
              <MenuItem value="default">
                {intl.formatMessage({ id: 'selectSkill' })}
              </MenuItem>
              {PRIMARY_SKILLS.map((skill) => (
                <MenuItem key={skill.value} value={skill.value}>
                  {skill.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Secondary Skill */}
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: 'secondarySkill' })}</InputLabel>
            <Select
              value={filters.secondarySkill}
              onChange={(e) => handleFilterChange('secondarySkill', e.target.value)}
              label={intl.formatMessage({ id: 'secondarySkill' })}
              sx={componentStyles.select}
            >
              <MenuItem value="default">
                {intl.formatMessage({ id: 'selectSkill' })}
              </MenuItem>
              {SECONDARY_SKILLS.map((skill) => (
                <MenuItem key={skill.value} value={skill.value}>
                  {skill.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Timezone */}
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: 'timezone' })}</InputLabel>
            <Select
              value={filters.timezone}
              onChange={(e) => handleFilterChange('timezone', e.target.value)}
              label={intl.formatMessage({ id: 'timezone' })}
              sx={componentStyles.select}
            >
              <MenuItem value="default">
                {intl.formatMessage({ id: 'selectTimezone' })}
              </MenuItem>
              {TIMEZONES.map((timezone) => (
                <MenuItem key={timezone.value} value={timezone.value}>
                  {timezone.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Location */}
        <Grid item xs={12} md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel>{intl.formatMessage({ id: 'location' })}</InputLabel>
            <Select
              value={filters.location}
              onChange={(e) => handleFilterChange('location', e.target.value)}
              label={intl.formatMessage({ id: 'location' })}
              sx={componentStyles.select}
            >
              <MenuItem value="default">
                {intl.formatMessage({ id: 'selectLocation' })}
              </MenuItem>
              <MenuItem value="usa">USA</MenuItem>
<MenuItem value="india">India</MenuItem>
<MenuItem value="canada">Canada</MenuItem>
<MenuItem value="australia">Australia</MenuItem>
<MenuItem value="uk">United Kingdom</MenuItem>
<MenuItem value="germany">Germany</MenuItem>
<MenuItem value="france">France</MenuItem>
<MenuItem value="china">China</MenuItem>
<MenuItem value="japan">Japan</MenuItem>
<MenuItem value="brazil">Brazil</MenuItem>
<MenuItem value="south_africa">South Africa</MenuItem>
<MenuItem value="mexico">Mexico</MenuItem>
<MenuItem value="italy">Italy</MenuItem>
<MenuItem value="spain">Spain</MenuItem>
<MenuItem value="russia">Russia</MenuItem>
<MenuItem value="south_korea">South Korea</MenuItem>
<MenuItem value="new_zealand">New Zealand</MenuItem>
<MenuItem value="singapore">Singapore</MenuItem>
<MenuItem value="uae">United Arab Emirates</MenuItem>
<MenuItem value="saudi_arabia">Saudi Arabia</MenuItem>
<MenuItem value="argentina">Argentina</MenuItem>
<MenuItem value="netherlands">Netherlands</MenuItem>
<MenuItem value="sweden">Sweden</MenuItem>
<MenuItem value="switzerland">Switzerland</MenuItem>
<MenuItem value="norway">Norway</MenuItem>
<MenuItem value="denmark">Denmark</MenuItem>
<MenuItem value="finland">Finland</MenuItem>
<MenuItem value="portugal">Portugal</MenuItem>
<MenuItem value="poland">Poland</MenuItem>
<MenuItem value="turkey">Turkey</MenuItem>
<MenuItem value="indonesia">Indonesia</MenuItem>
<MenuItem value="malaysia">Malaysia</MenuItem>
<MenuItem value="thailand">Thailand</MenuItem>
<MenuItem value="vietnam">Vietnam</MenuItem>
<MenuItem value="philippines">Philippines</MenuItem>
<MenuItem value="egypt">Egypt</MenuItem>
<MenuItem value="nigeria">Nigeria</MenuItem>
<MenuItem value="kenya">Kenya</MenuItem>
<MenuItem value="ghana">Ghana</MenuItem>
<MenuItem value="colombia">Colombia</MenuItem>
<MenuItem value="chile">Chile</MenuItem>
<MenuItem value="peru">Peru</MenuItem>
<MenuItem value="venezuela">Venezuela</MenuItem>
<MenuItem value="pakistan">Pakistan</MenuItem>
<MenuItem value="bangladesh">Bangladesh</MenuItem>
<MenuItem value="sri_lanka">Sri Lanka</MenuItem>
<MenuItem value="nepal">Nepal</MenuItem>
<MenuItem value="bhutan">Bhutan</MenuItem>
<MenuItem value="maldives">Maldives</MenuItem>
<MenuItem value="iceland">Iceland</MenuItem>
<MenuItem value="luxembourg">Luxembourg</MenuItem>
<MenuItem value="qatar">Qatar</MenuItem>
<MenuItem value="oman">Oman</MenuItem>
<MenuItem value="kuwait">Kuwait</MenuItem>
<MenuItem value="bahrain">Bahrain</MenuItem>
<MenuItem value="iraq">Iraq</MenuItem>
<MenuItem value="iran">Iran</MenuItem>
<MenuItem value="afghanistan">Afghanistan</MenuItem>
<MenuItem value="israel">Israel</MenuItem>
<MenuItem value="jordan">Jordan</MenuItem>
<MenuItem value="lebanon">Lebanon</MenuItem>
<MenuItem value="morocco">Morocco</MenuItem>
<MenuItem value="algeria">Algeria</MenuItem>
<MenuItem value="tunisia">Tunisia</MenuItem>
<MenuItem value="libya">Libya</MenuItem>
<MenuItem value="ethiopia">Ethiopia</MenuItem>
<MenuItem value="tanzania">Tanzania</MenuItem>
<MenuItem value="uganda">Uganda</MenuItem>
<MenuItem value="zimbabwe">Zimbabwe</MenuItem>
<MenuItem value="zambia">Zambia</MenuItem>
<MenuItem value="mozambique">Mozambique</MenuItem>
<MenuItem value="botswana">Botswana</MenuItem>
<MenuItem value="namibia">Namibia</MenuItem>
<MenuItem value="angola">Angola</MenuItem>
<MenuItem value="cuba">Cuba</MenuItem>
<MenuItem value="jamaica">Jamaica</MenuItem>
<MenuItem value="haiti">Haiti</MenuItem>
<MenuItem value="dominican_republic">Dominican Republic</MenuItem>
<MenuItem value="trinidad_tobago">Trinidad and Tobago</MenuItem>
<MenuItem value="bahamas">Bahamas</MenuItem>
<MenuItem value="barbados">Barbados</MenuItem>
<MenuItem value="panama">Panama</MenuItem>
<MenuItem value="costa_rica">Costa Rica</MenuItem>
<MenuItem value="honduras">Honduras</MenuItem>
<MenuItem value="guatemala">Guatemala</MenuItem>
<MenuItem value="el_salvador">El Salvador</MenuItem>
<MenuItem value="nicaragua">Nicaragua</MenuItem>
<MenuItem value="belize">Belize</MenuItem>
<MenuItem value="paraguay">Paraguay</MenuItem>
<MenuItem value="uruguay">Uruguay</MenuItem>
<MenuItem value="bolivia">Bolivia</MenuItem>
<MenuItem value="ecuador">Ecuador</MenuItem>
<MenuItem value="guinea">Guinea</MenuItem>
<MenuItem value="ivory_coast">Ivory Coast</MenuItem>
<MenuItem value="cameroon">Cameroon</MenuItem>
<MenuItem value="senegal">Senegal</MenuItem>
<MenuItem value="sudan">Sudan</MenuItem>
<MenuItem value="south_sudan">South Sudan</MenuItem>
<MenuItem value="congo">Congo</MenuItem>
<MenuItem value="madagascar">Madagascar</MenuItem>
<MenuItem value="malawi">Malawi</MenuItem>
<MenuItem value="sierra_leone">Sierra Leone</MenuItem>
<MenuItem value="chad">Chad</MenuItem>
<MenuItem value="mali">Mali</MenuItem>
<MenuItem value="burkina_faso">Burkina Faso</MenuItem>
<MenuItem value="rwanda">Rwanda</MenuItem>
<MenuItem value="burundi">Burundi</MenuItem>
<MenuItem value="lesotho">Lesotho</MenuItem>
<MenuItem value="eswatini">Eswatini</MenuItem>

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
  <FormControl fullWidth>
    <InputLabel>{intl.formatMessage({ id: 'budget' })}</InputLabel>
    <Select
      value={filters.budget}
      onChange={(e) => handleFilterChange('budget', e.target.value)}
      label={intl.formatMessage({ id: 'budget' })}
      sx={componentStyles.select}
    >
      <MenuItem value="default">
        {intl.formatMessage({ id: 'selectBudget' })}
      </MenuItem>
      {BUDGET_RANGES.map((range) => (
        <MenuItem key={range.value} value={range.value}>
          {range.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>
        {/* Filter Actions */}
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              startIcon={<ClearIcon />}
              onClick={resetFilters}
            >
              {intl.formatMessage({ id: 'clear' })}
            </Button>
            <Button
              variant="contained"
              onClick={searchTalents}
              sx={componentStyles.primaryButton}
            >
              {intl.formatMessage({ id: 'applyFilters' })}
            </Button>
          </Stack>
        </Grid>
      </FilterGrid>

      {/* Talents Grid */}
      <Grid container spacing={3}>
        {currentItems.map((talent) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={talent.c_id}>
            {renderTalentCard(talent)}
          </Grid>
        ))}
      </Grid>

      {/* Pagination */}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        sx={{ mt: 4 }}
      >
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          variant="outlined"
        >
          {intl.formatMessage({ id: 'prev' })}
        </Button>
        {[...Array(totalPages)].map((_, index) => (
          <Button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            variant={currentPage === index + 1 ? "contained" : "outlined"}
          >
            {index + 1}
          </Button>
        ))}
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          variant="outlined"
        >
          {intl.formatMessage({ id: 'next' })}
        </Button>
      </Stack>

      {/* Pod Selection Dialog */}
      <Dialog
        open={podDialogOpen}
        onClose={() => setPodDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        sx={componentStyles.dialog}
      >
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {intl.formatMessage({ id: 'selectPod' })}
          </Typography>

<FormControl fullWidth>
  <InputLabel id="pod-select-label">
    {intl.formatMessage({ id: 'selectPod' })}
  </InputLabel>
  <Select
    labelId="pod-select-label"
    value={selectedPod || ''}
    onChange={(e) => setSelectedPod(e.target.value)}
    label={intl.formatMessage({ id: 'selectPod' })} // This makes sure the label animates correctly
    displayEmpty
  >
    <MenuItem value="" disabled>
      {intl.formatMessage({ id: 'selectPod' })}
    </MenuItem>
    {podNames.map((pod) => (
      <MenuItem key={pod.value} value={pod.value}>
        {pod.label}
      </MenuItem>
    ))}
  </Select>
</FormControl>

          <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setPodDialogOpen(false)}
            >
              {intl.formatMessage({ id: 'cancel' })}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => handleAddToPod(selectedCandidate)}
              disabled={!selectedPod}
            >
              {intl.formatMessage({ id: 'addToPod' })}
            </Button>
          </Stack>
        </Box>
      </Dialog>

      {/* Error Alert */}
      {error && (
        <Alert
          severity="error"
          onClose={() => setError(null)}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: theme.zIndex.snackbar
          }}
        >
          {error}
        </Alert>
      )}
    </StyledContainer>
  );
};

export default BrowseTalents;
