import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  Divider,
  Stack
} from '@mui/material';
import {
  Close as CloseIcon,
  AccessTime as ClockIcon,
  People as PeopleIcon, 
  Warning as AlertIcon,
  TrendingUp as TrendingUpIcon,
  BarChart as ChartIcon,
  LocationOn as LocationIcon
} from '@mui/icons-material';

// SVG for the globe
const GlobeSVG = () => (
  <svg width="100%" height="100%" viewBox="0 0 800 500" xmlns="http://www.w3.org/2000/svg">
    {/* Dark background */}
    <rect width="800" height="500" fill="#111827" />
    
    {/* Stars with varying sizes and brightness */}
    {Array.from({ length: 150 }).map((_, i) => (
      <circle 
        key={i} 
        cx={Math.random() * 800} 
        cy={Math.random() * 500} 
        r={Math.random() * 1.8} 
        fill="#ffffff" 
        opacity={Math.random() * 0.8 + 0.2}
      />
    ))}
    
    {/* Globe advanced gradients for 3D effect */}
    <defs>
      {/* Main globe gradient */}
      <radialGradient id="globeGradient" cx="40%" cy="40%" r="60%" fx="30%" fy="30%">
        <stop offset="0%" style={{ stopColor: '#0c4a6e', stopOpacity: 1 }} />
        <stop offset="70%" style={{ stopColor: '#0369a1', stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: '#0284c7', stopOpacity: 1 }} />
      </radialGradient>
      
      {/* Highlight gradient for 3D effect */}
      <radialGradient id="highlightGradient" cx="30%" cy="30%" r="70%" fx="25%" fy="25%">
        <stop offset="0%" style={{ stopColor: '#7dd3fc', stopOpacity: 0.4 }} />
        <stop offset="100%" style={{ stopColor: '#0284c7', stopOpacity: 0 }} />
      </radialGradient>
      
      {/* Shadow gradient for 3D effect */}
      <radialGradient id="shadowGradient" cx="65%" cy="65%" r="70%" fx="65%" fy="65%">
        <stop offset="0%" style={{ stopColor: '#082f49', stopOpacity: 0.6 }} />
        <stop offset="100%" style={{ stopColor: '#0284c7', stopOpacity: 0 }} />
      </radialGradient>
      
      {/* Land highlight gradient */}
      <linearGradient id="landHighlight" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{ stopColor: '#16a34a', stopOpacity: 0.8 }} />
        <stop offset="100%" style={{ stopColor: '#10b981', stopOpacity: 0.7 }} />
      </linearGradient>
    </defs>
    
    {/* Globe base sphere */}
    <ellipse cx="400" cy="250" rx="250" ry="250" fill="url(#globeGradient)" />
    
    {/* 3D effect layers */}
    <ellipse cx="400" cy="250" rx="250" ry="250" fill="url(#highlightGradient)" />
    <ellipse cx="400" cy="250" rx="250" ry="250" fill="url(#shadowGradient)" />
    
    {/* Perspective latitude lines */}
    <ellipse cx="400" cy="250" rx="250" ry="60" fill="none" stroke="#93c5fd" strokeWidth="0.5" strokeOpacity="0.2" />
    <ellipse cx="400" cy="250" rx="200" ry="48" fill="none" stroke="#93c5fd" strokeWidth="0.5" strokeOpacity="0.2" />
    <ellipse cx="400" cy="250" rx="150" ry="36" fill="none" stroke="#93c5fd" strokeWidth="0.5" strokeOpacity="0.2" />
    <ellipse cx="400" cy="250" rx="100" ry="24" fill="none" stroke="#93c5fd" strokeWidth="0.5" strokeOpacity="0.2" />
    <ellipse cx="400" cy="250" rx="50" ry="12" fill="none" stroke="#93c5fd" strokeWidth="0.5" strokeOpacity="0.2" />
    
    {/* Perspective longitude lines */}
    <ellipse cx="400" cy="250" rx="250" ry="250" fill="none" stroke="#93c5fd" strokeWidth="0.5" strokeOpacity="0.2" />
    <path d="M400,0 Q250,150 400,500" fill="none" stroke="#93c5fd" strokeWidth="0.5" strokeOpacity="0.2" />
    <path d="M150,250 Q275,150 650,250" fill="none" stroke="#93c5fd" strokeWidth="0.5" strokeOpacity="0.2" />
    <path d="M650,250 Q525,350 150,250" fill="none" stroke="#93c5fd" strokeWidth="0.5" strokeOpacity="0.2" />
    
    {/* Realistic continents with 3D shading - North America */}
    <path 
      d="M280,140 C290,120 310,110 330,120 C350,130 360,150 350,170 C340,190 320,200 300,190 C280,180 270,160 280,140Z" 
      fill="url(#landHighlight)" 
    />
    
    {/* South America */}
    <path 
      d="M320,240 C310,260 315,280 330,290 C345,300 360,290 370,270 C380,250 375,230 360,220 C345,210 330,220 320,240Z" 
      fill="url(#landHighlight)" 
    />
    
    {/* Europe */}
    <path 
      d="M390,140 C380,130 385,120 400,115 C415,110 430,120 435,135 C440,150 435,165 420,170 C405,175 400,150 390,140Z" 
      fill="url(#landHighlight)" 
    />
    
    {/* Africa */}
    <path 
      d="M420,180 C410,195 410,220 420,240 C430,260 450,270 465,250 C480,230 480,200 470,180 C460,160 430,165 420,180Z" 
      fill="url(#landHighlight)" 
    />
    
    {/* Asia */}
    <path 
      d="M465,130 C450,120 445,100 460,80 C475,60 500,60 525,80 C550,100 560,130 540,150 C520,170 490,170 465,130Z" 
      fill="url(#landHighlight)" 
    />
    
    {/* Australia */}
    <path 
      d="M520,280 C510,295 515,310 530,320 C545,330 560,320 570,305 C580,290 575,275 560,265 C545,255 530,265 520,280Z" 
      fill="url(#landHighlight)" 
    />
    
    {/* Location markers with pulsing effect - approximate positions */}
    {/* Chicago */}
    <circle cx="315" cy="170" r="4" fill="#ffffff">
      <animate attributeName="r" values="3;5;3" dur="3s" repeatCount="indefinite" />
      <animate attributeName="opacity" values="0.7;1;0.7" dur="3s" repeatCount="indefinite" />
    </circle>
    
    {/* London */}
    <circle cx="395" cy="160" r="4" fill="#ffffff">
      <animate attributeName="r" values="3;5;3" dur="3s" repeatCount="indefinite" />
      <animate attributeName="opacity" values="0.7;1;0.7" dur="3s" repeatCount="indefinite" />
    </circle>
    
    {/* Tokyo */}
    <circle cx="550" cy="180" r="4" fill="#ffffff">
      <animate attributeName="r" values="3;5;3" dur="3s" repeatCount="indefinite" />
      <animate attributeName="opacity" values="0.7;1;0.7" dur="3s" repeatCount="indefinite" />
    </circle>
    
    {/* Hyderabad */}
    <circle cx="480" cy="240" r="4" fill="#ffffff">
      <animate attributeName="r" values="3;5;3" dur="3s" repeatCount="indefinite" />
      <animate attributeName="opacity" values="0.7;1;0.7" dur="3s" repeatCount="indefinite" />
    </circle>
    
    {/* Connection lines with glowing effect */}
    <path 
      d="M315,170 Q355,120 395,160" 
      fill="none" 
      stroke="#6ee7b7" 
      strokeWidth="1" 
      strokeOpacity="0.4"
    >
      <animate attributeName="strokeOpacity" values="0.3;0.6;0.3" dur="5s" repeatCount="indefinite" />
    </path>
    
    <path 
      d="M395,160 Q475,140 550,180" 
      fill="none" 
      stroke="#6ee7b7" 
      strokeWidth="1" 
      strokeOpacity="0.4"
    >
      <animate attributeName="strokeOpacity" values="0.3;0.6;0.3" dur="5s" repeatCount="indefinite" />
    </path>
    
    <path 
      d="M550,180 Q510,220 480,240" 
      fill="none" 
      stroke="#6ee7b7" 
      strokeWidth="1" 
      strokeOpacity="0.4"
    >
      <animate attributeName="strokeOpacity" values="0.3;0.6;0.3" dur="5s" repeatCount="indefinite" />
    </path>
    
    <path 
      d="M480,240 Q390,230 315,170" 
      fill="none" 
      stroke="#6ee7b7" 
      strokeWidth="1" 
      strokeOpacity="0.4"
    >
      <animate attributeName="strokeOpacity" values="0.3;0.6;0.3" dur="5s" repeatCount="indefinite" />
    </path>
    
    {/* Atmosphere glow effect */}
    <ellipse cx="400" cy="250" rx="260" ry="260" fill="none" stroke="#6ee7b7" strokeWidth="1.2" strokeOpacity="0.4" />
    <ellipse cx="400" cy="250" rx="270" ry="270" fill="none" stroke="#6ee7b7" strokeWidth="0.8" strokeOpacity="0.2" />
    <ellipse cx="400" cy="250" rx="280" ry="280" fill="none" stroke="#6ee7b7" strokeWidth="0.5" strokeOpacity="0.1" />
  </svg>
);



const GlobalLens = () => {
  // Sample data - replace with your actual data
  const [locations, setLocations] = useState([
    {
      name: 'BurgerMac-Chicago',
      coordinates: { x: 320, y: 180 }, // Position on the globe
      country: 'USA',
      countryCode: 'US',
      flag: '🇺🇸',
      initiatives: 'green',
      incidents: 'yellow',
      timezone: 'America/Chicago',
      timeFormat: 'h:mm A z',
      details: {
        employees: 124,
        activeIncidents: 3,
        resolvedIncidents: 18,
        ongoingInitiatives: 5,
        completedInitiatives: 12,
        performance: {
          sales: '+12% MoM',
          customer: '95% satisfaction',
          operations: '98.7% uptime'
        }
      }
    },
    {
      name: 'BurgerMac-Hyderabad',
      coordinates: { x: 520, y: 260 }, // Position on the globe
      country: 'India',
      countryCode: 'IN',
      flag: '🇮🇳',
      initiatives: 'green',
      incidents: 'red',
      timezone: 'Asia/Kolkata',
      timeFormat: 'h:mm A z',
      details: {
        employees: 205,
        activeIncidents: 7,
        resolvedIncidents: 22,
        ongoingInitiatives: 4,
        completedInitiatives: 9,
        performance: {
          sales: '+18% MoM',
          customer: '92% satisfaction',
          operations: '95.3% uptime'
        }
      }
    },
    {
      name: 'BurgerMac-London',
      coordinates: { x: 380, y: 160 }, // Position on the globe
      country: 'UK',
      countryCode: 'GB',
      flag: '🇬🇧',
      initiatives: 'yellow',
      incidents: 'green',
      timezone: 'Europe/London',
      timeFormat: 'h:mm A z',
      details: {
        employees: 98,
        activeIncidents: 1,
        resolvedIncidents: 15,
        ongoingInitiatives: 6,
        completedInitiatives: 11,
        performance: {
          sales: '+8% MoM',
          customer: '97% satisfaction',
          operations: '99.1% uptime'
        }
      }
    },
    {
      name: 'BurgerMac-Tokyo',
      coordinates: { x: 580, y: 190 }, // Position on the globe
      country: 'Japan',
      countryCode: 'JP',
      flag: '🇯🇵',
      initiatives: 'green',
      incidents: 'green',
      timezone: 'Asia/Tokyo',
      timeFormat: 'h:mm A z',
      details: {
        employees: 142,
        activeIncidents: 0,
        resolvedIncidents: 12,
        ongoingInitiatives: 7,
        completedInitiatives: 15,
        performance: {
          sales: '+15% MoM',
          customer: '98% satisfaction',
          operations: '99.8% uptime'
        }
      }
    }
  ]);

  const [currentTime, setCurrentTime] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);

  // Update current time for each location
  useEffect(() => {
    const timer = setInterval(() => {
      const times = {};
      locations.forEach(location => {
        const time = new Date().toLocaleTimeString('en-US', {
          timeZone: location.timezone,
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
        times[location.name] = time;
      });
      setCurrentTime(times);
    }, 1000);
    
    return () => clearInterval(timer);
  }, [locations]);

  const handleOpenDetails = (location) => {
    setSelectedLocation(location);
    setDetailsOpen(true);
  };

  const handleCloseDetails = () => {
    setDetailsOpen(false);
  };

  // Function to get color based on status
  const getStatusColor = (status) => {
    switch(status) {
      case 'green': return '#10B981';
      case 'yellow': return '#F59E0B';
      case 'red': return '#EF4444';
      default: return '#3B82F6';
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      bgcolor: '#111827', 
      color: 'white',
      minHeight: '100vh', 
      p: 3 
    }}>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4, fontWeight: 'bold', textAlign: 'center' }}>
        Global Lens: Organization Operations
      </Typography>
      
      {/* Globe Visualization */}
      <Box 
        sx={{ 
          width: '100%', 
          maxWidth: 800, 
          height: 500, 
          mb: 4,
          position: 'relative',
          borderRadius: 2,
          overflow: 'hidden',
          boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.5)'
        }}
      >
        {/* Globe SVG */}
        <GlobeSVG />
        
        {/* Location markers */}
        {locations.map((location, index) => (
          <Box
            key={index}
            onClick={() => handleOpenDetails(location)}
            sx={{
              position: 'absolute',
              left: `${location.coordinates.x}px`,
              top: `${location.coordinates.y}px`,
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              cursor: 'pointer',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                transform: 'translate(-50%, -50%) scale(1.05)',
              }
            }}
          >
            {/* Location bubble */}
            <Box
              sx={{
                bgcolor: 'rgba(0, 0, 0, 0.7)',
                backdropFilter: 'blur(4px)',
                border: '1px solid rgba(255, 255, 255, 0.1)',
                borderRadius: '8px',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                minWidth: '120px',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.5)'
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: '0.9rem', mb: 0.5 }}>
                {location.name}
              </Typography>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                <ClockIcon sx={{ fontSize: 16, mr: 0.5 }} />
                <Typography variant="body2">
                  {currentTime[location.name] || "Loading..."}
                </Typography>
              </Box>
              
              <Box sx={{ display: 'flex', gap: 1, mt: 0.5 }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    bgcolor: getStatusColor(location.initiatives)
                  }}
                />
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    bgcolor: getStatusColor(location.incidents)
                  }}
                />
              </Box>
            </Box>
            
            {/* Connection lines between locations */}
            {index < locations.length - 1 && (
              <Box
                sx={{
                  position: 'absolute',
                  width: '100px',
                  height: '1px',
                  backgroundColor: '#6EE7B7',
                  opacity: 0.3,
                  transform: `rotate(${Math.random() * 360}deg)`,
                  transformOrigin: 'left center',
                  left: '50%',
                  top: '50%'
                }}
              />
            )}
          </Box>
        ))}
      </Box>
      
      {/* Location Cards */}
      <Grid container spacing={3} sx={{ maxWidth: 1200, mx: 'auto' }}>
        {locations.map((location, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card 
              sx={{ 
                cursor: 'pointer', 
                bgcolor: '#1F2937',
                color: 'white',
                transition: 'all 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.5)'
                }
              }}
              onClick={() => handleOpenDetails(location)}
            >
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                  <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocationIcon sx={{ mr: 1, fontSize: 20 }} />
                    {location.name}
                  </Typography>
                  <Typography sx={{ bgcolor: '#374151', px: 0.8, py: 0.2, borderRadius: 1, fontSize: '0.75rem' }}>
                    {location.countryCode}
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                  <Typography variant="body2" sx={{ mr: 1, color: 'rgba(255, 255, 255, 0.7)' }}>
                    Initiatives:
                  </Typography>
                  <Box 
                    sx={{ 
                      width: 12, 
                      height: 12, 
                      borderRadius: '50%', 
                      bgcolor: getStatusColor(location.initiatives)
                    }} 
                  />
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                  <Typography variant="body2" sx={{ mr: 1, color: 'rgba(255, 255, 255, 0.7)' }}>
                    Incidents:
                  </Typography>
                  <Box 
                    sx={{ 
                      width: 12, 
                      height: 12, 
                      borderRadius: '50%', 
                      bgcolor: getStatusColor(location.incidents)
                    }} 
                  />
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ClockIcon sx={{ fontSize: 16, mr: 1, color: 'rgba(255, 255, 255, 0.7)' }} />
                  <Typography variant="body2">
                    {currentTime[location.name] || "Loading..."}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      {/* Details Dialog */}
      <Dialog 
        open={detailsOpen} 
        onClose={handleCloseDetails}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#1F2937',
            color: 'white'
          }
        }}
      >
        {selectedLocation && (
          <>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" component="span" sx={{ mr: 1 }}>
                  {selectedLocation.flag}
                </Typography>
                {selectedLocation.name}
              </Box>
              <IconButton edge="end" color="inherit" onClick={handleCloseDetails} aria-label="close">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }} />
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={3}>
                    <Paper elevation={2} sx={{ p: 2, bgcolor: '#111827', color: 'white' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <ClockIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">Local Time</Typography>
                      </Box>
                      <Typography variant="h4" sx={{ fontFamily: 'monospace' }}>
                        {currentTime[selectedLocation.name] || "Loading..."}
                      </Typography>
                    </Paper>
                    
                    <Paper elevation={2} sx={{ p: 2, bgcolor: '#111827', color: 'white' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <PeopleIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">Staff</Typography>
                      </Box>
                      <Typography variant="h4">
                        {selectedLocation.details.employees} <Typography variant="body2" component="span">employees</Typography>
                      </Typography>
                    </Paper>
                    
                    <Paper elevation={2} sx={{ p: 2, bgcolor: '#111827', color: 'white' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <AlertIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">Incidents</Typography>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Paper sx={{ p: 2, bgcolor: '#1F2937' }}>
                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Active</Typography>
                            <Typography variant="h5" color="#EF4444" sx={{ fontWeight: 'bold' }}>
                              {selectedLocation.details.activeIncidents}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper sx={{ p: 2, bgcolor: '#1F2937' }}>
                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Resolved</Typography>
                            <Typography variant="h5" color="#10B981" sx={{ fontWeight: 'bold' }}>
                              {selectedLocation.details.resolvedIncidents}
                            </Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Stack>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Stack spacing={3}>
                    <Paper elevation={2} sx={{ p: 2, bgcolor: '#111827', color: 'white' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <TrendingUpIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">Initiatives</Typography>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Paper sx={{ p: 2, bgcolor: '#1F2937' }}>
                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Ongoing</Typography>
                            <Typography variant="h5" color="#3B82F6" sx={{ fontWeight: 'bold' }}>
                              {selectedLocation.details.ongoingInitiatives}
                            </Typography>
                          </Paper>
                        </Grid>
                        <Grid item xs={6}>
                          <Paper sx={{ p: 2, bgcolor: '#1F2937' }}>
                            <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Completed</Typography>
                            <Typography variant="h5" color="#10B981" sx={{ fontWeight: 'bold' }}>
                              {selectedLocation.details.completedInitiatives}
                            </Typography>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Paper>
                    
                    <Paper elevation={2} sx={{ p: 2, bgcolor: '#111827', color: 'white' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <ChartIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">Performance</Typography>
                      </Box>
                      <Stack spacing={2}>
                        <Paper sx={{ p: 2, bgcolor: '#1F2937' }}>
                          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Sales</Typography>
                          <Typography variant="h6" color="#10B981">
                            {selectedLocation.details.performance.sales}
                          </Typography>
                        </Paper>
                        <Paper sx={{ p: 2, bgcolor: '#1F2937' }}>
                          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Customer Satisfaction</Typography>
                          <Typography variant="h6" color="#10B981">
                            {selectedLocation.details.performance.customer}
                          </Typography>
                        </Paper>
                        <Paper sx={{ p: 2, bgcolor: '#1F2937' }}>
                          <Typography variant="body2" color="rgba(255, 255, 255, 0.7)">Operations</Typography>
                          <Typography variant="h6" color="#10B981">
                            {selectedLocation.details.performance.operations}
                          </Typography>
                        </Paper>
                      </Stack>
                    </Paper>
                  </Stack>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default GlobalLens;