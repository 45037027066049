import React, { useState, useEffect } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../../TokenContext';
import { useIntl } from 'react-intl';
import axios from 'axios';
import moment from 'moment';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import {
  Table, TableBody, TableCell, Box, styled, TableContainer, TableHead,
  TableRow, Paper, Chip, Button, Menu, MenuItem, Tooltip, Typography
} from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px',
  height: '35rem',
  overflowY: 'auto',
  padding: '20px',
  boxShadow: theme.shadows[3],
  '@media screen and (max-width: 1080px)': {
    width: '100%'
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '20px',
  color: theme.palette.text.primary,
  fontWeight: 600,
  fontSize: '1.5rem'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  fontSize: '0.875rem',
  height: '60px',
  minWidth: '140px',
  maxWidth: '200px',
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  '&.header': {
    backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#f5f5f5',
    fontWeight: 600,
    fontSize: '0.875rem',
    color: theme.palette.primary.main,
    position: 'sticky',
    top: 0,
    zIndex: 1
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? theme.palette.action.hover 
      : theme.palette.grey[50]
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const FilterButton = styled(Button)(({ theme }) => ({
  marginBottom: '16px',
  fontSize: '0.875rem',
  height: '40px'
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: '2px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontSize: '0.75rem'
}));

// JiraIssueCell Component
const JiraIssueCell = ({ issueString }) => {
  if (!issueString) return null;

  const parseIssues = (issuesStr) => {
    return issuesStr.split(',').map(issue => {
      const firstColonIndex = issue.indexOf(':');
      if (firstColonIndex === -1) return { key: issue.trim(), url: '' };
      return {
        key: issue.substring(0, firstColonIndex).trim(),
        url: issue.substring(firstColonIndex + 1).trim()
      };
    });
  };

  const issues = parseIssues(issueString);

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {issues.map((issue, index) => (
        <Tooltip
          key={index}
          title={`Click to open ${issue.key} in Jira`}
          arrow
        >
          <StyledChip
            label={issue.key}
            size="small"
            clickable
            onClick={() => issue.url && window.open(issue.url, '_blank', 'noopener,noreferrer')}
          />
        </Tooltip>
      ))}
    </Box>
  );
};

// TeamMemberFilter Component
const TeamMemberFilter = ({ selectedMembers, onMemberSelect, activities, intl }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const uniqueMembers = [...new Set(activities.map(data => data.pod_candidates))];

  return (
    <>
      <FilterButton
        onClick={e => setAnchorEl(e.currentTarget)}
        variant="outlined"
        color="primary"
      >
        {selectedMembers.length === 0 
          ? intl.formatMessage({ id: 'selectPodTeamMember' })
          : selectedMembers.map(item => (
              <StyledChip
                key={item}
                label={item}
                onDelete={() => onMemberSelect(item)}
                size="small"
              />
            ))
        }
      </FilterButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {uniqueMembers.map(name => (
          <MenuItem
            key={name}
            onClick={() => {
              onMemberSelect(name);
              setAnchorEl(null);
            }}
            selected={selectedMembers.includes(name)}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

// ActivityTable Component
const ActivityTable = ({ activities, sortOrder, onSort, onVideoView, intl }) => (
  <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell className="header" onClick={onSort} sx={{ cursor: 'pointer' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {intl.formatMessage({ id: 'submittedDate' })}
              {sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />}
            </Box>
          </StyledTableCell>
          <StyledTableCell className="header">
            {intl.formatMessage({ id: 'teamMember' })}
          </StyledTableCell>
          <StyledTableCell className="header">
            {intl.formatMessage({ id: 'jiraIssue' })}
          </StyledTableCell>
          <StyledTableCell className="header">
            {intl.formatMessage({ id: 'todaysFocus' })}
          </StyledTableCell>
          <StyledTableCell className="header">
            {intl.formatMessage({ id: 'accomplishments' })}
          </StyledTableCell>
          <StyledTableCell className="header">
            {intl.formatMessage({ id: 'blockers' })}
          </StyledTableCell>
          <StyledTableCell className="header">
            {intl.formatMessage({ id: 'videoUrl' })}
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activities.length > 0 ? (
          activities.map((item, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                {moment(item.createdAt).format("MM-DD-YYYY")}
              </StyledTableCell>
              <StyledTableCell>
                {item.pod_candidates}
              </StyledTableCell>
              <StyledTableCell>
                <JiraIssueCell issueString={item.jira_issue} />
              </StyledTableCell>
              <StyledTableCell>
                {item.today_focus}
              </StyledTableCell>
              <StyledTableCell>
                {item.accomplishments}
              </StyledTableCell>
              <StyledTableCell>
                {item.blockers}
              </StyledTableCell>
              <StyledTableCell>
                {item.loom_url ? (
                  <Button
                    onClick={() => onVideoView(item.loom_url, item.id)}
                    variant="text"
                    color="primary"
                    size="small"
                  >
                    {intl.formatMessage({ id: "viewVideo" })}
                  </Button>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    {intl.formatMessage({ id: 'noDataAvailable' })}
                  </Typography>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))
        ) : (
          <TableRow>
            <StyledTableCell colSpan={7} align="center">
              <Typography variant="body1" color="text.secondary">
                {intl.formatMessage({ id: 'noDataAvailable' })}
              </Typography>
            </StyledTableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

// Main Component
const Invoices = ({ expanded }) => {
  const [activities, setActivities] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  const fetchActivities = async () => {
    if (!token) return;

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETLATESTPODACTIVITIESDASHBOARD,
        { clientID: user.data[0].emp_id },
        { headers: { Authorization: token, 'Content-Type': 'application/json' }}
      );

      const sortedData = response?.data?.data?.activities?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      }) || [];

      setActivities(sortedData);
    } catch (error) {
      console.error("Error fetching activities:", error);
      setActivities([]);
    }
  };

  const handleVideoView = async (loomUrl, id) => {
    try {
      const [filename] = loomUrl.split('?')[0].split('/').slice(-1);
      const fullUrl = `${process.env.REACT_APP_CLOUDFRONTs3}${filename}`;
      
      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { url: fullUrl }
      );

      const { signedUrl } = JSON.parse(response.data.body);
      if (!signedUrl) {
        throw new Error('Signed URL not found');
      }

      window.open(signedUrl, '_blank');
    } catch (error) {
      console.error('Error processing video:', error);
      alert(intl.formatMessage({ id: 'videoError' }));
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [token]);

  const filteredActivities = selectedMembers.length === 0
    ? activities
    : activities.filter(data => selectedMembers.includes(data.pod_candidates));

  return (
    <div expanded={expanded}>
      {activities.length === 0 ? (
        <Typography variant="h6" color="text.secondary" align="center">
          {intl.formatMessage({ id: 'noPodActivity' })}
        </Typography>
      ) : (
        <Container>
          <NavHashLink to="/PodActivity" smooth style={{ textDecoration: 'none' }}>
            <Title>
              {intl.formatMessage({ id: 'podActivity' })}
            </Title>
          </NavHashLink>

          <TeamMemberFilter
            selectedMembers={selectedMembers}
            onMemberSelect={member => {
              setSelectedMembers(prev => 
                prev.includes(member)
                  ? prev.filter(item => item !== member)
                  : [...prev, member]
              );
            }}
            activities={activities}
            intl={intl}
          />

          <ActivityTable
            activities={filteredActivities}
            sortOrder={sortOrder}
            onSort={() => {
              setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
              setActivities(prev => [...prev].reverse());
            }}
            onVideoView={handleVideoView}
            intl={intl}
          />
        </Container>
      )}
    </div>
  );
};

export default Invoices;