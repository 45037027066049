import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { Auth } from 'aws-amplify';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({ user: null, token: null });
  const [loading, setLoading] = useState(true);

  const refreshToken = useCallback(async () => {
    try {
      const currentSession = await Auth.currentSession();
      const { idToken } = currentSession;
      const token = idToken.jwtToken;

      setAuthState({ user: currentSession, token });
      return true;
    } catch (error) {
      console.error('Token refresh error:', error);
      // If the refresh fails due to invalid/expired refresh token
      if (error.name === 'NotAuthorizedException') {
        setAuthState({ user: null, token: null });
        // Optionally redirect to login page or show a message
      }
      return false;
    }
  }, []);
 
  // Initial auth state setup
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const currentSession = await Auth.currentSession();
        const { idToken } = currentSession;
        const token = idToken.jwtToken;

        setAuthState({ user: currentSession, token });
      } catch (error) {
        console.error('Token fetch error:', error);
        setAuthState({ user: null, token: null });
      } finally {
        setLoading(false);
      }
    };

    if (!authState.token && loading) {
      initializeAuth();
    }
  }, [authState.token, loading]);

  // Periodic token refresh (every 10 minutes)
  useEffect(() => {
    if (!authState.token) return;

    const refreshInterval = setInterval(() => {
      refreshToken();
    }, 10 * 60 * 1000); // 10 minutes

    return () => clearInterval(refreshInterval);
  }, [authState.token, refreshToken]);

  // Check for token expiration
  useEffect(() => {
    if (!authState.token || !authState.user) return;

    const checkTokenExpiration = () => {
      try {
        const tokenExpirationTime = authState.user.getIdToken().getExpiration();
        const currentTime = Math.floor(Date.now() / 1000);
        const timeUntilExpiration = tokenExpirationTime - currentTime;

        // Refresh token if it expires in less than 5 minutes
        if (timeUntilExpiration < 300) {
          refreshToken();
        }
      } catch (error) {
        console.error('Error checking token expiration:', error);
      }
    };

    // Check expiration every minute
    const expirationCheckInterval = setInterval(checkTokenExpiration, 60 * 1000);

    // Initial check
    checkTokenExpiration();

    return () => clearInterval(expirationCheckInterval);
  }, [authState.token, authState.user, refreshToken]);

  const contextValue = {
    ...authState,
    refreshToken,
    isAuthenticated: !!authState.token,
    isLoading: loading,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const useAuthToken = () => {
  const { token } = useAuth();
  return token;
};
