import React, { useState, useEffect } from 'react';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Menu, MenuItem, Avatar, Badge, Tooltip, Divider, Chip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Auth } from "aws-amplify";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "./action/auth";
import { DiAptana } from "react-icons/di";
import { useIntl } from 'react-intl';
import { styled, alpha } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import NotificationDropdown from "./Notificationtalent";
import Notificationmanagericon from "./Notificationmanagericon";
import globllogo from "./assets/Globlai-Logo-Clear.png";
import globllogodark from './assets/Globlai-Logo-Trans-White.png';
import MobileNavigation from './MobileNavigation';
import ThemeToggle from '../ThemeToggle';
import { useTheme as useMUITheme } from '@mui/material/styles';

// Compact styled components with glassmorphism and modern styling
const NavContainer = styled('nav')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '64px',
  backgroundColor: 'transparent',
  marginLeft: 'auto',
  gap: '8px'
}));

const StyledLink = styled('a')(({ theme, isActive }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  color: isActive 
    ? theme.palette.mode === 'dark' ? '#1DBF73' : '#1DBF73'
    : (theme.palette.mode === 'dark' ? '#f0f0f0' : '#333333'),
  textDecoration: 'none',
  padding: '0 12px',
  margin: '0 2px',
  transition: 'all 0.3s ease',
  fontSize: '14px',
  fontWeight: isActive ? 600 : 500,
  whiteSpace: 'nowrap',
  borderRadius: '8px',
  background: isActive 
    ? theme.palette.mode === 'dark' 
      ? 'linear-gradient(145deg, rgba(29, 191, 115, 0.15), rgba(29, 191, 115, 0.06))' 
      : 'linear-gradient(145deg, rgba(29, 191, 115, 0.15), rgba(29, 191, 115, 0.04))'
    : 'transparent',
  boxShadow: isActive 
    ? theme.palette.mode === 'dark' 
      ? '0 3px 8px rgba(0, 0, 0, 0.15), inset 0 -2px 0 #1DBF73' 
      : '0 3px 8px rgba(0, 0, 0, 0.05), inset 0 -2px 0 #1DBF73'
    : 'none',
  
  '&:hover': {
    transform: 'translateY(-1px)',
    background: theme.palette.mode === 'dark' 
      ? 'linear-gradient(145deg, rgba(29, 191, 115, 0.12), rgba(29, 191, 115, 0.04))' 
      : 'linear-gradient(145deg, rgba(29, 191, 115, 0.12), rgba(29, 191, 115, 0.04))',
    color: '#1DBF73',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.06)'
  },

  '&:active': {
    transform: 'translateY(0px)',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
  }
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0 16px',
  minHeight: '64px',
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(135deg, rgba(35, 35, 50, 0.95), rgba(22, 22, 35, 0.95))'
    : 'linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(245, 245, 250, 0.95))',
  backdropFilter: 'blur(10px)',
  WebkitBackdropFilter: 'blur(10px)'
}));

const NavigationWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  gap: '6px'
}));

const LogoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: 'auto',
  position: 'relative',
  zIndex: 2
});

const NavSection = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginLeft: 'auto',
  padding: '0 8px',

  '@media (max-width: 1200px)': {
    gap: '4px',
    padding: '0 6px'
  }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginRight: '8px',
  fontFamily: '"Poppins", sans-serif',
  fontWeight: 700,
  letterSpacing: '0.2rem',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#333333',
  textDecoration: 'none',
  marginLeft: '8px',
}));

const StyledLogo = styled('img')(({ theme }) => ({
  flex: 1,
  marginLeft: 10,
  width: "11rem",
  height: "3rem",
  objectFit: "contain", // This ensures the image maintains its aspect ratio
  maxWidth: "11rem",    // Set maximum width to prevent oversized logos
  transition: 'all 0.3s ease'
}));

const LogoGlow = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: theme.palette.mode === 'dark'
    ? 'radial-gradient(circle at center, rgba(29, 191, 115, 0.12) 0%, rgba(29, 191, 115, 0) 70%)'
    : 'radial-gradient(circle at center, rgba(29, 191, 115, 0.06) 0%, rgba(29, 191, 115, 0) 70%)',
  top: 0,
  left: 0,
  zIndex: -1,
  borderRadius: '50%',
  transform: 'scale(1.2)',
  animation: 'pulse 4s ease-in-out infinite alternate'
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#333333',
  transition: 'all 0.3s ease',
  zIndex: theme.zIndex.drawer + 1,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '64px',
    background: theme.palette.mode === 'dark' 
      ? 'linear-gradient(135deg, rgba(26, 26, 46, 0.85), rgba(23, 23, 38, 0.95))'
      : 'linear-gradient(135deg, rgba(255, 255, 255, 0.85), rgba(250, 250, 255, 0.95))',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 20px rgba(0, 0, 0, 0.25), 0 1px 0 rgba(255, 255, 255, 0.06) inset'
      : '0 4px 20px rgba(0, 0, 0, 0.06), 0 1px 0 rgba(255, 255, 255, 0.7) inset',
    zIndex: -1,
    borderRadius: '0 0 16px 16px'
  }
}));

const StyledNav = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledToolbarContainer = styled(StyledToolbar)(({ theme }) => ({
  position: 'relative',
  border: 'none'
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1DBF73' : '#1DBF73',
  width: 36,
  height: 36,
  fontSize: '1rem',
  fontWeight: 600,
  marginRight: 8,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 3px 8px rgba(0, 0, 0, 0.3), 0 0 0 2px rgba(29, 191, 115, 0.2)'
    : '0 3px 8px rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(29, 191, 115, 0.1)',
  border: theme.palette.mode === 'dark' ? '1px solid #2A2A42' : '1px solid #ffffff',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 12px rgba(0, 0, 0, 0.4), 0 0 0 2px rgba(29, 191, 115, 0.3)'
      : '0 4px 12px rgba(0, 0, 0, 0.15), 0 0 0 2px rgba(29, 191, 115, 0.2)',
  }
}));

const StyledDropdownToggle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 8px',
  borderRadius: '12px',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(145deg, rgba(40, 40, 60, 0.6), rgba(30, 30, 50, 0.6))'
    : 'linear-gradient(145deg, rgba(255, 255, 255, 0.8), rgba(245, 245, 250, 0.8))',
  backdropFilter: 'blur(5px)',
  WebkitBackdropFilter: 'blur(5px)',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0, 0, 0, 0.2), inset 0 1px 0 rgba(255, 255, 255, 0.06)'
    : '0 4px 12px rgba(0, 0, 0, 0.04), inset 0 1px 0 rgba(255, 255, 255, 0.8)',
  '&:hover': {
    transform: 'translateY(-1px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 6px 15px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.1)'
      : '0 6px 15px rgba(0, 0, 0, 0.06), inset 0 1px 0 rgba(255, 255, 255, 1)'
  }
}));

const StyledDropdownMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(35, 35, 50, 0.95)' : 'rgba(255, 255, 255, 0.95)',
    borderRadius: '12px',
    boxShadow: theme.palette.mode === 'dark' 
      ? '0 10px 25px rgba(0, 0, 0, 0.5), 0 0 0 1px rgba(255, 255, 255, 0.1)' 
      : '0 10px 25px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.03)',
    padding: '6px 0',
    minWidth: '220px',
    marginTop: '8px',
    backdropFilter: 'blur(10px)',
    WebkitBackdropFilter: 'blur(10px)',
    border: theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.05)' : 'none',
    overflow: 'hidden',
  }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '8px 16px',
  fontSize: '14px',
  color: theme.palette.mode === 'dark' ? '#e0e0e0' : '#424242',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  transition: 'all 0.2s ease',
  borderLeft: '2px solid transparent',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(29, 191, 115, 0.15)' : 'rgba(29, 191, 115, 0.08)',
    color: '#1DBF73',
    borderLeft: '2px solid #1DBF73',
    paddingLeft: '18px',
  },
  '& svg': {
    color: theme.palette.mode === 'dark' ? '#1DBF73' : '#1DBF73',
    fontSize: '18px',
    transition: 'all 0.2s ease',
  },
  '&:hover svg': {
    transform: 'scale(1.1) translateX(2px)'
  }
}));

const StatusChip = styled(Chip)(({ theme, userType }) => {
  // Define colors for different user types
  const colors = {
    Candidate: '#1e88e5', // Blue
    Client: '#1DBF73',    // Green
    Provider: '#9c27b0',  // Purple
    stakeholder: '#ff9800', // Orange
    default: '#757575'    // Grey
  };
  
  const color = colors[userType] || colors.default;
  
  return {
    height: '18px',
    fontSize: '10px',
    fontWeight: 600,
    backgroundColor: theme.palette.mode === 'dark' 
      ? alpha(color, 0.2)
      : alpha(color, 0.1),
    color: color,
    border: `1px solid ${alpha(color, theme.palette.mode === 'dark' ? 0.3 : 0.2)}`,
    '.MuiChip-label': {
      padding: '0 6px',
    }
  };
});

// Custom animation keyframes
const globalStyles = `
@keyframes pulse {
  0% {
    opacity: 0.6;
    transform: scale(1);
  }
  100% {
    opacity: 0.7;
    transform: scale(1.05);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

// Navigation Item component with icon support
const NavigationItem = ({ href, label, isActive, marginLeft = "0", icon }) => {
  const theme = useMUITheme() || { palette: { mode: 'light' }};
  return (
    <StyledLink 
      href={href}
      isActive={isActive}
      style={{ marginLeft }}
      theme={theme}
    >
      {icon && (
        <Box sx={{ 
          mr: 1, 
          display: 'flex', 
          alignItems: 'center',
          color: isActive ? '#1DBF73' : 'inherit',
          transition: 'all 0.2s ease',
          fontSize: '18px'
        }}>
          {icon}
        </Box>
      )}
      {label}
    </StyledLink>
  );
};

// Updated UserNavigation component with icons
const UserNavigation = ({ type, path, intl }) => {
  const navigationConfig = {
    Candidate: [
      { path: "/", label: intl.formatMessage({ id: 'home' }), icon: <HomeIcon fontSize="small" /> },
      { path: "/candidate-dashboard", label: intl.formatMessage({ id: 'dashboard' }), icon: <DashboardIcon fontSize="small" /> },
      { path: "/FindPods", label: intl.formatMessage({ id: 'findPods' }), icon: <GroupsIcon fontSize="small" /> },
      { component: <ThemeToggle />, isComponent: true },
      { path: "/EditTalentSettingsForm", label: <Tooltip title="Settings"><SettingsIcon fontSize="small" /></Tooltip> }
    ],
    Client: [
      { path: "/", label: intl.formatMessage({ id: 'home' }), icon: <HomeIcon fontSize="small" /> },
      { path: "/client-dashboard", label: intl.formatMessage({ id: 'dashboard' }), icon: <DashboardIcon fontSize="small" /> },
      { path: "/AIAgentSearch", label: intl.formatMessage({ id: 'findAgents' }), icon: <SearchIcon fontSize="small" /> },
      { path: "/browse-talent", label: intl.formatMessage({ id: 'findTalent' }), icon: <SearchIcon fontSize="small" /> },
      { path: "/FindPodsClient", label: intl.formatMessage({ id: 'findPods' }), icon: <GroupsIcon fontSize="small" /> },
      { path: "/Team", label: intl.formatMessage({ id: 'myPods' }), icon: <GroupsIcon fontSize="small" /> },
      { path: "/Popup", label: intl.formatMessage({ id: 'invite' }), icon: <PersonAddIcon fontSize="small" /> },
      { component: <ThemeToggle />, isComponent: true },
      { path: "/Setting", label: <Tooltip title="Settings"><SettingsIcon fontSize="small" /></Tooltip> }
    ],
    PartnerGuest: [
      { path: "/", label: intl.formatMessage({ id: 'home' }), icon: <HomeIcon fontSize="small" /> },
      { path: "/FindPodsPartnerGuest", label: intl.formatMessage({ id: 'findPods' }), icon: <GroupsIcon fontSize="small" /> },
      { path: "/FindTalentPartnerGuest", label: intl.formatMessage({ id: 'findTalent' }), icon: <SearchIcon fontSize="small" /> },
      { component: <ThemeToggle />, isComponent: true }
    ],
    stakeholder: [
      { path: "/", label: intl.formatMessage({ id: 'home' }), icon: <HomeIcon fontSize="small" /> },
      { path: "/StakeholderDashboard", label: intl.formatMessage({ id: 'dashboard' }), icon: <DashboardIcon fontSize="small" /> },
      { component: <ThemeToggle />, isComponent: true }
    ],
    Provider: [
      { path: "/subscriptions", label: intl.formatMessage({ id: 'subscription' }) },
      { path: "/PartnerInvite", label: intl.formatMessage({ id: 'invite' }), icon: <PersonAddIcon fontSize="small" /> },
      { path: "/PartnerInvoices", label: intl.formatMessage({ id: 'invoices' }) },
      { component: <ThemeToggle />, isComponent: true },
      { path: "/PartnerSettings", label: <Tooltip title="Settings"><SettingsIcon fontSize="small" /></Tooltip> }
    ],
    ClientAdmin: [
      { path: "/subscriptions", label: intl.formatMessage({ id: 'subscription' }) },
      { path: "/Clientinvite", label: intl.formatMessage({ id: 'invite' }), icon: <PersonAddIcon fontSize="small" /> },
      { path: "/InvoicesClient", label: intl.formatMessage({ id: 'invoices' }) },
      { component: <ThemeToggle />, isComponent: true }
    ]
  };

  const items = navigationConfig[type] || [];
  return items.map((item, index) => {
    if (item.isComponent) {
      return (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
          {item.component}
        </Box>
      );
    }
    return (
      <NavigationItem
        key={index}
        href={item.path}
        label={item.label}
        isActive={path === item.path}
        marginLeft={item.marginLeft}
        icon={item.icon}
      />
    );
  });
};

// Enhanced user dropdown
const UserDropdown = ({ username, handleLogOut, userType, intl }) => {
  const theme = useMUITheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const getProfileUrl = () => {
    const profileUrls = {
      Candidate: "/Updateyourprofile",
      Client: "/client-profile",
      stakeholder: "/stakeholder-profile",
      PartnerGuest: "/partnerguest-profile",
      Provider: "/provider-profile",
      ClientAdmin: "/costomer-profile"
    };
    return profileUrls[userType] || "/profile";
  };

  const getInitials = (name) => {
    if (!name) return '?';
    return name.charAt(0).toUpperCase();
  };

  // Keep these styles for the bootstrap dropdown (retained for compatibility)
  const dropdownItemStyle = {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    fontWeight: "bold",
    backgroundColor: 'transparent',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(29, 191, 115, 0.1)' : 'rgba(29, 191, 115, 0.2)',
      color: '#1DBF73'
    }
  };

  const toggleStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    '&:hover': {
      color: '#1DBF73'
    }
  };

  return (
    <Box>
      <StyledDropdownToggle onClick={handleClick}>
        <UserAvatar>{getInitials(username)}</UserAvatar>
        <Box sx={{ textAlign: 'left', mr: 1 }}>
          <Typography variant="subtitle2" sx={{ 
            fontWeight: 600, 
            color: theme.palette.mode === 'dark' ? '#e0e0e0' : '#424242',
            fontSize: '13px',
            lineHeight: 1.2
          }}>
            {username}
          </Typography>
          <StatusChip 
            size="small" 
            label={userType} 
            userType={userType}
          />
        </Box>
      </StyledDropdownToggle>
      
      <StyledDropdownMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ px: 2, py: 1.5 }}>
          <Typography variant="subtitle1" sx={{ 
            fontWeight: 600, 
            fontSize: '15px', 
            color: theme.palette.mode === 'dark' ? '#ffffff' : '#333333',
            mb: 0.5
          }}>
            {username}
          </Typography>
          <StatusChip 
            size="small" 
            label={userType}
            userType={userType}
          />
        </Box>
        
        <Divider sx={{ 
          my: 1, 
          borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.06)' 
        }} />
        
        <StyledMenuItem onClick={() => { window.location.href = getProfileUrl(); handleClose(); }}>
          <AccountCircleIcon fontSize="small" />
          {intl.formatMessage({ id: 'manageProfile' })}
        </StyledMenuItem>
        
        <StyledMenuItem onClick={() => { window.location.href = "/forgotpassword"; handleClose(); }}>
          <KeyIcon fontSize="small" />
          {intl.formatMessage({ id: 'resetPassword' })}
        </StyledMenuItem>
        
        <Divider sx={{ 
          my: 1, 
          borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.06)' 
        }} />
        
        <StyledMenuItem onClick={(event) => { handleLogOut(event); handleClose(); }}>
          <ExitToAppIcon fontSize="small" />
          {intl.formatMessage({ id: 'logOut' })}
        </StyledMenuItem>
      </StyledDropdownMenu>
    </Box>
  );
};

// Enhanced notification rendering function
const renderNotifications = (isLoggedIn, userType, theme) => {
  if (!isLoggedIn) return null;
  
  return userType === "Client" ? (
    <Box sx={{ 
      position: 'relative'
    }}>
      <IconButton
        size="small"
        sx={{
          color: theme.palette.mode === 'dark' ? '#e0e0e0' : '#424242',
          backgroundColor: theme.palette.mode === 'dark' ? 'rgba(40, 40, 60, 0.4)' : 'rgba(245, 245, 250, 0.6)',
          width: '36px',
          height: '36px',
          borderRadius: '8px',
          transition: 'all 0.2s ease',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(50, 50, 70, 0.6)' : 'rgba(240, 240, 245, 0.8)',
            transform: 'translateY(-1px)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <Notificationmanagericon sx={{ fontSize: 20 }} />
      </IconButton>
    </Box>
  ) : userType === "Candidate" ? (
    <Box>
      <NotificationDropdown />
    </Box>
  ) : null;
};

// Public navigation rendering function
const renderPublicNavigation = (currentPath, intl, theme) => (
  <NavContainer>
    <NavigationItem 
      href="/" 
      label={intl.formatMessage({ id: 'home' })} 
      isActive={currentPath === "/"} 
      marginLeft="auto"
      icon={<HomeIcon fontSize="small" />}
    />
    <NavigationItem 
      href="/login" 
      label={intl.formatMessage({ id: 'login' })} 
      isActive={currentPath === "/login"}
    />
    <NavigationItem 
      href="/register" 
      label={intl.formatMessage({ id: 'register' })} 
      isActive={currentPath === "/register"}
    />
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      ml: 1,
      '& .MuiSwitch-root': {
        boxShadow: theme.palette.mode === 'dark' 
          ? '0 2px 6px rgba(0, 0, 0, 0.2)' 
          : '0 2px 6px rgba(0, 0, 0, 0.05)',
        borderRadius: '12px',
        padding: '3px',
        backgroundColor: theme.palette.mode === 'dark' 
          ? 'rgba(40, 40, 60, 0.4)' 
          : 'rgba(245, 245, 250, 0.6)',
      }
    }}>
      <ThemeToggle />
    </Box>
  </NavContainer>
);

// Enhanced Header component
const Header = () => {const [anchorElNav, setAnchorElNav] = useState(null);
  const [username, setUsername] = useState("");
  const theme = useMUITheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const currentPath = window.location.pathname;
  const userType = user?.data?.[0]?.type;

  // Add global styles for animations
  useEffect(() => {
    const styleEl = document.createElement('style');
    styleEl.textContent = globalStyles;
    document.head.appendChild(styleEl);
    
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    
    const fetchUser = async () => {
      try {
        if (mounted && isLoggedIn) {
          const user = await Auth.currentAuthenticatedUser();
          setUsername(user.username);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    
    if (isLoggedIn) {
      fetchUser();
    }

    return () => {
      mounted = false;
    };
  }, [isLoggedIn]);

  const handleLogOut = async (event) => {
    event.preventDefault();
    try {
      await Auth.signOut();
      await dispatch(logout());
      sessionStorage.setItem("cprofile", "");
      history.push(userType === "ClientAdmin" ? "/admin-login" : "/login");
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);

  return (
    <StyledAppBar position="sticky">
      <StyledToolbarContainer>
        <LogoContainer>
          <StyledTypography
            variant="h6"
            component="a"
            href="/"
            sx={{
              display: { xs: "none", md: "flex" },
              position: 'relative'
            }}
          >
                   {theme.palette.mode === 'dark' ? (
  <StyledLogo   src={globllogodark} alt="Globl.ai logo" />
) : (
  <StyledLogo src={globllogo} alt="Globl.ai logo" />
)}
         
            <LogoGlow />
          </StyledTypography>
        </LogoContainer>

        <StyledNav sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, alignItems: "center" }}>
          <MobileNavigation intl={intl} handleLogOut={handleLogOut} />
          {theme.palette.mode === 'dark' ? (
  <StyledLogo src={globllogodark} alt="Globl.ai logo" />
) : (
  <StyledLogo src={globllogo} alt="Globl.ai logo" />
)}
       
        </StyledNav>

        <NavSection sx={{ display: { xs: "none", md: "flex" } }}>
          {isLoggedIn ? (
            <NavigationWrapper
              sx={{
                animation: 'fadeIn 0.3s ease-out',
              }}
            >
              <UserNavigation type={userType} path={currentPath} intl={intl} />
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '4px' }}>
                {renderNotifications(isLoggedIn, userType, theme)}
              </div>
              <UserDropdown 
                username={username}
                handleLogOut={handleLogOut}
                userType={userType}
                intl={intl}
              />
            </NavigationWrapper>
          ) : (
            <NavigationWrapper
              sx={{
                animation: 'fadeIn 0.3s ease-out',
              }}
            >
              {renderPublicNavigation(currentPath, intl, theme)}
            </NavigationWrapper>
          )}
        </NavSection>
      </StyledToolbarContainer>
    </StyledAppBar>
  );
};

export default Header;