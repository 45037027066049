import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Avatar,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Divider,
  Breadcrumbs,
  Card,
  CardContent,
  styled,
  CircularProgress,
  Snackbar,
  Alert,
  Tooltip,
  tooltipClasses
} from '@mui/material';
import {
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
  PhotoCamera as PhotoCameraIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  Business as BusinessIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
  Videocam as VideocamIcon
} from '@mui/icons-material';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import S3FileUpload from 'react-s3';
import AWS from 'aws-sdk';
import { podprovideredit } from "../action/auth";
import { useAuthToken } from "../../TokenContext";
import defaultimg from '../assets/defaultimge.png';

// Dark mode styled components
const ProfileContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(4)
  },
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(135deg, #121212 0%, #1e1e1e 100%)'
    : 'linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 20px 0 rgba(0, 0, 0, 0.5)'
    : '0 4px 20px 0 rgba(34, 41, 47, 0.1)'
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 24px 0 rgba(0, 0, 0, 0.3)'
    : '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
  overflow: 'visible',
  position: 'relative',
  background: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff',
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, 2)
  }
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  height: 200,
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(135deg, #01023b 0%, #005582 100%)'
    : 'linear-gradient(135deg, #03045e 0%, #0077b6 100%)',
  borderRadius: '16px 16px 0 0',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '40%',
    background: 'linear-gradient(to top, rgba(0,0,0,0.6), transparent)',
  }
}));

const ProfileAvatarWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: -60,
  transform: 'translateX(-50%)',
  zIndex: 2,
  '&:hover .MuiBox-root': {
    opacity: 1
  }
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  border: `4px solid ${theme.palette.mode === 'dark' ? '#1e1e1e' : theme.palette.background.paper}`,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0,0,0,0.3)'
    : '0 4px 12px rgba(0,0,0,0.15)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  }
}));

const UploadOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  transition: 'opacity 0.2s ease',
  borderRadius: '50%',
  cursor: 'pointer'
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#f8fafc'
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.15)' : '#fff',
      boxShadow: theme.palette.mode === 'dark'
        ? '0 0 0 2px rgba(0, 180, 216, 0.3)'
        : '0 0 0 2px rgba(0, 180, 216, 0.2)'
    }
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.text.secondary
  },
  '& .MuiInputBase-input': {
    color: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.text.primary
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 8,
  padding: '10px 24px',
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0, 120, 156, 0.3)'
    : '0 4px 12px rgba(0, 180, 216, 0.2)',
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark'
      ? '0 6px 16px rgba(0, 120, 156, 0.4)'
      : '0 6px 16px rgba(0, 180, 216, 0.3)'
  }
}));

const InfoSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : theme.palette.grey[50],
  borderRadius: 12,
  marginBottom: theme.spacing(3),
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : theme.palette.grey[100],
    transition: 'background-color 0.3s ease'
  }
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '&:last-child': {
    marginBottom: 0
  }
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : theme.palette.divider
}));

const EditModeWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : theme.palette.background.paper,
  borderRadius: 12,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 2px 8px rgba(0,0,0,0.2)'
    : '0 2px 8px rgba(0,0,0,0.05)',
  marginTop: theme.spacing(4)
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(0,0,0,0.8)'
    : 'rgba(255,255,255,0.8)',
  zIndex: 9999,
  backdropFilter: 'blur(4px)'
}));

const VideoPreview = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: 8,
  overflow: 'hidden',
  position: 'relative',
  marginTop: theme.spacing(2),
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0,0,0,0.3)'
    : '0 4px 12px rgba(0,0,0,0.1)',
}));

const VideoUploadContainer = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.mode === 'dark' ? '#555' : '#ccc'}`,
  borderRadius: 8,
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.03)' : '#f7fafc',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(0, 119, 182, 0.1)'
      : '#f0f7ff'
  }
}));

const Providerprofile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);

  const [state, setState] = useState({
    id: user.data[0].id,
    firstName: user.data[0].firstName,
    lastName: user.data[0].lastName,
    email: user.data[0].email,
    phone: user.data[0].phone,
    companyName: user.data[0].companyName,
    region: user.data[0].region,
    about: user.data[0].about,
    expertise: user.data[0].expertise,
    video: user.data[0].video,
    profilePicture: user.data[0].profilePicture,
    subscriptionID: user.data[0].subscriptionID,
    priceID: user.data[0].priceID,
    customerId: user.data[0].customerId,
  });

  const [edit, setEdit] = useState(user.data[0].profile === 0);
  const [image, setImage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [authImage, setAuthImage] = useState('');
  const [videoUploading, setVideoUploading] = useState(false);
  const [videoPreview, setVideoPreview] = useState('');
  const [authVideo, setAuthVideo] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const config = {
    bucketName: process.env.REACT_APP_BUCKETNAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
  };

  useEffect(() => {
    generatePreSignedUrl();
    generateVideoPreSignedUrl();
  
    AWS.config.update({
      region: config.region,
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey
    });
  }, []);

  const generatePreSignedUrl = async () => {
    if (!state.profilePicture) return;
    
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + state.profilePicture
        })
      });
      
      const data = await response.json();
      if (data.body) {
        const signedUrl = JSON.parse(data.body).signedUrl;
        setAuthImage(signedUrl);
      }
    } catch (error) {
      console.error("Error generating signed URL:", error);
      setSnackbar({
        open: true,
        message: 'Failed to load profile picture',
        severity: 'error'
      });
    }
  };

  const generateVideoPreSignedUrl = async () => {
    if (!state.video) return;
    
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + state.video
        })
      });
      
      const data = await response.json();
      if (data.body) {
        const signedUrl = JSON.parse(data.body).signedUrl;
        setAuthVideo(signedUrl);
      }
    } catch (error) {
      console.error("Error generating signed URL for video:", error);
      setSnackbar({
        open: true,
        message: 'Failed to load profile video',
        severity: 'error'
      });
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
  
    // Check file size (5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      setSnackbar({
        open: true,
        message: "File size should be less than 5MB",
        severity: "error"
      });
      return;
    }
  
    setUploading(true);
    try {
      // Create preview URL
      const preview = URL.createObjectURL(file);
      setImage(preview);
  
      // Upload to S3
      const uploadResult = await S3FileUpload.uploadFile(file, config);
      setState(prev => ({
        ...prev,
        profilePicture: uploadResult.key
      }));
  
      setSnackbar({
        open: true,
        message: "Profile picture updated successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Upload error:", error);
      setSnackbar({
        open: true,
        message: "Failed to upload profile picture",
        severity: "error"
      });
      setImage("");
    } finally {
      setUploading(false);
    }
  };

  const handleVideoUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;
    
    // Check file size (50MB limit)
    if (file.size > 50 * 1024 * 1024) {
      setSnackbar({
        open: true,
        message: "Video size should be less than 50MB",
        severity: "error"
      });
      return;
    }
    
    // Check file type
    if (!file.type.includes('video/')) {
      setSnackbar({
        open: true,
        message: "Please upload a valid video file",
        severity: "error"
      });
      return;
    }
    
    setVideoUploading(true);
    try {
      // Create preview URL
      const preview = URL.createObjectURL(file);
      setVideoPreview(preview);
      
      // Configure S3 for larger uploads
      const s3 = new AWS.S3({
        region: config.region,
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey
      });
      
      // Create unique filename with timestamp and original extension
      const fileExtension = file.name.split('.').pop();
      const uniqueFileName = `${Date.now()}-${Math.random().toString(36).substring(2, 15)}.${fileExtension}`;
      
      // Upload to S3 with progress monitoring for larger files
      const params = {
        Bucket: config.bucketName,
        Key: uniqueFileName,
        Body: file,
        ContentType: file.type
      };
      
      const uploadResult = await s3.upload(params).promise();
      
      setState(prev => ({
        ...prev,
        video: uploadResult.Key
      }));
      
      setSnackbar({
        open: true,
        message: "Profile video uploaded successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Video upload error:", error);
      setSnackbar({
        open: true,
        message: "Failed to upload profile video",
        severity: "error"
      });
      setVideoPreview("");
    } finally {
      setVideoUploading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!state.firstName || !state.lastName || !state.phone || !state.region || !state.companyName) {
      setSnackbar({
        open: true,
        message: 'Please fill all required fields',
        severity: 'error'
      });
      return;
    }

    try {
      const providerdata = {
        id: state.id,
        firstName: state.firstName,
        lastName: state.lastName,
        phone: state.phone,
        region: state.region,
        companyName: state.companyName,
        profilePicture: state.profilePicture,
        about: state.about,
        expertise: state.expertise,
        video: state.video,
        subscriptionID: state.subscriptionID,
        priceID: state.priceID,
        customerId: state.customerId
      };

      await dispatch(podprovideredit(providerdata, {
        'Authorization': token,
        'Content-Type': 'application/json'
      }));

      setSnackbar({
        open: true,
        message: 'Profile updated successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update profile',
        severity: 'error'
      });
    }
  };

  const handleInputChange = (e) => {
    setState(prev => ({
      ...prev,
      [e.target.id || e.target.name]: e.target.value
    }));
  };

  const handleCancel = () => {
    if (edit && user.data[0].profile !== 0) {
      setEdit(false);
    } else {
      history.push('/PartnerSubscription');
    }
  };

  return (
    <ProfileContainer maxWidth="lg">
      <Box sx={{ mb: 4 }}>
        <Breadcrumbs 
          separator="›" 
          aria-label="breadcrumb"
          sx={{ 
            '& .MuiBreadcrumbs-ol': { 
              color: theme => theme.palette.mode === 'dark' ? '#ffffff' : 'inherit' 
            } 
          }}
        >
          <Link 
            to="PartnerSubscription"
            style={{
              color: '#90caf9',
              textDecoration: 'none',
              fontSize: '16px',
              fontWeight: 600
            }}
          >
            Subscription
          </Link>
          <Typography 
            color="rgba(255, 255, 255, 0.7)"
            fontWeight={600}
          >
            {user.data[0].profile === 0 ? 'Create Profile' : 'Manage Profile'}
          </Typography>
        </Breadcrumbs>
      </Box>

      <ProfileCard>
        <ProfileHeader>
          <ProfileAvatarWrapper>
            <ProfileAvatar
              src={image || authImage || defaultimg}
              alt={`${state.firstName} ${state.lastName}`}
            />
            {edit && (
              <UploadOverlay>
                <label htmlFor="profile-image">
                  <input
                    accept="image/*"
                    type="file"
                    hidden
                    id="profile-image"
                    onChange={handleImageUpload}
                  />
                  <Tooltip title="Upload profile picture">
                    <IconButton 
                      component="span"
                      sx={{ 
                        color: 'white',
                        '&:hover': { 
                          backgroundColor: 'rgba(255, 255, 255, 0.1)',
                          transform: 'scale(1.1)'
                        }
                      }}
                    >
                      <PhotoCameraIcon />
                    </IconButton>
                  </Tooltip>
                </label>
              </UploadOverlay>
            )}
          </ProfileAvatarWrapper>
        </ProfileHeader>

        {!edit && (
          <IconButton
            sx={{
              position: 'absolute',
              right: 16,
              top: 180,
              backgroundColor: '#333',
              boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
              '&:hover': { 
                backgroundColor: '#444',
                transform: 'scale(1.1)'
              }
            }}
            onClick={() => setEdit(true)}
          >
            <EditIcon color="primary" />
          </IconButton>
        )}

        <CardContent sx={{ pt: 10 }}>
          {edit ? (
            <form onSubmit={handleSubmit}>
              <EditModeWrapper>
                <Typography 
                  variant="h6" 
                  gutterBottom 
                  sx={{ 
                    mb: 3,
                    color: '#fff'
                  }}
                >
                  Edit Profile Information
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <StyledTextField
                      fullWidth
                      label="First Name"
                      id="firstName"
                      value={state.firstName}
                      onChange={handleInputChange}
                      required
                      helperText="Enter your first name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledTextField
                      fullWidth
                      label="Last Name"
                      id="lastName"
                      value={state.lastName}
                      onChange={handleInputChange}
                      required
                      helperText="Enter your last name"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledTextField
                      fullWidth
                      label="Phone"
                      id="phone"
                      value={state.phone}
                      onChange={handleInputChange}
                      required
                      helperText="Enter your contact number"
                      InputProps={{
                        startAdornment: (
                          <PhoneIcon color="action" sx={{ mr: 1 }} />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledTextField
                      fullWidth
                      label="Region"
                      id="region"
                      value={state.region}
                      onChange={handleInputChange}
                      required
                      helperText="Enter your region"
                      InputProps={{
                        startAdornment: (
                          <LocationIcon color="action" sx={{ mr: 1 }} />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      fullWidth
                      label="Company Name"
                      id="companyName"
                      value={state.companyName}
                      onChange={handleInputChange}
                      required
                      helperText="Enter your company name"
                      InputProps={{
                        startAdornment: (
                          <BusinessIcon color="action" sx={{ mr: 1 }} />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      fullWidth
                      label="About"
                      id="about"
                      value={state.about}
                      onChange={handleInputChange}
                      required
                      multiline
                      rows={4}
                      helperText="Tell us about your company"
                      InputProps={{
                        startAdornment: (
                          <BusinessIcon color="action" sx={{ mr: 1, alignSelf: 'flex-start', mt: 1 }} />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTextField
                      fullWidth
                      label="Expertise"
                      id="expertise"
                      value={state.expertise}
                      onChange={handleInputChange}
                      required
                      helperText="Describe your company's expertise"
                      InputProps={{
                        startAdornment: (
                          <BusinessIcon color="action" sx={{ mr: 1 }} />
                        ),
                      }}
                    />
                  </Grid>
                  
                  {/* Video Upload Section */}
                  <Grid item xs={12}>
                    <Box sx={{ mb: 2 }}>
                      <Typography 
                        variant="h6" 
                        color="primary" 
                        gutterBottom
                      >
                        Profile Video
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="grey.400" 
                        paragraph
                      >
                        Upload a short video introducing your company (max 50MB)
                      </Typography>
                    </Box>
                    
                    <input
                      accept="video/*"
                      type="file"
                      hidden
                      id="profile-video"
                      onChange={handleVideoUpload}
                    />
                    
                    {videoPreview || authVideo ? (
                      <VideoPreview>
                        <video 
                          width="100%" 
                          height="auto" 
                          controls 
                          src={videoPreview || authVideo}
                        >
                          Your browser does not support the video tag.
                        </video>
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                          <label htmlFor="profile-video">
                            <Button
                              component="span"
                              variant="outlined"
                              color="primary"
                              startIcon={<VideocamIcon />}
                              sx={{ 
                                borderRadius: 2, 
                                mr: 1,
                                '&:hover': { backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgba(144, 202, 249, 0.08)' : 'rgba(3, 4, 94, 0.04)' }
                              }}
                            >
                              Replace Video
                            </Button>
                          </label>
                        </Box>
                      </VideoPreview>
                    ) : (
                      <label htmlFor="profile-video">
                        <VideoUploadContainer>
                          <VideocamIcon sx={{ fontSize: 48, color: '#0077b6', mb: 2 }} />
                          <Typography 
                            variant="subtitle1" 
                            gutterBottom 
                            sx={{ 
                              fontWeight: 600,
                              color: theme => theme.palette.mode === 'dark' ? '#fff' : 'inherit'
                            }}
                          >
                            Upload Company Introduction Video
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="grey.400"
                          >
                            Click to browse or drag and drop your video file here
                          </Typography>
                          <Typography 
                            variant="caption" 
                            color={theme => theme.palette.mode === 'dark' ? 'grey.500' : 'textSecondary'} 
                            sx={{ mt: 1 }}
                          >
                            Supported formats: MP4, MOV, AVI (Max 50MB)
                          </Typography>
                          <Button
                            variant="contained"
                            component="span"
                            sx={{ mt: 2 }}
                            startIcon={<VideocamIcon />}
                          >
                            Select Video
                          </Button>
                        </VideoUploadContainer>
                      </label>
                    )}
                  </Grid>
                </Grid>

                <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'center' }}>
                  <ActionButton
                    variant="outlined"
                    onClick={handleCancel}
                    startIcon={<CloseIcon />}
                    sx={{
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                      color: '#fff'
                    }}
                  >
                    Cancel
                  </ActionButton>
                  <ActionButton
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    disabled={uploading || videoUploading}
                  >
                    {(uploading || videoUploading) ? 
                      <CircularProgress size={24} /> : 
                      (user.data[0].profile === 0 ? 'Create Profile' : 'Save Changes')
                    }
                  </ActionButton>
                  <ActionButton
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    disabled={uploading || videoUploading}
                  >
                    {(uploading || videoUploading) ? 
                      <CircularProgress size={24} /> : 
                      (user.data[0].profile === 0 ? 'Create Profile' : 'Save Changes')
                    }
                  </ActionButton>
                </Box>
              </EditModeWrapper>
            </form>
          ) : (
            <Box>
              <Typography variant="h5" align="center" gutterBottom>
                {`${state.firstName} ${state.lastName}`}
              </Typography>
              <Typography 
                variant="body1" 
                color="textSecondary" 
                align="center" 
                gutterBottom
              >
                {state.email}
              </Typography>
              
              <Box sx={{ mt: 4 }}>
                <InfoSection>
                  <Typography 
                    variant="h6" 
                    color="primary" 
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    Basic Information
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <InfoItem>
                        <PhoneIcon color="action" />
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">
                            Phone
                          </Typography>
                          <Typography variant="body1">{state.phone}</Typography>
                        </Box>
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <InfoItem>
                        <LocationIcon color="action" />
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">
                            Region
                          </Typography>
                          <Typography variant="body1">{state.region}</Typography>
                        </Box>
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12}>
                      <InfoItem>
                        <BusinessIcon color="action" />
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">
                            Company Name
                          </Typography>
                          <Typography variant="body1">{state.companyName}</Typography>
                        </Box>
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12}>
                      <InfoItem>
                        <BusinessIcon color="action" />
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">
                            About
                          </Typography>
                          <Typography variant="body1">{state.about}</Typography>
                        </Box>
                      </InfoItem>
                    </Grid>
                    <Grid item xs={12}>
                      <InfoItem>
                        <BusinessIcon color="action" />
                        <Box>
                          <Typography variant="subtitle2" color="textSecondary">
                            Expertise
                          </Typography>
                          <Typography variant="body1">{state.expertise}</Typography>
                        </Box>
                      </InfoItem>
                    </Grid>
                  </Grid>
                </InfoSection>
                
                {/* Video Display in View Mode */}
                {(state.video || authVideo) && (
                  <InfoSection>
                    <Typography 
                      variant="h6" 
                      color="primary" 
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      Company Introduction Video
                    </Typography>
                    <VideoPreview>
                      <video 
                        width="100%" 
                        height="auto" 
                        controls 
                        src={authVideo}
                      >
                        Your browser does not support the video tag.
                      </video>
                    </VideoPreview>
                  </InfoSection>
                )}
              </Box>
            </Box>
          )}
        </CardContent>
      </ProfileCard>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          elevation={6}
          variant="filled"
          sx={{ 
            width: '100%',
            borderRadius: '8px'
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {(uploading || videoUploading) && (
        <LoadingOverlay>
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress size={48} />
            <Typography 
              variant="body2" 
              sx={{ mt: 2, color: 'text.secondary' }}
            >
              {videoUploading ? 'Uploading video...' : 'Uploading...'}
            </Typography>
          </Box>
        </LoadingOverlay>
      )}
    </ProfileContainer>
  );
};

export default Providerprofile;