import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Divider,
  CircularProgress,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  InputAdornment,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  Description as DescriptionIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
  Sort as SortIcon,
  FilterList as FilterListIcon,
  MoreVert as MoreVertIcon,
  ViewModule as GridViewIcon,
  ViewList as ListViewIcon,
} from '@mui/icons-material';

const CreateGigForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const token = useAuthToken();
  
  // State declarations
  const [gigs, setGigs] = useState([]);
  const [filteredGigs, setFilteredGigs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [gigToDelete, setGigToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  
  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  
  // Search and filter state
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState('Gig_Name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [viewMode, setViewMode] = useState('grid');
  
  // Menu state
  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  
  // Form state
  const [formData, setFormData] = useState({
    Gigs_id: '',
    Gig_Name: '',
    Gig_Description: '',
    Organization_Code: user?.data?.[0]?.client_code || ''
  });
  
  const [viewGig, setViewGig] = useState(null);
  
  // Fetch gigs on component mount
  useEffect(() => {
    fetchGigs();
  }, [user?.data, token]);
  
  // Apply filtering and sorting
  useEffect(() => {
    let result = [...gigs];
    
    // Apply search
    if (searchQuery) {
      const lowerQuery = searchQuery.toLowerCase();
      result = result.filter(gig => 
        gig.Gig_Name.toLowerCase().includes(lowerQuery) || 
        gig.Gig_Description.toLowerCase().includes(lowerQuery)
      );
    }
    
    // Apply sorting
    result.sort((a, b) => {
      let fieldA = a[sortField]?.toLowerCase() || '';
      let fieldB = b[sortField]?.toLowerCase() || '';
      
      if (sortDirection === 'asc') {
        return fieldA.localeCompare(fieldB);
      } else {
        return fieldB.localeCompare(fieldA);
      }
    });
    
    setFilteredGigs(result);
    setPage(0); // Reset to first page when filters change
  }, [gigs, searchQuery, sortField, sortDirection]);
  
  // Fetch gigs function
  const fetchGigs = async () => {
    if (!user?.data?.[0]?.client_code || !token) return;
    
    setLoading(true);
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
      };
      
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHGIG, 
        {
          Organization_Code: user.data[0].client_code,
        },
        { headers }
      );
      
      if (response.data.body.statuscode === 200) {
        setGigs(response.data.body.body);
        setFilteredGigs(response.data.body.body);
      }
    } catch (error) {
      console.error('Error fetching gigs:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load gigs. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  // Handle create gig
  const handleCreateGig = async () => {
    if (!formData.Gig_Name || !formData.Gig_Description) {
      setSnackbar({
        open: true,
        message: 'Please fill all required fields.',
        severity: 'warning'
      });
      return;
    }
    
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_CREATEAGIG, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Gig_Name: formData.Gig_Name,
          Gig_Description: formData.Gig_Description,
          Organization_Code: user.data[0].client_code
        }),
      });
      
      if (response.ok) {
        setSnackbar({
          open: true,
          message: 'Gig created successfully!',
          severity: 'success'
        });
        setCreateDialogOpen(false);
        resetFormData();
        fetchGigs(); // Refresh gig list
      } else {
        throw new Error('Failed to create gig');
      }
    } catch (error) {
      console.error('Error creating gig:', error);
      setSnackbar({
        open: true,
        message: 'Failed to create gig. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Handle edit gig
  const handleEditGig = async () => {
    if (!formData.Gig_Name || !formData.Gig_Description || !formData.Gigs_id) {
      setSnackbar({
        open: true,
        message: 'Please fill all required fields.',
        severity: 'warning'
      });
      return;
    }
    
    setLoading(true);
    try {
      const response = await fetch('https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/EditGig', {
        method: 'PUT',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
          
        },
        body: JSON.stringify({
          Gigs_id: formData.Gigs_id,
          Gig_Name: formData.Gig_Name,
          Gig_Description: formData.Gig_Description,
          Organization_Code: user.data[0].client_code
        }),
      });
      
      if (response.ok) {
        setSnackbar({
          open: true,
          message: 'Gig updated successfully!',
          severity: 'success'
        });
        setEditDialogOpen(false);
        resetFormData();
        fetchGigs(); // Refresh gig list
      } else {
        throw new Error('Failed to update gig');
      }
    } catch (error) {
      console.error('Error updating gig:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update gig. Please try again.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle delete gig
  const handleDeleteGig = async () => {
    if (!gigToDelete || !gigToDelete.Gigs_id) {
      setSnackbar({
        open: true,
        message: 'Invalid Gig selected for deletion.',
        severity: 'error'
      });
      return;
    }
    
    setLoading(true);
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
        'Date': new Date().toUTCString()
      };
      
      const response = await axios.post(
        'https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/deleteGigs',
        {
          Gigs_id: gigToDelete.Gigs_id || gigToDelete.id
        },
        { headers }
      );
      
      if (response.status === 200) {
        setSnackbar({
          open: true,
          message: 'Gig deleted successfully!',
          severity: 'success'
        });
        setDeleteDialogOpen(false);
        setGigToDelete(null);
        fetchGigs(); // Refresh gig list
      } else {
        throw new Error('Failed to delete gig');
      }
    } catch (error) {
      console.error('Error deleting gig:', error);
      setSnackbar({
        open: true,
        message: `Failed to delete gig: ${error.response?.data?.message || error.message}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };
  
  // Open delete confirmation dialog
  const openDeleteDialog = (gig) => {
    setGigToDelete(gig);
    setDeleteDialogOpen(true);
  };
  
  // Open edit dialog with gig data
  const openEditDialog = (gig) => {
    setFormData({
      Gigs_id: gig.id || gig.Gigs_id,
      Gig_Name: gig.Gig_Name,
      Gig_Description: gig.Gig_Description,
      Organization_Code: user.data[0].client_code
    });
    setEditDialogOpen(true);
  };
  
  // Open view dialog with gig data
  const openViewDialog = (gig) => {
    setViewGig(gig);
    setViewDialogOpen(true);
  };
  
  // Reset form data
  const resetFormData = () => {
    setFormData({
      Gigs_id: '',
      Gig_Name: '',
      Gig_Description: '',
      Organization_Code: user?.data?.[0]?.client_code || ''
    });
  };
  
  // Close dialogs
  const handleCloseDialogs = () => {
    setCreateDialogOpen(false);
    setEditDialogOpen(false);
    setViewDialogOpen(false);
    setDeleteDialogOpen(false);
    setGigToDelete(null);
    resetFormData();
  };
  
  // Handle close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false
    });
  };
  
  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  // Handle sort menu
  const handleSortMenuOpen = (event) => {
    setSortMenuAnchor(event.currentTarget);
  };
  
  const handleSortMenuClose = () => {
    setSortMenuAnchor(null);
  };
  
  const handleSort = (field) => {
    if (field === sortField) {
      // Toggle direction if same field
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // New field, set to ascending
      setSortField(field);
      setSortDirection('asc');
    }
    handleSortMenuClose();
  };
  
  // Get paginated data
  const getPaginatedData = () => {
    return filteredGigs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };
  
  // Render gig cards (grid view)
  const renderGigCards = () => {
    if (loading && gigs.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      );
    }
    
    if (filteredGigs.length === 0) {
      return (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="textSecondary">
            {searchQuery ? 'No gigs match your search criteria.' : 'No gigs found. Create your first gig by clicking the "Create New Gig" button.'}
          </Typography>
        </Paper>
      );
    }
    
    return (
      <Grid container spacing={3}>
        {getPaginatedData().map((gig) => (
          <Grid item xs={12} sm={6} md={4} key={gig.id || gig.Gigs_id}>
            <Card 
              elevation={3} 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 10px 20px rgba(0,0,0,0.1)'
                }
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="h2" gutterBottom noWrap>
                  {gig.Gig_Name}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="textSecondary" 
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {gig.Gig_Description}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                <Tooltip title="View Details">
                  <IconButton 
                    size="small" 
                    color="primary"
                    onClick={() => openViewDialog(gig)}
                  >
                    <DescriptionIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton 
                    size="small" 
                    color="primary"
                    onClick={() => openEditDialog(gig)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={parseInt(gig.delete_check) === 1 ? "Delete" : "Cannot delete - associated with ARTs"}>
                  <span>
                    <IconButton 
                      size="small" 
                      color="error"
                      onClick={() => openDeleteDialog(gig)}
                      disabled={parseInt(gig.delete_check) === 0}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };
  
  // Render gig list (list view)
  const renderGigList = () => {
    if (loading && gigs.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      );
    }
    
    if (filteredGigs.length === 0) {
      return (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1" color="textSecondary">
            {searchQuery ? 'No gigs match your search criteria.' : 'No gigs found. Create your first gig by clicking the "Create New Gig" button.'}
          </Typography>
        </Paper>
      );
    }
    
    return (
      <Paper elevation={2}>
        <List>
          {getPaginatedData().map((gig, index) => (
            <React.Fragment key={gig.id || gig.Gigs_id}>
              <ListItem 
                alignItems="flex-start"
                sx={{ 
                  py: 2,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                  }
                }}
                secondaryAction={
                  <Box>
                    <Tooltip title="View Details">
                      <IconButton 
                        edge="end" 
                        onClick={() => openViewDialog(gig)}
                        color="primary"
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton 
                        edge="end" 
                        onClick={() => openEditDialog(gig)}
                        color="primary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={parseInt(gig.delete_check) === 1 ? "Delete" : "Cannot delete - associated with ARTs"}>
                      <span>
                        <IconButton 
                          edge="end" 
                          onClick={() => openDeleteDialog(gig)}
                          color="error"
                          disabled={parseInt(gig.delete_check) === 0}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                }
              >
                <ListItemText
                  primary={
                    <Typography variant="h6" component="div">
                      {gig.Gig_Name}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      component="span"
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        display: 'inline-block',
                        maxWidth: '80%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {gig.Gig_Description}
                    </Typography>
                  }
                />
              </ListItem>
              {index < getPaginatedData().length - 1 && <Divider component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    );
  };
  
  return (
    <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Gig Management
        </Typography>
        <Box>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={fetchGigs}
            sx={{ mr: 2 }}
          >
            Refresh
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setCreateDialogOpen(true)}
          >
            Create New Gig
          </Button>
        </Box>
      </Box>
      
      {/* Toolbar with search and filters */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              placeholder="Search gigs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                startIcon={<SortIcon />}
                onClick={handleSortMenuOpen}
                sx={{ mr: 2 }}
                size="small"
              >
                Sort By
              </Button>
              <Menu
                anchorEl={sortMenuAnchor}
                open={Boolean(sortMenuAnchor)}
                onClose={handleSortMenuClose}
              >
                <MenuItem 
                  onClick={() => handleSort('Gig_Name')}
                  selected={sortField === 'Gig_Name'}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    Name
                    {sortField === 'Gig_Name' && (
                      <Typography variant="caption" color="primary">
                        {sortDirection === 'asc' ? '(A-Z)' : '(Z-A)'}
                      </Typography>
                    )}
                  </Box>
                </MenuItem>
                <MenuItem 
                  onClick={() => handleSort('Gig_Description')}
                  selected={sortField === 'Gig_Description'}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    Description
                    {sortField === 'Gig_Description' && (
                      <Typography variant="caption" color="primary">
                        {sortDirection === 'asc' ? '(A-Z)' : '(Z-A)'}
                      </Typography>
                    )}
                  </Box>
                </MenuItem>
              </Menu>
              <Tooltip title="Toggle View">
                <IconButton 
                  onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
                  color="primary"
                  size="small"
                >
                  {viewMode === 'grid' ? <ListViewIcon /> : <GridViewIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Display gigs based on view mode */}
      {viewMode === 'grid' ? renderGigCards() : renderGigList()}
      
      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <TablePagination
          component="div"
          count={filteredGigs.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[9, 18, 36, 99]}
          labelRowsPerPage="Gigs per page:"
          showFirstButton
          showLastButton
        />
      </Box>
      
      {/* Create Gig Dialog */}
      <Dialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Create New Gig</Typography>
            <IconButton onClick={handleCloseDialogs} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="Gig_Name"
              label="Gig Name"
              name="Gig_Name"
              value={formData.Gig_Name}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
              helperText={`${formData.Gig_Name.length}/50`}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              multiline
              rows={4}
              id="Gig_Description"
              label="Gig Description"
              name="Gig_Description"
              value={formData.Gig_Description}
              onChange={handleInputChange}
              inputProps={{ maxLength: 200 }}
              helperText={`${formData.Gig_Description.length}/200`}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleCreateGig} 
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Create Gig
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Edit Gig Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Edit Gig</Typography>
            <IconButton onClick={handleCloseDialogs} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="Gig_Name"
              label="Gig Name"
              name="Gig_Name"
              value={formData.Gig_Name}
              onChange={handleInputChange}
              inputProps={{ maxLength: 50 }}
              helperText={`${formData.Gig_Name.length}/50`}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              multiline
              rows={4}
              id="Gig_Description"
              label="Gig Description"
              name="Gig_Description"
              value={formData.Gig_Description}
              onChange={handleInputChange}
              inputProps={{ maxLength: 200 }}
              helperText={`${formData.Gig_Description.length}/200`}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button 
            variant="contained" 
            onClick={handleEditGig} 
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Update Gig
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* View Gig Dialog */}
      <Dialog
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Gig Details</Typography>
            <IconButton onClick={handleCloseDialogs} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {viewGig && (
            <List>
              <ListItem>
                <ListItemText 
                  primary="Gig ID" 
                  secondary={viewGig.id || viewGig.Gigs_id} 
                  primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText 
                  primary="Gig Name" 
                  secondary={viewGig.Gig_Name} 
                  primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
              <Divider component="li" />
              <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <ListItemText 
                  primary="Gig Description" 
                  primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                  sx={{ mb: 1 }}
                />
                <Typography variant="body1" sx={{ width: '100%' }}>
                  {viewGig.Gig_Description}
                </Typography>
              </ListItem>
              <Divider component="li" />
              <ListItem>
                <ListItemText 
                  primary="Organization Code" 
                  secondary={viewGig.Organization_Code} 
                  primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                  secondaryTypographyProps={{ variant: 'body1' }}
                />
              </ListItem>
            </List>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCloseDialogs}>Close</Button>
          {viewGig && parseInt(viewGig.delete_check) === 1 && (
            <Button 
              variant="outlined" 
              color="error"
              onClick={() => {
                handleCloseDialogs();
                openDeleteDialog(viewGig);
              }}
            >
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the gig "{gigToDelete?.Gig_Name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogs}>Cancel</Button>
          <Button 
            color="error" 
            variant="contained"
            onClick={handleDeleteGig}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity} 
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CreateGigForm;