import React, { useState, useRef } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Divider,
  useTheme,
  Chip,
  List,
  alpha,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import AWS from 'aws-sdk';

import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import BugReportIcon from '@mui/icons-material/BugReport';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TaskIcon from '@mui/icons-material/Task';
import SecurityIcon from '@mui/icons-material/Security';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import { useAuthToken } from "../TokenContext";
import { useSelector } from "react-redux";
import ExportOptions from './ExportOptions'; // Import the new export component

const MessageContainer = styled(Box)(({ theme }) => ({
  margin: 'auto',
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  height: 'calc(100vh - 250px)',
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'dark' 
      ? alpha(theme.palette.common.white, 0.2)
      : theme.palette.grey[300],
    borderRadius: '4px'
  }
}));

const UserMessage = styled(Card)(({ theme }) => ({
  maxWidth: '80%',
  marginLeft: 'auto',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' 
    ? alpha(theme.palette.primary.main, 0.2)
    : theme.palette.primary.light,
  color: theme.palette.mode === 'dark'
    ? theme.palette.primary.light
    : theme.palette.primary.contrastText
}));

const BotMessage = styled(Card)(({ theme }) => ({
  maxWidth: '80%',
  marginRight: 'auto',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark'
    ? alpha(theme.palette.background.paper, 0.5)
    : theme.palette.background.paper
}));

const TaskSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark'
    ? alpha(theme.palette.background.paper, 0.3)
    : theme.palette.grey[50],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Message = ({ message }) => {
 
  const renderTaskSection = (content) => {
    const lines = content.split('\n').map(line => line.trim());
    const taskType = lines[0].replace('•', '').trim();
    
    const findSection = (startText) => {
      const startIndex = lines.findIndex(line => line.includes(startText));
      if (startIndex === -1) return [];
      
      const section = [];
      for (let i = startIndex + 1; i < lines.length; i++) {
        if (lines[i].startsWith('•') || !lines[i]) break;
        section.push(lines[i]);
      }
      return section;
    };

    const storyPoints = lines.find(line => line.includes('Story Points:'))?.split(':')[1]?.trim();
    const acceptanceCriteria = findSection('Acceptance Criteria:');
    const testCases = findSection('Test Cases:');
    const securityGuidelines = findSection('Security Guidelines:');

    return (
      <TaskSection>
        <Typography variant="h6" gutterBottom color="primary">
          {taskType}
        </Typography>

        {storyPoints && (
          <Chip
            label={`${storyPoints} Points`}
            color="primary"
            size="small"
            sx={{ mb: 2 }}
          />
        )}

        {lines.find(line => line.includes('Issue Summary:')) && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Summary
            </Typography>
            <Typography>
              {lines.find(line => line.includes('Issue Summary:'))
                .split(':')[1]
                .trim()}
            </Typography>
          </Box>
        )}

        {acceptanceCriteria.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Acceptance Criteria
            </Typography>
            <List dense>
              {acceptanceCriteria.map((criterion, index) => (
                <ListItem key={index} sx={{ py: 0.5 }}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <TaskIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={criterion.replace(/^\d+\./, '').trim()}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {testCases.length > 0 && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Test Cases
            </Typography>
            <List dense>
              {testCases.map((test, index) => (
                <ListItem key={index} sx={{ py: 0.5 }}>
                  <ListItemIcon sx={{ minWidth: 36 }}>
                    <CheckCircleIcon color="success" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={test.replace(/^\d+\./, '').trim()}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {securityGuidelines.length > 0 && (
          <Box>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Security Guidelines
            </Typography>
            <List dense>
              {securityGuidelines
                .filter(line => line.startsWith('-'))
                .map((guideline, index) => (
                  <ListItem key={index} sx={{ py: 0.5 }}>
                    <ListItemIcon sx={{ minWidth: 36 }}>
                      <SecurityIcon color="info" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={guideline.replace(/^-/, '').trim()}
                    />
                  </ListItem>
                ))}
            </List>
          </Box>
        )}
      </TaskSection>
    );
  };

  if (message.type === 'user') {
    return (
      <UserMessage elevation={1}>
        <CardContent>
          <Typography>{message.text}</Typography>
        </CardContent>
      </UserMessage>
    );
  }

  return (
    <BotMessage elevation={1}>
      <CardContent>
        {message.text && (
          <Typography sx={{ mb: 2 }}>{message.text}</Typography>
        )}
        {message.insights && message.insights.map((insight, index) => (
          <Box key={index}>
            {insight.includes('blocker:') ? (
              <Box sx={{ 
                bgcolor: 'error.light', 
                p: 2, 
                borderRadius: 1,
                mb: 2 
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <BugReportIcon color="error" sx={{ mr: 1 }} />
                  <Typography color="error.dark">
                    {insight.split('blocker:')[1].split('reported_date:')[0]}
                  </Typography>
                </Box>
                <Box sx={{ ml: 4 }}>
                  <Typography variant="body2" color="error.dark">
                    Reported: {insight.split('reported_date:')[1].split('talent_name:')[0]}
                  </Typography>
                  <Typography variant="body2" color="error.dark">
                    Talent: {insight.split('talent_name:')[1]}
                  </Typography>
                </Box>
              </Box>
            ) : (
              renderTaskSection(insight)
            )}
          </Box>
        ))}
        {message.jiraStories && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              Results submitted Successfully:
            </Typography>
            <List dense>
              {message.jiraStories.map((story, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <DescriptionIcon color="success" />
                  </ListItemIcon>
                  <ListItemText primary={story} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </CardContent>
    </BotMessage>
  );
};

const PodAutomation = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [question, setQuestion] = useState('');
  const [podInsights, setPodInsights] = useState(null);
  const [jiraResponse, setJiraResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const theme = useTheme();
  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await handleFileUpload(file);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  // S3 Configuration
  const S3Config = {
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  };

  const s3 = new AWS.S3(S3Config);

  const handleFileUpload = async (file) => {
    setIsUploading(true);
    setErrorMessage(null);

    try {
      if (!user?.data[0]?.emp_id) {
        throw new Error('Manager ID not found');
      }

      // Initialize S3
      const s3 = new AWS.S3({
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESSKEYID,
        secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
        signatureVersion: 'v4'
      });

      // Construct the file path using manager_id
      const managerId = user.data[0].emp_id;
      const key = `${managerId}/${Date.now()}-${file.name}`;

      const uploadParams = {
        Bucket: process.env.REACT_APP_BUCKETNAME,
        Key: key,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read'
      };

      console.log('Starting S3 upload to path:', key);
      const s3UploadResult = await s3.upload(uploadParams).promise();
      console.log('S3 upload successful:', s3UploadResult);

      // Call your API with the upload result
      const apiResponse = await axios.post('https://q0aippwzde.execute-api.us-east-1.amazonaws.com/prod/org_file_upload', {
        manager_id: managerId,
        file_name: file.name,
        org_code: "CLIENT#c4a7530851b09f06caef227637a2fbcd",
        file_location: s3UploadResult.Location
      }, { 
        headers: { 
          Authorization: token 
        }
      });

      setMessages(prev => [
        ...prev,
        { type: 'user', text: `Uploaded file: ${file.name}` },
        { type: 'bot', text: 'File uploaded successfully!' }
      ]);
    } catch (error) {
      console.error('Upload error:', error);
      setErrorMessage('Error uploading file: ' + (error.response?.data?.message || error.message));
    } finally {
      setIsUploading(false);
    }
  };

  const handleSendClick = async () => {
    if (!question.trim()) return;
    
    setIsLoading(true);
    setErrorMessage(null);
    setPodInsights(null);
    setJiraResponse(null);

    try {
      const response = await axios.post('https://agents.globl.ai/prod/pod_automation', {
        manager_id: user?.data[0]?.emp_id,
        query: question,
      });
      const responseData = response?.data?.pod_insights;
      setPodInsights(responseData);

      if (responseData) {
        const insights = responseData.split('\n • ').slice(1);
        setMessages(prev => [
          ...prev,
          { type: 'user', text: question },
          { type: 'bot', insights: insights }
        ]);
        setQuestion('');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('Error fetching data: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateJiraStories = async () => {
    if (!podInsights) return;

    setIsSubmitting(true);
    setErrorMessage(null);

    try {
      const response = await axios.post('https://agents.globl.ai/prod/creating_jira_story', {
        manager_id: user?.data[0]?.emp_id,
        pod_insights: podInsights 
      });
      setJiraResponse(response.data.pod_automation);
      
      setMessages(prev => [
        ...prev,
        { type: 'bot', jiraStories: response.data.pod_automation }
      ]);
    } catch (error) {
      console.error('Error creating Jira stories:', error);
      setErrorMessage('Error creating Jira stories: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendClick();
    }
  };

  return (
    <Container maxWidth="lg" sx={{ height: '90vh', py: 3, marginTop: '5rem' }}>
      <Paper elevation={3} sx={{ 
        height: '100%', 
        overflow: 'hidden', 
        display: 'flex', 
        flexDirection: 'column' ,
        bgcolor: theme => theme.palette.mode === 'dark' 
        ? alpha(theme.palette.background.paper, 0.8)
        : theme.palette.background.paper
    }}>
        <Box sx={{ 
          p: 2, 
          borderBottom: 1, 
          borderColor: 'divider', 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center' 
        }}>
          <Typography variant="h5" fontWeight="bold">
            Pod Automation
          </Typography>
          <Box sx={{ display: 'flex' }}>
            {/* Add the export button */}
            <ExportOptions 
              messages={messages} 
              disabled={isLoading || isUploading || isSubmitting}
            />
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              disabled={isUploading}
              sx={{ ml: 2 }}
            >
              {isUploading ? 'Uploading...' : 'Upload File'}
              <VisuallyHiddenInput 
                type="file" 
                onChange={handleFileChange}
                ref={fileInputRef}
                accept=".csv,.xlsx,.xls"
              />
            </Button>
          </Box>
        </Box>

        <MessageContainer>
          {messages.map((message, index) => (
            <Message key={index} message={message} />
          ))}
          {(isLoading || isUploading) && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>
          )}
        </MessageContainer>

        <Box sx={{ 
          p: 2, 
          borderTop: 1, 
          borderColor: 'divider', 
          backgroundColor: theme => theme.palette.mode === 'dark'
          ? alpha(theme.palette.background.paper, 0.9)
          : theme.palette.background.paper
        }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              fullWidth
              multiline
              maxRows={4}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Enter your question here"
              variant="outlined"
              disabled={isLoading || isSubmitting}
              sx={{
                '& .MuiOutlinedInput-root': {
                  bgcolor: theme => theme.palette.mode === 'dark'
                    ? alpha(theme.palette.background.paper, 0.3)
                    : theme.palette.background.paper
                }
              }}
            />
            <Button
              variant="contained"
              onClick={handleSendClick}
              disabled={isLoading || isSubmitting || !question.trim()}
              endIcon={<SendIcon />}
            >
              Send
            </Button>
          </Box>
          
          {podInsights && !jiraResponse && (
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={handleCreateJiraStories}
              disabled={isSubmitting}
              sx={{ mt: 2 }}
            >
              {isSubmitting ? (
                <>
                  <CircularProgress size={20} sx={{ mr: 1 }} color="inherit" />
                  Submitting...
                </>
              ) : 'Submit the results'}
            </Button>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default PodAutomation;