import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import './BarGraph.css';
import { useAuthToken } from "../TokenContext";
const PRCountTalent = () => {
  const [metrics, setMetrics] = useState(null);
  const chartRefs = useRef({});
  const isMounted = useRef(true); // Use a ref to track component mount status
  const token = useAuthToken();


  useEffect(() => {
    Chart.register(...registerables);
    fetchMetrics();

    return () => {
      isMounted.current = false; // Cleanup function to update the ref on component unmount
    };
  }, [token]);

  const fetchMetrics = async () => {
    const requestBody = {
      github_username: "krishnareddy97",
      repository_name: "testing",
      github_access_token: "",
    };

    try {
      //https://dzxo7aosv6.execute-api.us-east-1.amazonaws.com/dev/githubcicdpullrequestbytalent
      const response = await fetch(process.env.REACT_APP_TALENT_GITHUBCCIDPULLREQUESTBYTALENT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : token,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      if (isMounted.current) {
        setMetrics(data.body);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
    }
  };

  useEffect(() => {
    if (metrics) {
      const parsedMetrics = JSON.parse(metrics);
      const { approved_pull_request_count, submitted_pull_request_count } = parsedMetrics;
      displayChart('completed', 'PR', [approved_pull_request_count, submitted_pull_request_count]);
    }

    return () => {
      if (chartRefs.current.completed) {
        chartRefs.current.completed.destroy(); // Destroy the chart instance in the cleanup function
      }
    };
  }, [metrics]);

  const displayChart = (chartId, chartLabel, chartData) => {
    if (chartRefs.current[chartId]) {
      chartRefs.current[chartId].destroy(); // Destroy existing chart instance with the same ID
    }

    const canvasId = generateUniqueID(); // Generate a unique ID for the canvas element
    const chartWrapper = document.getElementById(chartId).parentElement;
    chartWrapper.innerHTML = `<canvas id="${canvasId}" width="150" height="150"></canvas>`; // Create a new canvas element

    const ctx = document.getElementById(canvasId);

    chartRefs.current[chartId] = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Approved Pull Requests', 'Submitted Pull Requests',],
        datasets: [
          {
            label: chartLabel,
            data: chartData,
            backgroundColor: ['yellow', 'grey',],
            borderWidth: 1,
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            labels: {
              generateLabels: () => [] // Return an empty array to hide the labels on the outside of the pie
            }
          }
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10
          }
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const dataIndex = tooltipItem.dataIndex;
              const datasetIndex = tooltipItem.datasetIndex;
              const value = data.datasets[datasetIndex].data[dataIndex];
              const label = data.labels[dataIndex];
              return `${label}: ${value}`;
            }
          }
        }
      }
    });
  };

  const generateUniqueID = () => {
    return `canvas-${Math.random().toString(36).substr(2, 9)}`;
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">Pull Request</h2>
        <br/>
        <div className="chart-wrapper">
          <canvas id="completed" width="150" height="150"></canvas>
        </div>
      </div>
    </div>
  );
};

export default PRCountTalent;
