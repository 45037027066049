import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Breadcrumbs,

  Paper,
  Card,
  CardContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  Alert,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Avatar,
  Tooltip,
  CircularProgress,
  Divider,
  useTheme,
  alpha,
  Fade,
  Grid,
  
  InputAdornment,
  OutlinedInput,
  Popover
} from '@mui/material';

import { 
  ArrowBack as ArrowBackIcon 
} from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import CommentCell from './CommentCell';
import { formatDistanceToNow } from 'date-fns';
import {
  Close as CloseIcon,
  CloudUpload as CloudUploadIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Search as SearchIcon,
  VideoLibrary as VideoIcon,
  Assignment as AssignmentIcon,
  Dashboard as DashboardIcon,
  FilterList as FilterListIcon,
  CalendarToday as CalendarIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import ReactSelect from 'react-select';
import moment from 'moment';
import axios from 'axios';
import AWS from 'aws-sdk';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
import { useLocation ,Link} from 'react-router-dom';



// AWS Configuration
AWS.config.update({
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
});

const s3 = new AWS.S3();

const MainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 1200,
  margin: '0 auto',
  padding: theme.spacing(4),
  background: theme.palette.mode === 'dark'
    ? alpha(theme.palette.background.paper, 0.15)
    : alpha(theme.palette.background.paper, 0.8),
  borderRadius: theme.shape.borderRadius * 2,
  backdropFilter: 'blur(8px)',
  border: theme.palette.mode === 'dark'
    ? `1px solid ${alpha(theme.palette.common.white, 0.1)}`
    : 'none',
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark'
    ? alpha(theme.palette.background.paper, 0.2)
    : theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(4),
  border: theme.palette.mode === 'dark'
    ? `1px solid ${alpha(theme.palette.common.white, 0.1)}`
    : 'none',
  '& .MuiTab-root': {
    minHeight: 48,
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(0, 1),
    textTransform: 'none',
    fontWeight: 600,
    transition: 'all 0.3s ease',
    color: theme.palette.mode === 'dark' 
      ? theme.palette.text.secondary
      : theme.palette.text.primary,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? alpha(theme.palette.primary.main, 0.2)
        : alpha(theme.palette.primary.main, 0.1),
      color: theme.palette.mode === 'dark'
        ? theme.palette.primary.light
        : theme.palette.primary.main,
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const FilterPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  flexWrap: 'wrap',
  backgroundColor: theme.palette.mode === 'dark'
    ? alpha(theme.palette.background.paper, 0.2)
    : theme.palette.background.paper,
  border: theme.palette.mode === 'dark'
    ? `1px solid ${alpha(theme.palette.common.white, 0.1)}`
    : 'none',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  backgroundColor: theme.palette.mode === 'dark'
    ? alpha(theme.palette.background.paper, 0.2)
    : theme.palette.background.paper,
  border: theme.palette.mode === 'dark'
    ? `1px solid ${alpha(theme.palette.common.white, 0.1)}`
    : 'none',
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.primary.main, 0.2)
      : theme.palette.primary.main,
    color: theme.palette.mode === 'dark'
      ? theme.palette.primary.light
      : theme.palette.common.white,
    fontWeight: 600,
    fontSize: '0.95rem',
  },
  '& .MuiTableCell-body': {
    color: theme.palette.mode === 'dark'
      ? theme.palette.text.primary
      : 'inherit',
  },
  '& .MuiTableRow-root': {
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? alpha(theme.palette.primary.main, 0.1)
        : alpha(theme.palette.primary.light, 0.1),
    },
  },
}));

const StatusChip = styled(Chip)(({ status, theme }) => ({
  '&.blocked': {
    backgroundColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.error.main, 0.2)
      : alpha(theme.palette.error.main, 0.1),
    color: theme.palette.mode === 'dark'
      ? theme.palette.error.light
      : theme.palette.error.main,
    borderColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.error.main, 0.5)
      : theme.palette.error.main,
  },
  '&.onTrack': {
    backgroundColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.success.main, 0.2)
      : alpha(theme.palette.success.main, 0.1),
    color: theme.palette.mode === 'dark'
      ? theme.palette.success.light
      : theme.palette.success.main,
    borderColor: theme.palette.mode === 'dark'
      ? alpha(theme.palette.success.main, 0.5)
      : theme.palette.success.main,
  },
}));

const PodActivityTalent = () => {
  const theme = useTheme();
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
    const intl = useIntl();
  
  // State
  const [tabValue, setTabValue] = useState(0);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [jiraIssues, setJiraIssues] = useState([]);
  const [editDialog, setEditDialog] = useState(false);
  const [editFormData, setEditFormData] = useState(null);

  const location=useLocation();
  const{podid}=location.state;
  
const isDark = theme.palette.mode === 'dark';

const customStyles = {
  control: (base) => ({
    ...base,
    minHeight: 56,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
    borderColor: theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.2) : theme.palette.divider,
    '&:hover': {
      borderColor: theme.palette.primary.main
    }
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.4)',
    border: `1px solid ${theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.1) : theme.palette.divider}`,
    zIndex: 9999
  }),
  option: (base, state) => ({
    ...base,
    padding: '8px 12px',
    backgroundColor: state.isSelected 
      ? theme.palette.primary.main 
      : state.isFocused 
        ? (theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.1) : alpha(theme.palette.primary.main, 0.1))
        : 'transparent',
    color: state.isSelected 
      ? '#ffffff' 
      : theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: state.isSelected
        ? theme.palette.primary.main
        : theme.palette.mode === 'dark' ? alpha(theme.palette.common.white, 0.1) : alpha(theme.palette.primary.main, 0.1)
    }
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' 
      ? alpha(theme.palette.common.white, 0.1) 
      : alpha(theme.palette.primary.main, 0.1),
    borderRadius: theme.shape.borderRadius / 2
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary,
    padding: '2px 6px'
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[600],
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' 
        ? alpha(theme.palette.common.white, 0.15) 
        : alpha(theme.palette.primary.main, 0.15),
      color: theme.palette.error.main
    }
  }),
  input: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary
  }),
  placeholder: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? theme.palette.grey[500] : theme.palette.text.secondary
  }),
  noOptionsMessage: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary
  })
};

  // Filter states
  const [searchTerm, setSearchTerm] = useState('');
  
  const [statusFilter, setStatusFilter] = useState('all');
  const [talentFilter, setTalentFilter] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: '',
    end: ''
  });
  // Form state
  const [formData, setFormData] = useState({
    talent_id: user?.data[0]?.c_id || '',
    pod_candidates: user?.data[0]?.firstName || '',
    categories: '',
    today_focus: '',
    blockers: '',
    podID: podid,
    loom_url: '',
    accomplishments: '',
    jira_issue: [],
    videoFile: null,
    source_language: 'en'
  });

  // Fetch initial data
  useEffect(() => {
    fetchActivities();
    fetchJiraIssues();
  }, []);

  // API Functions
  const fetchActivities = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_TALENT_PODACTIVITYDASHBORDTALENT,
        {
          talent_id: user.data[0].c_id,
          status: "view_all"
        },
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.data?.data?.data) {
        // Make sure each activity has a comments field, even if it's empty
        const activitiesWithComments = response.data.data.data.map(activity => ({
          ...activity,
          comments: activity.comments || '[]'
        }));
        setActivities(activitiesWithComments);
      }
    } catch (error) {
      showSnackbar('Failed to load activities', 'error');
    } finally {
      setLoading(false);
    }
  };
  const handleCommentAdded = (activityId, newComments) => {
    setActivities(prevActivities => 
      prevActivities.map(activity => 
        activity.id === activityId 
          ? { ...activity, comments: newComments }
          : activity
      )
    );
  };
  const fetchJiraIssues = async () => {
    try {
      const response = await axios.post(
        'https://q0aippwzde.execute-api.us-east-1.amazonaws.com/prod/jira_issues_list_manager',
        {
          pod_id: podid,
          c_id: user.data[0].c_id
        },
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.data.statusCode === 200 && response.data.body) {
        const parsedBody = response.data.body;
        if (Array.isArray(parsedBody.jiraIssues)) {
          setJiraIssues(parsedBody.jiraIssues.map(issue => ({
            value: issue.issue_key,
            label: `${issue.issue_key}: ${issue.issue_summary}`,
            issue_url: issue.issue_url // Add the issue URL
          })));
        }
      }
    } catch (error) {
      showSnackbar('Failed to load Jira issues', 'error');
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
  
      // Handle both array and string formats of jira_issue
      const selectedIssues = Array.isArray(formData.jira_issue) 
        ? formData.jira_issue 
        : formData.jira_issue.split(',').map(issue => issue.trim());
  
      // Create combined key:url strings
      const formattedIssues = selectedIssues.map(issue => {
        // If it's already an object with value and issue_url
        if (typeof issue === 'object' && issue.value) {
          return `${issue.value}:${issue.issue_url || ''}`;
        }
        
        // Find the matching issue from jiraIssues state
        const matchingIssue = jiraIssues.find(ji => ji.value === issue);
        const issueUrl = matchingIssue?.issue_url || `https://your-jira-domain.com/browse/${issue}`;
        return `${issue}:${issueUrl}`;
      }).join(',');
  
      const payload = {
        talent_id: user?.data[0]?.c_id || '',
        pod_candidates: user?.data[0]?.firstName || '',
        categories: formData.categories,
        today_focus: formData.today_focus,
        blockers: formData.blockers,
        podID: podid,
        loom_url: formData.loom_url,
        accomplishments: formData.accomplishments,
        jira_issue: formattedIssues, // Combined key:url format
        videoFile: formData.videoFile,
        source_language: formData.source_language,
        blockercheck: formData.blockers ? 1 : 0
      };
  
      const response = await axios.post(
        process.env.REACT_APP_TALENT_PODACTIVITYTALENT,
        payload,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.data.status === 200) {
        showSnackbar('Activity posted successfully', 'success');
        fetchActivities();
        resetForm();
      } else {
        showSnackbar(response.data.data || 'Failed to post activity', 'error');
      }
    } catch (error) {
      console.error('Submit error:', error);
      showSnackbar('Failed to post activity', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Continued in next section...
  // Video Upload Handlers
  const uploadFileToS3 = async (file) => {
    const params = {
      Bucket: process.env.REACT_APP_VIDEOURLBUCKETNAME,
      Key: file.name.replace(/\s/g, ''),
      Body: file,
      ContentType: "video/mp4"
    };

    try {
      const uploadResult = await s3.upload(params).promise();
      return uploadResult.Key;
    } catch (error) {
      throw new Error('Failed to upload file to S3');
    }
  };

  const handleVideoUpload = async () => {
    if (!formData.videoFile) {
      showSnackbar('Please select a video file', 'error');
      return;
    }

    try {
      setUploadLoading(true);
      const fileName = await uploadFileToS3(formData.videoFile);
      const loomUrl = `https://${process.env.REACT_APP_VIDEOURLBUCKETNAME}.s3.amazonaws.com/${fileName}`;

      const transcribePayload = {
        file_name: fileName,
        created_by: user.data[0].c_id,
        pod_id: podid,
        source_language: formData.source_language,
        target_language: "en",
        posted_to: '',
        loom_url: loomUrl,
        categories: formData.categories,
        pod_candidates: user.data[0].firstName
      };

      const response = await axios.post(
        process.env.REACT_APP_TALENT_TRANSCRIBEPOC,
        transcribePayload,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.statusCode === 200) {
        setFormData(prev => ({ ...prev, loom_url: loomUrl }));
        showSnackbar('Video uploaded successfully', 'success');
        setUploadDialog(false);
      }
    } catch (error) {
      showSnackbar('Failed to upload video', 'error');
    } finally {
      setUploadLoading(false);
    }
  };

  const handlePlayVideo = async (loomUrl) => {
    try {
      const filename = loomUrl.split('/').pop();
      const cloudFrontUrl = `${process.env.REACT_APP_CLOUDFRONTs3}${filename}`;

      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { url: cloudFrontUrl }
      );

      const signedUrl = JSON.parse(response.data.body).signedUrl;
      if (signedUrl) {
        window.open(signedUrl, '_blank');
      }
    } catch (error) {
      showSnackbar('Error accessing video', 'error');
    }
  };

  // Filter Functions
  const getFilteredActivities = () => {
    return activities.filter(activity => {
      const matchesSearch = 
        activity.today_focus?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        activity.pod_candidates?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        activity.jira_issue?.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesStatus = 
        statusFilter === 'all' || 
        (statusFilter === 'blocked' ? !!activity.blockers : !activity.blockers);

      // Updated talent filtering logic for multiple selections
      const matchesTalent = 
        talentFilter.length === 0 || 
        talentFilter.some(talent => talent.value === activity.pod_candidates);

      const activityDate = moment(activity.createdAt);
      const matchesDate = 
        (!dateRange.start || activityDate.isSameOrAfter(moment(dateRange.start), 'day')) &&
        (!dateRange.end || activityDate.isSameOrBefore(moment(dateRange.end), 'day'));

      return matchesSearch && matchesStatus && matchesTalent && matchesDate;
    });
  };

  // Helper Functions
  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const resetForm = () => {
    setFormData({
      talent_id: user?.data[0]?.c_id || '',
      pod_candidates: user?.data[0]?.firstName || '',
      categories: '',
      today_focus: '',
      blockers: '',
      podID: '',
      loom_url: '',
      accomplishments: '',
      jira_issue: [],
      videoFile: null,
      source_language: 'en'
    });
  };
  const getUniqueTalents = () => {
    const uniqueTalents = new Set();
    activities.forEach(activity => {
      if (activity.pod_candidates) {
        uniqueTalents.add(activity.pod_candidates);
      }
    });
    return Array.from(uniqueTalents)
      .sort()
      .map(talent => ({
        value: talent,
        label: talent
      }));
  };

  // Render Functions
  const renderFilters = () => (
   
      <FilterPaper elevation={0}>
        <TextField
          placeholder="Search..."
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ minWidth: 200 }}
        />
  

      {/* <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Start Date"
          value={dateRange.start}
          onChange={(date) => setDateRange(prev => ({ ...prev, start: date }))}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="End Date"
          value={dateRange.end}
          onChange={(date) => setDateRange(prev => ({ ...prev, end: date }))}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider> */}
      <TextField
        type="date"
        label="Start Date"
        value={dateRange.start}
        onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
        InputLabelProps={{ shrink: true }}
        sx={{ minWidth: 150 }}
      />

      <TextField
        type="date"
        label="End Date"
        value={dateRange.end}
        onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
        InputLabelProps={{ shrink: true }}
        sx={{ minWidth: 150 }}
      />

      <FormControl sx={{ minWidth: 150 }}>
        <InputLabel>Status</InputLabel>
        <Select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          label="Status"
        >
          <MenuItem value="all">All Status</MenuItem>
          <MenuItem value="blocked">Blocked</MenuItem>
          <MenuItem value="onTrack">On Track</MenuItem>
        </Select>
      </FormControl>

    
      <Box sx={{ minWidth: 200 }}>
        <ReactSelect
          isMulti
          value={talentFilter}
          options={getUniqueTalents()}
          onChange={(selected) => setTalentFilter(selected || [])}
          placeholder="Select Talents"
          formatOptionLabel={({ value, label }) => (
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar sx={{ width: 24, height: 24, fontSize: '0.75rem' }}>
                {label[0]}
              </Avatar>
              <Typography variant="body2">{label}</Typography>
            </Stack>
          )}
          styles={customStyles}
        />
      </Box>
    
    </FilterPaper>
  );


    // Handle edit submission
   
    // Helper function to format Jira issues
const formatJiraIssues = (jiraIssueData) => {
  try {
    // If it's already in the correct format, return as is
    if (Array.isArray(jiraIssueData) && jiraIssueData.every(issue => issue.value && issue.label)) {
      return jiraIssueData;
    }

    // If it's a string, split and format
    if (typeof jiraIssueData === 'string' && jiraIssueData.length > 0) {
      return jiraIssueData.split(',').map(issue => ({
        value: issue.trim(),
        label: issue.trim()
      }));
    }

    // If it's an array but not in the correct format
    if (Array.isArray(jiraIssueData)) {
      return jiraIssueData.map(issue => {
        if (typeof issue === 'string') {
          return {
            value: issue.trim(),
            label: issue.trim()
          };
        }
        return issue;
      });
    }

    // Return empty array if no valid input
    return [];
  } catch (error) {
    console.error('Error formatting Jira issues:', error);
    return [];
  }
};
    // Update the renderViewDialog function
const renderViewDialog = () => (
  <Dialog
    open={viewDialog}
    onClose={() => setViewDialog(false)}
    maxWidth="md"
    fullWidth
    PaperProps={{
      sx: { borderRadius: theme.shape.borderRadius * 2 }
    }}
  >
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Activity Details</Typography>
        <Stack direction="row" spacing={1}>
          <IconButton
            onClick={() => {
              setEditFormData({
                ...selectedActivity,
                jira_issue: formatJiraIssues(selectedActivity.jira_issue)
              });
              setViewDialog(false);
              setEditDialog(true);
            }}
            color="primary"
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => setViewDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
    </DialogTitle>
    <DialogContent dividers>
      {selectedActivity && (
        <Stack spacing={3}>
          <Box>
            <Typography variant="subtitle2" color="textSecondary">Date</Typography>
            <Typography>{moment(selectedActivity.createdAt).format('MMMM DD, YYYY')}</Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="textSecondary">Talent</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar>{selectedActivity.pod_candidates?.[0]}</Avatar>
              <Typography>{selectedActivity.pod_candidates}</Typography>
            </Stack>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="textSecondary">Jira Issues</Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 1 }}>
              {formatJiraIssues(selectedActivity.jira_issue).map((issue, index) => (
                <Chip
                  key={index}
                  label={issue.value || issue.label || issue}
                  variant="outlined"
                  size="small"
                />
              ))}
            </Stack>
          </Box>

          <Box>
            <Typography variant="subtitle2" color="textSecondary">Today's Focus</Typography>
            <Typography sx={{ whiteSpace: 'pre-wrap' }}>{selectedActivity.today_focus}</Typography>
          </Box>

          {selectedActivity.accomplishments && (
            <Box>
              <Typography variant="subtitle2" color="textSecondary">Accomplishments</Typography>
              <Typography sx={{ whiteSpace: 'pre-wrap' }}>{selectedActivity.accomplishments}</Typography>
            </Box>
          )}

          {selectedActivity.blockers && (
            <Box>
              <Typography variant="subtitle2" color="textSecondary">Blockers</Typography>
              <Typography sx={{ whiteSpace: 'pre-wrap' }}>{selectedActivity.blockers}</Typography>
            </Box>
          )}

          {selectedActivity.loom_url && (
            <Box>
              <Typography variant="subtitle2" color="textSecondary">Video Update</Typography>
              <Button
                startIcon={<VideoIcon />}
                variant="outlined"
                onClick={() => handlePlayVideo(selectedActivity.loom_url)}
                sx={{ mt: 1 }}
              >
                View Video
              </Button>
            </Box>
          )}
             <Box>
            <Typography variant="subtitle2" color="textSecondary">Comments</Typography>
            <Box mt={1}>
              <CommentCell 
                comments={selectedActivity.comments || '[]'}
                activity_id={selectedActivity.id}
                onCommentAdded={(id, newComments) => {
                  // Update the selected activity with new comments
                  setSelectedActivity(prev => ({
                    ...prev,
                    comments: newComments
                  }));
                  // Also update in the main activities list
                  handleCommentAdded(id, newComments);
                }}
              />
            </Box>
          </Box>
        </Stack>
      )}
    </DialogContent>
  </Dialog>
);
    


// Update the handleOpenEdit function
const handleOpenEdit = (activity) => {
  if (!activity || !activity.id) {
    console.error('Invalid activity data:', activity);
    showSnackbar('Invalid activity data', 'error');
    return;
  }

  // First fetch Jira issues to ensure we have the latest data
  fetchJiraIssues().then(() => {
    try {
      // Format the Jira issues with proper error handling
      const formattedJiraIssues = formatJiraIssues(activity.jira_issue);

      // Map to full Jira issue data if available
      const jiraIssueArray = formattedJiraIssues.map(issue => {
        const matchingIssue = jiraIssues.find(ji => ji.value === issue.value);
        return matchingIssue || issue;
      });

      // Set the edit form data
      setEditFormData({
        id: activity.id,
        // talent_id: activity.talent_id || user?.data[0]?.c_id,
        // pod_candidates: activity.pod_candidates || user?.data[0]?.firstName,
        jira_issue: jiraIssueArray,
        today_focus: activity.today_focus || '',
        accomplishments: activity.accomplishments || '',
        blockers: activity.blockers || '',
        // podID: activity.podID || '',
        loom_url: activity.loom_url || '',
        categories: activity.categories || '',
        // createdAt: activity.createdAt
      });
      
      setEditDialog(true);
    } catch (error) {
      console.error('Error processing activity data:', error);
      showSnackbar('Error processing activity data', 'error');
    }
  }).catch(error => {
    console.error('Error fetching Jira issues:', error);
    showSnackbar('Failed to load Jira issues', 'error');
  });
};

// Update handleEditSubmit to use the same formatting
const handleEditSubmit = async (e) => {
  e.preventDefault();
  
  try {
    setLoading(true);
    
    // Safely handle jira_issue formatting with null checks
    let formattedIssues = '';
    
    if (editFormData.jira_issue) {
      // Handle different possible formats of jira_issue
      const issueArray = Array.isArray(editFormData.jira_issue) 
        ? editFormData.jira_issue 
        : typeof editFormData.jira_issue === 'string'
          ? editFormData.jira_issue.split(',')
          : [];

      formattedIssues = issueArray
        .filter(issue => issue) // Remove any null/undefined values
        .map(issue => {
          if (typeof issue === 'object' && issue.value) {
            return `${issue.value}:${issue.issue_url || ''}`;
          }
          // Handle string case
          const issueKey = typeof issue === 'string' ? issue.trim() : issue;
          const matchingIssue = jiraIssues.find(ji => ji.value === issueKey);
          const issueUrl = matchingIssue?.issue_url || `https://your-jira-domain.com/browse/${issueKey}`;
          return `${issueKey}:${issueUrl}`;
        })
        .join(',');
    }

    // Format the payload
    const payload = {
      id: editFormData.id,
      talent_id: user?.data[0]?.c_id,
      pod_candidates: user?.data[0]?.firstName,
      jira_issue: formattedIssues,
      today_focus: editFormData.today_focus,
      accomplishments: editFormData.accomplishments || '',
      blockers: editFormData.blockers || '',
      blockercheck: editFormData.blockers ? 1 : 0,
      podID: editFormData.podID || '',
      loom_url: editFormData.loom_url || '',
      categories: editFormData.categories || '',
      status: 'update'
    };

    console.log('Edit payload:', payload); // For debugging

    const response = await axios({
      method: 'post',
      url: process.env.REACT_APP_TALENT_PODACTIVITYUPDATETALENT,
      data: payload,
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      }
    });

    if (response.data.status === 200) {
      showSnackbar('Activity updated successfully', 'success');
      await fetchActivities();
      setEditDialog(false);
      setEditFormData(null);
    } else {
      throw new Error(response.data.message || 'Failed to update activity');
    }
  } catch (error) {
    console.error('Edit submission error:', error);
    showSnackbar(error.response?.data?.message || error.message || 'Failed to update activity', 'error');
  } finally {
    setLoading(false);
  }
};
// Make sure the edit form correctly binds all fields
const renderEditDialog = () => (
  <Dialog
    open={editDialog}
    onClose={() => setEditDialog(false)}
    maxWidth="md"
    fullWidth
    PaperProps={{
      sx: { borderRadius: theme.shape.borderRadius * 2 }
    }}
  >
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Edit Activity</Typography>
        <IconButton onClick={() => setEditDialog(false)}>
          <CloseIcon />
        </IconButton>
      </Stack>
    </DialogTitle>
    <form onSubmit={handleEditSubmit}>
      <DialogContent dividers>
        {editFormData && (
          <Stack spacing={3}>
         <FormControl fullWidth>
  <ReactSelect
    isMulti
    value={editFormData.jira_issue}
    options={jiraIssues}
    onChange={(selected) => setEditFormData(prev => ({ 
      ...prev, 
      jira_issue: selected || [] 
    }))}
    placeholder="Select Jira Issues"
    styles={customStyles}
  />
</FormControl>

            <TextField
              label="Today's Focus"
              multiline
              rows={4}
              value={editFormData.today_focus || ''}
              onChange={(e) => setEditFormData(prev => ({ 
                ...prev, 
                today_focus: e.target.value 
              }))}
              required
            />

            <TextField
              label="Accomplishments"
              multiline
              rows={4}
              value={editFormData.accomplishments || ''}
              onChange={(e) => setEditFormData(prev => ({ 
                ...prev, 
                accomplishments: e.target.value 
              }))}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={!!editFormData.blockers}
                  onChange={(e) => setEditFormData(prev => ({ 
                    ...prev, 
                    blockers: e.target.checked ? prev.blockers || '' : null 
                  }))}
                />
              }
              label="Add Blocker Information"
            />

            {editFormData.blockers !== null && (
              <TextField
                label="Blocker Details"
                multiline
                rows={3}
                value={editFormData.blockers || ''}
                onChange={(e) => setEditFormData(prev => ({ 
                  ...prev, 
                  blockers: e.target.value 
                }))}
              />
            )}
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={() => setEditDialog(false)}>Cancel</Button>
        <Button
          type="submit"
          variant="contained"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
        >
          Save Changes
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);
const renderJiraIssues = (jiraIssueString) => {
  if (!jiraIssueString) return [];

  // Helper function to parse key:url format
  const parseIssueString = (issueStr) => {
    // Find the first occurrence of ':' to split key and url
    const firstColonIndex = issueStr.indexOf(':');
    if (firstColonIndex === -1) return { key: issueStr.trim(), url: '' };
    
    const key = issueStr.substring(0, firstColonIndex).trim();
    const url = issueStr.substring(firstColonIndex + 1).trim();
    
    return { key, url };
  };

  // Handle different input formats
  const issues = typeof jiraIssueString === 'string' 
    ? jiraIssueString.split(',').map(parseIssueString)
    : Array.isArray(jiraIssueString) 
      ? jiraIssueString.map(issue => {
          if (typeof issue === 'string') {
            return parseIssueString(issue);
          }
          return {
            key: issue.value || '',
            url: issue.issue_url || ''
          };
        })
      : [];

  return issues.map((issue, index) => {
    const handleClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (issue.url) {
        // For debugging
        console.log('Opening URL:', issue.url);
        window.open(issue.url, '_blank', 'noopener,noreferrer');
      }
    };

    return (
      <Tooltip title={`Click to open ${issue.key} in Jira`} key={index}>
        <Chip
          label={issue.key}
          size="small"
          variant="outlined"
          clickable
          onClick={handleClick}
          sx={{
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: theme.palette.mode === 'dark' 
                ? alpha(theme.palette.primary.main, 0.2)
                : alpha(theme.palette.primary.main, 0.1)
            }
          }}
        />
      </Tooltip>
    );
  });
};


  const renderActivitiesTable = () => {
    const filteredActivities = getFilteredActivities();

    return (
      <StyledTableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>
      <FormattedMessage id="date" defaultMessage="Date" />
    </TableCell>
    <TableCell>
      <FormattedMessage id="talent" defaultMessage="Talent" />
    </TableCell>
    <TableCell>
      <FormattedMessage id="jiraIssues" defaultMessage="Jira Issues" />
    </TableCell>
    <TableCell>
      <FormattedMessage id="focus" defaultMessage="Focus" />
    </TableCell>
    <TableCell>
      <FormattedMessage id="status" defaultMessage="Status" />
    </TableCell>
    <TableCell>
      <FormattedMessage id="comments" defaultMessage="Comments" />
    </TableCell>
    <TableCell>
      <FormattedMessage id="actions" defaultMessage="Actions" />
    </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredActivities?.map((activity) => (
              <TableRow key={activity.id}>
                <TableCell>
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {moment(activity.createdAt).format('MMM DD, YYYY')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                      {activity.pod_candidates?.[0]}
                    </Avatar>
                    <Typography variant="body2">
                      {activity.pod_candidates}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {renderJiraIssues(activity.jira_issue)}
                </Stack>
              </TableCell>
                <TableCell>
                  <Tooltip title={activity.today_focus}>
                    <Typography
                      variant="body2"
                      sx={{
                        maxWidth: 300,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {activity.today_focus}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <StatusChip
                    size="small"
                    className={activity.blockers ? 'blocked' : 'onTrack'}
                    icon={activity.blockers ? <WarningIcon /> : <CheckCircleIcon />}
                    label={activity.blockers ? 'Blocked' : 'On Track'}
                  />
                </TableCell>
                {/* Add the new Comments cell */}
<TableCell>
  <CommentCell 
    comments={activity.comments || '[]'}
    activity_id={activity.id}
    onCommentAdded={handleCommentAdded}
  />
</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <Tooltip title="View Details">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedActivity(activity);
                          setViewDialog(true);
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
  <IconButton
    size="small"
    onClick={() => handleOpenEdit(activity)}
  >
    <EditIcon />
  </IconButton>
</Tooltip>
                    {activity.loom_url && (
                      <Tooltip title="View Video">
                        <IconButton
                          size="small"
                          onClick={() => handlePlayVideo(activity.loom_url)}
                        >
                          <VideoIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    );
  };

  // Continue with the rest of your component's JSX...
  // Render Activity Form
  const renderActivityForm = () => {
   
    
    return (
      <Card elevation={0} sx={{ borderRadius: theme.shape.borderRadius * 2 }}>
        <CardContent sx={{ p: 4 }}>
          <Typography variant="h6" gutterBottom color="primary" fontWeight={600}>
            <FormattedMessage id="newActivityUpdate" defaultMessage="New Activity Update" />
          </Typography>
          <Divider sx={{ mb: 4 }} />
          
          <form onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <FormControl fullWidth>
                <ReactSelect
                  isMulti
                  value={formData.jira_issue}
                  options={jiraIssues}
                  onChange={(selected) => setFormData(prev => ({ ...prev, jira_issue: selected }))}
                  placeholder={intl.formatMessage({ id: 'selectJiraIssues', defaultMessage: 'Select Jira Issues' })}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                  styles={customStyles}
                />
              </FormControl>
  
              <TextField
                label={intl.formatMessage({ id: 'todaysFocus', defaultMessage: "Today's Focus" })}
                multiline
                rows={4}
                value={formData.today_focus}
                onChange={(e) => setFormData(prev => ({ ...prev, today_focus: e.target.value }))}
                required
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: theme.shape.borderRadius
                  }
                }}
              />
  
              <TextField
                label={intl.formatMessage({ id: 'accomplishments', defaultMessage: 'Accomplishments' })}
                multiline
                rows={4}
                value={formData.accomplishments}
                onChange={(e) => setFormData(prev => ({ ...prev, accomplishments: e.target.value }))}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: theme.shape.borderRadius
                  }
                }}
              />
  
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!formData.blockers}
                    onChange={(e) => setFormData(prev => ({ ...prev, blockers: e.target.checked ? '' : null }))}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2" color="textSecondary">
                    <FormattedMessage id="addBlocker" defaultMessage="Add Blocker Information" />
                  </Typography>
                }
              />
  
              {formData.blockers !== null && (
                <TextField
                  label={intl.formatMessage({ id: 'blockerDetails', defaultMessage: 'Blocker Details' })}
                  multiline
                  rows={3}
                  value={formData.blockers || ''}
                  onChange={(e) => setFormData(prev => ({ ...prev, blockers: e.target.value }))}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: theme.shape.borderRadius
                    }
                  }}
                />
              )}
  
              <Button
                variant="outlined"
                startIcon={<VideoIcon />}
                onClick={() => setUploadDialog(true)}
                sx={{
                  borderRadius: theme.shape.borderRadius,
                  textTransform: 'none',
                  py: 1.5
                }}
              >
                <FormattedMessage id="addVideoUpdate" defaultMessage="Add Video Update" />
              </Button>
  
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
                sx={{
                  borderRadius: theme.shape.borderRadius,
                  textTransform: 'none',
                  py: 1.5,
                  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`
                }}
              >
                {loading ? (
                  <FormattedMessage id="posting" defaultMessage="Posting..." />
                ) : (
                  <FormattedMessage id="postActivity" defaultMessage="Post Activity" />
                )}
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    );
  };

  // Video Upload Dialog
  const renderUploadDialog = () => (
    <Dialog
      open={uploadDialog}
      onClose={() => setUploadDialog(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: { borderRadius: theme.shape.borderRadius * 2 }
      }}
    >
      <DialogTitle>
        Upload Video Update
        <IconButton
          onClick={() => setUploadDialog(false)}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={formData.categories}
              onChange={(e) => setFormData(prev => ({ ...prev, categories: e.target.value }))}
              label="Category"
            >
              <MenuItem value="Design">Design</MenuItem>
              <MenuItem value="Development">Development</MenuItem>
              <MenuItem value="Testing">Testing</MenuItem>
              <MenuItem value="Bug Fix">Bug Fix</MenuItem>
              <MenuItem value="Documentation">Documentation</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Language</InputLabel>
            <Select
              value={formData.source_language}
              onChange={(e) => setFormData(prev => ({ ...prev, source_language: e.target.value }))}
              label="Language"
            >
         <MenuItem value="ab">Abkhazian</MenuItem>
<MenuItem value="aa">Afar</MenuItem>
<MenuItem value="af">Afrikaans</MenuItem>
<MenuItem value="ak">Akan</MenuItem>
<MenuItem value="sq">Albanian</MenuItem>
<MenuItem value="am">Amharic</MenuItem>
<MenuItem value="ar">Arabic</MenuItem>
<MenuItem value="an">Aragonese</MenuItem>
<MenuItem value="hy">Armenian</MenuItem>
<MenuItem value="as">Assamese</MenuItem>
<MenuItem value="av">Avaric</MenuItem>
<MenuItem value="ae">Avestan</MenuItem>
<MenuItem value="ay">Aymara</MenuItem>
<MenuItem value="az">Azerbaijani</MenuItem>
<MenuItem value="bm">Bambara</MenuItem>
<MenuItem value="ba">Bashkir</MenuItem>
<MenuItem value="eu">Basque</MenuItem>
<MenuItem value="be">Belarusian</MenuItem>
<MenuItem value="bn">Bengali</MenuItem>
<MenuItem value="bi">Bislama</MenuItem>
<MenuItem value="bs">Bosnian</MenuItem>
<MenuItem value="br">Breton</MenuItem>
<MenuItem value="bg">Bulgarian</MenuItem>
<MenuItem value="my">Burmese</MenuItem>
<MenuItem value="ca">Catalan</MenuItem>
<MenuItem value="ch">Chamorro</MenuItem>
<MenuItem value="ce">Chechen</MenuItem>
<MenuItem value="ny">Chichewa</MenuItem>
<MenuItem value="zh">Chinese</MenuItem>
<MenuItem value="cv">Chuvash</MenuItem>
<MenuItem value="kw">Cornish</MenuItem>
<MenuItem value="co">Corsican</MenuItem>
<MenuItem value="cr">Cree</MenuItem>
<MenuItem value="hr">Croatian</MenuItem>
<MenuItem value="cs">Czech</MenuItem>
<MenuItem value="da">Danish</MenuItem>
<MenuItem value="dv">Divehi</MenuItem>
<MenuItem value="nl">Dutch</MenuItem>
<MenuItem value="dz">Dzongkha</MenuItem>
<MenuItem value="en">English</MenuItem>
<MenuItem value="eo">Esperanto</MenuItem>
<MenuItem value="et">Estonian</MenuItem>
<MenuItem value="ee">Ewe</MenuItem>
<MenuItem value="fo">Faroese</MenuItem>
<MenuItem value="fj">Fijian</MenuItem>
<MenuItem value="fi">Finnish</MenuItem>
<MenuItem value="fr">French</MenuItem>
<MenuItem value="ff">Fulah</MenuItem>
<MenuItem value="gl">Galician</MenuItem>
<MenuItem value="ka">Georgian</MenuItem>
<MenuItem value="de">German</MenuItem>
<MenuItem value="el">Greek</MenuItem>
<MenuItem value="gn">Guarani</MenuItem>
<MenuItem value="gu">Gujarati</MenuItem>
<MenuItem value="ht">Haitian</MenuItem>
<MenuItem value="ha">Hausa</MenuItem>
<MenuItem value="he">Hebrew</MenuItem>
<MenuItem value="hz">Herero</MenuItem>
<MenuItem value="hi">Hindi</MenuItem>
<MenuItem value="ho">Hiri Motu</MenuItem>
<MenuItem value="hu">Hungarian</MenuItem>
<MenuItem value="is">Icelandic</MenuItem>
<MenuItem value="io">Ido</MenuItem>
<MenuItem value="ig">Igbo</MenuItem>
<MenuItem value="id">Indonesian</MenuItem>
<MenuItem value="ia">Interlingua</MenuItem>
<MenuItem value="ie">Interlingue</MenuItem>
<MenuItem value="iu">Inuktitut</MenuItem>
<MenuItem value="ik">Inupiaq</MenuItem>
<MenuItem value="ga">Irish</MenuItem>
<MenuItem value="it">Italian</MenuItem>
<MenuItem value="ja">Japanese</MenuItem>
<MenuItem value="jv">Javanese</MenuItem>
<MenuItem value="kl">Kalaallisut</MenuItem>
<MenuItem value="kn">Kannada</MenuItem>
<MenuItem value="kr">Kanuri</MenuItem>
<MenuItem value="ks">Kashmiri</MenuItem>
<MenuItem value="kk">Kazakh</MenuItem>
<MenuItem value="km">Khmer</MenuItem>
<MenuItem value="ki">Kikuyu</MenuItem>
<MenuItem value="rw">Kinyarwanda</MenuItem>
<MenuItem value="ky">Kirghiz</MenuItem>
<MenuItem value="kv">Komi</MenuItem>
<MenuItem value="kg">Kongo</MenuItem>
<MenuItem value="ko">Korean</MenuItem>
<MenuItem value="ku">Kurdish</MenuItem>
<MenuItem value="kj">Kwanyama</MenuItem>
<MenuItem value="lo">Lao</MenuItem>
<MenuItem value="la">Latin</MenuItem>
<MenuItem value="lv">Latvian</MenuItem>
<MenuItem value="li">Limburgan</MenuItem>
<MenuItem value="ln">Lingala</MenuItem>
<MenuItem value="lt">Lithuanian</MenuItem>
<MenuItem value="lu">Luba-Katanga</MenuItem>
<MenuItem value="lg">Luganda</MenuItem>
<MenuItem value="lb">Luxembourgish</MenuItem>
<MenuItem value="mk">Macedonian</MenuItem>
<MenuItem value="mg">Malagasy</MenuItem>
<MenuItem value="ms">Malay</MenuItem>
<MenuItem value="ml">Malayalam</MenuItem>
<MenuItem value="mt">Maltese</MenuItem>
<MenuItem value="gv">Manx</MenuItem>
<MenuItem value="mi">Maori</MenuItem>
<MenuItem value="mr">Marathi</MenuItem>
<MenuItem value="mh">Marshallese</MenuItem>
<MenuItem value="mn">Mongolian</MenuItem>
<MenuItem value="na">Nauru</MenuItem>
<MenuItem value="nv">Navajo</MenuItem>
<MenuItem value="ng">Ndonga</MenuItem>
<MenuItem value="ne">Nepali</MenuItem>
<MenuItem value="nd">North Ndebele</MenuItem>
<MenuItem value="se">Northern Sami</MenuItem>
<MenuItem value="no">Norwegian</MenuItem>
<MenuItem value="nb">Norwegian Bokmål</MenuItem>
<MenuItem value="nn">Norwegian Nynorsk</MenuItem>
<MenuItem value="oc">Occitan</MenuItem>
<MenuItem value="oj">Ojibwa</MenuItem>
<MenuItem value="or">Oriya</MenuItem>
<MenuItem value="om">Oromo</MenuItem>
<MenuItem value="os">Ossetian</MenuItem>
<MenuItem value="pi">Pali</MenuItem>
<MenuItem value="ps">Pashto</MenuItem>
<MenuItem value="fa">Persian</MenuItem>
<MenuItem value="pl">Polish</MenuItem>
<MenuItem value="pt">Portuguese</MenuItem>
<MenuItem value="pa">Punjabi</MenuItem>
<MenuItem value="qu">Quechua</MenuItem>
<MenuItem value="ro">Romanian</MenuItem>
<MenuItem value="rm">Romansh</MenuItem>
<MenuItem value="rn">Rundi</MenuItem>
<MenuItem value="ru">Russian</MenuItem>
<MenuItem value="sm">Samoan</MenuItem>
<MenuItem value="sg">Sango</MenuItem>
<MenuItem value="sa">Sanskrit</MenuItem>
<MenuItem value="sc">Sardinian</MenuItem>
<MenuItem value="sr">Serbian</MenuItem>
<MenuItem value="sn">Shona</MenuItem>
<MenuItem value="sd">Sindhi</MenuItem>
<MenuItem value="si">Sinhala</MenuItem>
<MenuItem value="sk">Slovak</MenuItem>
<MenuItem value="sl">Slovenian</MenuItem>
<MenuItem value="so">Somali</MenuItem>
<MenuItem value="nr">South Ndebele</MenuItem>
<MenuItem value="st">Southern Sotho</MenuItem>
<MenuItem value="es">Spanish</MenuItem>
<MenuItem value="su">Sundanese</MenuItem>
<MenuItem value="sw">Swahili</MenuItem>
<MenuItem value="ss">Swati</MenuItem>
<MenuItem value="sv">Swedish</MenuItem>
<MenuItem value="tl">Tagalog</MenuItem>
<MenuItem value="ty">Tahitian</MenuItem>
<MenuItem value="tg">Tajik</MenuItem>
<MenuItem value="ta">Tamil</MenuItem>
<MenuItem value="tt">Tatar</MenuItem>
<MenuItem value="te">Telugu</MenuItem>
<MenuItem value="th">Thai</MenuItem>
<MenuItem value="bo">Tibetan</MenuItem>
<MenuItem value="ti">Tigrinya</MenuItem>
<MenuItem value="to">Tonga</MenuItem>
<MenuItem value="ts">Tsonga</MenuItem>
<MenuItem value="tn">Tswana</MenuItem>
<MenuItem value="tr">Turkish</MenuItem>
<MenuItem value="tk">Turkmen</MenuItem>
<MenuItem value="tw">Twi</MenuItem>
<MenuItem value="ug">Uighur</MenuItem>
<MenuItem value="uk">Ukrainian</MenuItem>
<MenuItem value="ur">Urdu</MenuItem>
<MenuItem value="uz">Uzbek</MenuItem>
<MenuItem value="ve">Venda</MenuItem>
<MenuItem value="vi">Vietnamese</MenuItem>
<MenuItem value="vo">Volapük</MenuItem>
<MenuItem value="wa">Walloon</MenuItem>
<MenuItem value="cy">Welsh</MenuItem>
<MenuItem value="fy">Western Frisian</MenuItem>
<MenuItem value="wo">Wolof</MenuItem>
<MenuItem value="xh">Xhosa</MenuItem>
<MenuItem value="yi">Yiddish</MenuItem>
<MenuItem value="yo">Yoruba</MenuItem>
<MenuItem value="za">Zhuang</MenuItem>
<MenuItem value="zu">Zulu</MenuItem>
            </Select>
          </FormControl>

          <Button
            component="label"
            variant="outlined"
            startIcon={<CloudUploadIcon />}
            sx={{
              borderRadius: theme.shape.borderRadius,
              textTransform: 'none',
              py: 1.5
            }}
          >
            Select Video File
            <input
              type="file"
              hidden
              accept="video/*"
              onChange={(e) => setFormData(prev => ({ ...prev, videoFile: e.target.files[0] }))}
            />
          </Button>

          {formData.videoFile && (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                borderRadius: theme.shape.borderRadius,
                bgcolor: alpha(theme.palette.primary.main, 0.05)
              }}
            >
              <Typography variant="body2" color="textSecondary">
                Selected file: {formData.videoFile.name}
              </Typography>
            </Paper>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          onClick={() => setUploadDialog(false)}
          sx={{ textTransform: 'none' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleVideoUpload}
          disabled={uploadLoading || !formData.videoFile}
          startIcon={uploadLoading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
          sx={{
            textTransform: 'none',
            borderRadius: theme.shape.borderRadius
          }}
        >
          {uploadLoading ? 'Uploading...' : 'Upload Video'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Main Component Return
  return (
    <MainContainer>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 700,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}
        >
          <FormattedMessage id="podActivities" defaultMessage="Pod Activities" />
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          <FormattedMessage 
            id="manageActivities" 
            defaultMessage="Manage your Daily Pod activities and Updates" 
          />
        </Typography>
      </Box>
      <Stack spacing={3}>
        <Stack 
          direction="row" 
          alignItems="center" 
          spacing={2}
          sx={{ mb: 2 }}
        >
          <Tooltip title={<FormattedMessage id="backToPods" defaultMessage="Back to My Pods" />}>
            <IconButton
              component={Link}
              to="/Pods"
              sx={{ 
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                }
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <Breadcrumbs>
            <Link
              to="/Pods"
              style={{
                color: theme.palette.primary.main,
                textDecoration: 'none',
                fontWeight: 600,
              }}
            >
              <FormattedMessage id="myPods" defaultMessage="My Pods" />
            </Link>
          </Breadcrumbs>
        </Stack>
  
        <StyledTabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(newValue)}
          variant="fullWidth"
        >
          <Tab
            icon={<AssignmentIcon />}
            label={<FormattedMessage id="postActivity" defaultMessage="Post Activity" />}
            sx={{
              minHeight: 60,
              display: 'flex',
              flexDirection: 'row',
              gap: 1
            }}
          />
          <Tab
            icon={<DashboardIcon />}
            label={<FormattedMessage id="viewActivities" defaultMessage="View Activities" />}
            sx={{
              minHeight: 60,
              display: 'flex',
              flexDirection: 'row',
              gap: 1
            }}
          />
        </StyledTabs>
      </Stack>
  
      <Fade in={true}>
        <Box>
          {tabValue === 0 ? renderActivityForm() : (
            <>
              {renderFilters()}
              {renderActivitiesTable()}
            </>
          )}
        </Box>
      </Fade>
  
      {renderUploadDialog()}
  
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          variant="filled"
          sx={{ borderRadius: theme.shape.borderRadius }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {renderViewDialog()}
      {renderEditDialog()}
    </MainContainer>
  );
};

export default PodActivityTalent;