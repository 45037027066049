import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import './BarGraph.css';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";
const PRcountgraph = (props) => {
  // Define state variables
  const [metrics, setMetrics] = useState(null);
  const chartId = useRef(uuidv4());
  const chartInstance = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();

  // Fetch metrics and initialize the chart
  useEffect((token) => {
    Chart.register(...registerables);
    if (props.podid) {
      fetchMetricsPod();
    } else {
      fetchMetrics();
    }
  }, [props.podid,token]);

  // Display the chart when metrics are available
 // Display the chart when metrics are available
useEffect(() => {
  if (metrics && metrics.total_prs && Object.values(metrics.total_prs).some(value => value > 0)) {
    displayChart();
  } else {
    setErrorMessage('No Data Available');
  }
}, [metrics]);

  // Fetch metrics data from the API for a specific pod
  const fetchMetricsPod = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_CLIENT_GITHUBPRMETRICSMANAGERPODID,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : token,
          },
          body: JSON.stringify({ pod_id: props.podid ? [props.podid] : null }),
        }
      );

      if (!response.ok) {
        // Handle non-OK responses (e.g., 404, 500) here
        if (response.status === 500) {
          setErrorMessage('Internal Server Error: Please try again later');
        } else {
          setErrorMessage('Failed to fetch data');
        }
        return;
      }

      const data = await response.json();
     
      if (data && data.body) {
        const responseJSON = JSON.parse(data.body);
        setMetrics(responseJSON);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
      setErrorMessage('Error fetching data: Please try again later');
    }
  };

  // Fetch metrics data from the API for the logged-in user
  const fetchMetrics = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_CLIENT_GITHUBPRDASHBOARDMANAGER,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : token,
          },
          body: JSON.stringify({
            manager_id: user.data[0].emp_id,
            org_code: user.data[0].client_code || null,
          }),
        }
      );

      const data = await response.json();
     
      if (response.status === 500) {
        // Handle 500 error by displaying an error message
        setErrorMessage('No Data Available');
      } else if (data && data.body) {
        const responseJSON = JSON.parse(data.body);
        setMetrics(responseJSON);
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
    }
  };

  // Display the doughnut chart
  // Display the doughnut chart
const displayChart = () => {
  if (chartId.current) {
    const ctx = document.getElementById(chartId.current);
    if (metrics && metrics.total_prs && Object.values(metrics.total_prs).some(value => value > 0)) {

      const chartData = [
        metrics.total_prs['Total Submitted PRs'],
        metrics.total_prs['Total Approved PRs'],
      ];

      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      chartInstance.current = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Submitted PRs', 'Approved PRs'],
          datasets: [
            {
              label: 'PR Count',
              data: chartData,
              backgroundColor: ['lavender', 'turquoise'],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            annotation: {},
            legend: {
              display: false,
            },
          },
          layout: {
            padding: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            },
          },
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: function (tooltipItem, chartData) {
                const dataIndex = tooltipItem.dataIndex;
                const datasetIndex = tooltipItem.datasetIndex;
                const value = chartData.datasets[datasetIndex].data[dataIndex];
                const label = chartData.labels[dataIndex];
                return `${label}: ${value}`;
              },
            },
          },
          onClick: () => {
            openModal();
          },
        },
      });
    } else {
      // No data available for the chart
      setErrorMessage('No Data Available');
    }
  }
};


  // Open the modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">PR Count</h2>
        <br />
        {errorMessage ? (
          <p>{errorMessage}</p>
        ) : (
          <div className="chart-wrapper">
            <canvas
              id={chartId.current}
              width="150"
              height="150"
            ></canvas>
          </div>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="PR Summary Modal"
        style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem',
          },
        }}
      >
        <h6>PR Summary</h6>
        {metrics && (
          <div>
            <pre>{JSON.stringify(metrics, null, 2)}</pre>
          </div>
        )}

        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default PRcountgraph;
