import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  IconButton,
  Container,
  Paper,
  InputBase,
  Button
} from '@mui/material';
import { styled,keyframes  } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import { useIntl } from 'react-intl';
import { useAuthToken } from "../TokenContext";
import { useSelector } from "react-redux";
// Main wrapper that takes full viewport height
const PageWrapper = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 64px)',
  display: 'flex',
  width: "100vw",
  flexDirection: 'column',
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  // marginTop: '64px',
  backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#f5f7fa',
  transition: 'background-color 0.3s ease',
}));

// Animation keyframes remain the same
const pulseAnimation = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
`;
// Add this with your other styled components
const ApiResponseBubble = styled(Box)(({ theme, status }) => ({
  maxWidth: '90%',
  padding: '12px 16px',
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? (status === 200 ? '#1E3A5F' : '#5F1E1E')
    : (status === 200 ? '#e3f2fd' : '#ffebee'),
  alignSelf: 'center',
  fontFamily: 'Poppins, Medium',
  fontSize: '14px',
  lineHeight: '1.6',
  marginTop: '8px',
  marginBottom: '8px',
  color: theme.palette.mode === 'dark' 
    ? '#E0E0E0' 
    : (status === 200 ? '#1565c0' : '#c62828'),
  boxShadow: theme.palette.mode === 'dark'
    ? '0 1px 2px rgba(0, 0, 0, 0.2)'
    : '0 1px 2px rgba(0, 0, 0, 0.05)',
  border: theme.palette.mode === 'dark'
    ? (status === 200 ? '1px solid #2C5282' : '1px solid #822C2C')
    : (status === 200 ? '1px solid #90caf9' : '1px solid #ef9a9a'),
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  '&::before': {
    content: '""',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: status === 200 
      ? theme.palette.mode === 'dark' ? '#4FC3F7' : '#2196f3'
      : theme.palette.mode === 'dark' ? '#f44336' : '#e57373',
  }
}));
const SendResultsButton = styled(Button)(({ theme }) => ({
  margin: '16px auto',
  backgroundColor: theme.palette.mode === 'dark' ? '#4FC3F7' : '#2196f3',
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#81D4FA' : '#1976d2',
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.mode === 'dark' ? '#404040' : '#bdc3c7',
  }
}));
const LoadingIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  padding: '12px 16px',
  maxWidth: '90%',
  alignSelf: 'flex-start',
}));

const LoadingDot = styled(Box)(({ theme }) => ({
  width: '8px',
  height: '8px',
  backgroundColor: theme.palette.mode === 'dark' ? '#4FC3F7' : '#2196f3',
  borderRadius: '50%',
  animation: `${pulseAnimation} 1.4s infinite`,
  '&:nth-of-type(2)': {
    animationDelay: '0.2s',
  },
  '&:nth-of-type(3)': {
    animationDelay: '0.4s',
  }
}));

const MainContainer = styled(Container)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  paddingTop: '2rem',
  paddingBottom: '2rem',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#ffffff',
  borderRadius: '16px',
  overflow: 'hidden',
  position: 'relative',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 20px rgba(0, 0, 0, 0.4)'
    : '0 4px 20px rgba(0, 0, 0, 0.08)',
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  padding: '24px',
  paddingBottom: '80px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#ffffff',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.mode === 'dark' ? '#2D2D2D' : '#f1f1f1',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'dark' ? '#404040' : '#c1c7cd',
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.mode === 'dark' ? '#4A4A4A' : '#a5adb5',
    },
  }
}));

const MessageBubble = styled(Box)(({ theme, isUser }) => ({
  maxWidth: '90%',
  padding: '16px 20px',
  borderRadius: '16px',
  backgroundColor: theme.palette.mode === 'dark'
    ? (isUser ? '#1E3A5F' : '#2D2D2D')
    : (isUser ? '#e3f2fd' : '#f8f9fa'),
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  fontFamily: 'Poppins, Medium',
  fontSize: '14px',
  lineHeight: '1.6',
  whiteSpace: 'pre-wrap',
  color: theme.palette.mode === 'dark' ? '#E0E0E0' : '#2c3e50',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 1px 2px rgba(0, 0, 0, 0.2)'
    : '0 1px 2px rgba(0, 0, 0, 0.05)',
  border: theme.palette.mode === 'dark'
    ? (isUser ? '1px solid #2C5282' : '1px solid #404040')
    : (isUser ? '1px solid #bbdefb' : '1px solid #e9ecef'),
  
  '& .category': {
    fontWeight: 600,
    color: theme.palette.mode === 'dark' ? '#4FC3F7' : '#1976d2',
    marginTop: '16px',
    marginBottom: '8px',
    '&:first-of-type': {
      marginTop: 0
    }
  },
  
  '& ul': {
    margin: '8px 0',
    paddingLeft: '20px',
    listStyle: 'none',
  },
  
  '& li': {
    position: 'relative',
    padding: '8px 0',
    paddingLeft: '24px',
    marginBottom: '4px',
    '&:before': {
      content: '"•"',
      position: 'absolute',
      left: '8px',
      color: theme.palette.mode === 'dark' ? '#4FC3F7' : '#2196f3',
      fontWeight: 'bold',
    }
  }
}));

const InputContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '16px 24px',
  backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#ffffff',
  borderTop: `1px solid ${theme.palette.mode === 'dark' ? '#404040' : '#e9ecef'}`,
  zIndex: 10,
}));

const StyledInputContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '12px 16px',
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#e8eaf6',
  color: theme.palette.mode === 'dark' ? '#E0E0E0' : '#1a237e',
  border: `1px solid ${theme.palette.mode === 'dark' ? '#404040' : '#c5cae9'}`,
  transition: 'all 0.2s ease',
  '&:hover': {
    border: `1px solid ${theme.palette.mode === 'dark' ? '#4FC3F7' : '#3f51b5'}`,
    backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#e3f2fd',
  },
  '&:focus-within': {
    border: `1px solid ${theme.palette.mode === 'dark' ? '#4FC3F7' : '#3f51b5'}`,
    boxShadow: theme.palette.mode === 'dark'
      ? '0 0 0 2px rgba(79, 195, 247, 0.2)'
      : '0 0 0 2px rgba(63, 81, 181, 0.2)',
    backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#e3f2fd',
  },
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  flex: 1,
  fontFamily: 'Poppins, Medium',
  fontSize: '14px',
  '& input': {
    color: theme.palette.mode === 'dark' ? '#E0E0E0' : '#2c3e50',
  },
  '& input::placeholder': {
    color: theme.palette.mode === 'dark' ? '#808080' : '#95a5a6',
  },
}));

const UserQuery = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  maxWidth: '800px',
  margin: '0 auto 16px',
  padding: '16px',
  '& > div': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1E3A5F' : '#e3f2fd',
    border: theme.palette.mode === 'dark' 
      ? '1px solid #2C5282'
      : '1px solid #bbdefb',
    borderRadius: '24px',
    padding: '12px 24px',
    color: theme.palette.mode === 'dark' ? '#E0E0E0' : '#2c3e50',
    fontSize: '14px',
    fontFamily: 'Poppins, Medium',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 2px 4px rgba(0, 0, 0, 0.2)'
      : '0 2px 4px rgba(0, 0, 0, 0.05)',
  }
}));

const SendButton = styled(IconButton)(({ theme }) => ({
  padding: '8px',
  color: theme.palette.mode === 'dark' ? '#4FC3F7' : '#2196f3',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(79, 195, 247, 0.15)'
      : 'rgba(33, 150, 243, 0.08)',
    color: theme.palette.mode === 'dark' ? '#81D4FA' : '#1976d2',
  },
  '&.Mui-disabled': {
    color: theme.palette.mode === 'dark' ? '#404040' : '#bdc3c7',
  }
}));

const OpenAi = () => {
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const chatEndRef = useRef(null);
  const [showSendButton, setShowSendButton] = useState(false);
  const intl = useIntl();
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  const [apiResponse, setApiResponse] = useState(null);
  const [sendResultsLoading, setSendResultsLoading] = useState(false);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });

    if (messages.length > 0) {
      setShowSendButton(true);
    } else {
      setShowSendButton(false);
    }
  }, [messages]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputText.trim() || isLoading) return;

    const userMessage = inputText;
    setInputText('');
    setIsLoading(true);

    try {
      const response = await fetch('https://ogfnmdqiqg.execute-api.us-east-1.amazonaws.com/prod/jira_automate_new', {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          conversation_history: messages,
          user_input: userMessage
        })
      });

      const data = await response.json();
      
      if (data.latest_response) {
        setMessages(prevMessages => [
          ...prevMessages,
          {
            user: userMessage,
            assistant: data.latest_response
          }
        ]);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };
 
  
  const formatMessage = (text) => {
    if (!text) return '';
    
    const categories = {
      'Input Fields': [],
      'Validation': [],
      'User Experience': [],
      'Security': []
    };
    
    const lines = text.split('\n').filter(line => line.trim());
    
    // If it's not a test case response, return the text as is
    if (!lines[0]?.toLowerCase().includes('test case')) {
      return text;
    }
    
    // Process test cases and categorize them
    lines.slice(1).forEach(line => {
      const testCase = line.replace(/^Test Case /, '').trim();
      
      if (testCase.toLowerCase().includes('field') || testCase.toLowerCase().includes('input')) {
        categories['Input Fields'].push(testCase);
      } else if (testCase.toLowerCase().includes('valid') || testCase.toLowerCase().includes('error')) {
        categories['Validation'].push(testCase);
      } else if (testCase.toLowerCase().includes('user') || testCase.toLowerCase().includes('responsive')) {
        categories['User Experience'].push(testCase);
      } else if (testCase.toLowerCase().includes('security') || testCase.toLowerCase().includes('protect')) {
        categories['Security'].push(testCase);
      }
    });
    return (
      <>
        {Object.entries(categories).map(([category, testCases]) => 
          testCases.length > 0 && (
            <React.Fragment key={category}>
              <div className="category">{category}</div>
              <ul>
                {testCases.map((testCase, index) => (
                  <li key={index}>{testCase}</li>
                ))}
              </ul>
            </React.Fragment>
          )
        )}
      </>
    );
  };

 
  const handleSendResults = async () => {
    if (messages.length === 0) return;
    setSendResultsLoading(true); // Start loading
    const latestMessage = messages[messages.length - 1];
    const latestResponse = latestMessage.assistant;
    const userInput = latestMessage.user;

    try {
      const payload = {
        manager_id: user.data[0].emp_id,
        latest_response: latestResponse, 
        user_input: userInput
      };

      const apiUrl = 'https://q0aippwzde.execute-api.us-east-1.amazonaws.com/prod/jira_story_create_manager';
      
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();
      setApiResponse(data);
    } catch (error) {
      console.error('Error sending results:', error);
      setApiResponse({
        statusCode: 400,
        body: 'Failed to create issue. Please try again.'
      });
    }finally {
      setSendResultsLoading(false); // End loading
    }
  };

  // Update your ChatContainer to include the API response
 
  return (
    <PageWrapper>
    <MainContainer maxWidth="lg">
      {messages.length === 0 && (
        <UserQuery>
          <Box>POD Assist</Box>
        </UserQuery>
      )}
      
      <StyledPaper elevation={0}>
        <ChatContainer>
          {messages.map((message, index) => (
            <React.Fragment key={index}>
              <MessageBubble isUser>
                {message.user}
              </MessageBubble>
              <MessageBubble>
                {formatMessage(message.assistant)}
              </MessageBubble>
            </React.Fragment>
          ))}

{!sendResultsLoading && apiResponse && (
              <ApiResponseBubble status={apiResponse.statusCode}>
                {apiResponse.body}
              </ApiResponseBubble>
            )}
           {isLoading && (
              <LoadingIndicator>
                <LoadingDot />
                <LoadingDot />
                <LoadingDot />
              </LoadingIndicator>
            )}
          <div ref={chatEndRef} />
        </ChatContainer>

        <InputContainer>
          <form onSubmit={handleSubmit}>
            <StyledInputContainer elevation={0}>
              <StyledInput
                fullWidth
                multiline
                maxRows={4}
                value={inputText}
                onChange={handleInputChange}
                placeholder="Enter a Story Description"
                disabled={isLoading}
              />
              <SendButton
                type="submit"
                disabled={!inputText.trim() || isLoading}
                onClick={handleSubmit}
              >
                <SendIcon />
              </SendButton>
            </StyledInputContainer>
          </form>
        </InputContainer>
       
      </StyledPaper>
      {showSendButton && (
        <SendResultsButton onClick={handleSendResults}>
          Send Results
        </SendResultsButton>
      )}
    </MainContainer>
  </PageWrapper>
  );
};

export default OpenAi;