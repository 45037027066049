// PartnerAdminInvoice.jsx
import React, { useState } from 'react';
import {
  Box,
  Container,
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Constants
const PAYMENT_MODES = {
  ACH: 'ACH Bank Transfer',
  GLOBAL: 'Global Payments'
};

// Sample data - In real app, this would come from API
const SAMPLE_POD_MEMBERS = [
  { id: 1, name: 'John Doe', role: 'Developer', hourlyRate: 100 },
  { id: 2, name: 'Jane Smith', role: 'Designer', hourlyRate: 90 },
  { id: 3, name: 'Mike Johnson', role: 'QA', hourlyRate: 80 },
];

const SAMPLE_CLIENTS = [
  { id: 1, name: 'Client A' },
  { id: 2, name: 'Client B' },
  { id: 3, name: 'Client C' },
];

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
}));

const PartnerAdminInvoice = () => {
  // State
  const [invoiceData, setInvoiceData] = useState({
    clientId: '',
    projectName: '',
    selectedMonth: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
    paymentMode: 'ACH',
    invoiceType: 'individual',
  });

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [memberHours, setMemberHours] = useState({});
  const [generatedInvoice, setGeneratedInvoice] = useState(null);

  // Constants
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Handlers
  const handleInputChange = (field) => (event) => {
    setInvoiceData({
      ...invoiceData,
      [field]: event.target.value
    });
  };

  const handleMemberSelection = (memberId) => {
    setSelectedMembers(prev => {
      if (prev.includes(memberId)) {
        return prev.filter(id => id !== memberId);
      }
      return [...prev, memberId];
    });
  };

  const handleHoursChange = (memberId, hours) => {
    setMemberHours(prev => ({
      ...prev,
      [memberId]: parseFloat(hours) || 0
    }));
  };

  const calculateTotalAmount = () => {
    return selectedMembers.reduce((total, memberId) => {
      const member = SAMPLE_POD_MEMBERS.find(m => m.id === memberId);
      const hours = memberHours[memberId] || 0;
      return total + (hours * member.hourlyRate);
    }, 0);
  };

  const generateInvoice = () => {
    const invoiceItems = selectedMembers.map(memberId => {
      const member = SAMPLE_POD_MEMBERS.find(m => m.id === memberId);
      const hours = memberHours[memberId] || 0;
      return {
        name: member.name,
        role: member.role,
        hours: hours,
        rate: member.hourlyRate,
        total: hours * member.hourlyRate
      };
    });

    setGeneratedInvoice({
      ...invoiceData,
      items: invoiceItems,
      totalAmount: calculateTotalAmount(),
      invoiceNumber: `INV-${Date.now().toString().slice(-6)}`,
      date: new Date().toLocaleDateString(),
    });
  };

  const isFormValid = () => {
    return (
      invoiceData.clientId &&
      invoiceData.projectName &&
      selectedMembers.length > 0 &&
      selectedMembers.every(memberId => memberHours[memberId] > 0)
    );
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom>
          Partner Admin Invoice Management
        </Typography>

        <StyledCard>
          <CardContent>
            <Grid container spacing={3}>
              {/* Basic Information */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Client</InputLabel>
                  <Select
                    value={invoiceData.clientId}
                    label="Client"
                    onChange={handleInputChange('clientId')}
                  >
                    {SAMPLE_CLIENTS.map(client => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Project Name"
                  value={invoiceData.projectName}
                  onChange={handleInputChange('projectName')}
                />
              </Grid>

              {/* Time Period Selection */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    value={invoiceData.selectedMonth}
                    label="Month"
                    onChange={handleInputChange('selectedMonth')}
                  >
                    {months.map((month, index) => (
                      <MenuItem key={month} value={index}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={invoiceData.selectedYear}
                    label="Year"
                    onChange={handleInputChange('selectedYear')}
                  >
                    {[2023, 2024, 2025].map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <StyledDivider />
                <Typography variant="h6" gutterBottom>
                  Payment Mode
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={invoiceData.paymentMode}
                    onChange={handleInputChange('paymentMode')}
                  >
                    {Object.entries(PAYMENT_MODES).map(([key, label]) => (
                      <FormControlLabel
                        key={key}
                        value={key}
                        control={<Radio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <StyledDivider />
                <Typography variant="h6" gutterBottom>
                  Select Team Members
                </Typography>
                <Grid container spacing={2}>
                  {SAMPLE_POD_MEMBERS.map(member => (
                    <Grid item xs={12} key={member.id}>
                      <Paper sx={{ p: 2 }}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={4}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedMembers.includes(member.id)}
                                  onChange={() => handleMemberSelection(member.id)}
                                />
                              }
                              label={`${member.name} (${member.role})`}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              type="number"
                              label="Hours Worked"
                              fullWidth
                              disabled={!selectedMembers.includes(member.id)}
                              value={memberHours[member.id] || ''}
                              onChange={(e) => handleHoursChange(member.id, e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Typography color="textSecondary">
                              Rate: ${member.hourlyRate}/hour
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={generateInvoice}
                  disabled={!isFormValid()}
                >
                  Generate Invoice
                </Button>
              </Grid>
            </Grid>

            {/* Invoice Preview */}
            {generatedInvoice && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Invoice Preview
                </Typography>
                <Paper sx={{ p: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Invoice Number</Typography>
                      <Typography variant="body1">{generatedInvoice.invoiceNumber}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Date</Typography>
                      <Typography variant="body1">{generatedInvoice.date}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">Payment Mode</Typography>
                      <Typography variant="body1">
                        {PAYMENT_MODES[generatedInvoice.paymentMode]}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Team Member</TableCell>
                              <TableCell>Role</TableCell>
                              <TableCell align="right">Hours</TableCell>
                              <TableCell align="right">Rate (USD)</TableCell>
                              <TableCell align="right">Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {generatedInvoice.items.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.role}</TableCell>
                                <TableCell align="right">{item.hours}</TableCell>
                                <TableCell align="right">${item.rate}</TableCell>
                                <TableCell align="right">${item.total}</TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell colSpan={4} align="right">
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                  Total Amount
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                  ${generatedInvoice.totalAmount}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            )}
          </CardContent>
        </StyledCard>
      </Box>
    </Container>
  );
};

export default PartnerAdminInvoice;