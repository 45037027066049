import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  FaHome, FaTachometerAlt, FaQuestionCircle, 
  FaSearch, FaChartBar, FaTasks, FaComment, 
  FaInfoCircle, FaChartPie, FaPlusCircle,
  FaClipboardList, FaRobot, FaListAlt, FaCog
} from 'react-icons/fa';
import SmartToyIcon from '@mui/icons-material/SmartToy';import {

  SyncAlt,
} from '@mui/icons-material';
import { useIntl } from "react-intl";
import HubIcon from '@mui/icons-material/Hub'; 
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'; // Shows connection/invitation concept

import TrendingUpIcon from '@mui/icons-material/TrendingUp';  // Shows growth/productivity
import { DiAptana } from "react-icons/di";
import PeopleIcon from '@mui/icons-material/People';

const MobileNavigation = ({  handleLogOut }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const intl = useIntl();
  const getHeaderItems = () => {
    if (!isLoggedIn) {
      return [
        { text: intl.formatMessage({ id: 'home' }), icon: <FaHome size={20} />, path: '/' },
        { text: intl.formatMessage({ id: 'login' }), icon: <FaInfoCircle size={20} />, path: '/login' },
        { text: intl.formatMessage({ id: 'register' }), icon: <FaPlusCircle size={20} />, path: '/register' }
      ];
    }
    
    const headerConfig = {
      Candidate: [
        { text: intl.formatMessage({ id: 'home' }), icon: <FaHome size={20} />, path: '/' },
        { text: intl.formatMessage({ id: 'dashboard' }), icon: <FaTachometerAlt size={20} />, path: '/candidate-dashboard' },
        { text: intl.formatMessage({ id: 'findPods' }), icon: <FaSearch size={20} />, path: '/FindPods' },
        { text: intl.formatMessage({ id: 'settings' }), icon: <DiAptana size={20} />, path: '/EditTalentSettingsForm' }
      ],
      Client: [
        { text: intl.formatMessage({ id: 'home' }), icon: <FaHome size={20} />, path: '/' },
        { text: intl.formatMessage({ id: 'dashboard' }), icon: <FaTachometerAlt size={20} />, path: '/client-dashboard' },
        { text: intl.formatMessage({ id: 'findAgents' }), icon: <SmartToyIcon size={20} />, path: '/AIAgentSearch' },

        { text: intl.formatMessage({ id: 'findTalent' }), icon: <FaSearch size={20} />, path: '/browse-talent' },
        { text: intl.formatMessage({ id: 'findPods' }), icon: <HubIcon size={20} />, path: '/FindPodsClient' },
        { text: intl.formatMessage({ id: 'myPods' }), icon: <PeopleIcon sx={{ fontSize: 20 }} />, path: '/Team' },
        { text: intl.formatMessage({ id: 'invite' }), icon: <ConnectWithoutContactIcon size={20} />, path: '/Popup' },
        { text: intl.formatMessage({ id: 'Settings' }), icon: <DiAptana size={20} />, path: '/Setting' }
      ]
    };

    return headerConfig[user?.data?.[0]?.type] || [];
  };

  const getMenuItems = () => {
    if (!isLoggedIn) return [];

    const menuConfig = {
      Candidate: [
        { text: intl.formatMessage({ id: 'podAssist' }), icon: <FaQuestionCircle size={20} />, path: '/OpenAi' },
        { text: intl.formatMessage({ id: 'myPods' }), icon: <PeopleIcon sx={{ fontSize: 20 }} />, path: '/Pods' },
        { text: intl.formatMessage({ id: 'podMetrics' }), icon: <FaChartBar size={20} />, path: '/PodMetricsTalentDetailview' },
        { text: intl.formatMessage({ id: 'myTasks' }), icon: <FaTasks size={20} />, path: '/TalentViewTask' },
        { text: intl.formatMessage({ id: 'podFeedback' }), icon: <FaComment size={20} />, path: '/Reviewsandratingcandidate' },
        { text: intl.formatMessage({ id: 'podsync' }), icon: <SyncAlt size={20} />, path: '/MeetingSummariesTimeline' },
        
        { text: intl.formatMessage({ id: 'manageProfile' }), icon: <FaInfoCircle size={20} />, path: '/Updateyourprofile' }
      ],
      Client: [
        { text: intl.formatMessage({ id: 'createPod' }), icon: <FaPlusCircle size={20} />, path: '/client-createpod' },
        { text: intl.formatMessage({ id: 'podActivity' }), icon: <FaListAlt size={20} />, path: '/PodActivity' },
        { text: intl.formatMessage({ id: 'podTasks' }), icon: <FaTasks size={20} />, path: '/ViewPodTasksManagerbyid' },
        { text: intl.formatMessage({ id: 'podMetrics' }), icon: <FaChartBar size={20} />, path: '/PodMetricsmanager' },
        { text: intl.formatMessage({ id: 'podAssist' }), icon: <FaQuestionCircle size={20} />, path: '/OpenAi' },
        { text: intl.formatMessage({ id: 'podsync' }), icon: <SyncAlt size={20} />, path: '/MeetingSummariesTimeline' },

        { text: intl.formatMessage({ id: 'manageProfile' }), icon: <FaInfoCircle size={20} />, path: '/client-profile' },
        { text: "Pod Productivity", icon: <TrendingUpIcon size={20} />, path: '/PodProductivity' }
 
      ]
    };

    return menuConfig[user?.data?.[0]?.type] || [];
  };

  const handleNavigation = (path) => {
    history.push(path);
    setIsDrawerOpen(false);
  };

  return (
    <Box className="md:hidden">
      <IconButton
        onClick={() => setIsDrawerOpen(true)}
        className="text-black"
        size="large"
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: '240px',
            backgroundColor: '#f3f3f3',
            borderRight: '1px solid #e0e0e0'
          }
        }}
      >
        <List className="pt-4">
          {/* <Typography variant="subtitle2" sx={{ px: 2, py: 1, color: '#666' }}>
            Main Navigation
          </Typography> */}
          {getHeaderItems().map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => handleNavigation(item.path)}
              sx={{
                mb: 1,
                mx: 1,
                borderRadius: '8px',
                color: location.pathname === item.path ? '#1DBF73' : '#333',
                backgroundColor: location.pathname === item.path ? '#e8f5e9' : 'transparent',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                  color: '#1DBF73'
                }
              }}
            >
              <ListItemIcon 
                sx={{ 
                  minWidth: '40px',
                  color: location.pathname === item.path ? '#1DBF73' : '#666'
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText 
                primary={item.text}
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: location.pathname === item.path ? 600 : 400
                  }
                }}
              />
            </ListItem>
          ))}

          {isLoggedIn && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle2" sx={{ px: 2, py: 1, color: '#666' }}>
                Additional Options
              </Typography>
              {getMenuItems().map((item) => (
                <ListItem
                  button
                  key={item.text}
                  onClick={() => handleNavigation(item.path)}
                  sx={{
                    mb: 1,
                    mx: 1,
                    borderRadius: '8px',
                    color: location.pathname === item.path ? '#1DBF73' : '#333',
                    backgroundColor: location.pathname === item.path ? '#e8f5e9' : 'transparent',
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                      color: '#1DBF73'
                    }
                  }}
                >
                  <ListItemIcon 
                    sx={{ 
                      minWidth: '40px',
                      color: location.pathname === item.path ? '#1DBF73' : '#666'
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.text}
                    sx={{
                      '& .MuiTypography-root': {
                        fontWeight: location.pathname === item.path ? 600 : 400
                      }
                    }}
                  />
                </ListItem>
              ))}

              {/* Logout Option */}
              <Divider sx={{ my: 2 }} />
              <ListItem
                button
                onClick={handleLogOut}
                sx={{
                  mb: 1,
                  mx: 1,
                  borderRadius: '8px',
                  color: '#333',
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                    color: '#1DBF73'
                  }
                }}
              >
                <ListItemIcon sx={{ minWidth: '40px', color: '#666' }}>
                  <FaCog size={20} />
                </ListItemIcon>
                <ListItemText primary={intl.formatMessage({ id: 'logOut' })} />
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </Box>
  );
};

export default MobileNavigation;