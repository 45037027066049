import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  Grid,
  Chip,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Rating,
  Stack,
  Link as MuiLink,
  Breadcrumbs,
  CircularProgress,
  styled,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useTheme,
  alpha,
  Tooltip,
  Badge,
  Tabs,
  Tab,
  Fab
} from "@mui/material";
import {
  Edit as EditIcon,
  LocationOn,
  WorkOutline,
  Save as SaveIcon,
  School as SchoolIcon,
  Assessment,
  LinkedIn,
  Description as DescriptionIcon,
  
  AccessTime,
  NavigateNext,
  ArrowBack,
  Business,
  Videocam,
  Close as CloseIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
  Email as EmailIcon,
  AccessTime as AccessTimeIcon,
  EventAvailable as EventAvailableIcon,
  Link as LinkIcon,
  AttachMoney as AttachMoneyIcon,
  Star as StarIcon,
  StarBorder as StarBorderIcon,
  GroupWork as GroupWorkIcon,
  Work as WorkIcon,
  AccountCircle as AccountCircleIcon,
  VerifiedUser as VerifiedUserIcon,
  Settings as SettingsIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  CalendarToday as CalendarTodayIcon,
  FormatQuote as FormatQuoteIcon,
  Code as CodeIcon,
  Favorite as FavoriteIcon,
  Share as ShareIcon
} from "@mui/icons-material";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PortfolioList from "./PortfolioList";
import defaultimg from "./assets/defaultimge.png";
import DocumentViewer from "./DocumentViewer";
import BusinessIcon from '@mui/icons-material/Business';
// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(10),
  },
}));

const PageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' 
    ? alpha(theme.palette.background.paper, 0.2)
    : alpha(theme.palette.background.paper, 0.5),
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  boxShadow: '0 8px 32px rgba(31, 38, 135, 0.1)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: 'linear-gradient(135deg, #4776E6 0%, #8E54E9 100%)',
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  color: '#fff',
  overflow: 'hidden',
  boxShadow: '0 12px 24px rgba(0, 0, 0, 0.15)',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at top right, rgba(255,255,255,0.15) 0%, transparent 70%)',
    zIndex: 1,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(3),
  },
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 160,
  height: 160,
  border: `5px solid ${theme.palette.background.paper}`,
  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 12px 28px rgba(0, 0, 0, 0.2)',
  },
  [theme.breakpoints.down('sm')]: {
    width: 120,
    height: 120,
  },
}));

const SectionCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 1.5,
  overflow: 'visible',
  boxShadow: '0 6px 18px rgba(0, 0, 0, 0.08)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.02)'}`,
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 24px rgba(0, 0, 0, 0.12)',
  },
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: alpha(theme.palette.primary.light, 0.1),
}));

const SectionIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  marginRight: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.dark,
}));

const SectionContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: '#ffb400',
  },
  '& .MuiRating-iconEmpty': {
    color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.2)',
  },
}));

const ReviewCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.background.paper,
  position: 'relative',
  overflow: 'visible',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 20,
    left: -10,
    width: 20,
    height: 20,
    background: theme.palette.background.paper,
    transform: 'rotate(45deg)',
    zIndex: -1,
    boxShadow: '-3px 3px 6px rgba(0, 0, 0, 0.1)',
  },
}));

const QuoteIcon = styled(FormatQuoteIcon)(({ theme }) => ({
  position: 'absolute',
  top: -15,
  right: 20,
  fontSize: 60,
  color: alpha(theme.palette.primary.main, 0.1),
  transform: 'rotate(180deg)',
}));

const PartnerCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius * 1.5,
  background: theme.palette.background.paper,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
    borderColor: theme.palette.primary.light,
    transform: 'translateY(-4px)',
  },
}));

const PartnerLogo = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  marginRight: theme.spacing(2),
  border: `3px solid ${theme.palette.primary.main}`,
  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
}));

const InfoChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  borderRadius: 8,
  fontWeight: 500,
  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.05)',
  '& .MuiChip-icon': {
    color: 'inherit',
  },
}));

const SkillChip = styled(Chip)(({ theme, color = 'primary' }) => ({
  margin: theme.spacing(0.5),
  fontWeight: 600,
  background: color === 'primary' 
    ? theme.palette[color].main
    : theme.palette[color].main,
  color: '#fff',
  boxShadow: `0 4px 8px ${alpha(theme.palette[color].main, 0.3)}`,
  '&:hover': {
    boxShadow: `0 6px 12px ${alpha(theme.palette[color].main, 0.4)}`,
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  '& .MuiTable-root': {
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
  },
  '& .MuiTableRow-root': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.05)',
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    },
  },
  '& .MuiTableCell-root': {
    borderBottom: 'none',
    padding: theme.spacing(2),
    '&:first-of-type': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
    '&:last-of-type': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
  '& .MuiTableHead-root .MuiTableRow-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      transform: 'none',
      boxShadow: 'none',
    },
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ padding: '16px 0' }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: '8px 16px',
  fontWeight: 600,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  textTransform: 'none',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  },
}));

const Candidatedetailsbyid = () => {
  const token = useAuthToken();
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const theme = useTheme();
  const { c_id } = location.state;
  const { talent_type } = location.state;

  const [state, setState] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [arrexp, setArrexp] = useState({
    experience: [],
    education: [],
    certification: [],
  });
  const [pod, setPod] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [resume, setResume] = useState('');
  const [assessment, setAssesment] = useState('');
  const [error, setError] = useState('');
  const [authImage, setAuthImage] = useState('');
  const [partnerDetailsOpen, setPartnerDetailsOpen] = useState(false);
  const [partnerVideoUrl, setPartnerVideoUrl] = useState('');
  const [partnerImageUrl, setPartnerImageUrl] = useState('');
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const generatePreSignedPutUrl11 = async () => {
    try {
      if (!state?.profilePicture) return;

      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + state.profilePicture,
        }),
      });

      const data = await response.json();
      const signedUrl = JSON.parse(data.body).signedUrl;
      setAuthImage(signedUrl);
    } catch (error) {
      console.error("Error generating signed URL:", error);
      setError("Failed to load profile picture");
    }
  };

  // Generate signed URL for partner's video
  const generatePartnerVideoUrl = async () => {
    try {
      if (!state?.partnerdetails?.video) return;

      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + state.partnerdetails.video,
        }),
      });

      const data = await response.json();
      const signedUrl = JSON.parse(data.body).signedUrl;
      setPartnerVideoUrl(signedUrl);
    } catch (error) {
      console.error("Error generating signed URL for partner video:", error);
    }
  };

  // Generate signed URL for partner's image
  const generatePartnerImageUrl = async () => {
    try {
      if (!state?.partnerdetails?.profilePicture) return;

      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + state.partnerdetails.profilePicture,
        }),
      });

      const data = await response.json();
      const signedUrl = JSON.parse(data.body).signedUrl;
      setPartnerImageUrl(signedUrl);
    } catch (error) {
      console.error("Error generating signed URL for partner image:", error);
    }
  };

  // Update the generateAndSetSignedUrl function
  const generateAndSetSignedUrl = async () => {
    try {
      if (!state.resume) return;
      
      // Encode the resume filename to handle special characters like '+'
      const encodedResume = encodeURIComponent(state.resume);
      
      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + encodedResume,
        }),
      });

      const data = await response.json();
      const signedUrl = JSON.parse(data.body).signedUrl;
      setResume(signedUrl);
    } catch (error) {
      console.error("Error generating signed URL:", error);
      setError("Failed to load resume URL");
    }
  };

  const generateAndSetSignedUrlassessment = async () => {
    try {
      if (!state.coding_assessment_url) return;

      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + state.coding_assessment_url,
        }),
      });

      const data = await response.json();
      const signedUrl = JSON.parse(data.body).signedUrl;
      setAssesment(signedUrl);
    } catch (error) {
      console.error("Error generating signed URL:", error);
      setError("Failed to load assessment URL");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };

        // First fetch basic candidate data to determine talent_type
        const candidateBasicResponse = await axios.post(
          process.env.REACT_APP_CLIENT_GETCANDIDATEDATA,
          { id: c_id },
          { headers }
        );
        
        // Get talent_type from initial data
        const talentType = talent_type || "";
        
        // Determine the type parameter value based on talent_type
        const typeParam = talentType === "PARTNER" ? "P" : "I";
        
        // Now fetch the detailed data with the correct type parameter
        const [candidateData, additionalData] = await Promise.all([
          axios.post(
            process.env.REACT_APP_CLIENT_GETCANDIDATEBYID,
            { id: c_id, review: "yes", type: typeParam },
            { headers }
          ),
          Promise.resolve(candidateBasicResponse) // Reuse the data we already have
        ]);

        const candidateFullData = candidateData.data.data[0];
        candidateFullData.talent_type = talentType; // Add talent_type to the state
        
        setState(candidateFullData);
        setPod(additionalData.data.data.podExperience || []);
        setArrexp({
          experience: additionalData.data.data.exp || [],
          education: additionalData.data.data.education || [],
          certification: additionalData.data.data.certification || [],
        });
        setReviews(candidateFullData.reviews || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [token, c_id]);

  // Generate signed URLs when state is loaded
  useEffect(() => {
    if (state) {
      generatePreSignedPutUrl11();
      generateAndSetSignedUrl();
      generateAndSetSignedUrlassessment();
      generatePartnerVideoUrl();
      generatePartnerImageUrl();
    }
  }, [state]);

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={60} thickness={4} color="primary" />
        <Typography variant="h6" sx={{ mt: 3, fontWeight: 500 }}>Loading talent profile...</Typography>
      </Box>
    );
  }

  if (!state) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Paper 
          elevation={3} 
          sx={{ 
            p: 4, 
            textAlign: 'center',
            borderRadius: 2,
            maxWidth: 400
          }}
        >
          <Typography variant="h5" color="error" gutterBottom>No Data Found</Typography>
          <Typography color="textSecondary" paragraph>
            We couldn't find the talent profile you're looking for.
          </Typography>
          <Button 
            component={Link} 
            to="/browse-talent" 
            variant="contained" 
            color="primary"
            startIcon={<ArrowBack />}
            sx={{ mt: 2 }}
          >
            Back to Talent List
          </Button>
        </Paper>
      </Box>
    );
  }

  const handleInputChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // Add this save handler
  const handleSave = async () => {
    try {
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      const payload = {
        id: c_id,
        ...state,
      };

      await axios.put(process.env.REACT_APP_TALENT_EDITCANDIDATE, payload, {
        headers,
      });

      setEditMode(false);
      // Optionally refresh data
      window.location.reload();
    } catch (error) {
      console.error("Error saving changes:", error);
      // Handle error (show notification, etc.)
    }
  };

  const handleOpenPartnerDetails = () => {
    setPartnerDetailsOpen(true);
  };
 
  return (
    <StyledContainer maxWidth="lg">
      <PageWrapper>
        {/* Breadcrumbs */}
        <Breadcrumbs 
          separator={<NavigateNext fontSize="small" />} 
          sx={{ 
            mb: 3,
            '& .MuiBreadcrumbs-ol': {
              background: alpha(theme.palette.background.paper, 0.6),
              padding: '8px 16px',
              borderRadius: 30,
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            }
          }}
        >
          <MuiLink
            component={Link}
            to="/browse-talent"
            color="inherit"
            sx={{ 
              display: 'flex', 
              alignItems: 'center',
              fontWeight: 500,
              transition: 'all 0.2s ease',
              '&:hover': {
                color: theme.palette.primary.main,
                transform: 'translateX(-2px)',
              }
            }}
          >
            <ArrowBack sx={{ mr: 0.5 }} fontSize="small" />
            Find Talent
          </MuiLink>
          <Typography color="text.primary" fontWeight={600}>Talent Details</Typography>
        </Breadcrumbs>

        {/* Profile Header */}
        <ProfileHeader>
          <Grid container spacing={3} sx={{ position: 'relative', zIndex: 2 }}>
            <Grid item xs={12} md={3} display="flex" justifyContent="center" alignItems="center">
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <Tooltip title={state.talent_type === "PARTNER" ? "Partner Talent" : "Individual Talent"}>
                    <Avatar
                      sx={{
                        bgcolor: state.talent_type === "PARTNER" ? 'warning.main' : 'success.main',
                        width: 36,
                        height: 36,
                        border: `2px solid ${theme.palette.background.paper}`,
                      }}
                    >
                      {state.talent_type === "PARTNER" ? <Business /> : <AccountCircleIcon />}
                    </Avatar>
                  </Tooltip>
                }
              >
                <ProfileAvatar
                  src={authImage || defaultimg}
                  alt={`${state.firstName} ${state.lastName}`}
                />
              </Badge>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                mb={2}
              >
                <Box>
                  <Typography variant="h3" fontWeight="800" gutterBottom>
                    {`${state.firstName} ${state.lastName}`}
                  </Typography>
                  <Typography variant="h6" gutterBottom fontWeight="500" color="rgba(255,255,255,0.8)">
                    {state.work_position}
                  </Typography>
                </Box>
                <Box>
                  {editMode ? (
                    <ActionButton
                      variant="contained"
                      onClick={handleSave}
                      startIcon={<SaveIcon />}
                      color="success"
                      sx={{ px: 3, py: 1 }}
                    >
                      Save Changes
                    </ActionButton>
                  ) : (
                    <Tooltip title="Edit Profile">
                      <IconButton 
                        onClick={() => setEditMode(true)}
                        sx={{ 
                          bgcolor: 'rgba(255,255,255,0.2)', 
                          '&:hover': { 
                            bgcolor: 'rgba(255,255,255,0.3)'
                          } 
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
              
              <Box display="flex" flexWrap="wrap" sx={{ gap: 1, mb: 3 }}>
                <InfoChip
                  icon={<LocationOn />}
                  label={state.country}
                  variant="filled"
                  color="primary"
                />
                <InfoChip
                  icon={<WorkOutline />}
                  label={state.work_position}
                  variant="filled"
                  color="primary"
                />
                <InfoChip
                  icon={<AttachMoneyIcon />}
                  label={`${state.currency} ${state.ratePerhour}/hr`}
                  variant="filled"
                  color="primary"
                />
                {state.linkedin && (
                  <InfoChip
                    icon={<LinkedIn />}
                    label="LinkedIn"
                    variant="filled"
                    color="primary"
                    component="a"
                    href={state.linkedin}
                    target="_blank"
                    clickable
                  />
                )}
                {state.talent_type === "PARTNER" && (
                  <InfoChip
                    icon={<Business />}
                    label="Partner Talent"
                    variant="filled"
                    color="warning"
                  />
                )}
                
                <Box sx={{ flexGrow: 1 }} />
                
                {/* <Tooltip title="Share Profile">
                  <IconButton 
                    size="small" 
                    sx={{ 
                      bgcolor: 'rgba(255,255,255,0.2)', 
                      color: 'white',
                      '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                    }}
                  >
                    <ShareIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                
                <Tooltip title="Add to Favorites">
                  <IconButton 
                    size="small" 
                    sx={{ 
                      bgcolor: 'rgba(255,255,255,0.2)', 
                      color: 'white',
                      '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                    }}
                  >
                    <FavoriteIcon fontSize="small" />
                  </IconButton>
                </Tooltip> */}
              </Box>

              <Typography 
                variant="body1" 
                sx={{ 
                  color: 'rgba(255,255,255,0.9)',
                  lineHeight: 1.7,
                  fontSize: '1.05rem',
                }}
              >
                {state.about}
              </Typography>

              {/* Partner Company Button - Only show for PARTNER talent_type */}
              {state.partnerdetails && state.talent_type === "PARTNER" && (
                <Box mt={3}>
                  <Button 
                    variant="contained"
                    onClick={handleOpenPartnerDetails}
                    startIcon={<Business />}
                    sx={{ 
                      borderRadius: 30,
                      px: 3,
                      py: 1,
                      boxShadow: '0 4px 20px rgba(254, 107, 139, 0.25)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-3px)',
                        boxShadow: '0 6px 25px rgba(254, 107, 139, 0.4)',
                      }
                    }}
                  >
                    View Partner: {state.partnerdetails.companyName}
                  </Button>
                </Box>
              )}
              
              <Box 
                sx={{ 
                  display: 'flex', 
                  flexWrap: 'wrap',
                  justifyContent: 'center', 
                  gap: 2,
                  mt: 3
                }}
              >
                {/* <Button
                  variant="contained" 
                  startIcon={<AttachMoneyIcon />}
                  sx={{
                    borderRadius: 30,
                    px: 3,
                    py: 1,
                    '&:hover': {
                      boxShadow: '0 6px 20px rgba(67, 160, 71, 0.4)',
                    }
                  }}
                >
                  Hire This Talent
                </Button> */}
                
                {state.calendlyurl && (
                  <Button
                    variant="contained" 
                    startIcon={<EventAvailableIcon />}
                    onClick={() => window.open(state.calendlyurl, '_blank')}
                    sx={{
                      borderRadius: 30,
                      px: 3,
                      py: 1,
                      '&:hover': {
                        boxShadow: '0 6px 20px rgba(30, 136, 229, 0.4)',
                      }
                    }}
                  >
                    Schedule Interview
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </ProfileHeader>

        {/* Tabs for better content organization */}
        <Box sx={{ mb: 4 }}>
          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: theme.palette.primary.main,
                height: 3,
                borderRadius: 3,
              },
              '& .MuiTab-root': {
                fontWeight: 600,
                fontSize: '0.95rem',
                minHeight: 48,
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                }
              },
              backgroundColor: alpha(theme.palette.background.paper, 0.7),
              borderRadius: 50,
              p: 0.8,
              boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            }}
          >
            <Tab icon={<WorkIcon sx={{ mr: 1 }} />} iconPosition="start" label="Skills & Experience" />
            <Tab icon={<SchoolIcon sx={{ mr: 1 }} />} iconPosition="start" label="Education" />
            <Tab icon={<StarIcon sx={{ mr: 1 }} />} iconPosition="start" label="Reviews" />
            <Tab icon={<DescriptionIcon sx={{ mr: 1 }} />} iconPosition="start" label="Documents" />
            <Tab icon={<SettingsIcon sx={{ mr: 1 }} />} iconPosition="start" label="Other Info" />
          </Tabs>
        </Box>

        {/* Tab Content */}
        <TabPanel value={tabValue} index={0}>
          {/* Skills Section */}
          <SectionCard elevation={2}>
            <SectionHeader>
              <SectionTitle variant="h6">
                <SectionIcon>
                  <CodeIcon />
                </SectionIcon>
                Skills & Expertise
              </SectionTitle>
            </SectionHeader>
            <SectionContent>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom color="primary">
                      Primary Skills
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      <SkillChip 
                        label={state.primaryskill} 
                        color="primary" 
                        size="medium"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom color="primary">
                      Secondary Skills
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      <SkillChip 
                        label={state.secondaryskill} 
                        color="secondary" 
                        size="medium"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </SectionContent>
          </SectionCard>

          {/* Experience Section */}
          <SectionCard elevation={2}>
            <SectionHeader>
              <SectionTitle variant="h6">
                <SectionIcon>
                  <WorkIcon />
                </SectionIcon>
                Work Experience
              </SectionTitle>
            </SectionHeader>
            <SectionContent>
              {arrexp.experience.length > 0 ? (
                <StyledTableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Position</TableCell>
                        <TableCell>Organization</TableCell>
                        <TableCell>Duration</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {arrexp.experience.map((exp, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle2" fontWeight="bold">
                              {exp.work_position}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <BusinessIcon sx={{ mr: 1, color: 'primary.main', fontSize: 18 }} />
                              <Typography>{exp.work_organization}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <CalendarTodayIcon sx={{ mr: 1, color: 'primary.main', fontSize: 18 }} />
                              <Typography>
                                {`${exp.workStartdate} - ${exp.present ? "Present" : exp.workEnddate}`}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              ) : (
                <Box sx={{ p: 3, textAlign: 'center' }}>
                  <Typography color="text.secondary">No work experience data available</Typography>
                </Box>
              )}
            </SectionContent>
          </SectionCard>

          {/* Pod Experience Section */}
          <SectionCard elevation={2}>
            <SectionHeader>
              <SectionTitle variant="h6">
                <SectionIcon>
                  <GroupWorkIcon />
                </SectionIcon>
                {intl.formatMessage({ id: "podExperience" })}
              </SectionTitle>
            </SectionHeader>
            <SectionContent>
              {pod.length > 0 ? (
                <Grid container spacing={2}>
                  {pod.map((data, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Paper
                        elevation={3}
                        sx={{
                          p: 3,
                          textAlign: "center",
                          borderRadius: 2,
                          bgcolor: alpha(theme.palette.primary.light, 0.1),
                          transition: 'all 0.3s ease',
                          border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                          '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: `0 10px 20px ${alpha(theme.palette.primary.main, 0.15)}`,
                            bgcolor: alpha(theme.palette.primary.light, 0.2),
                          }
                        }}
                      >
                        <GroupWorkIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                        <Typography variant="subtitle1" fontWeight="bold">
                          {data.podName}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box sx={{ p: 3, textAlign: 'center' }}>
                  <Typography color="text.secondary">
                    {intl.formatMessage({ id: "noExperience" })}
                  </Typography>
                </Box>
              )}
            </SectionContent>
          </SectionCard>

          {/* Portfolio Section */}
          <SectionCard elevation={2}>
            <SectionHeader>
              <SectionTitle variant="h6">
                <SectionIcon>
                  <WorkIcon />
                </SectionIcon>
                Portfolio
              </SectionTitle>
            </SectionHeader>
            <SectionContent>
              <PortfolioList candidateid={state.c_id} />
            </SectionContent>
          </SectionCard>
        </TabPanel>
        
        <TabPanel value={tabValue} index={1}>
          {/* Education Section */}
          <SectionCard elevation={2}>
            <SectionHeader>
              <SectionTitle variant="h6">
                <SectionIcon>
                  <SchoolIcon />
                </SectionIcon>
                {intl.formatMessage({ id: "education" })}
              </SectionTitle>
            </SectionHeader>
            <SectionContent>
              {arrexp.education.length > 0 ? (
                <StyledTableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Degree</TableCell>
                        <TableCell>Institution</TableCell>
                        <TableCell>Duration</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {arrexp.education.map((edu, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle2" fontWeight="bold">
                              {edu.degree}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <SchoolIcon sx={{ mr: 1, color: 'primary.main', fontSize: 18 }} />
                              <Typography>{edu.institution}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <CalendarTodayIcon sx={{ mr: 1, color: 'primary.main', fontSize: 18 }} />
                              <Typography>{`${edu.startDate} - ${edu.endDate}`}</Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              ) : (
                <Box sx={{ p: 3, textAlign: 'center' }}>
                  <Typography color="text.secondary">No education data available</Typography>
                </Box>
              )}
            </SectionContent>
          </SectionCard>

          {/* Certifications Section */}
          <SectionCard elevation={2}>
            <SectionHeader>
              <SectionTitle variant="h6">
                <SectionIcon>
                  <VerifiedUserIcon />
                </SectionIcon>
                {intl.formatMessage({ id: "certifications" })}
              </SectionTitle>
            </SectionHeader>
            <SectionContent>
              {arrexp.certification.length > 0 ? (
                <StyledTableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Certification Name</TableCell>
                        <TableCell>Institution</TableCell>
                        <TableCell>Valid Period</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {arrexp.certification.map((cert, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="subtitle2" fontWeight="bold">
                              {cert.cert_name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <BusinessIcon sx={{ mr: 1, color: 'primary.main', fontSize: 18 }} />
                              <Typography>{cert.certificationInstitution}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <CalendarTodayIcon sx={{ mr: 1, color: 'primary.main', fontSize: 18 }} />
                              <Typography>
                                {`${moment(cert.startDate).format("YYYY-MM-DD")} - ${moment(cert.endDate).format("YYYY-MM-DD")}`}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              ) : (
                <Box sx={{ p: 3, textAlign: 'center' }}>
                  <Typography color="text.secondary">No certification data available</Typography>
                </Box>
              )}
            </SectionContent>
          </SectionCard>
        </TabPanel>

        <TabPanel value={tabValue} index={2}>
          {/* Reviews Section */}
          <SectionCard elevation={2}>
            <SectionHeader>
              <SectionTitle variant="h6">
                <SectionIcon>
                  <ChatBubbleOutlineIcon />
                </SectionIcon>
                Reviews & Feedback
              </SectionTitle>
            </SectionHeader>
            <SectionContent>
              {reviews && reviews.length > 0 ? (
                <Stack spacing={3}>
                  {reviews.map((review, index) => (
                    <ReviewCard key={index} elevation={3}>
                      <QuoteIcon />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Avatar 
                              src={review.profilePicture} 
                              sx={{ 
                                width: 80, 
                                height: 80, 
                                mb: 1,
                                border: `2px solid ${theme.palette.primary.main}`,
                                boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                              }}
                            />
                            <Typography variant="subtitle1" fontWeight="bold" align="center">
                              Client Review
                            </Typography>
                            <Typography variant="caption" color="text.secondary" align="center">
                              {moment(review.r_createdAt).fromNow()}
                            </Typography>
                            <Box sx={{ mt: 1 }}>
                              <StyledRating 
                                value={Number(review.rating)} 
                                readOnly 
                                precision={0.5}
                                icon={<StarIcon fontSize="inherit" />}
                                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <Typography 
                            variant="body1" 
                            sx={{ 
                              fontStyle: 'italic', 
                              color: theme.palette.text.primary,
                              lineHeight: 1.8,
                              position: 'relative',
                              '&::before': {
                                content: '"""',
                                fontSize: '2rem',
                                position: 'absolute',
                                left: -10,
                                top: -10,
                                color: alpha(theme.palette.primary.main, 0.2),
                              },
                              '&::after': {
                                content: '"""',
                                fontSize: '2rem',
                                position: 'relative',
                                marginLeft: '0.2em',
                                verticalAlign: 'bottom',
                                color: alpha(theme.palette.primary.main, 0.2),
                              }
                            }}
                          >
                            {review.review}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ReviewCard>
                  ))}
                </Stack>
              ) : (
                <Box sx={{ 
                  p: 5, 
                  textAlign: 'center',
                  background: alpha(theme.palette.background.paper, 0.5),
                  borderRadius: 2
                }}>
                  <ChatBubbleOutlineIcon sx={{ fontSize: 60, color: 'text.disabled', mb: 2 }} />
                  <Typography color="text.secondary" variant="h6">
                    No reviews available yet
                  </Typography>
                  <Typography color="text.secondary" variant="body2" sx={{ mt: 1 }}>
                    This talent has not received any client reviews so far.
                  </Typography>
                </Box>
              )}
            </SectionContent>
          </SectionCard>
        </TabPanel>

        <TabPanel value={tabValue} index={3}>
          {/* Documents Section */}
          <Grid container spacing={3}>
            {/* Resume */}
            <Grid item xs={12} md={6}>
              <SectionCard elevation={2}>
                <SectionHeader>
                  <SectionTitle variant="h6">
                    <SectionIcon>
                      <DescriptionIcon />
                    </SectionIcon>
                    {intl.formatMessage({ id: 'resume' })}
                  </SectionTitle>
                </SectionHeader>
                <SectionContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
                  {state.resume ? (
                    <>
                      <DescriptionIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                      <Typography variant="h6" gutterBottom>Resume Available</Typography>
                      <Typography color="text.secondary" align="center" paragraph>
                        View or download the candidate's resume
                      </Typography>
                      <DocumentViewer 
                        url={resume}
                        buttonText={intl.formatMessage({ id: 'viewResume', defaultMessage: 'View Resume' })}
                        filename={state.resume.split('/').pop()}
                      />
                    </>
                  ) : (
                    <>
                      <DescriptionIcon sx={{ fontSize: 60, color: 'text.disabled', mb: 2 }} />
                      <Typography variant="h6" color="text.secondary" gutterBottom>
                        No Resume Available
                      </Typography>
                      <Typography color="text.secondary" align="center">
                        This talent has not uploaded a resume.
                      </Typography>
                    </>
                  )}
                </SectionContent>
              </SectionCard>
            </Grid>

            {/* Coding Assessment */}
            <Grid item xs={12} md={6}>
              <SectionCard elevation={2}>
                <SectionHeader>
                  <SectionTitle variant="h6">
                    <SectionIcon>
                      <CodeIcon />
                    </SectionIcon>
                    Coding Assessment
                  </SectionTitle>
                </SectionHeader>
                <SectionContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 4 }}>
                  {state.coding_assessment_url ? (
                    <>
                      <Assessment sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                      <Typography variant="h6" gutterBottom>Assessment Available</Typography>
                      <Typography color="text.secondary" align="center" paragraph>
                        View the candidate's coding assessment results
                      </Typography>
                      <Button
                        variant="contained"
                        startIcon={<Assessment />}
                        href={assessment}
                        target="_blank"
                        rel="noopener noreferrer"
                        disabled={!assessment}
                        sx={{ 
                          mt: 2,
                          borderRadius: 30,
                          px: 3,
                          py: 1,
                        }}
                      >
                        View Assessment Results
                      </Button>
                    </>
                  ) : (
                    <>
                      <Assessment sx={{ fontSize: 60, color: 'text.disabled', mb: 2 }} />
                      <Typography variant="h6" color="text.secondary" gutterBottom>
                        No Assessment Available
                      </Typography>
                      <Typography color="text.secondary" align="center">
                        This talent has not completed a coding assessment.
                      </Typography>
                    </>
                  )}
                </SectionContent>
              </SectionCard>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={4}>
          {/* Other Information Section */}
          <SectionCard elevation={2}>
            <SectionHeader>
              <SectionTitle variant="h6">
                <SectionIcon>
                  <SettingsIcon />
                </SectionIcon>
                {intl.formatMessage({ id: "otherInformation" })}
              </SectionTitle>
            </SectionHeader>
            <SectionContent>
              <Grid container spacing={4}>
                {/* LinkedIn Field */}
                <Grid item xs={12} sm={6}>
                  <Paper 
                    elevation={2} 
                    sx={{ 
                      p: 3, 
                      borderRadius: 2,
                      height: '100%'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Avatar sx={{ bgcolor: '#0077B5', mr: 2 }}>
                        <LinkedIn />
                      </Avatar>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {intl.formatMessage({ id: "linkedIn" })}
                      </Typography>
                    </Box>
                    {editMode ? (
                      <TextField
                        fullWidth
                        value={state.linkedin || ""}
                        onChange={(e) => handleInputChange("linkedin", e.target.value)}
                        placeholder="LinkedIn URL"
                        size="small"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <LinkIcon sx={{ mr: 1, color: 'action.active' }} />,
                        }}
                      />
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LinkIcon sx={{ mr: 1, color: 'primary.main' }} />
                        {state.linkedin ? (
                          <MuiLink 
                            href={state.linkedin} 
                            target="_blank" 
                            rel="noopener"
                            sx={{
                              fontWeight: 500,
                              '&:hover': {
                                textDecoration: 'none',
                                color: 'primary.dark',
                              }
                            }}
                          >
                            {state.linkedin}
                          </MuiLink>
                        ) : (
                          <Typography color="text.secondary">Not provided</Typography>
                        )}
                      </Box>
                    )}
                  </Paper>
                </Grid>

                {/* Preferred Work Hours Field */}
                <Grid item xs={12} sm={6}>
                  <Paper 
                    elevation={2} 
                    sx={{ 
                      p: 3, 
                      borderRadius: 2,
                      height: '100%'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Avatar sx={{ bgcolor: '#673AB7', mr: 2 }}>
                        <AccessTimeIcon />
                      </Avatar>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {intl.formatMessage({ id: "preferredWorkHours" })}
                      </Typography>
                    </Box>
                    {editMode ? (
                      <Select
                        value={state.workhours || ""}
                        onChange={(e) => handleInputChange("workhours", e.target.value)}
                        size="small"
                        displayEmpty
                        fullWidth
                        variant="outlined"
                      >
                        <MenuItem value="">Select work hours</MenuItem>
                        <MenuItem value="Morning Shift, 9am - 6pm">Morning Shift, 9am - 6pm</MenuItem>
                        <MenuItem value="Evening Shift, 10pm - 7am">Evening Shift, 10pm - 7am</MenuItem>
                      </Select>
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTimeIcon sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography fontWeight={500}>
                          {state.workhours || "Not specified"}
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Grid>

                {/* Calendly URL Field */}
                <Grid item xs={12} sm={6}>
                  <Paper 
                    elevation={2} 
                    sx={{ 
                      p: 3, 
                      borderRadius: 2,
                      height: '100%'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Avatar sx={{ bgcolor: '#FF5722', mr: 2 }}>
                        <EventAvailableIcon />
                      </Avatar>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {intl.formatMessage({ id: "calendlyUrl" })}
                      </Typography>
                    </Box>
                    {editMode ? (
                      <TextField
                        fullWidth
                        value={state.calendlyurl || ""}
                        onChange={(e) => handleInputChange("calendlyurl", e.target.value)}
                        placeholder="Calendly URL"
                        size="small"
                        variant="outlined"
                        InputProps={{
                          startAdornment: <LinkIcon sx={{ mr: 1, color: 'action.active' }} />,
                        }}
                      />
                    ) : (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LinkIcon sx={{ mr: 1, color: 'primary.main' }} />
                        {state.calendlyurl ? (
                          <MuiLink 
                            href={state.calendlyurl} 
                            target="_blank" 
                            rel="noopener"
                            sx={{
                              fontWeight: 500,
                              '&:hover': {
                                textDecoration: 'none',
                                color: 'primary.dark',
                              }
                            }}
                          >
                            Schedule Meeting
                          </MuiLink>
                        ) : (
                          <Typography color="text.secondary">Not provided</Typography>
                        )}
                      </Box>
                    )}
                  </Paper>
                </Grid>

                {/* Rate Per Hour */}
                <Grid item xs={12} sm={6}>
                  <Paper 
                    elevation={2} 
                    sx={{ 
                      p: 3, 
                      borderRadius: 2,
                      height: '100%'
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Avatar sx={{ bgcolor: '#4CAF50', mr: 2 }}>
                        <AttachMoneyIcon />
                      </Avatar>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {intl.formatMessage({ id: "ratePerHour" })}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <AttachMoneyIcon sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography variant="h5" fontWeight="bold" color="primary.main">
                        {state.currency} {state.ratePerhour}
                        <Typography component="span" variant="body1" color="text.secondary" sx={{ ml: 1 }}>
                          / hour
                        </Typography>
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </SectionContent>
          </SectionCard>
        </TabPanel>

        {/* Partner Details Dialog */}
        <Dialog
          open={partnerDetailsOpen}
          onClose={() => setPartnerDetailsOpen(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 3,
              overflow: 'visible',
              boxShadow: '0 12px 30px rgba(0, 0, 0, 0.2)',
            }
          }}
        >
          <DialogTitle 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              background: 'linear-gradient(135deg, #FF8E53 0%, #FE6B8B 100%)',
              color: '#fff',
              borderRadius: '12px 12px 0 0',
              position: 'relative',
              overflow: 'hidden',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'radial-gradient(circle at top right, rgba(255,255,255,0.2) 0%, transparent 70%)',
                zIndex: 0,
              }
            }}
          >
            <Typography variant="h5" fontWeight="bold" sx={{ zIndex: 1, display: 'flex', alignItems: 'center' }}>
              <Business sx={{ mr: 1.5 }} /> Partner Company Details
            </Typography>
            <IconButton 
              onClick={() => setPartnerDetailsOpen(false)} 
              sx={{
                zIndex: 1,
                color: 'white',
                bgcolor: 'rgba(255,255,255,0.2)',
                '&:hover': {
                  bgcolor: 'rgba(255,255,255,0.3)',
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent 
            dividers 
            sx={{
              p: 4,
            }}
          >
            {state.partnerdetails && (
            <Box sx={{ position: 'relative' }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={3} display="flex" justifyContent="center" alignItems="flex-start">
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <Tooltip title="Verified Partner">
                        <Avatar
                          sx={{
                            bgcolor: 'success.main',
                            width: 30,
                            height: 30,
                            border: `2px solid ${theme.palette.background.paper}`,
                          }}
                        >
                          <VerifiedUserIcon fontSize="small" />
                        </Avatar>
                      </Tooltip>
                    }
                  >
                    <PartnerLogo
                      src={partnerImageUrl || defaultimg}
                      alt={state.partnerdetails.companyName}
                    />
                  </Badge>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant="h4" fontWeight="bold" gutterBottom>
                    {state.partnerdetails.companyName}
                  </Typography>
                  <Typography 
                    variant="subtitle1" 
                    gutterBottom
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      color: theme.palette.primary.dark,
                      fontWeight: 500,
                    }}
                  >
                    <AccountCircleIcon sx={{ mr: 1 }} fontSize="small" />
                    {state.partnerdetails.firstName} {state.partnerdetails.lastName}
                  </Typography>
                  
                  <Box 
                    sx={{ 
                      mt: 3, 
                      p: 2, 
                      borderRadius: 2, 
                      background: alpha(theme.palette.primary.light, 0.1),
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                          <Avatar sx={{ bgcolor: 'primary.main', width: 32, height: 32, mr: 1.5 }}>
                            <PhoneIcon fontSize="small" />
                          </Avatar>
                          <Box>
                            <Typography variant="caption" color="text.secondary">PHONE</Typography>
                            <Typography variant="body1" fontWeight="medium">{state.partnerdetails.phone}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                          <Avatar sx={{ bgcolor: 'primary.main', width: 32, height: 32, mr: 1.5 }}>
                            <LocationIcon fontSize="small" />
                          </Avatar>
                          <Box>
                            <Typography variant="caption" color="text.secondary">LOCATION</Typography>
                            <Typography variant="body1" fontWeight="medium">{state.partnerdetails.region}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  
                  <Divider sx={{ 
                    my: 3,
                    borderColor: alpha(theme.palette.primary.main, 0.2),
                    borderWidth: 1,
                    borderRadius: 1,
                  }} />
                  
                  <Box sx={{ mb: 4 }}>
                    <Typography 
                      variant="h6" 
                      gutterBottom 
                      sx={{ 
                        color: theme.palette.primary.dark,
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Business sx={{ mr: 1 }} /> About
                    </Typography>
                    <Paper
                      elevation={1}
                      sx={{
                        p: 2.5,
                        borderRadius: 2,
                        background: alpha(theme.palette.background.paper, 0.7),
                      }}
                    >
                      <Typography 
                        variant="body1" 
                        paragraph
                        sx={{ 
                          lineHeight: 1.8,
                          fontSize: '1.05rem',
                        }}
                      >
                        {state.partnerdetails.about}
                      </Typography>
                    </Paper>
                  </Box>
                  
                  <Box>
                    <Typography 
                      variant="h6" 
                      gutterBottom 
                      sx={{ 
                        color: theme.palette.primary.dark,
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <VerifiedUserIcon sx={{ mr: 1 }} /> Expertise
                    </Typography>
                    <Paper
                      elevation={1}
                      sx={{
                        p: 2.5,
                        borderRadius: 2,
                        background: alpha(theme.palette.background.paper, 0.7),
                      }}
                    >
                      <Typography 
                        variant="body1" 
                        paragraph
                        sx={{ 
                          lineHeight: 1.8,
                          fontSize: '1.05rem',
                        }}
                      >
                        {state.partnerdetails.expertise}
                      </Typography>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
              
              {/* Partner Video Section */}
              {partnerVideoUrl && (
                <Box mt={5} sx={{ position: 'relative' }}>
                  <Box 
                    sx={{ 
                      position: 'absolute',
                      top: -15,
                      left: 40,
                      zIndex: 10,
                      background: 'linear-gradient(135deg, #FF4B2B 0%, #FF416C 100%)',
                      px: 2.5,
                      py: 1,
                      borderRadius: '20px',
                      boxShadow: '0 4px 15px rgba(0,0,0,0.15)',
                    }}
                  >
                    <Typography 
                      variant="subtitle1" 
                      fontWeight="bold" 
                      color="white" 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center' 
                      }}
                    >
                      <Videocam sx={{ mr: 1 }} />
                      Company Introduction Video
                    </Typography>
                  </Box>
                  <Paper 
                    elevation={4} 
                    sx={{ 
                      overflow: 'hidden', 
                      borderRadius: 3,
                      position: 'relative',
                      paddingTop: '56.25%', // 16:9 aspect ratio
                      mt: 3,
                      border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
                      boxShadow: `0 10px 30px ${alpha(theme.palette.primary.main, 0.1)}`,
                    }}
                  >
                    <Box
                      component="video"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                      controls
                      src={partnerVideoUrl}
                    >
                      Your browser does not support the video tag.
                    </Box>
                  </Paper>
                </Box>
              )}
            </Box>
          )}
          </DialogContent>
          <DialogActions sx={{
            p: 3,
          }}>
            <Button 
              onClick={() => setPartnerDetailsOpen(false)} 
              variant="contained"
              color="primary"
              size="large"
              startIcon={<CloseIcon />}
              sx={{
                borderRadius: 30,
                px: 4,
                py: 1,
                textTransform: 'none',
                fontWeight: 600,
                boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
              }}
            >
              Close Details
            </Button>
          </DialogActions>
        </Dialog>
        
        {/* Floating Actions */}
        <Box
          sx={{
            position: 'fixed',
            bottom: 30,
            right: 30,
            zIndex: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {/* <Tooltip title="Contact Talent">
            <Fab
              color="primary"
              aria-label="contact"
              sx={{
                boxShadow: '0 4px 20px rgba(76, 175, 80, 0.4)',
                '&:hover': {
                  boxShadow: '0 6px 25px rgba(76, 175, 80, 0.6)',
                },
              }}
              onClick={() => window.open(state.calendlyurl || '#', '_blank')}
            >
              <ChatBubbleOutlineIcon />
            </Fab>
          </Tooltip> */}
          
          <Tooltip title="Back to Talent List">
            <Fab
              color="secondary"
              aria-label="back"
              sx={{
                boxShadow: '0 4px 20px rgba(33, 150, 243, 0.4)',
                '&:hover': {
                  boxShadow: '0 6px 25px rgba(33, 150, 243, 0.6)',
                },
              }}
              onClick={() => history.push('/browse-talent')}
            >
              <ArrowBack />
            </Fab>
          </Tooltip>
        </Box>
      </PageWrapper>
    </StyledContainer>
  );
};

export default Candidatedetailsbyid;