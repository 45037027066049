import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';

// Auto logout time - 1 hour for production
const AUTO_LOGOUT_TIME = 60 * 60 * 1000; // 1 hour in milliseconds
const INACTIVITY_CHECK_INTERVAL = 60 * 1000; // Check every minute in production
const ACTIVITY_TRACKER_KEY = 'lastActivityTimestamp';

// Enable debug mode for testing
const DEBUG = true;

const log = (...args) => {
  if (DEBUG) {
    console.log('[AutoLogout]', ...args);
  }
};

// Initialize session with timestamp logging
export const initializeSession = () => {
  const timestamp = Date.now();
  const oldTimestamp = localStorage.getItem(ACTIVITY_TRACKER_KEY);
  
  // Store the timestamp in localStorage
  try {
    localStorage.setItem(ACTIVITY_TRACKER_KEY, timestamp.toString());
    log('Session initialized at:', new Date(timestamp).toLocaleTimeString(), 
        'Previous timestamp:', oldTimestamp ? new Date(parseInt(oldTimestamp, 10)).toLocaleTimeString() : 'none');
  } catch (error) {
    log('ERROR: Failed to set timestamp in localStorage:', error.message);
  }
  
  // Explicitly verify localStorage after initialization
  setTimeout(() => {
    try {
      const storedTimestamp = localStorage.getItem(ACTIVITY_TRACKER_KEY);
      log('Verification - stored timestamp is:', storedTimestamp ? 
          new Date(parseInt(storedTimestamp, 10)).toLocaleTimeString() : 'none');
      
      // Double-verify by setting it again if missing
      if (!storedTimestamp) {
        const newTimestamp = Date.now(); 
        localStorage.setItem(ACTIVITY_TRACKER_KEY, newTimestamp.toString());
        log('RECOVERY: Missing timestamp detected during verification, reset to:', 
            new Date(newTimestamp).toLocaleTimeString());
      }
    } catch (error) {
      log('ERROR: Failed to verify timestamp in localStorage:', error.message);
    }
  }, 100);
};

const AutoLogout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const initialCheckComplete = useRef(false);
  const currentlyLoggingOut = useRef(false);
  const activityCount = useRef(0);
  
  // Debug AWS auth - safely handle errors
  const debugAwsAuth = async () => {
    console.log('===== AWS AUTH DEBUG INFO =====');
    try {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log('User found:', user ? 'Object' : 'None');
      } catch (userError) {
        console.log('Error getting authenticated user:', userError.message);
      }
      
      try {
        const session = await Auth.currentSession();
        console.log('Session found: Valid');
      } catch (sessionError) {
        console.log('Error getting session:', sessionError.message);
      }
    } catch (error) {
      console.log('Error during AWS auth debug:', error.message);
    }
    
    console.log('LocalStorage auth-related items:');
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes('aws') || key.includes('token') || key.includes('auth') || 
          key.includes('user') || key.includes('amplify') || key === ACTIVITY_TRACKER_KEY) {
        console.log(`- ${key}: ${localStorage.getItem(key).substring(0, 50)}...`);
      }
    }
    console.log('===== END DEBUG INFO =====');
  };

  // Authentication check using both Redux and AWS Amplify
  const checkAuthentication = async () => {
    try {
      // First check Redux state
      if (!user) {
        log('Redux auth check: No user in Redux state');
        setIsAuthenticated(false);
        return false;
      }
      
      // Then verify with AWS Amplify
      try {
        await Auth.currentAuthenticatedUser();
        log('AWS auth check: Valid session found');
      } catch (error) {
        log('AWS auth check: No valid session -', error.message);
        // No valid AWS session, force logout
        setIsAuthenticated(false);
        return false;
      }
      
      // Check for last activity to determine if session is expired
      const lastActivity = localStorage.getItem(ACTIVITY_TRACKER_KEY);
      if (lastActivity) {
        const lastActivityTime = parseInt(lastActivity, 10);
        const currentTime = Date.now();
        const timeSinceLastActivity = currentTime - lastActivityTime;
        
        log('Auth check with timestamp verification:',
            'Last activity:', new Date(lastActivityTime).toLocaleTimeString(),
            'Current time:', new Date(currentTime).toLocaleTimeString(),
            'Time since last activity:', Math.round(timeSinceLastActivity/1000), 'seconds');
        
        // If we have a timestamp and it's expired, consider user not authenticated
        if (timeSinceLastActivity > AUTO_LOGOUT_TIME) {
          log('EXPIRED SESSION DETECTED during auth check - Time since activity:', 
              Math.round(timeSinceLastActivity/1000), 'seconds exceeds limit of', 
              AUTO_LOGOUT_TIME/1000, 'seconds');
          setIsAuthenticated(false);
          return false;
        }
      } else {
        log('WARNING: No timestamp found during auth check');
        // When no timestamp is found but user exists in Redux, create a new timestamp
        const timestamp = Date.now();
        localStorage.setItem(ACTIVITY_TRACKER_KEY, timestamp.toString());
        log('Created new timestamp during auth check:', new Date(timestamp).toLocaleTimeString());
      }
      
      log('Auth check: User authenticated in both Redux and AWS');
      setIsAuthenticated(true);
      return true;
    } catch (error) {
      log('Error during authentication check:', error.message);
      setIsAuthenticated(false);
      return false;
    }
  };

  // Logout function with protection against multiple calls
  const logout = async () => {
    // Prevent multiple logout attempts
    if (currentlyLoggingOut.current) {
      log('Logout already in progress, skipping duplicate call');
      return;
    }
    
    currentlyLoggingOut.current = true;
    log('Performing auto-logout due to inactivity...');
    
    try {
      // First clear the timestamp to prevent race conditions
      localStorage.removeItem(ACTIVITY_TRACKER_KEY);
      log('Timestamp removed from localStorage');
      
      // Then sign out from AWS
      try {
        await Auth.signOut();
        log('AWS signout successful');
      } catch (error) {
        log('Error during AWS signout:', error.message);
        // Continue with local logout even if AWS fails
      }
      
      // Update Redux state
      dispatch({ type: 'LOGOUT' });
      log('Redux logout dispatched');
      
      // Final verification
      setTimeout(() => {
        const verifyTimestamp = localStorage.getItem(ACTIVITY_TRACKER_KEY);
        if (verifyTimestamp) {
          log('WARNING: Timestamp still present after logout, removing again');
          localStorage.removeItem(ACTIVITY_TRACKER_KEY);
        }
        
        log('Auto-logout successful, redirecting to login page');
        history.push('/login');
        
        // Reset logout flag after redirection
        currentlyLoggingOut.current = false;
      }, 100);
    } catch (error) {
      log('Error during logout process:', error.message);
      // Last resort - force reload the page
      log('Forcing page reload as fallback');
      window.location.href = '/login';
    }
  };

  // Update last activity timestamp with minimal logging
  const updateActivityTimestamp = () => {
    if (initialCheckComplete.current && isAuthenticated) {
      const timestamp = Date.now();
      localStorage.setItem(ACTIVITY_TRACKER_KEY, timestamp.toString());
      
      // Increment activity counter
      activityCount.current += 1;
      
      // Only log very occasionally to reduce performance impact
      if (activityCount.current % 100 === 0) {
        log('Activity count:', activityCount.current, '- Last update:', 
            new Date(timestamp).toLocaleTimeString());
      }
    }
    // No logging for non-authenticated activity to reduce console spam
  };

  // Enhanced inactivity check with better logging
  const checkInactivity = () => {
    // Don't check if already logging out
    if (currentlyLoggingOut.current) {
      log('Skipping inactivity check - logout already in progress');
      return false;
    }
    
    // Make sure we're authenticated before checking
    if (!isAuthenticated) {
      log('Skipping inactivity check - not authenticated');
      return false;
    }
    
    const lastActivity = localStorage.getItem(ACTIVITY_TRACKER_KEY);
    if (!lastActivity) {
      log('No activity timestamp found in localStorage - this is unexpected if properly initialized');
      if (isAuthenticated) {
        // Auto-set timestamp if missing but authenticated to prevent immediate logout
        const timestamp = Date.now();
        localStorage.setItem(ACTIVITY_TRACKER_KEY, timestamp.toString());
        log('Auto-created missing timestamp:', new Date(timestamp).toLocaleTimeString());
      }
      return false;
    }

    const lastActivityTime = parseInt(lastActivity, 10);
    const currentTime = Date.now();
    const timeSinceLastActivity = currentTime - lastActivityTime;
    
    log(
      'Inactivity check:',
      'Last activity:', new Date(lastActivityTime).toLocaleTimeString(),
      'Current time:', new Date(currentTime).toLocaleTimeString(),
      'Inactive for:', Math.round(timeSinceLastActivity/1000), 'seconds',
      'Timeout:', AUTO_LOGOUT_TIME/1000, 'seconds'
    );

    if (timeSinceLastActivity > AUTO_LOGOUT_TIME) {
      log('TIMEOUT EXCEEDED - User inactive for', Math.round(timeSinceLastActivity/1000), 
          'seconds (threshold:', AUTO_LOGOUT_TIME/1000, 'seconds)');
      return true;
    }
    
    const remainingTime = AUTO_LOGOUT_TIME - timeSinceLastActivity;
    log('User still active - seconds remaining:', Math.round(remainingTime/1000),
        '- minutes remaining:', Math.round(remainingTime/60000));
    return false;
  };

  // Track authentication state changes
  useEffect(() => {
    log('Authentication state changed to:', isAuthenticated);
    
    // Reset activity counter when auth state changes
    activityCount.current = 0;
  }, [isAuthenticated]);

  // Handle tab visibility change more robustly
  const handleVisibilityChange = async () => {
    log('Visibility changed to:', document.visibilityState);
    
    if (document.visibilityState === 'visible') {
      log('Tab became visible, performing full auth check...');
      
      // Perform a complete authentication check when tab becomes visible
      const isAuth = await checkAuthentication();
      
      if (!isAuth) {
        log('Auth check failed after tab became visible, logging out');
        logout();
      } else {
        log('Auth check passed after tab became visible, updating timestamp');
        updateActivityTimestamp();
      }
    }
  };

  useEffect(() => {
    log('AutoLogout component mounted');
    
    // Force a periodic re-render less frequently to reduce performance impact
    const forceUpdateInterval = setInterval(() => {
      // This small state update ensures React keeps the component "alive"
      setIsAuthenticated(prevState => {
        // Only update if still authenticated to avoid unnecessary renders
        if (prevState) {
          // Minimal logging here
          const lastActivity = localStorage.getItem(ACTIVITY_TRACKER_KEY);
          if (lastActivity) {
            const lastActivityTime = parseInt(lastActivity, 10);
            const timeSinceLastActivity = Date.now() - lastActivityTime;
            if (timeSinceLastActivity > AUTO_LOGOUT_TIME) {
              log('FORCE UPDATE DETECTED TIMEOUT - logging out');
              logout();
              return false;
            }
          }
        }
        return prevState;
      });
    }, 5 * 60 * 1000); // Every 5 minutes for production
    
    // Verify timestamp is present
    const storedTimestamp = localStorage.getItem(ACTIVITY_TRACKER_KEY);
    log('Initial stored timestamp on mount:', storedTimestamp ? 
        new Date(parseInt(storedTimestamp, 10)).toLocaleTimeString() : 'none');
    
    // For debugging only
    setTimeout(() => {
      debugAwsAuth();
    }, 500); // Delay debug to allow AWS to initialize
    
    const initializeComponent = async () => {
      try {
        // On component mount, check if we need to immediately logout due to stored timeout
        const lastActivity = localStorage.getItem(ACTIVITY_TRACKER_KEY);
        if (lastActivity) {
          const lastActivityTime = parseInt(lastActivity, 10);
          const currentTime = Date.now();
          const timeSinceLastActivity = currentTime - lastActivityTime;
          
          log('Initial timeout check on component mount:',
              'Last activity:', new Date(lastActivityTime).toLocaleTimeString(),
              'Current time:', new Date(currentTime).toLocaleTimeString(),
              'Time elapsed:', Math.round(timeSinceLastActivity/1000), 'seconds',
              'Timeout limit:', AUTO_LOGOUT_TIME/1000, 'seconds');
          
          if (timeSinceLastActivity > AUTO_LOGOUT_TIME) {
            log('INITIAL TIMEOUT DETECTED: Session expired on mount - inactive for', 
              Math.round(timeSinceLastActivity/60000), 'minutes - logging out immediately');
            logout();
            return;
          }
        }
      
        // Now check if user exists in Redux and AWS
        const isAuth = await checkAuthentication();
        
        if (!isAuth) {
          log('Not authenticated according to checks, skipping inactivity setup');
          return;
        }
        
        log('User authenticated, checking inactivity');
        
        // Verify timestamp again after auth check
        const timestampAfterAuthCheck = localStorage.getItem(ACTIVITY_TRACKER_KEY);
        log('Timestamp after auth check:', timestampAfterAuthCheck ? 
            new Date(parseInt(timestampAfterAuthCheck, 10)).toLocaleTimeString() : 'none');
        
        // Auto-initialize timestamp if missing but authenticated
        if (!timestampAfterAuthCheck) {
          log('No timestamp found but user is authenticated - initializing now');
          initializeSession();
        }
        
        // Check for inactivity
        if (checkInactivity()) {
          log('User has been inactive too long, logging out');
          logout();
          return;
        }
        
        // User is authenticated and not inactive
        log('User is authenticated and still active, setting up monitoring');
        initialCheckComplete.current = true;
        
        // Set up activity monitoring with optimized event list
        const activityEvents = [
          // Mouse/touch major events only
          'mousedown', 'touchstart',
          // Keyboard events
          'keydown',
          // Form interaction
          'input', 'change', 'submit',
          // Page navigation
          'scroll'
        ];
        
        log('Setting up optimized activity listeners');
        activityEvents.forEach(event => {
          // Only attach to document to reduce duplicate events
          document.addEventListener(event, updateActivityTimestamp, { passive: true });
        });
        
        // Periodic inactivity checks with minimal logging for performance
        log(`Setting up inactivity check interval: every ${INACTIVITY_CHECK_INTERVAL/1000} seconds`);
        const inactivityCheckInterval = setInterval(() => {
          // Force re-read from localStorage to ensure we have the latest data
          const lastActivity = localStorage.getItem(ACTIVITY_TRACKER_KEY);
          if (!lastActivity) {
            log('WARNING: No activity timestamp found during interval check - resetting');
            updateActivityTimestamp(); // Force update timestamp
            return;
          }
          
          const lastActivityTime = parseInt(lastActivity, 10);
          const currentTime = Date.now();
          const timeSinceLastActivity = currentTime - lastActivityTime;
          
          // Only log when approaching timeout to reduce console spam
          if (timeSinceLastActivity > (AUTO_LOGOUT_TIME * 0.8)) {
            log(
              'Approaching timeout:',
              'Inactive for:', Math.round(timeSinceLastActivity/60000), 'minutes',
              'Remaining:', Math.round((AUTO_LOGOUT_TIME - timeSinceLastActivity)/60000), 'minutes'
            );
          }
          
          if (timeSinceLastActivity > AUTO_LOGOUT_TIME) {
            log('TIMEOUT EXCEEDED - Inactive for', 
                Math.round(timeSinceLastActivity/60000), 'minutes - LOGGING OUT NOW');
            logout();
          }
        }, INACTIVITY_CHECK_INTERVAL);
        
        // Set up robust visibility change handling
        document.addEventListener('visibilitychange', handleVisibilityChange);
        
        // Set up window focus event
        window.addEventListener('focus', async () => {
          log('Window focus detected, performing full auth check');
          const isAuth = await checkAuthentication();
          if (!isAuth) {
            log('Auth check failed after window focus, logging out');
            logout();
          } else {
            log('Auth check passed after window focus');
            updateActivityTimestamp();
          }
        });
        
        // Handle storage events to synchronize across tabs
        window.addEventListener('storage', (event) => {
          if (event.key === ACTIVITY_TRACKER_KEY) {
            log('Storage event detected for activity tracker');
            if (!event.newValue && isAuthenticated) {
              log('Activity timestamp cleared in another tab - logging out in this tab');
              logout();
            }
          }
        });
        
        return () => {
          log('AutoLogout component unmounting, removing listeners');
          activityEvents.forEach(event => {
            document.removeEventListener(event, updateActivityTimestamp);
          });
          document.removeEventListener('visibilitychange', handleVisibilityChange);
          window.removeEventListener('focus', async () => {
            log('Window focus detected, performing full auth check');
            const isAuth = await checkAuthentication();
            if (!isAuth) {
              log('Auth check failed after window focus, logging out');
              logout();
            } else {
              log('Auth check passed after window focus');
              updateActivityTimestamp();
            }
          });
          window.removeEventListener('storage', (event) => {
            if (event.key === ACTIVITY_TRACKER_KEY) {
              log('Storage event detected for activity tracker');
              if (!event.newValue && isAuthenticated) {
                log('Activity timestamp cleared in another tab - logging out in this tab');
                logout();
              }
            }
          });
          clearInterval(inactivityCheckInterval);
          clearInterval(forceUpdateInterval);
        };// This is the completion of the AutoLogout component
        // Add this code at the end of your existing file
        
              } catch (error) {
                log('Error during component initialization:', error.message);
                // On initialization error, force logout to be safe
                logout();
              }
            };
            
            initializeComponent();
            
            // Extra safety - check session on page load completion
            window.addEventListener('load', async () => {
              log('Window load event detected, verifying session');
              const isAuth = await checkAuthentication();
              if (!isAuth) {
                log('Auth check failed after window load, logging out');
                logout();
              }
            });
            
            // Set up beforeunload event to capture tab/window closing
            window.addEventListener('beforeunload', () => {
              log('Window unload event detected, updating last activity time');
              if (isAuthenticated) {
                const timestamp = Date.now();
                localStorage.setItem(ACTIVITY_TRACKER_KEY, timestamp.toString());
                log('Updated timestamp before unload:', new Date(timestamp).toLocaleTimeString());
              }
            });
            
            // Return cleanup function to remove load and beforeunload listeners
            return () => {
              log('Removing additional window event listeners');
              window.removeEventListener('load', async () => {
                log('Window load event detected, verifying session');
                const isAuth = await checkAuthentication();
                if (!isAuth) {
                  log('Auth check failed after window load, logging out');
                  logout();
                }
              });
              
              window.removeEventListener('beforeunload', () => {
                log('Window unload event detected, updating last activity time');
                if (isAuthenticated) {
                  const timestamp = Date.now();
                  localStorage.setItem(ACTIVITY_TRACKER_KEY, timestamp.toString());
                  log('Updated timestamp before unload:', new Date(timestamp).toLocaleTimeString());
                }
              });
            };
          }, [dispatch, history, user]);
        
          // Add a method to force logout from outside the component
          const forceLogout = () => {
            log('External force logout requested');
            logout();
          };
        
          // No visible UI - this is a utility component
          return null;
        };
        
        // Attach functions to the component
        AutoLogout.initializeSession = initializeSession;
        
        // Static function to force logout from anywhere in the application
        AutoLogout.logout = async () => {
          console.log('[AutoLogout] Static logout method: Logging out user');
          localStorage.removeItem(ACTIVITY_TRACKER_KEY);
          try {
            await Auth.signOut();
            console.log('[AutoLogout] Static logout method: Successfully signed out from AWS');
          } catch (error) {
            console.log('[AutoLogout] Static logout method: Error during AWS signout:', error.message);
          }
          window.location.href = '/login';
        };
        
        // Function to verify timestamp and logout if needed - can be called from anywhere
        AutoLogout.verifySession = async () => {
          console.log('[AutoLogout] Performing static session verification');
          try {
            // Check timestamp
            const lastActivity = localStorage.getItem(ACTIVITY_TRACKER_KEY);
            if (!lastActivity) {
              console.log('[AutoLogout] No activity timestamp found - session invalid');
              AutoLogout.logout();
              return false;
            }
            
            const lastActivityTime = parseInt(lastActivity, 10);
            const currentTime = Date.now();
            const timeSinceLastActivity = currentTime - lastActivityTime;
            
            if (timeSinceLastActivity > AUTO_LOGOUT_TIME) {
              console.log('[AutoLogout] Session expired - logging out');
              AutoLogout.logout();
              return false;
            }
            
            // Check AWS auth
            try {
              await Auth.currentAuthenticatedUser();
              console.log('[AutoLogout] AWS auth check passed');
            } catch (error) {
              console.log('[AutoLogout] AWS auth check failed:', error.message);
              AutoLogout.logout();
              return false;
            }
            
            return true;
          } catch (error) {
            console.log('[AutoLogout] Error during session verification:', error.message);
            AutoLogout.logout();
            return false;
          }
        };
        
        export default AutoLogout;