import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Select,
  MenuItem,
  Grid,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  FormControl,
  InputLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const InvoiceGenerator = () => {
  const [userType, setUserType] = useState('freelancer');
  const [hours, setHours] = useState({});
  const [podMembers, setPodMembers] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [invoiceData, setInvoiceData] = useState(null);

  // Sample data - In a real app, this would come from an API
  const samplePodMembers = [
    { id: 1, name: 'John Doe', role: 'Developer', hourlyRate: 100 },
    { id: 2, name: 'Jane Smith', role: 'Designer', hourlyRate: 90 },
    { id: 3, name: 'Mike Johnson', role: 'QA', hourlyRate: 80 }
  ];

  useEffect(() => {
    if (userType === 'partner') {
      setPodMembers(samplePodMembers);
    } else {
      setPodMembers([samplePodMembers[0]]); // Just the freelancer
    }
  }, [userType]);

  const handleUserTypeChange = (event, newValue) => {
    if (newValue !== null) {
      setUserType(newValue);
    }
  };

  const handleHoursChange = (memberId, newHours) => {
    setHours(prev => ({
      ...prev,
      [memberId]: parseFloat(newHours) || 0
    }));
  };

  const generateInvoice = () => {
    const invoiceItems = podMembers.map(member => ({
      name: member.name,
      hours: hours[member.id] || 0,
      rate: member.hourlyRate,
      total: (hours[member.id] || 0) * member.hourlyRate
    }));

    const totalAmount = invoiceItems.reduce((sum, item) => sum + item.total, 0);

    setInvoiceData({
      items: invoiceItems,
      total: totalAmount,
      month: selectedMonth,
      year: selectedYear
    });
  };

  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" gutterBottom>
          Invoice Generator
        </Typography>

        <StyledCard>
          <CardContent>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                User Type
              </Typography>
              <ToggleButtonGroup
                value={userType}
                exclusive
                onChange={handleUserTypeChange}
                color="primary"
              >
                <ToggleButton value="freelancer">
                  Freelancer
                </ToggleButton>
                <ToggleButton value="partner">
                  Partner
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    value={selectedMonth}
                    label="Month"
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    {months.map((month, index) => (
                      <MenuItem key={month} value={index}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select
                    value={selectedYear}
                    label="Year"
                    onChange={(e) => setSelectedYear(e.target.value)}
                  >
                    {[2023, 2024, 2025].map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Typography variant="h6" gutterBottom>
              Hours Worked
            </Typography>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              {podMembers.map(member => (
                <Grid item xs={12} key={member.id}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Typography>{member.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        type="number"
                        label="Hours"
                        fullWidth
                        value={hours[member.id] || ''}
                        onChange={(e) => handleHoursChange(member.id, e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography color="textSecondary">
                        ${member.hourlyRate}/hour
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={generateInvoice}
              size="large"
            >
              Generate Invoice
            </Button>

            {invoiceData && (
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6" gutterBottom>
                  Invoice Preview
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Hours</TableCell>
                        <TableCell align="right">Rate</TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceData.items.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.name}</TableCell>
                          <TableCell align="right">{item.hours}</TableCell>
                          <TableCell align="right">${item.rate}</TableCell>
                          <TableCell align="right">${item.total}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={3} align="right">
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Total
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            ${invoiceData.total}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </CardContent>
        </StyledCard>
      </Box>
    </Container>
  );
};

export default InvoiceGenerator;