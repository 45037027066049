import React from 'react';
import { styled } from '@mui/material/styles';
import { 
  Card, 
  CardContent, 
  Typography,
  Grid,
  Box,
  createTheme
} from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PercentIcon from '@mui/icons-material/Percent';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StarIcon from '@mui/icons-material/Star';

// Styled components
const theme = createTheme({
  palette: {
    primary: {
      main: '#4F46E5', // Modern indigo
      light: '#818CF8',
      dark: '#3730A3',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#10B981', // Modern emerald
      light: '#34D399',
      dark: '#059669',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#10B981', // Emerald
      light: '#6EE7B7',
      dark: '#047857'
    },
    warning: {
      main: '#F59E0B', // Warm amber
      light: '#FBBF24',
      dark: '#D97706'
    },
    error: {
      main: '#EF4444', // Vibrant red
      light: '#FCA5A5',
      dark: '#DC2626'
    },
    info: {
      main: '#3B82F6', // Bright blue
      light: '#60A5FA',
      dark: '#2563EB'
    },
    background: {
      default: '#F9FAFB',
      paper: '#FFFFFF'
    },
    text: {
      primary: '#111827',
      secondary: '#4B5563',
      disabled: '#9CA3AF'
    },
    divider: '#E5E7EB',
  },
  // Enhanced shadows for better depth
  shadows: [
    'none',
    '0px 1px 3px rgba(0, 0, 0, 0.08)',
    '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
    '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
    '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
  ]
});

// Styled components with theme.palette.mode approach
const PageWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#f0f0f0',
  minHeight: '100vh',
  width: '100vw',
}));

const DashboardContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'collapsed'
})(({ theme, collapsed }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: "center",
  margin: "auto",
  marginLeft: collapsed ? "60px" : "240px",
  padding: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#f0f0f0',
  height: 'auto',
  overflowY: 'scroll',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  paddingTop: '80px',
  fontFamily: 'Poppins, sans-serif',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  '@media (max-width: 768px)': {
    marginLeft: '60px',
    width: 'calc(100vw - 80px)',
    padding: '10px',
    paddingTop: '80px',
    overflow: 'visible',
  },
}));

const OpportunityCard = styled(Card)(({ theme, status }) => {
  // Define colors for different status types
  let backgroundColor;
  if (status === 'new') {
    backgroundColor = theme.palette.mode === 'dark' ? '#9368AD' : '#7A5195';
  } else if (status === 'inProgress') {
    backgroundColor = theme.palette.mode === 'dark' ? '#FF6B89' : '#EF5675';
  } else if (status === 'followUp') {
    backgroundColor = theme.palette.mode === 'dark' ? '#FFB733' : '#FFA600';
  }
  
  return {
    backgroundColor,
    color: 'white',
    padding: '16px',
    height: '100%',
    overflow: 'visible',
    boxShadow: theme.palette.mode === 'dark'
      ? '0px 4px 20px rgba(0, 0, 0, 0.3)'
      : '0px 4px 20px rgba(0, 0, 0, 0.15)',
    '@media (max-width: 768px)': {
      marginBottom: '20px',
    }
  };
});

const OpportunityItem = styled(Box)(({ theme }) => ({
  marginTop: '8px',
  padding: '12px',
  backgroundColor: theme => theme.palette.mode === 'dark' 
    ? 'rgba(255,255,255,0.15)' 
    : 'rgba(255,255,255,0.1)',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme => theme.palette.mode === 'dark' 
      ? 'rgba(255,255,255,0.25)' 
      : 'rgba(255,255,255,0.2)',
    cursor: 'pointer',
  },
  '@media (max-width: 768px)': {
    marginBottom: '10px',
  }
}));

const CardsContainer = styled('div')({
  display: 'flex',
  gap: '20px',
  marginBottom: '20px',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
});

const CardWrapper = styled('div')({
  position: 'relative',
  flex: 1,
});

const DataCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  position: 'relative',
  padding: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  boxShadow: theme => theme.palette.mode === 'dark'
    ? '0px 4px 20px rgba(0, 0, 0, 0.2)'
    : '0px 4px 20px rgba(34, 41, 47, 0.1)',
  height: '10rem',
  fontSize: '18px',
  fontFamily: 'Poppins, Medium',
  overflow: 'auto',
  borderRadius: '20px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme => theme.palette.mode === 'dark'
      ? '0px 12px 24px rgba(0, 0, 0, 0.3)'
      : '0px 12px 24px rgba(34, 41, 47, 0.15)'
  }
}));

const CardIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgColor'
})(({ theme, bgColor }) => {
  // Adjust brightness of icons in dark mode
  const getBgColor = () => {
    if (theme.palette.mode === 'dark') {
      // Make icon backgrounds brighter in dark mode
      switch(bgColor) {
        case '#1976d2': return '#2196f3'; // Blue
        case '#2e7d32': return '#4caf50'; // Green
        case '#9c27b0': return '#ba68c8'; // Purple
        case '#ed6c02': return '#ff9800'; // Orange
        default: return bgColor;
      }
    }
    return bgColor; // Return original color for light mode
  };
  
  return {
    position: 'absolute',
    top: '-30px',
    left: '20px',
    width: '60px',
    height: '60px',
    borderRadius: '20px',
    boxShadow: theme => theme.palette.mode === 'dark'
      ? '0px 4px 20px rgba(0, 0, 0, 0.2)'
      : '0px 4px 20px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    color: 'white',
    zIndex: 10,
    backgroundColor: getBgColor(),
  };
});

const ChartCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  backgroundColor: theme => theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  boxShadow: theme => theme.palette.mode === 'dark'
    ? '0px 4px 20px rgba(0, 0, 0, 0.2)'
    : '0px 4px 20px rgba(34, 41, 47, 0.1)',
  borderRadius: '16px',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme => theme.palette.mode === 'dark'
      ? '0px 12px 24px rgba(0, 0, 0, 0.3)'
      : '0px 12px 24px rgba(34, 41, 47, 0.15)'
  }
}));

const ContentCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  overflow: 'visible',
  backgroundColor: theme => theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  boxShadow: theme => theme.palette.mode === 'dark'
    ? '0px 4px 20px rgba(0, 0, 0, 0.2)'
    : '0px 4px 20px rgba(34, 41, 47, 0.1)',
  borderRadius: '16px',
  '@media (max-width: 768px)': {
    padding: theme.spacing(2),
  }
}));

const QuoteTable = styled('table')(({ theme }) => ({
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: '0',
  backgroundColor: theme => theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: theme => theme.palette.mode === 'dark'
    ? '0 4px 6px rgba(0, 0, 0, 0.3)'
    : '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const TableHeader = styled('th')(({ theme }) => ({
  backgroundColor: theme => theme.palette.mode === 'dark'
    ? '#333333'
    : '#f5f5f5',
  padding: '16px',
  textAlign: 'left',
  fontWeight: '600',
  color: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.9)'
    : '#333',
  borderBottom: theme => theme.palette.mode === 'dark'
    ? '2px solid #444'
    : '2px solid #e0e0e0',
  '&:first-of-type': {
    paddingLeft: '24px',
  },
  '&:last-of-type': {
    paddingRight: '24px',
  },
}));

const TableCell = styled('td')(({ theme }) => ({
  padding: '16px',
  borderBottom: theme => theme.palette.mode === 'dark'
    ? '1px solid #444'
    : '1px solid #e0e0e0',
  color: theme => theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, 0.7)'
    : '#666',
  '&:first-of-type': {
    paddingLeft: '24px',
  },
  '&:last-of-type': {
    paddingRight: '24px',
  },
}));

const StatusChip = styled('span')(({ theme, status }) => {
  // Define status colors based on theme mode
  let bgColor, textColor;
  
  if (theme.palette.mode === 'dark') {
    if (status === 'Approved') {
      bgColor = 'rgba(16, 185, 129, 0.2)';
      textColor = '#34D399';
    } else if (status === 'Pending') {
      bgColor = 'rgba(245, 158, 11, 0.2)';
      textColor = '#FBBF24';
    } else if (status === 'Under Review') {
      bgColor = 'rgba(59, 130, 246, 0.2)';
      textColor = '#60A5FA';
    } else {
      bgColor = '#333333';
      textColor = '#ffffff';
    }
  } else {
    if (status === 'Approved') {
      bgColor = 'rgba(16, 185, 129, 0.1)';
      textColor = '#059669';
    } else if (status === 'Pending') {
      bgColor = 'rgba(245, 158, 11, 0.1)';
      textColor = '#D97706';
    } else if (status === 'Under Review') {
      bgColor = 'rgba(59, 130, 246, 0.1)';
      textColor = '#2563EB';
    } else {
      bgColor = '#eeeeee';
      textColor = '#333333';
    }
  }
  
  return {
    padding: '6px 12px',
    borderRadius: '16px',
    fontSize: '0.875rem',
    fontWeight: '500',
    backgroundColor: bgColor,
    color: textColor,
    border: `1px solid ${theme.palette.mode === 'dark'
      ? 'rgba(255,255,255,0.1)'
      : 'rgba(229, 231, 235, 0.5)'}`,
  };
});
// Chart data
const utilData = [
  { month: 'Jan', rate: 75 },
  { month: 'Feb', rate: 78 },
  { month: 'Mar', rate: 82 },
  { month: 'Apr', rate: 85 },
  { month: 'May', rate: 83 },
  { month: 'Jun', rate: 88 }
];

const revenueData = [
  { month: 'Jan', revenue: 120000 },
  { month: 'Feb', revenue: 125000 },
  { month: 'Mar', revenue: 135000 },
  { month: 'Apr', revenue: 142000 },
  { month: 'May', revenue: 148000 },
  { month: 'Jun', revenue: 155000 }
];

// Opportunity data
const opportunityData = {
  previousWeek: {
    new: [
      {
        id: "OPP-001",
        clientName: "Tech Corp",
        requirement: "Senior React Developer",
        probability: "75%",
        status: "new",
        nextAction: "Technical Interview",
        dueDate: "2024-12-20",
        value: 150000,
        location: "Remote",
      },
      {
        id: "OPP-002",
        clientName: "Digital Solutions",
        requirement: "Full Stack Developer",
        probability: "60%",
        status: "new",
        nextAction: "Initial Discussion",
        dueDate: "2024-12-21",
        value: 120000,
        location: "Hybrid",
      }
    ],
    inProgress: [
      {
        id: "OPP-003",
        clientName: "Innovation Labs",
        requirement: "DevOps Engineer",
        probability: "85%",
        status: "inProgress",
        nextAction: "Final Interview",
        dueDate: "2024-12-18",
        value: 180000,
        location: "Remote",
      }
    ],
    followUps: [
      {
        id: "OPP-004",
        clientName: "Cloud Systems",
        requirement: "Cloud Architect",
        probability: "90%",
        status: "followUp",
        nextAction: "Contract Review",
        dueDate: "2024-12-19",
        value: 200000,
        location: "Onsite",
      }
    ]
  },
  currentWeek: {
    new: [
      {
        id: "OPP-005",
        clientName: "Data Analytics Co",
        requirement: "Data Engineer",
        probability: "65%",
        status: "new",
        nextAction: "Skills Assessment",
        dueDate: "2024-12-24",
        value: 160000,
        location: "Remote",
      }
    ],
    inProgress: [
      {
        id: "OPP-006",
        clientName: "Health Systems",
        requirement: "System Architect",
        probability: "95%",
        status: "inProgress",
        nextAction: "Final Approval",
        dueDate: "2024-12-22",
        value: 220000,
        location: "Remote",
      }
    ],
    followUps: [
      {
        id: "OPP-007",
        clientName: "Retail Solutions",
        requirement: "QA Engineer",
        probability: "75%",
        status: "followUp",
        nextAction: "Test Plan Review",
        dueDate: "2024-12-20",
        value: 110000,
        location: "Remote",
      }
    ]
  }
};

const PartnerMetricsDashboard = ({ collapsed }) => {
  // Helper function to render opportunity cards
  const renderOpportunityCards = (weekData, weekTitle) => (
    <Box mb={4}>
      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', color: '#666' }}>
        {weekTitle}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <OpportunityCard status="new">
            <Typography variant="h6">New ({weekData.new.length})</Typography>
            {weekData.new.map(opp => (
              <OpportunityItem key={opp.id}>
                <Typography variant="subtitle2">{opp.clientName}</Typography>
                <Typography variant="body2">{opp.requirement}</Typography>
                <Typography variant="caption">Due: {opp.dueDate}</Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Next: {opp.nextAction}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">{opp.location}</Typography>
                  <Typography variant="caption">Probability: {opp.probability}</Typography>
                </Box>
              </OpportunityItem>
            ))}
          </OpportunityCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <OpportunityCard status="inProgress">
            <Typography variant="h6">In Progress ({weekData.inProgress.length})</Typography>
            {weekData.inProgress.map(opp => (
              <OpportunityItem key={opp.id}>
                <Typography variant="subtitle2">{opp.clientName}</Typography>
                <Typography variant="body2">{opp.requirement}</Typography>
                <Typography variant="caption">Due: {opp.dueDate}</Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Next: {opp.nextAction}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">{opp.location}</Typography>
                  <Typography variant="caption">Probability: {opp.probability}</Typography>
                </Box>
              </OpportunityItem>
            ))}
          </OpportunityCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <OpportunityCard status="followUp">
            <Typography variant="h6">Follow Ups ({weekData.followUps.length})</Typography>
            {weekData.followUps.map(opp => (
              <OpportunityItem key={opp.id}>
                <Typography variant="subtitle2">{opp.clientName}</Typography>
                <Typography variant="body2">{opp.requirement}</Typography>
                <Typography variant="caption">Due: {opp.dueDate}</Typography>
                <Typography variant="caption" sx={{ display: 'block' }}>
                  Next: {opp.nextAction}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">{opp.location}</Typography>
                  <Typography variant="caption">Probability: {opp.probability}</Typography>
                </Box>
              </OpportunityItem>
            ))}
          </OpportunityCard>
        </Grid>
      </Grid>
    </Box>
  );

  const quoteData = [
    {
      customerName: "Tech Solutions Inc",
      quoteDate: "2024-12-10",
      description: "Full Stack Development Team",
      budget: "$250,000",
      status: "Pending",
      amount: "$245,000"
    },
    {
      customerName: "Global Systems Corp",
      quoteDate: "2024-12-11",
      description: "DevOps Implementation",
      budget: "$180,000",
      status: "Approved",
      amount: "$175,000"
    },
    {
      customerName: "DataFlow Analytics",
      quoteDate: "2024-12-12",
      description: "Data Engineering Team",
      budget: "$200,000",
      status: "Under Review",
      amount: "$198,000"
    },
    {
      customerName: "Cloud Innovate",
      quoteDate: "2024-12-13",
      description: "Cloud Architecture Team",
      budget: "$300,000",
      status: "Pending",
      amount: "$295,000"
    }
  ];
  
  // Add these styled components with your other styled components
  const QuoteTable = styled('table')({
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  });
  
  const TableHeader = styled('th')({
    backgroundColor: '#f5f5f5',
    padding: '16px',
    textAlign: 'left',
    fontWeight: '600',
    color: '#333',
    borderBottom: '2px solid #e0e0e0',
    '&:first-of-type': {
      paddingLeft: '24px',
    },
    '&:last-of-type': {
      paddingRight: '24px',
    },
  });
  
  const TableCell = styled('td')({
    padding: '16px',
    borderBottom: '1px solid #e0e0e0',
    color: '#666',
    '&:first-of-type': {
      paddingLeft: '24px',
    },
    '&:last-of-type': {
      paddingRight: '24px',
    },
  });
  
  const StatusChip = styled('span')(({ status }) => ({
    padding: '6px 12px',
    borderRadius: '16px',
    fontSize: '0.875rem',
    fontWeight: '500',
    backgroundColor:
      status === 'Approved' ? '#e6f4ea' :
      status === 'Pending' ? '#fff3e0' :
      '#f3e5f5',
    color:
      status === 'Approved' ? '#1b5e20' :
      status === 'Pending' ? '#e65100' :
      '#4a148c',
  }));
  

  return (
    <PageWrapper>
    <DashboardContainer collapsed={collapsed}>
  
      {/* Metric Cards */}
      <CardsContainer>
        <CardWrapper>
          <CardIcon style={{ backgroundColor: "#1976d2" }}>
            <PercentIcon />
          </CardIcon>
          <DataCard>
            <CardContent>
              <Typography variant="h6">Billable %</Typography>
              <Typography variant="h4">85%</Typography>
              <Typography variant="body2">Currently Assigned to PODs</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>

        <CardWrapper>
          <CardIcon style={{ backgroundColor: "#2e7d32" }}>
            <GroupIcon />
          </CardIcon>
          <DataCard>
            <CardContent>
              <Typography variant="h6">Bench Count</Typography>
              <Typography variant="h4">12</Typography>
              <Typography variant="body2">Available Talent</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>

        <CardWrapper>
          <CardIcon style={{ backgroundColor: "#9c27b0" }}>
            <AttachMoneyIcon />
          </CardIcon>
          <DataCard>
            <CardContent>
              <Typography variant="h6">Revenue Forecast</Typography>
              <Typography variant="h4">$155,000</Typography>
              <Typography variant="body2">This Month</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>

        <CardWrapper>
          <CardIcon style={{ backgroundColor: "#ed6c02" }}>
            <StarIcon />
          </CardIcon>
          <DataCard>
            <CardContent>
              <Typography variant="h6">Talent Quality Score</Typography>
              <Typography variant="h4">4.8/5.0</Typography>
              <Typography variant="body2">Average Rating</Typography>
            </CardContent>
          </DataCard>
        </CardWrapper>
      </CardsContainer>

      {/* Charts */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Billable Utilization Rate (BUR)</Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer>
                <LineChart data={utilData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="rate" stroke="#1976d2" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 2, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Revenue Growth</Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer>
                <LineChart data={revenueData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="revenue" stroke="#2e7d32" />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Card>
        </Grid>
      </Grid>

      {/* Opportunity Activity */}
      <Card sx={{   p: 3, 
      overflow: 'visible',     // Added to ensure content is visible
      '@media (max-width: 768px)': {
        p: 2,                  // Reduced padding on mobile
      }}}>
        <Typography variant="h5" gutterBottom>Opportunity Activity</Typography>
        {renderOpportunityCards(opportunityData.previousWeek, "Previous Week")}
        {renderOpportunityCards(opportunityData.currentWeek, "Current Week")}
      </Card>
      <Card sx={{ 
  p: 3, 
  mt: 4,
  overflow: 'visible',
  '@media (max-width: 768px)': {
    p: 2,
  }
}}>
  <Typography variant="h5" gutterBottom>Quote Requests</Typography>
  <Box sx={{ overflowX: 'auto' }}>
  <ContentCard sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>Quote Requests</Typography>
            <Box sx={{ overflowX: 'auto' }}>
              <QuoteTable>
                <thead>
                  <tr>
                    <TableHeader>Customer Name</TableHeader>
                    <TableHeader>Quote Date</TableHeader>
                    <TableHeader>Quote Description</TableHeader>
                    <TableHeader>Budget</TableHeader>
                    <TableHeader>Status</TableHeader>
                    <TableHeader>Quote Amount</TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {quoteData.map((quote, index) => (
                    <tr key={index}>
                      <TableCell>{quote.customerName}</TableCell>
                      <TableCell>{new Date(quote.quoteDate).toLocaleDateString()}</TableCell>
                      <TableCell>{quote.description}</TableCell>
                      <TableCell>{quote.budget}</TableCell>
                      <TableCell>
                        <StatusChip status={quote.status}>{quote.status}</StatusChip>
                      </TableCell>
                      <TableCell>{quote.amount}</TableCell>
                    </tr>
                  ))}
                </tbody>
              </QuoteTable>
            </Box>
          </ContentCard>
  </Box>
</Card>
     
    </DashboardContainer>
    </PageWrapper>
  );
};

export default PartnerMetricsDashboard;