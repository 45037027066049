import React, { useState } from 'react';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Avatar,
  Stack,
  Badge,
  Divider,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { formatDistanceToNow } from 'date-fns';
import CommentIcon from '@mui/icons-material/Comment';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../TokenContext';

// Styled Components
const CommentDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '600px',
    maxHeight: '80vh',
  },
}));

const CommentListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  '& .MuiListItemText-root': {
    margin: 0,
  },
  '& .comment-header': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  '& .comment-metadata': {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
}));

const CommentCell = ({ comments, activity_id, onCommentAdded }) => {
  const [open, setOpen] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = useAuthToken();
  const { user } = useSelector((state) => state.auth);
  
  // Parse comments string to array
  const parsedComments = comments ? JSON.parse(comments) : [];
  const commentCount = parsedComments.length;

  const handleAddComment = async () => {
    if (!newComment.trim() || !activity_id) return;
    
    setIsSubmitting(true);
    try {
      const commentObj = {
        id: parseInt(activity_id),
        user_id: parseInt(user.data[0].c_id),
        new_text: newComment,
        is_reply: false,
        author: user.data[0].firstName
      };

      const response = await axios.post(
        "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/addCommentpodactivity",
        [commentObj],
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          }
        }
      );

      if (response.status === 200) {
        const newComments = [
          ...parsedComments,
          {
            text: newComment,
            user_id: parseInt(user.data[0].c_id),
            author: user.data[0].firstName,
            timestamp: new Date().toISOString(),
            replies: []
          }
        ];
        
        if (onCommentAdded) {
          onCommentAdded(activity_id, JSON.stringify(newComments));
        }
        
        setNewComment("");
      }
    } catch (error) {
      console.error("Error adding comment:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Tooltip title={`${commentCount} comments`}>
        <IconButton 
          onClick={() => setOpen(true)}
          color={commentCount > 0 ? "primary" : "default"}
        >
          <Badge badgeContent={commentCount} color="primary">
            <CommentIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <CommentDialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Comments History</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {parsedComments.length > 0 ? (
            <List>
              {parsedComments.map((comment, index) => (
                <React.Fragment key={comment.comment_id || index}>
                  <CommentListItem>
                    <div className="comment-header">
                      <Box display="flex" alignItems="center" gap={1}>
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                          {comment.author ? comment.author[0].toUpperCase() : 'U'}
                        </Avatar>
                        <Typography variant="subtitle2">
                          {comment.author || 'User'}
                        </Typography>
                      </Box>
                      <Typography variant="caption" className="comment-metadata">
                        {formatDistanceToNow(new Date(comment.timestamp), { addSuffix: true })}
                      </Typography>
                    </div>
                    <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                      {comment.text}
                    </Typography>
                    {comment.replies && comment.replies.length > 0 && (
                      <Box ml={4} mt={1}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Replies:
                        </Typography>
                        {comment.replies.map((reply, replyIndex) => (
                          <Box key={replyIndex} mt={1} ml={2}>
                            <Typography variant="body2">{reply}</Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </CommentListItem>
                  {index < parsedComments.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Box p={3} textAlign="center">
              <Typography color="textSecondary">
                No comments yet
              </Typography>
            </Box>
          )}
          {/* Comment Form */}
          <Box sx={{ 
              mt: 2, 
              pt: 2, 
              borderTop: 1, 
              borderColor: 'divider',
              position: 'sticky',
              bottom: 0,
              bgcolor: 'background.paper'
            }}>
            <TextField
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              placeholder="Write a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              sx={{ mb: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddComment}
              disabled={isSubmitting || !newComment.trim()}
              sx={{ float: 'right' }}
            >
              {isSubmitting ? <CircularProgress size={24} /> : 'Add Comment'}
            </Button>
          </Box>
        </DialogContent>
      </CommentDialog>
    </>
  );
};

export default CommentCell;