import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
  TextField,
  MenuItem,
  IconButton,
  Chip,
  Stack,
  useTheme,
  CircularProgress
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import axios from 'axios';
import allergens from './allergens';

// Styled Components
const RootContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
  paddingTop: theme.spacing(12)
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  boxShadow: theme.shadows[3],
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[6]
  }
}));

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3)
}));

const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  fontWeight: 600,
  fontSize: '2rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem'
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      borderColor: theme.palette.primary.main
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`
    }
  }
}));

const CandidateChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  '& .MuiChip-deleteIcon': {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.error.main
    }
  }
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  fontSize: '1.1rem',
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 1.5
}));

// React-Select custom styles
const selectStyles = (theme) => ({
  control: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? '#2a2a2a' : theme.palette.background.paper,
    borderColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.palette.mode === 'dark' ? '#505050' : theme.palette.primary.main
    },
    minHeight: '56px'
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? '#2a2a2a' : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: 4
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected 
      ? theme.palette.mode === 'dark' ? '#3b82f6' : theme.palette.primary.main
      : state.isFocused 
        ? theme.palette.mode === 'dark' ? '#404040' : theme.palette.action.hover
        : 'transparent',
    color: state.isSelected
      ? '#ffffff'
      : theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.action.hover
    },
    cursor: 'pointer',
    padding: '8px 12px'
  }),
  input: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  placeholder: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#808080' : theme.palette.text.secondary
  }),
  singleValue: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.grey[200]
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#808080' : theme.palette.grey[600],
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#505050' : theme.palette.grey[300],
      color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.error.main
    }
  })
});

const ClientCreatePod = () => {
  const intl = useIntl();
  const token = useAuthToken();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [podData, setPodData] = useState({
    podName: '',
    pod_rate: '',
    pod_ratepermonth:'',
    ART_id: '',
    ART_Name: '',
    skills: [],
    podDescription: '',
    podsize: '',
    podtype: '',
    techStack: '',
    primary_language: '',
    primary_timezone: '',
    clientID: user?.data[0]?.emp_id || '',
    candidatesid: [],
    Client_Code: user?.data[0]?.client_code || ''
  });

  const [artOptions, setARTOptions] = useState([]);

  useEffect(() => {
    fetchARTOptions();
  }, [token]);

  const fetchARTOptions = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHGIGSLIST,
        { Organization_Code: user.data[0].client_code },
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data?.data) {
        setARTOptions(Array.isArray(response.data.data) ? response.data.data : []);
      }
    } catch (error) {
      console.error('Error fetching ART options:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_CLIENTCREATEPOD,
        podData,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === 200) {
        alert("Pod created successfully!");
        history.push('/client-dashboard');
      } else {
        alert(response.data.data || 'Failed to create pod');
      }
    } catch (error) {
      console.error('Error creating pod:', error);
      alert('An error occurred while creating the pod');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setPodData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSkillsChange = (selectedOptions) => {
    setPodData(prev => ({
      ...prev,
      skills: selectedOptions?.map(option => ({ skill: option.label })) || []
    }));
  };
  const TIMEZONES = [
    { value: "utc_minus_12", label: "UTC-12:00" },
    { value: "utc_minus_11", label: "UTC-11:00" },
    { value: "utc_minus_10", label: "UTC-10:00 (Hawaii)" },
    { value: "utc_minus_09_30", label: "UTC-09:30" },
    { value: "utc_minus_09", label: "UTC-09:00 (Alaska)" },
    { value: "utc_minus_08", label: "UTC-08:00 (Pacific Time)" },
    { value: "utc_minus_07", label: "UTC-07:00 (Mountain Time)" },
    { value: "utc_minus_06", label: "UTC-06:00 (Central Time)" },
    { value: "utc_minus_05", label: "UTC-05:00 (Eastern Time)" },
    { value: "utc_minus_04", label: "UTC-04:00 (Atlantic Time)" },
    { value: "utc_minus_03_30", label: "UTC-03:30 (Newfoundland)" },
    { value: "utc_minus_03", label: "UTC-03:00 (Brazil, Argentina)" },
    { value: "utc_minus_02", label: "UTC-02:00" },
    { value: "utc_minus_01", label: "UTC-01:00 (Azores)" },
    { value: "utc", label: "UTC±00:00 (London, Lisbon)" },
    { value: "utc_plus_01", label: "UTC+01:00 (Central European Time)" },
    { value: "utc_plus_02", label: "UTC+02:00 (Eastern European Time)" },
    { value: "utc_plus_03", label: "UTC+03:00 (Moscow, Istanbul)" },
    { value: "utc_plus_03_30", label: "UTC+03:30 (Iran)" },
    { value: "utc_plus_04", label: "UTC+04:00 (Dubai)" },
    { value: "utc_plus_04_30", label: "UTC+04:30 (Afghanistan)" },
    { value: "utc_plus_05", label: "UTC+05:00 (Pakistan)" },
    { value: "utc_plus_05_30", label: "UTC+05:30 (India)" },
    { value: "utc_plus_05_45", label: "UTC+05:45 (Nepal)" },
    { value: "utc_plus_06", label: "UTC+06:00 (Bangladesh)" },
    { value: "utc_plus_06_30", label: "UTC+06:30 (Myanmar)" },
    { value: "utc_plus_07", label: "UTC+07:00 (Thailand, Vietnam)" },
    { value: "utc_plus_08", label: "UTC+08:00 (China, Singapore)" },
    { value: "utc_plus_08_45", label: "UTC+08:45 (Eucla)" },
    { value: "utc_plus_09", label: "UTC+09:00 (Japan, Korea)" },
    { value: "utc_plus_09_30", label: "UTC+09:30 (Central Australia)" },
    { value: "utc_plus_10", label: "UTC+10:00 (Eastern Australia)" },
    { value: "utc_plus_10_30", label: "UTC+10:30 (Lord Howe Island)" },
    { value: "utc_plus_11", label: "UTC+11:00 (Solomon Islands)" },
    { value: "utc_plus_12", label: "UTC+12:00 (New Zealand)" },
    { value: "utc_plus_12_45", label: "UTC+12:45 (Chatham Islands)" },
    { value: "utc_plus_13", label: "UTC+13:00 (Tonga)" },
    { value: "utc_plus_14", label: "UTC+14:00 (Kiribati)" }
  ];
  const POD_TYPES = [
    { value: 'SaaS', label: 'SaaS' },
    { value: 'Web + Mobile App', label: 'Web + Mobile App' },
    { value: 'Web App', label: 'Web App' },
    { value: 'Mobile App', label: 'Mobile App' },
    { value: 'Data & AI', label: 'Data & AI' }
  ];
  
 
const languages = [
  { value: 'aa', label: 'Afar' },
  { value: 'ab', label: 'Abkhazian' },
  { value: 'ae', label: 'Avestan' },
  { value: 'af', label: 'Afrikaans' },
  { value: 'ak', label: 'Akan' },
  { value: 'am', label: 'Amharic' },
  { value: 'an', label: 'Aragonese' },
  { value: 'ar', label: 'Arabic' },
  { value: 'as', label: 'Assamese' },
  { value: 'av', label: 'Avaric' },
  { value: 'ay', label: 'Aymara' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'ba', label: 'Bashkir' },
  { value: 'be', label: 'Belarusian' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'bh', label: 'Bihari languages' },
  { value: 'bi', label: 'Bislama' },
  { value: 'bm', label: 'Bambara' },
  { value: 'bn', label: 'Bengali' },
  { value: 'bo', label: 'Tibetan' },
  { value: 'br', label: 'Breton' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'ca', label: 'Catalan' },
  { value: 'ce', label: 'Chechen' },
  { value: 'ch', label: 'Chamorro' },
  { value: 'co', label: 'Corsican' },
  { value: 'cr', label: 'Cree' },
  { value: 'cs', label: 'Czech' },
  { value: 'cu', label: 'Church Slavic' },
  { value: 'cv', label: 'Chuvash' },
  { value: 'cy', label: 'Welsh' },
  { value: 'da', label: 'Danish' },
  { value: 'de', label: 'German' },
  { value: 'dv', label: 'Divehi' },
  { value: 'dz', label: 'Dzongkha' },
  { value: 'ee', label: 'Ewe' },
  { value: 'el', label: 'Greek' },
  { value: 'en', label: 'English' },
  { value: 'eo', label: 'Esperanto' },
  { value: 'es', label: 'Spanish' },
  { value: 'et', label: 'Estonian' },
  { value: 'eu', label: 'Basque' },
  { value: 'fa', label: 'Persian' },
  { value: 'ff', label: 'Fulah' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fj', label: 'Fijian' },
  { value: 'fo', label: 'Faroese' },
  { value: 'fr', label: 'French' },
  { value: 'fy', label: 'Western Frisian' },
  { value: 'ga', label: 'Irish' },
  { value: 'gd', label: 'Gaelic' },
  { value: 'gl', label: 'Galician' },
  { value: 'gn', label: 'Guarani' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'gv', label: 'Manx' },
  { value: 'ha', label: 'Hausa' },
  { value: 'he', label: 'Hebrew' },
  { value: 'hi', label: 'Hindi' },
  { value: 'ho', label: 'Hiri Motu' },
  { value: 'hr', label: 'Croatian' },
  { value: 'ht', label: 'Haitian' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'hy', label: 'Armenian' },
  { value: 'hz', label: 'Herero' },
  { value: 'ia', label: 'Interlingua' },
  { value: 'id', label: 'Indonesian' },
  { value: 'ie', label: 'Interlingue' },
  { value: 'ig', label: 'Igbo' },
  { value: 'ii', label: 'Sichuan Yi' },
  { value: 'ik', label: 'Inupiaq' },
  { value: 'io', label: 'Ido' },
  { value: 'is', label: 'Icelandic' },
  { value: 'it', label: 'Italian' },
  { value: 'iu', label: 'Inuktitut' },
  { value: 'ja', label: 'Japanese' },
  { value: 'jv', label: 'Javanese' },
  { value: 'ka', label: 'Georgian' },
  { value: 'kg', label: 'Kongo' },
  { value: 'ki', label: 'Kikuyu' },
  { value: 'kj', label: 'Kuanyama' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'kl', label: 'Kalaallisut' },
  { value: 'km', label: 'Central Khmer' },
  { value: 'kn', label: 'Kannada' },
  { value: 'ko', label: 'Korean' },
  { value: 'kr', label: 'Kanuri' },
  { value: 'ks', label: 'Kashmiri' },
  { value: 'ku', label: 'Kurdish' },
  { value: 'kv', label: 'Komi' },
  { value: 'kw', label: 'Cornish' },
  { value: 'ky', label: 'Kirghiz' },
  { value: 'la', label: 'Latin' },
  { value: 'lb', label: 'Luxembourgish' },
  { value: 'lg', label: 'Ganda' },
  { value: 'li', label: 'Limburgan' },
  { value: 'ln', label: 'Lingala' },
  { value: 'lo', label: 'Lao' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'lu', label: 'Luba-Katanga' },
  { value: 'lv', label: 'Latvian' },
  { value: 'mg', label: 'Malagasy' },
  { value: 'mh', label: 'Marshallese' },
  { value: 'mi', label: 'Maori' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'mr', label: 'Marathi' },
  { value: 'ms', label: 'Malay' },
  { value: 'mt', label: 'Maltese' },
  { value: 'my', label: 'Burmese' },
  { value: 'na', label: 'Nauru' },
  { value: 'nb', label: 'Norwegian Bokmål' },
  { value: 'nd', label: 'North Ndebele' },
  { value: 'ne', label: 'Nepali' },
  { value: 'ng', label: 'Ndonga' },
  { value: 'nl', label: 'Dutch' },
  { value: 'nn', label: 'Norwegian Nynorsk' },
  { value: 'no', label: 'Norwegian' },
  { value: 'nr', label: 'South Ndebele' },
  { value: 'nv', label: 'Navajo' },
  { value: 'ny', label: 'Nyanja' },
  { value: 'oc', label: 'Occitan' },
  { value: 'oj', label: 'Ojibwa' },
  { value: 'om', label: 'Oromo' },
  { value: 'or', label: 'Oriya' },
  { value: 'os', label: 'Ossetian' },
  { value: 'pa', label: 'Panjabi' },
  { value: 'pi', label: 'Pali' },
  { value: 'pl', label: 'Polish' },
  { value: 'ps', label: 'Pushto' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'qu', label: 'Quechua' },
  { value: 'rm', label: 'Romansh' },
  { value: 'rn', label: 'Rundi' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ru', label: 'Russian' },
  { value: 'rw', label: 'Kinyarwanda' },
  { value: 'sa', label: 'Sanskrit' },
  { value: 'sc', label: 'Sardinian' },
  { value: 'sd', label: 'Sindhi' },
  { value: 'se', label: 'Northern Sami' },
  { value: 'sg', label: 'Sango' },
  { value: 'si', label: 'Sinhala' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'sm', label: 'Samoan' },
  { value: 'sn', label: 'Shona' },
  { value: 'so', label: 'Somali' },
  { value: 'sq', label: 'Albanian' },
  { value: 'sr', label: 'Serbian' },
  { value: 'ss', label: 'Swati' },
  { value: 'st', label: 'Southern Sotho' },
  { value: 'su', label: 'Sundanese' },
  { value: 'sv', label: 'Swedish' },
  { value: 'sw', label: 'Swahili' },
  { value: 'ta', label: 'Tamil' },
  { value: 'te', label: 'Telugu' },
  { value: 'tg', label: 'Tajik' },
  { value: 'th', label: 'Thai' },
  { value: 'ti', label: 'Tigrinya' },
  { value: 'tk', label: 'Turkmen' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'tn', label: 'Tswana' },
  { value: 'to', label: 'Tonga' },
  { value: 'tr', label: 'Turkish' },
  { value: 'ts', label: 'Tsonga' },
  { value: 'tt', label: 'Tatar' },
  { value: 'tw', label: 'Twi' },
  { value: 'ty', label: 'Tahitian' },
  { value: 'ug', label: 'Uighur' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz', label: 'Uzbek' },
  { value: 've', label: 'Venda' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'vo', label: 'Volapük' },
  { value: 'wa', label: 'Walloon' },
  { value: 'wo', label: 'Wolof' },
  { value: 'xh', label: 'Xhosa' },
  { value: 'yi', label: 'Yiddish' },
  { value: 'yo', label: 'Yoruba' },
  { value: 'za', label: 'Zhuang' },
  { value: 'zh', label: 'Chinese' },
  { value: 'zu', label: 'Zulu' }
];
  return (
    <RootContainer>
      <Container maxWidth="lg">
        <StyledPaper elevation={3}>
          <PageTitle variant="h1">
            {intl.formatMessage({ id: 'createPod' })}
          </PageTitle>

          <FormContainer onSubmit={handleSubmit}>
            <StyledTextField
              fullWidth
              name="podName"
              label={intl.formatMessage({ id: 'podName' })}
              value={podData.podName}
              onChange={handleInputChange}
              required
              variant="outlined"
            />

            <StyledTextField
              select
              fullWidth
              name="ART_id"
              label={intl.formatMessage({ id: 'selectArtName' })}
              value={podData.ART_id}
              onChange={handleInputChange}
              required
            >
              {artOptions.map((option) => (
                <MenuItem key={option.ART_id} value={option.ART_id.toString()}>
                  {option.ART_Name}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              select
              fullWidth
              name="podsize"
              label={intl.formatMessage({ id: 'podSize' })}
              value={podData.podsize}
              onChange={handleInputChange}
              required
            >
              {[3, 5, 7, 9].map((size) => (
                <MenuItem key={size} value={size.toString()}>
                  {size}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              select
              fullWidth
              name="podtype"
              label={intl.formatMessage({ id: 'podType' })}
              value={podData.podtype}
              onChange={handleInputChange}
              required
            >
              {POD_TYPES.map(type => (
                                   <MenuItem key={type.value} value={type.value}>
                                     {type.label}
                                   </MenuItem>
                                 ))}
            </StyledTextField>

            <StyledTextField
              select
              fullWidth
              name="techStack"
              label={intl.formatMessage({ id: 'techStack' })}
              value={podData.techStack}
              onChange={handleInputChange}
              required
            >
              {['MEAN', 'MERN', 'LAMP', 'MEVN'].map((stack) => (
                <MenuItem key={stack} value={stack}>
                  {stack}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              select
              fullWidth
              name="primary_language"
              label="Primary Language"
              value={podData.primary_language}
              onChange={handleInputChange}
            >
             {languages.map((language) => (
                <MenuItem key={language.value} value={language.value}>
                  {language.label}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              select
              fullWidth
              name="primary_timezone"
              label="Time Zone"
              value={podData.primary_timezone}
              onChange={handleInputChange}
            >
              {TIMEZONES.map((timezone) => (
                <MenuItem key={timezone.value} value={timezone.value}>
                  {timezone.label}
                </MenuItem>
              ))}
            </StyledTextField>

            {/* {user.data[0].manager_type === "PartnerClient" && ( */}
              <StyledTextField
                fullWidth
                type="number"
                name="pod_rate"
                label={intl.formatMessage({ id: 'ratePerHour' })}
                value={podData.pod_rate}
                onChange={handleInputChange}
                required
              />
                 <StyledTextField
                fullWidth
                type="number"
                name="pod_ratepermonth"
                label={intl.formatMessage({ id: 'ratePerMonth' })}
                value={podData.pod_ratepermonth}
                onChange={handleInputChange}
                required
              />
            {/* )} */}

            <Select
              isMulti
              options={allergens}
              value={podData.skills.map(skill => ({ 
                label: skill.skill, 
                value: skill.skill 
              }))}
              onChange={handleSkillsChange}
              placeholder="Select Skills"
              styles={selectStyles(theme)}
            />

            {podData.candidatesid.length > 0 && (
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {podData.candidatesid.map((candidate, index) => (
                  <CandidateChip
                    key={index}
                    label={candidate.name}
                    onDelete={() => {
                      const newCandidates = [...podData.candidatesid];
                      newCandidates.splice(index, 1);
                      setPodData(prev => ({ 
                        ...prev, 
                        candidatesid: newCandidates 
                      }));
                    }}
                  />
                ))}
              </Stack>
            )}

            <SubmitButton
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              fullWidth
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                intl.formatMessage({ id: 'create' })
              )}
            </SubmitButton>
          </FormContainer>
        </StyledPaper>
      </Container>
    </RootContainer>
  );
};

export default ClientCreatePod;