import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { styled as muiStyled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import {
  Paper,
  Box,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Alert,
  InputLabel,
  Container,
} from '@mui/material';

const FormContainer = muiStyled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
  maxWidth: '650px',
  margin: 'auto',
  marginTop: theme.spacing(4),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  [theme.breakpoints.down('md')]: {
    maxWidth: '320px',
    padding: theme.spacing(2),
  }
}));

const StyledForm = muiStyled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

const StyledTextField = muiStyled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledFormControl = muiStyled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const StyledButton = muiStyled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  fontWeight: 600,
  boxShadow: theme.shadows[3],
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledLink = muiStyled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  fontWeight: 600,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const Register = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(null);

  const [user, setUser] = useState({
    username: '',
    email: '',
    code: '',
    password: '',
    usertype: '',
    authCode: '',
    confirmpassword: '',
  });

  // Update the handleInputChange function to handle both Select and TextField changes
const handleInputChange = (e) => {
  // For Select, name is in target.name and for TextField it's in target.id
  const fieldName = e.target.name || e.target.id;
  const value = e.target.value;
  
  setUser(prev => ({
    ...prev,
    [fieldName]: value
  }));
  setError(null);
};

  const validateForm = () => {
    if (!user.usertype) {
      setError('Please select a user type');
      return false;
    }
    if (!user.username) {
      setError('Please enter a username');
      return false;
    }
    if (!user.code) {
      setError('Please enter a talent code');
      return false;
    }
    if (!user.email) {
      setError('Please enter an email');
      return false;
    }
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!user.email || !emailRegex.test(user.email.toLowerCase())) {
      setError('Please enter a valid email address');
      return false;
    }
    if (!user.password) {
      setError('Please enter a password');
      return false;
    }
    if (!user.confirmpassword) {
      setError('Please confirm your password');
      return false;
    }
    if (user.password !== user.confirmpassword) {
      setError('Passwords do not match');
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    
    setLoading(true);
    try {
      const usertype = {
        candidate: 1,
        podprovider: 2,
        manager: 0,
        stakeholder: 4,
        partnerguest: 5,
        customer: 3
      }[user.usertype];

      const registrationResponse = await axios.post(
        process.env.REACT_APP_CANDIDATESIGNUP,
        {
          usertype,
          email: user.email,
          code: user.code,
        }
      );

      if (registrationResponse.data.status === 200) {
        const signUpResponse = await Auth.signUp({
          username: user.username,
          password: user.password,
          attributes: {
            email: user.email,
          },
        });
        setConfirm(true);
      } else if (registrationResponse.data.status === 400) {
        setError('Invalid invite code');
      }
    } catch (err) {
      setError(err.message || 'Registration failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const confirm = await Auth.confirmSignUp(user.username, user.authCode);
      if (confirm === 'SUCCESS') {
        history.push('/login');
      }
    } catch (err) {
      setError(err.message || 'Verification failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      {error && (
        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
          {error}
        </Alert>
      )}

      {!confirm ? (
        <FormContainer elevation={3}>
          <Typography variant="h4" component="h1" gutterBottom>
            Register
          </Typography>

          {loading && <CircularProgress sx={{ my: 2 }} />}

          <StyledForm onSubmit={handleSubmit}>
            <StyledFormControl>
              <InputLabel>Select User Type</InputLabel>
              <Select
                value={user.usertype}
                onChange={handleInputChange}
                name="usertype" 
                id="usertype"
                label="Select User Type"
              >
                <MenuItem value="candidate">Talent</MenuItem>
                <MenuItem value="podprovider">Partner</MenuItem>
                <MenuItem value="manager">Manager</MenuItem>
                <MenuItem value="stakeholder">Stake Holder</MenuItem>
                <MenuItem value="partnerguest">Partner Guest</MenuItem>
                <MenuItem value="customer">Admin</MenuItem>
              </Select>
            </StyledFormControl>

            <StyledTextField
              fullWidth
              id="username"
              label="Username"
              value={user.username}
              onChange={handleInputChange}
              variant="outlined"
            />

            <StyledTextField
              fullWidth
              id="email"
              label="Email"
              type="email"
              value={user.email}
              onChange={handleInputChange}
              variant="outlined"
            />

            <StyledTextField
              fullWidth
              id="password"
              label="Password"
              type="password"
              value={user.password}
              onChange={handleInputChange}
              variant="outlined"
            />

            <StyledTextField
              fullWidth
              id="confirmpassword"
              label="Confirm Password"
              type="password"
              value={user.confirmpassword}
              onChange={handleInputChange}
              variant="outlined"
            />

            <StyledTextField
              fullWidth
              id="code"
              label="Invite Code"
              value={user.code}
              onChange={handleInputChange}
              variant="outlined"
            />

            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Register'}
            </StyledButton>

            <Box mt={2} textAlign="center">
              <Typography variant="body1">
                Already have an account?{' '}
                <StyledLink to="/login">
                  Login
                </StyledLink>
              </Typography>
            </Box>
          </StyledForm>
        </FormContainer>
      ) : (
        <FormContainer elevation={3}>
          <Typography variant="h4" component="h1" gutterBottom>
            Welcome!
          </Typography>

          <Typography variant="body1" align="center" paragraph>
            You have successfully registered with Globl.ai
          </Typography>

          <Typography variant="body1" align="center" paragraph>
            We've sent you an email. Please enter the code to verify your account
          </Typography>

          <StyledForm onSubmit={handleConfirmSignUp}>
            <StyledTextField
              fullWidth
              id="authCode"
              label="Confirmation Code"
              type="number"
              value={user.authCode}
              onChange={handleInputChange}
              variant="outlined"
            />

            <StyledButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Verify Account'}
            </StyledButton>
          </StyledForm>
        </FormContainer>
      )}
    </Container>
  );
};

export default Register;