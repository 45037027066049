import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { 
  Box, 
  Tabs, 
  Tab, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  IconButton,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Fade,
  useTheme,
  useMediaQuery,
  Chip,
  Tooltip,
  Badge,
  Card,
  CardContent,
  Divider,
  Alert,
  Snackbar
} from '@mui/material';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArticleIcon from '@mui/icons-material/Article';
import GroupIcon from '@mui/icons-material/Group';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';

import moment from 'moment';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { NavHashLink } from 'react-router-hash-link';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 4,
            gap: 2,
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 48, color: 'error.main' }} />
          <Typography variant="h6" color="error">
            Something went wrong
          </Typography>
          <Button
            variant="contained"
            startIcon={<RefreshIcon />}
            onClick={() => window.location.reload()}
          >
            Reload Page
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

// Styled Components


const StatsGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
  gap: '24px',
  padding: '24px',
  '@media (max-width: 600px)': {
    gridTemplateColumns: '1fr',
    padding: '16px',
  }
}));

const StatsCard = styled(Card)(({ theme }) => ({
  padding: '24px',
  borderRadius: '16px',
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  position: 'relative',
  overflow: 'hidden',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  transition: 'all 0.3s ease',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(135deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 100%)',
    opacity: 0,
    transition: 'opacity 0.3s ease',
  },
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
    '&::before': {
      opacity: 1,
    }
  },
  '& .icon-wrapper': {
    padding: '12px',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.4) 100%)',
    boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
  }
}));



const StyledTable = styled(Table)({
  borderCollapse: 'separate',
  borderSpacing: '0 4px',
});







// Updated styled components with dark mode support
const AppContainer = styled(Box)(({ theme }) => ({
  margin: "auto",
  marginTop: "5rem",
  width: "80vw",
  minHeight: '100vh',
  padding: theme.spacing(3),
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%)'
    : 'linear-gradient(135deg, #f6f9fc 0%, #eef2f7 100%)',
  '@media (max-width: 600px)': {
    padding: theme.spacing(1),
  }
}));

const DashboardCard = styled(Card)(({ theme }) => ({
  borderRadius: '16px',
  boxShadow: '0 4px 24px 0 rgba(34,41,47,0.1)',
  background: theme.palette.mode === 'dark' ? '#2d2d2d' : '#fff',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.palette.mode === 'dark' 
      ? '0 8px 32px rgba(0,0,0,0.3)'
      : '0 8px 32px rgba(34,41,47,0.15)',
  }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.08)'}`,
  '& .MuiTabs-flexContainer': {
    gap: '8px',
    padding: '0 16px',
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '14px',
    minHeight: '56px',
    color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.text.primary,
    transition: 'all 0.2s ease',
    '&:hover': {
      color: '#00B4D8',
      backgroundColor: alpha('#00B4D8', theme.palette.mode === 'dark' ? 0.15 : 0.08),
    },
    '&.Mui-selected': {
      color: '#00B4D8',
    },
  },
  '& .MuiTabs-indicator': {
    height: '3px',
    borderRadius: '3px 3px 0 0',
    background: 'linear-gradient(90deg, #00B4D8 0%, #48CAE4 100%)',
  }
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  maxHeight: '65vh',
  borderRadius: '16px',
  overflow: 'hidden',
  backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 24px rgba(0,0,0,0.2)'
    : '0 4px 24px rgba(34,41,47,0.1)',
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.mode === 'dark' ? '#1a1a1a' : '#f1f1f1',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'dark' ? '#555' : '#888',
    borderRadius: '3px',
    '&:hover': {
      background: theme.palette.mode === 'dark' ? '#666' : '#999',
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  padding: '16px 24px',
  fontFamily: 'Inter, sans-serif',
  fontWeight: 500,
  borderBottom: 'none',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#00B4D8',
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    fontSize: '14px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    borderBottom: 'none',
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '&:last-child': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  position: 'relative',
  transition: 'all 0.3s ease',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? alpha('#1a1a1a', 0.3)
      : alpha('#E3F2FD', 0.3),
  },
  '& td': {
    borderBottom: 'none',
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
    position: 'relative',
    '&:first-of-type': {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
    },
    '&:last-child': {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? alpha('#1a1a1a', 0.4)
      : alpha('#BBDEFB', 0.2),
    transform: 'translateX(6px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 12px rgba(0,0,0,0.2)'
      : '0 4px 12px rgba(0,0,0,0.05)',
  }
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  padding: '24px',
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(135deg, rgba(45,45,45,0.9) 0%, rgba(35,35,35,0.8) 100%)'
    : 'linear-gradient(135deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.8) 100%)',
  backdropFilter: 'blur(10px)',
  borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)'}`,
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  zIndex: 10,
  transition: 'all 0.3s ease',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '16px',
  }
}));

const SearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    background: theme.palette.mode === 'dark' ? '#1a1a1a' : 'rgba(255,255,255,0.8)',
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
    backdropFilter: 'blur(5px)',
    transition: 'all 0.3s ease',
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.15)' : 'rgba(0,0,0,0.1)',
    },
    '&:hover': {
      background: theme.palette.mode === 'dark' ? '#252525' : 'rgba(255,255,255,0.9)',
      '& fieldset': {
        borderColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
      },
    },
    '&.Mui-focused': {
      background: theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
      '& fieldset': {
        borderColor: '#00B4D8',
      },
    }
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? theme.palette.text.secondary : 'inherit',
  },
  '& .MuiInputBase-input': {
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
  }
}));

const StatusChip = styled(Chip)(({ status = 'active' }) => {
  const getStatusColor = () => {
    const colors = {
      active: {
        bg: alpha('#4CAF50', 0.1),
        color: '#2E7D32',
        border: '#4CAF50'
      },
      pending: {
        bg: alpha('#FFA726', 0.1),
        color: '#EF6C00',
        border: '#FFA726'
      },
      completed: {
        bg: alpha('#9C27B0', 0.1),
        color: '#6A1B9A',
        border: '#9C27B0'
      },
      private: {
        bg: alpha('#00B4D8', 0.1),
        color: '#00B4D8',
        border: '#00B4D8'
      }
    };
    return colors[status?.toLowerCase()] || colors.active;
  };

  const statusColor = getStatusColor();
  return {
    borderRadius: '16px',
    fontWeight: 600,
    fontSize: '12px',
    height: '24px',
    backgroundColor: statusColor.bg,
    color: statusColor.color,
    border: `1px solid ${statusColor.border}`,
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: `0 4px 12px ${alpha(statusColor.border, 0.2)}`,
    }
  };
});



// Main Component
const TeamMembers = () => {
  const intl = useIntl();
  const theme = useTheme();
  const token = useAuthToken();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // States
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    privatePods: [],
    activePods: [],
    completedPods: [],
    pendingPods: []
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [dateFilter, setDateFilter] = useState({ start: '', end: '' });
  const [sortDirection, setSortDirection] = useState('asc');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  
  const recordsPerPage = isMobile ? 5 : 10;

  // Fetch Data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_GETHIREDPODS,
          { clientID: user?.data?.[0]?.emp_id },
          { headers: { Authorization: token } }
        );

        setData({
          privatePods: response.data?.private_pods || [],
          activePods: response.data?.hired_pods || [],
          completedPods: response.data?.completed_pods || [],
          pendingPods: response.data?.pending_pods || []
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch pods data. Please try again.');
        setSnackbar({
          open: true,
          message: 'Failed to fetch data. Please try again.',
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };

    if (user?.data?.[0]?.emp_id && token) {
      fetchData();
    }
  }, [token, user]);

  // Memoized Calculations
  const currentTabData = useMemo(() => {
    const tabData = {
      0: data.privatePods,
      1: data.activePods,
      2: data.completedPods,
      3: data.pendingPods
    }[value] || [];

    let filtered = [...tabData];

    if (search) {
      filtered = filtered.filter(pod => 
        pod.podName?.toLowerCase().includes(search.toLowerCase()) ||
        pod.podDescription?.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (dateFilter.start && dateFilter.end) {
      filtered = filtered.filter(pod => {
        const podDate = new Date(pod.created_at);
        const start = new Date(dateFilter.start);
        const end = new Date(dateFilter.end);
        return podDate >= start && podDate <= end;
      });
    }

    filtered.sort((a, b) => {
      const comparison = a.podName.localeCompare(b.podName);
      return sortDirection === 'asc' ? comparison : -comparison;
    });

    return filtered;
  }, [value, data, search, dateFilter, sortDirection]);

  const paginatedData = useMemo(() => {
    const start = (currentPage - 1) * recordsPerPage;
    return currentTabData.slice(start, start + recordsPerPage);
  }, [currentTabData, currentPage, recordsPerPage]);

  const totalPages = Math.ceil(currentTabData.length / recordsPerPage);

  // Event Handlers
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1);
    setSearch('');
    setDateFilter({ start: '', end: '' });
  };

  const handleSort = () => {
    setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleDateFilterChange = (field) => (event) => {
    setDateFilter(prev => ({ ...prev, [field]: event.target.value }));
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setSearch('');
    setDateFilter({ start: '', end: '' });
    setCurrentPage(1);
  };

  const navigateToDetails = (id) => {
    localStorage.setItem('selectedItemId', id);
    history.push('/FindPodsDetails');
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // Render Functions
  const renderStats = () => (
    <StatsGrid>
      <StatsCard>
        <div className="icon-wrapper" style={{ background: alpha('#00B4D8', 0.1) }}>
          <Badge 
            color="primary" 
            badgeContent={data.privatePods.length} 
            max={999}
            sx={{
              '& .MuiBadge-badge': {
                background: 'linear-gradient(45deg, #00B4D8 30%, #48CAE4 90%)',
                boxShadow: '0 2px 8px rgba(0,180,216,0.3)',
              }
            }}
          >
            <ArticleIcon sx={{ fontSize: 40, color: '#00B4D8' }} />
          </Badge>
        </div>
        <Box>
          <Typography 
            variant="h4" 
            fontWeight="bold"
            sx={{ 
              background: 'linear-gradient(45deg, #00B4D8 30%, #48CAE4 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {data.privatePods.length}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              fontSize: '0.875rem',
              fontWeight: 500,
              color: alpha('#00B4D8', 0.8)
            }}
          >
            Private Pods
          </Typography>
        </Box>
        <Box 
          sx={{ 
            position: 'absolute', 
            right: -20, 
            bottom: -20, 
            opacity: 0.05,
            transform: 'rotate(-15deg)',
          }}
        >
          <ArticleIcon sx={{ fontSize: 120 }} />
        </Box>
      </StatsCard>

      {/* Active Pods Card */}
      <StatsCard>
        <div className="icon-wrapper" style={{ background: alpha('#2E7D32', 0.1) }}>
          <Badge 
            color="success" 
            badgeContent={data.activePods.length} 
            max={999}
            sx={{
              '& .MuiBadge-badge': {
                background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
                boxShadow: '0 2px 8px rgba(46,125,50,0.3)',
              }
            }}
          >
            <GroupIcon sx={{ fontSize: 40, color: '#2E7D32' }} />
          </Badge>
        </div>
        <Box>
          <Typography 
            variant="h4" 
            fontWeight="bold"
            sx={{ 
              background: 'linear-gradient(45deg, #2E7D32 30%, #4CAF50 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {data.activePods.length}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              fontSize: '0.875rem',
              fontWeight: 500,
              color: alpha('#2E7D32', 0.8)
            }}
          >
            Active Pods
          </Typography>
        </Box>
        <Box 
          sx={{ 
            position: 'absolute', 
            right: -20, 
            bottom: -20, 
            opacity: 0.05,
            transform: 'rotate(-15deg)',
          }}
        >
          <GroupIcon sx={{ fontSize: 120 }} />
        </Box>
      </StatsCard>

      {/* Completed Pods Card */}
      <StatsCard>
        <div className="icon-wrapper" style={{ background: alpha('#9C27B0', 0.1) }}>
          <Badge 
            color="secondary" 
            badgeContent={data.completedPods.length} 
            max={999}
            sx={{
              '& .MuiBadge-badge': {
                background: 'linear-gradient(45deg, #9C27B0 30%, #BA68C8 90%)',
                boxShadow: '0 2px 8px rgba(156,39,176,0.3)',
              }
            }}
          >
            <CheckCircleIcon sx={{ fontSize: 40, color: '#9C27B0' }} />
          </Badge>
        </div>
        <Box>
          <Typography 
            variant="h4" 
            fontWeight="bold"
            sx={{ 
              background: 'linear-gradient(45deg, #9C27B0 30%, #BA68C8 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {data.completedPods.length}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              fontSize: '0.875rem',
              fontWeight: 500,
              color: alpha('#9C27B0', 0.8)
            }}
          >
            Completed Pods
          </Typography>
        </Box>
        <Box 
          sx={{ 
            position: 'absolute', 
            right: -20, 
            bottom: -20, 
            opacity: 0.05,
            transform: 'rotate(-15deg)',
          }}
        >
          <CheckCircleIcon sx={{ fontSize: 120 }} />
        </Box>
      </StatsCard>

      {/* Pending Pods Card */}
      <StatsCard>
        <div className="icon-wrapper" style={{ background: alpha('#ED6C02', 0.1) }}>
          <Badge 
            color="warning" 
            badgeContent={data.pendingPods.length} 
            max={999}
            sx={{
              '& .MuiBadge-badge': {
                background: 'linear-gradient(45deg, #ED6C02 30%, #FFA726 90%)',
                boxShadow: '0 2px 8px rgba(237,108,2,0.3)',
              }
            }}
          >
            <PendingIcon sx={{ fontSize: 40, color: '#ED6C02' }} />
          </Badge>
        </div>
        <Box>
          <Typography 
            variant="h4" 
            fontWeight="bold"
            sx={{ 
              background: 'linear-gradient(45deg, #ED6C02 30%, #FFA726 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {data.pendingPods.length}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ 
              fontSize: '0.875rem',
              fontWeight: 500,
              color: alpha('#ED6C02', 0.8)
            }}
          >
            Pending Pods
          </Typography>
        </Box>
        <Box 
          sx={{ 
            position: 'absolute', 
            right: -20, 
            bottom: -20, 
            opacity: 0.05,
            transform: 'rotate(-15deg)',
          }}
        >
          <PendingIcon sx={{ fontSize: 120 }} />
        </Box>
      </StatsCard>
    </StatsGrid>
  );

  const renderFilters = () => (
    <FilterContainer>
      <Box sx={{ display: 'flex', gap: '16px', flexGrow: 1, maxWidth: isMobile ? '100%' : '600px' }}>
        <SearchField
          fullWidth
          size="small"
          placeholder="Search pods..."
          value={search}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />
          }}
        />
      </Box>

      <Box sx={{ 
        display: 'flex', 
        gap: '16px', 
        alignItems: 'center',
        flexWrap: isMobile ? 'wrap' : 'nowrap'
      }}>
        <TextField
          type="date"
          size="small"
          label="Start Date"
          value={dateFilter.start}
          onChange={handleDateFilterChange('start')}
          InputLabelProps={{ shrink: true }}
          sx={{ minWidth: isMobile ? '100%' : '150px' }}
        />
        <TextField
          type="date"
          size="small"
          label="End Date"
          value={dateFilter.end}
          onChange={handleDateFilterChange('end')}
          InputLabelProps={{ shrink: true }}
          sx={{ minWidth: isMobile ? '100%' : '150px' }}
        />
        {(dateFilter.start || dateFilter.end || search) && (
          <Tooltip title="Clear filters">
            <IconButton 
              onClick={clearFilters} 
              size="small"
              sx={{
                backgroundColor: alpha('#ff4444', 0.1),
                color: '#ff4444',
                '&:hover': {
                  backgroundColor: alpha('#ff4444', 0.2),
                }
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </FilterContainer>
  );

  const renderTable = () => {
    if (loading) {
      return (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'center', 
          p: 4,
          gap: 2
        }}>
          <CircularProgress size={40} sx={{ color: '#00B4D8' }} />
          <Typography variant="body2" color="text.secondary">
            Loading pods...
          </Typography>
        </Box>
      );
    }

    if (error) {
      return (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center', 
          justifyContent: 'center', 
          p: 4,
          gap: 2
        }}>
          <ErrorOutlineIcon sx={{ fontSize: 48, color: 'error.main' }} />
          <Typography variant="body1" color="error">
            {error}
          </Typography>
          <Button
            variant="contained"
            startIcon={<RefreshIcon />}
            onClick={() => window.location.reload()}
          >
            Retry
          </Button>
        </Box>
      );
    }

    if (!paginatedData.length) {
      return (
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          gap: 2, 
          p: 4 
        }}>
          <ArticleIcon 
            sx={{ 
              fontSize: 48, 
              color: 'text.secondary',
              animation: 'float 3s ease-in-out infinite',
              '@keyframes float': {
                '0%, 100%': {
                  transform: 'translateY(0)',
                },
                '50%': {
                  transform: 'translateY(-10px)',
                },
              },
            }} 
          />
          <Typography variant="h6" color="text.secondary">
            No pods found
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {search || dateFilter.start || dateFilter.end ? 
              'Try adjusting your filters' : 
              'Create your first pod to get started'}
          </Typography>
          {(search || dateFilter.start || dateFilter.end) && (
            <Button
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={clearFilters}
              sx={{ mt: 1 }}
            >
              Clear Filters
            </Button>
          )}
        </Box>
      );
    }

    return (
      <StyledTableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Pod Name
                  <Tooltip title={`Sort ${sortDirection === 'asc' ? 'descending' : 'ascending'}`}>
                    <IconButton size="small" onClick={handleSort}>
                      <SortIcon 
                        sx={{ 
                          color: '#fff', 
                          fontSize: 18,
                          transform: sortDirection === 'desc' ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s ease'
                        }} 
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </StyledTableCell>
              <StyledTableCell>Type</StyledTableCell>
              <StyledTableCell align="center">Size</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell>Created Date</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((pod) => (
              <StyledTableRow key={pod.id}>
                <StyledTableCell>
                  <Typography variant="subtitle2" fontWeight={500}>
                    {pod.podName}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Chip
                    label={pod.podtype}
                    size="small"
                    sx={{
                      backgroundColor: alpha('#00B4D8', 0.1),
                      color: '#00B4D8',
                      fontWeight: 500,
                      borderRadius: '6px',
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="body2" fontWeight={500}>
                    {pod.podsize}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip 
                    title={pod.podDescription} 
                    arrow
                    placement="top"
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        maxWidth: '200px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {pod.podDescription}
                    </Typography>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body2">
                      {moment(pod.created_at).format('MMM DD, YYYY')}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {moment(pod.created_at).format('hh:mm A')}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <StatusChip
                    label={pod.status || 'Active'}
                    status={pod.status || 'active'}
                    size="small"
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Box className="action-buttons">
                    <Tooltip title="View Details" arrow placement="left">
                      <IconButton
                        size="small"
                        onClick={() => navigateToDetails(pod.id)}
                        sx={{
                          color: '#1DBF73',
                          '&:hover': {
                            backgroundColor: alpha('#1DBF73', 0.1),
                            transform: 'scale(1.1)',
                          },
                        }}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    );
  };

  const renderPagination = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px 24px',
        borderTop: '1px solid rgba(0, 0, 0, 0.08)',
        flexWrap: 'wrap',
        gap: 2
      }}
    >
      <Typography variant="body2" color="text.secondary">
        Showing {paginatedData.length} of {currentTabData.length} pods
      </Typography>
      
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Button
          size="small"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(prev => prev - 1)}
          sx={{
            minWidth: 'unset',
            px: 2,
            '&:not(:disabled)': {
              color: '#00B4D8',
            },
          }}
        >
          Previous
        </Button>
        
        {!isMobile && Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <Button
            key={page}
            size="small"
            variant={currentPage === page ? 'contained' : 'text'}
            onClick={() => setCurrentPage(page)}
            sx={{
              minWidth: '32px',
              height: '32px',
              p: 0,
              borderRadius: '6px',
              ...(currentPage === page && {
                backgroundColor: '#00B4D8',
                '&:hover': {
                  backgroundColor: '#0098b7',
                },
              }),
            }}
          >
            {page}
          </Button>
        ))}
        
        <Button
          size="small"
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(prev => prev + 1)}
          sx={{
            minWidth: 'unset',
            px: 2,
            '&:not(:disabled)': {
              color: '#00B4D8',
            },
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );

  return (
    <ErrorBoundary>
      <AppContainer>
        <Typography variant="h4" sx={{ mb: 3, fontWeight: 600 }}>
          Pod Management
        </Typography>
        
        {renderStats()}
        
        <DashboardCard>
          <StyledTabs value={value} onChange={handleTabChange}>
            <Tab
              icon={<ArticleIcon sx={{ fontSize: 20 }} />}
              iconPosition="start"
              label="Private Pods"
            />
            <Tab
              icon={<GroupIcon sx={{ fontSize: 20 }} />}
              iconPosition="start"
              label="Active Pods"
            />
            <Tab
              icon={<CheckCircleIcon sx={{ fontSize: 20 }} />}
              iconPosition="start"
              label="Completed"
            />
            <Tab
              icon={<PendingIcon sx={{ fontSize: 20 }} />}
              iconPosition="start"
              label="Pending"
            />
          </StyledTabs>

          {renderFilters()}
          
          <Divider />
          
          <Box sx={{ position: 'relative' }}>
            {renderTable()}
            {paginatedData.length > 0 && renderPagination()}
          </Box>
        </DashboardCard>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert 
            onClose={handleSnackbarClose} 
            severity={snackbar.severity}
            sx={{ width: '100%' }}
            elevation={6}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </AppContainer>
    </ErrorBoundary>
  );
};

export default TeamMembers;